import React, { Component } from "react";
import "../../styles/table.css";
import "../../minified-css/material-min.css";
import "../../styles/dropdowns.css";
import "../../styles/theme-style.css";
import "../../styles/classnote.css";
import axios from "axios";
import * as moment from "moment";
class AddStaffDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchAllowed: false,
      salaryDetails: [],
      isFocussed: "",
      onFocus: false,
      onYearFocus: false,
      isYearFocussed: "",
      selectedMonth: "",
      selectedYear: "",
      background: "",
      yearBackground: "",
      selectedDepartment: "",
      selectedIncharge: "",
      selectedType: "",
      userID: "",
      staffDetails: [],
      values: [
        {
          userID: "",
          password: "",
          name: "",
          designation: "",
          qualification: "",
          contact: "",
          email: "",
          emergencyContact: "",
          joiningDate: moment(new Date()).format("YYYY-MM-DD"),
        },
      ],
    };
    this.baseState = this.state;
  }

  componentDidMount() {
    let values = [...this.state.values];
    values.forEach((e) => {
      e.password = this.generatePassword();
    });
    this.setState({ values });
    this.fetchDepartmentDetails();
    if (this.props && this.props.location && this.props.location.userID) {
      const userID = this.props.location.userID;
      this.setState({ userID: userID.userID });
    }
    this.unlisten = this.props.history.listen((location, action) => {
      this.setState(this.baseState);
      this.fetchDepartmentDetails();
    });
  }

  generatePassword() {
    let length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (let i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  onDropDownFocus(event) {
    if (event.target) {
      // event.target.parentNode.classList.add("is-focused");
      // event.target.nextSibling.classList.add("is-shown");
    }
  }

  onFocusOut(event) {
    if (event.target) {
      if (event.target.value === "") {
        // event.target.parentNode.classList.remove("is-focused");
      }
      // event.target.nextSibling.classList.remove("is-shown");
    }
  }

  componentWillUnmount() {
    this.unlisten();
  }

  fillUserID() {
    let values = this.state.values;
    values.forEach((e) => {
      let previous = values[values.indexOf(e) - 1];

      if (!previous) {
        e.userID = "S" + `${Math.floor(1000 + Math.random() * 9000)}`;
      } else {
        e.userID = "S" + (Number(previous.userID.slice(-4)) + 1);
      }

      this.setState({ values });
    });
  }

  /**
   * Adds the empty form element
   */
  addClick() {
    this.setState((prevState) => ({
      values: [
        ...prevState.values,
        {
          userID: "",
          password: this.generatePassword(),
          name: "",
          designation: "",
          qualification: "",
          contact: "",
          email: "",
          emergencyContact: "",
          joiningDate: moment(new Date()).format("YYYY-MM-DD"),
        },
      ],
    }));

    setTimeout(() => {
      this.fillUserID();
    }, 1000);
  }

  /**
   * Removes the selected row
   * @param i selected row index
   */
  removeClick(i) {
    let values = [...this.state.values];
    values.splice(i, 1);
    this.setState({ values });

    setTimeout(() => {
      this.fillUserID();
    }, 400);
  }
  /**
   * Triggers when the form is changed and stores the values in state
   * @param event form values
   */
  handleFormChange(i, event) {
    let values = [...this.state.values];
    const { name, value } = event.target;
    values[i][name] = value;
    this.setState({ values });
  }

  /**
   * Resets to base state
   */
  resetForm() {
    this.setState({
      values: [
        {
          // userID: "",
          password: this.generatePassword(),
          name: "",
          designation: "",
          qualification: "",
          contact: "",
          email: "",
          emergencyContact: "",
          joiningDate: moment(new Date()).format("YYYY-MM-DD"),
        },
      ],
    });
  }

  /**
   * Fetches all department
   */
  fetchDepartmentDetails() {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/departmentdetail`)
      .then((response) => {
        this.setState({ departmentDetails: response.data.data });
      });
  }

  /**
   * Displays the list of department based on the API response
   */
  displayDepartment() {
    if (
      this.state &&
      this.state.departmentDetails &&
      this.state.departmentDetails.length
    ) {
      return this.state.departmentDetails.map((singleDepartment, index) => {
        return (
          <li className="mdl-menu__item animation" key={index}>
            <button
              id={singleDepartment.name}
              name={singleDepartment.name}
              onClick={this.handleDepartmentChange.bind(this)}
            >
              {singleDepartment.name}
            </button>
          </li>
        );
      });
    }
  }

  displayType() {
    if (
      this.state &&
      this.state.departmentDetails &&
      this.state.departmentDetails.length
    ) {
      return this.state.departmentDetails.map((singleDepartment, index) => {
        return (
          <li className="mdl-menu__item animation" key={index}>
            <button
              id={singleDepartment.name}
              name={singleDepartment.name}
              onClick={this.handleDepartmentChange.bind(this)}
            >
              {singleDepartment.name}
            </button>
          </li>
        );
      });
    }
  }

  /**
   * Triggers when department dropdown is focused
   */
  onDeptDropDownFocus() {
    this.setState({
      isDepartmentFocussed: "is-focused",
      onDepartmentFocus: true,
      backgroundDepartment: "is-shown",
    });
  }

  onTypeDropDownFocus() {
    this.setState({
      isDepartmentFocussed: "is-focused",
      onDepartmentFocus: true,
      backgroundDepartment: "is-shown",
    });
  }

  /**
   * Triggers when the department is changed and stores the values in state
   * @param event form values
   */
  handleDepartmentChange(event) {
    this.setState({
      selectedDepartment: event.target.id,
      onDepartmentFocus: false,
      backgroundDepartment: "is-hidden",
      background: "is-hidden",
      hasDepartmentValue: true,
    });
    setTimeout(() => {
      this.fillUserID();
    }, 300);
    this.resetForm();
  }

  handleTypeChange(event) {
    this.setState({
      selectedType: event.target.id,
      onDepartmentFocus: false,
      backgroundDepartment: "is-hidden",
      background: "is-hidden",
      hasDepartmentValue: true,
    });
    this.resetForm();
  }

  /**
   * Triggers when the form is submitted
   * Checks whether the values are entered properly
   */
  formSubmit() {
    if (this.state.values && this.state.values.length > 0) {
      this.state.values.forEach((element) => {
        if (
          element.userID &&
          element.password &&
          element.name &&
          element.designation &&
          element.qualification &&
          element.joiningDate &&
          element.contact &&
          element.emergencyContact &&
          element.email
        ) {
          const params = {
            userID: element.userID.toUpperCase(),
            password: element.password,
            userRole: "staff",
            updatedBy: element.userID.toUpperCase(),
            updatedDate: new Date(Date.now()).toLocaleString(),
            collegeCode: "",
            collegeName: "",
            registerNr: "",
          };
          axios
            .post(`${process.env.PUBLIC_URL}/xakal/adduser`, params)
            .then((res) => {
              if (res.data.ok) {
                this.insertStaffDetails();
              } else {
                alert("Something Went Wrong!");
              }
            })
            .catch((err) => console.log(err));
        } else {
          alert("Please give all the details");
        }
      });
    } else {
      alert("Please give atleast one record to proceed");
    }
  }

  insertStaffDetails() {
    let isUpdated = false;
    this.state.values.forEach((element) => {
      if (
        element.name &&
        element.designation &&
        element.qualification &&
        element.joiningDate &&
        element.contact &&
        element.emergencyContact &&
        element.email
      ) {
        const params = {
          userID: element.userID.toUpperCase(),
          name: element.name,
          qualification: element.qualification,
          uploadedBy: this.state.userID.toUpperCase(),
          uploadedDate: new Date(Date.now()).toLocaleString(),
          designation: element.designation,
          email: element.email,
          bloodGroup: "NA",
          contact: element.contact,
          emergencyContact: element.emergencyContact,
          parentSpouse: "NA",
          joiningDate: element.joiningDate,
          departmentName: this.state.selectedDepartment,
          typeName: this.state.selectedType,
        };
        axios
          .post(`${process.env.PUBLIC_URL}/xakal/staffdetail`, params)
          .then(() => {
            if (!isUpdated) {
              alert("Updated Successfully");
            }
            isUpdated = true;
          })
          .catch((err) => console.log(err));
      } else {
        alert("Please give all the details");
      }
    });
    this.resetForm();
  }

  render() {
    return (
      <div className="container-fluid background bk">
        <div className="d-sm-flex  mb-4">
          <nav className="navbar navbar-expand-lg  navprac bar nav-max-th">
            <div className="title">Staffs</div>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <form className="form-inline my-2 my-lg-0">
                <input
                  style={{
                    width: "500px",
                    marginLeft: "120px",
                    backgroundColor: "rgba(54, 42, 65, 0.04)",
                    fontStyle: "italic",
                    height: "45px",
                  }}
                  className="form-control mr-sm-2 "
                  type="search"
                  placeholder="Search....."
                  aria-label="Search"
                ></input>
              </form>
            </div>
          </nav>
          <div className="row">
            <h4
              className="head resp staff-max-head"
              style={{ marginLeft: "-1000px", marginTop: "150px" }}
            >
              {" "}
              Add Staff Details
            </h4>

            {/* <h1 className="h3 mb-0 text-gray-800 m-t-20">Notes</h1> */}
          </div>
          {/* <h1 className="h3 mb-0 text-gray-800 m-t-20">Add Staff Details</h1> */}
        </div>
        <div className="row m-r-20">
          <div className="col-sm-12">
            <div>
              <div className=" row">
                <div className="col-sm-8 p-t-20 m-t-1">
                  <div
                    className={
                      "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width " +
                      this.state.isDepartmentFocussed
                    }
                  >
                    <label
                      style={{ marginLeft: "56%", marginTop: "-100px" }}
                      className="input-text labell resp"
                    >
                      Department
                    </label>
                    <input
                      name="selectedDepartment"
                      onKeyPress={(e) => e.preventDefault()}
                      autoComplete="off"
                      onFocus={this.onDeptDropDownFocus.bind(this)}
                      className="new-xakal-input background height resp"
                      type="text"
                      id={`department`}
                      style={{ height: "40px", marginLeft: "150px" }}
                      value={this.state.selectedDepartment}
                      onChange={this.handleDepartmentChange.bind(this)}
                    />

                    {this.state.onDepartmentFocus ? (
                      <div
                        className="mdl-menu__container is-upgraded dropdown-list is-visible"
                        style={{
                          width: "200px",
                          marginTop: "20px",
                          marginLeft: "170px",
                        }}
                      >
                        <div
                          className="mdl-menu__outline  dropdown-div"
                          style={{ width: "200px" }}
                        >
                          <ul
                            className="scrollable-menu ul-list"
                            style={{ width: "100%" }}
                          >
                            {this.displayDepartment()}
                          </ul>
                        </div>
                      </div>
                    ) : (
                      <p></p>
                    )}
                  </div>
                </div>
              </div>
              {this.state.selectedDepartment !== "" ? (
                <div>
                  {this.state.values.map((el, i) => (
                    <div className="card-body row" key={i}>
                   <div className="col-lg-3 p-t-20">
                            <div style={{ marginLeft: '35px' }}
                              className={
                                "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "

                              }
                            >
                              <label className="input-text labell">
                                User ID
                              </label>
                              <input
                                autoComplete="off"
                                onBlur={this.onFocusOut.bind(this)}
                                onFocus={this.onDropDownFocus.bind(this)}
                                className="new-xakal-input background height"
                                type="text"
                                readOnly
                                id={`name${i}`}
                                value={el.userID}
                                style={{marginLeft:'-10px'}}
                                onChange={this.handleFormChange.bind(this, i)}
                                name="userID"
                              />

                            </div>
                          </div>
                          <div className="col-lg-3 p-t-20">
                            <div
                              className={
                                "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                              }
                            >
                              <label style={{ marginLeft: '-27px' }} className="input-text labell">
                                Password
                              </label>
                              <input
                                autoComplete="off"
                                onBlur={this.onFocusOut.bind(this)}
                                onFocus={this.onDropDownFocus.bind(this)}
                                className="new-xakal-input background height"
                                readOnly
                                id={`password${i}`}
                                value={el.password}
                                onChange={this.handleFormChange.bind(this, i)}
                                name="password"
                                style={{ marginLeft: '-45px' }}

                              />

                            </div>
                          </div>
                      <div
                        class="col-md-12 col-sm-12"
                        style={{ marginLeft: "30px" }}
                      >
                        <div class="row">
                          <div className="col-lg-3 p-t-20">
                            <div
                              className={
                                "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                              }
                            >
                              <label className="input-text">Name</label>
                              <input
                                value={el.name}
                                autoComplete="off"
                                onBlur={this.onFocusOut.bind(this)}
                                onFocus={this.onDropDownFocus.bind(this)}
                                className="new-xakal-input"
                                type="text"
                                id="name"
                                style={{ height: "40px" }}
                                onChange={this.handleFormChange.bind(this, i)}
                                name="name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 p-t-20">
                            <div
                              className={
                                "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                              }
                            >
                              <label className="input-text">Designation</label>
                              <input
                                value={el.designation}
                                autoComplete="off"
                                onBlur={this.onFocusOut.bind(this)}
                                onFocus={this.onDropDownFocus.bind(this)}
                                className="new-xakal-input"
                                style={{ height: "40px" }}
                                type="text"
                                id="designation"
                                onChange={this.handleFormChange.bind(this, i)}
                                name="designation"
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 p-t-20">
                            <div
                              className={
                                "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                              }
                            >
                              <label className="input-text">
                                Qualification
                              </label>
                              <input
                                value={el.qualification}
                                autoComplete="off"
                                onBlur={this.onFocusOut.bind(this)}
                                onFocus={this.onDropDownFocus.bind(this)}
                                className="new-xakal-input"
                                type="text"
                                id="qualification"
                                onChange={this.handleFormChange.bind(this, i)}
                                name="qualification"
                                style={{ height: "40px" }}
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 p-t-20">
                            <div
                              className={
                                "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                              }
                            >
                              <label className="input-text">Mobile</label>
                              <input
                                value={el.contact}
                                autoComplete="off"
                                onBlur={this.onFocusOut.bind(this)}
                                onFocus={this.onDropDownFocus.bind(this)}
                                className="new-xakal-input"
                                type="number"
                                id="contact"
                                onChange={this.handleFormChange.bind(this, i)}
                                name="contact"
                                style={{ height: "40px" }}
                              />
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div className="col-lg-3 p-t-20">
                            <div
                              className={
                                "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                              }
                            >
                              <label className="input-text">Email</label>
                              <input
                                style={{ height: "40px" }}
                                value={el.email}
                                autoComplete="off"
                                onBlur={this.onFocusOut.bind(this)}
                                onFocus={this.onDropDownFocus.bind(this)}
                                className="new-xakal-input"
                                type="email"
                                id="email"
                                onChange={this.handleFormChange.bind(this, i)}
                                name="email"
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 p-t-20">
                            <div
                              className={
                                "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                              }
                            >
                              <label className="input-text">
                                Emergency contact
                              </label>
                              <input
                                value={el.emergencyContact}
                                style={{ height: "40px" }}
                                autoComplete="off"
                                onBlur={this.onFocusOut.bind(this)}
                                onFocus={this.onDropDownFocus.bind(this)}
                                className="new-xakal-input"
                                type="text"
                                id="emergencyContact"
                                onChange={this.handleFormChange.bind(this, i)}
                                name="emergencyContact"
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 p-t-20">
                            <div
                              className={
                                "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width is-focused"
                              }
                            >
                              <label
                                htmlFor="joiningDate"
                                className="input-text"
                              >
                                Joining date
                              </label>
                              <input
                                value={
                                  el.joiningDate ||
                                  moment(new Date()).format("YYYY-MM-DD")
                                }
                                autoComplete="off"
                                style={{ height: "40px" }}
                                placeholder=""
                                onBlur={this.onFocusOut.bind(this)}
                                onFocus={this.onDropDownFocus.bind(this)}
                                className="new-xakal-input"
                                type="date"
                                id="joiningDate"
                                onChange={this.handleFormChange.bind(this, i)}
                                name="joiningDate"
                              />
                            </div>
                          </div>
                          <div className="col-sm-3 p-t-20 p-l-20">
                            {this.state.values.length !== 1 && (
                              <div className="row">
                                <button
                                  type="button"
                                  onClick={this.removeClick.bind(this, i)}
                                  className=" col-sm-2 btn btn-primary m-t-15"
                                  style={{
                                    marginTop: "3.6rem",
                                    backgroundColor: "#362A41",
                                  }}
                                >
                                  X
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p></p>
              )}

              {/* {this.state.selectedDepartment !== "" ? (
                <div className="card-body row">
                  <div className="col-sm-8 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width " +
                        this.state.isDepartmentFocussed
                      }
                    >
                       <label
                        className="text-input"
                        
                      >
                        Select Type
                      </label>
                      <input
                        name="selectedType"
                        onKeyPress={(e) => e.preventDefault()}
                        autoComplete="off"
                        onFocus={this.onTypeDropDownFocus.bind(this)}
                        className="new-xakal-input"
                        type="text"
                        id={`department`}
                        value={this.state.selectedType}
                        onChange={this.handleTypeChange.bind(this)}
                        style={{height:'40px'}}
                      />
                     
                      {this.state.onDepartmentFocus ? (
                        <div className="mdl-menu__container is-upgraded dropdown-list is-visible">
                          <div className="mdl-menu__outline mdl-menu--bottom-left dropdown-div">
                            <ul className="scrollable-menu mdl-menu mdl-menu--bottom-left mdl-js-menu ul-list">
                              {this.displayType()}
                            </ul>
                          </div>
                        </div>
                      ) : (
                        <p></p>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <p></p>
              )} */}

              {this.state.selectedDepartment !== "" ? (
                <div className="card-body row  background">
                  <div className="col-sm-8 p-t-20">
                    {/* <button
                      type="button"
                      onClick={this.state.selectedIncharge}
                      className=" 
                       m-t-15"
                       style={{backgroundColor:"#FCFBFF",color:"#362A41",padding:"0.5rem",border:"2px solid #362A41"}}
                    >
                      Add Incharge of
                    </button> */}
                    <button
                      type="button"
                      onClick={this.addClick.bind(this)}
                      className=" m-t-15 shadow m-l-30"
                      style={{
                        backgroundColor: "#362A41",
                        color: "#fff",
                        padding: "0.5rem 1rem",
                      }}
                    >
                      Add
                    </button>
                    <button
                      type="button"
                      onClick={this.formSubmit.bind(this)}
                      className="shadow m-t-15 m-l-30"
                      style={{
                        backgroundColor: "#362A41",
                        color: "#fff",
                        padding: "0.5rem 1rem",
                      }}
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      onClick={this.resetForm.bind(this)}
                      className="shadow m-t-15 m-l-30"
                      style={{
                        backgroundColor: "#FCFBFF",
                        color: "#362A41",
                        padding: "0.5rem 1rem",
                        border: "2px solid #362A41",
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              ) : (
                <p></p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddStaffDetails;
