import React, { Component } from "react";
import axios from "axios";
// import moment from "moment";
import "../../../styles/table.css";
import "../../../minified-css/material-min.css";
import "../../../styles/dropdowns.css";
import "../../../styles/theme-style.css";

class AddBusRoutes extends Component {
    constructor(props) {
        super(props)
        this.state = {
            availableBusStops: [],
            busTerminals: this.props.busStopsCount || [1],
            selectedBusStops: this.props.selectedBusStops || []
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.getAllBusStops();
        if (this.state.selectedBusStops) {
            this.disableSelected(this.state.selectedBusStops)
        }
    }

    getAllBusStops() {
        const adminId = '5dd6ed68c0052a052cbdb09c';
        axios.get(`/xakal/transport/admin/getAllBusStops/${adminId}`)
            .then((response) => {
                if (response.status !== 200) {
                    return alert(response.data.msg)
                }
                this.setDropDown(response.data.data);
                this.initBusTerminal();
            })
            .catch(e => alert(e))
    }

    setDropDown(busStops) {
        let busStopArr = busStops.map(b => ({ _id: b._id, name: b.name, isDisabled: false }));
        this.setState({ availableBusStops: busStopArr });
    }

    initBusTerminal() {
        if (this.state.selectedBusStops.length > 0) {
            this.disableSelected(this.state.selectedBusStops)
            const busTerminals = this.state.selectedBusStops.map((b, i) => ({ 
                _id: b._id, 
                index: i+1,  
                name: b.name, 
                isDisabled: true,
                isDefault: true 
            }));
            console.log('initial busStop', busTerminals)
            this.setState({
                busTerminals
            })
        }
    }

    handleSelectBusRoute(e) {
        console.log('selectedBusStops', e.target.id)
        const busStopData = this.state.availableBusStops.find((item, i) => item.name === e.target.value);
        const busStop = {
            _id: busStopData._id,
            index: e.target.id,
            name: busStopData.name,
            selected: true
        }
        const selectedBusStops = this.state.selectedBusStops;
        const prevBusStopIndex = selectedBusStops.findIndex(item => item.index === e.target.id);

        //user trying to modify previously selected bus stop
        if (prevBusStopIndex !== -1) {
            selectedBusStops.splice(prevBusStopIndex, 1, busStop)
            this.setState({
                selectedBusStops
            })
            this.disableSelected(selectedBusStops);
            return;
        }
        this.disableSelected([...selectedBusStops, busStop]);
        this.setState({
            selectedBusStops: [...selectedBusStops, busStop]
        })
    }

    disableSelected(selectedBusStops) {
        const availableBusStops = this.state.availableBusStops;
        const selectedBusStopIds = selectedBusStops.map(e => e._id);
        let modifiedBusStops = [];
        console.log('selected', selectedBusStopIds)
        //disable
        let busStopsToBeDisabled = availableBusStops.filter(stop => selectedBusStopIds.includes(stop._id)).map(e => e._id);
        if (busStopsToBeDisabled.length) {
            console.log('busStopsToBeDisabled', busStopsToBeDisabled);
            for (const item of availableBusStops) {
                if (busStopsToBeDisabled.includes(item._id)) {
                    item.isDisabled = true;
                } else {
                    item.isDisabled = false;
                }
                modifiedBusStops.push(item)
            }
        }
        console.log(modifiedBusStops)
        this.setState({ availableBusStops: modifiedBusStops })
    }

    handleAddBusRoute() {
        this.setBusTerminal();
    }

    setBusTerminal() {
        const busTerminals = [...this.state.busTerminals, { index: this.state.busTerminals.length + 1 }];
        this.setState({
            busTerminals
        })
    }

    handleSaveBusRoute() {
        if (!this.props.updateBus) {
            window.scrollTo(0, document.body.scrollHeight);
        }
        this.props.handleSaveBusRoutes(false, this.state.selectedBusStops, this.state.busTerminals)
    }

    render() {
        return (
            <div className="addBusRoutes" style={{ left: '25vw', width: '50vw', border: '1px solid #74a5f2', backgroundColor: '#fff', color: 'black', padding: 20 }}>
                <div style={{ marginLeft: 100, marginTop: 0, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <h2>Add Bus Routes</h2>
                </div>
                <div className="d-sm-flex align-items-center justify-content-between mb-4" style={{ paddingTop: 10 }}>
                    <div class="row" style={{ marginLeft: 8, justifyContent: 'center' }}>
                        <select disabled style={{ margin: 10 }}>
                            <option>College</option>
                        </select>
                        {this.state.busTerminals.map(e => (
                            <select id={e.index} onChangeCapture={this.handleSelectBusRoute.bind(this)} style={{ margin: 10 }}>
                                <option>select bus stop</option>
                                {
                                    this.state.availableBusStops.length &&
                                    this.state.availableBusStops.map(item => 
                                    (e._id === item._id) 
                                        ?
                                        <option selected={true} style={{ backgroundColor: item.isDisabled ? 'grey' : 'white' }} id={item._id}>{item.name}</option>
                                        :
                                        <option style={{ backgroundColor: item.isDisabled ? 'grey' : 'white' }} id={item._id}>{item.name}</option>
                                    )
                                }
                            </select>
                        ))}
                    </div>
                </div>
                <div class="row" style={{ marginLeft: 8, justifyContent: 'center' }}>
                    <button
                        type="button"
                        className="btn btn-danger m-t-15 m-l-30"
                        onClick={this.handleAddBusRoute.bind(this)}
                    >
                        Add Route
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary m-t-15 m-l-30"
                        onClick={this.handleSaveBusRoute.bind(this)}
                    >
                        Save
                    </button>
                </div>
            </div>
        )
    }

}

export default AddBusRoutes;