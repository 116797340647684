import React, { Component } from 'react';
import InternalDetails from './internal-details';
import SemesterDetails from './semester-details';
import '../../../styles/classnote.css';
import '../../../styles/dropdown.css';
import '../../../styles/course-dropdown.css';
 class StudentAssessment extends Component {
  constructor(props) {
    super(props);
    this.state = {
        onType: false,
        onSelected: '',
        background: '',
        isFocussed: '',
        onFocus: false,
        searchAllowed: false,
    };
  }

  componentDidMount() {
    if (this.props && this.props.location && this.props.location.userID) {
      const userID = this.props.location.userID;
      this.setState({ userID: userID.userID });
    }
    this.unlisten = this.props.history.listen((location, action) => {
      this.setState(this.baseState);
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  onDropDownFocus() {
    this.setState({ isFocussed: 'is-focused', onFocus: true, background: 'is-shown' });
  }

  onSelect(event) {
    this.setState({ onSelected: event.target.id, onFocus: false, background: 'is-hidden' });
  }

  getResult() {
    if (this.state.selected !== '') {
      this.setState({ searchAllowed: true })
      if (this.state.onSelected === "Internal") this.setState({ onType: true })
      if (this.state.onSelected === "Semester") this.setState({ onType: false })
    } else {
      alert('Please select the values');
      this.setState({ searchAllowed: false })
    }
  }

  render() {
    return (
      <div className="container-fluid background bk">
          <div className="d-sm-flex  mb-4">
        </div>
        <nav className="navbar navbar-expand-lg  navprac bar assres " style={{ width: '1050px', marginTop: '20px' }} >
          <div className="title">Assesments</div>
          <form className="form-inline my-2 my-lg-0"> 
      <input  style={{width:'500px',marginLeft:'120px',backgroundColor:'rgba(54, 42, 65, 0.04)',height:'45px',fontStyle:'italic'}}className="form-control formres mr-sm-2 " type="search" placeholder="Search....." aria-label="Search"></input>
      {/* <i class="fal fa-search"></i> */}
    </form>
      </nav>
      <div className="row ">
      <div className="col-lg-4">
          <div
            className={
              // "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width " +
              this.state.isFocussed
            }
          >
      
            <label
              className="input-text head resp"
              style={{ marginLeft: '270px', marginTop: '5%' }}

            >
              Type:
            </label>
            <input
              onKeyPress={(e) => e.preventDefault()}
              autoComplete="off"
              onFocus={this.onDropDownFocus.bind(this)}
              className="new-xakal-input box background  max-resp"
              type="text"
              value={this.state.onSelected}
              style={{marginLeft: '270px', width: '368px', marginTop: '0%' }}
            />

            {this.state.onFocus ? (
              <div className="mdl-menu__container is-upgraded dropdown-list is-visible intres" style={{ width: '628px', marginLeft: '-95px' }} >
                <div className="mdl-menu__outline  dropdown-div intres" style={{ width: '368px', marginLeft: '60%', marginTop: '6%',height:'100px' }} >
                  <ul className="scrollable-menu  ul-list" style={{ width: '368%' }} >
                    <li
                      className="mdl-menu__item animation label-weight"
                      id="Internal" style={{ width: '100%' }}
                      onClick={this.onSelect.bind(this)}
                    >
                      Internal
                    </li>
                    <li
                      className="mdl-menu__item animation1 label-weight"
                      id="Semester"
                      onClick={this.onSelect.bind(this)}
                    >
                      Semester
                    </li>
                  </ul>
                </div>
              </div>
              
            ) : (
              <p></p>
            )}
            </div>
            </div>
            <div className="col-lg-4 m-t-0">
              <button
                type="button"
                onClick={this.getResult.bind(this)}
              className="getresult heading-weight button-size r-max"
                style={{ marginTop: '11%', width: '150px', marginLeft: '260px' }}

              >
                Go
              </button>
            </div>
        </div>
      { this.state.searchAllowed ? (
        <div>
          { this.state.onType ? (
            <InternalDetails/>
          ):(
            <SemesterDetails/>
          )}
        </div>
      ): (
        <p></p>
      )}
      </div>
     
    )
  }
}

export default StudentAssessment
