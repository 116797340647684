import React, { Component } from 'react';
import "../../../styles/classroom.css";
import SubjectNameList from './SubjectNameList'
import SubjectCodeList from './SubjectCodeList'
import StaffNameList from './StaffNameList'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as classListHelper from "../helpers/classListHelper";
import {
    faPencilAlt
} from "@fortawesome/free-solid-svg-icons";
import EditStaffName from './EditStaffName';
import { editClass, deleteClasses } from '../../../api/classroom';

class ClassList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            editClass: true,
            currentEditingStaffList: [],
            currentEditingClass: {},
            editFormData: {},
            activeButtons: [],
            classesToBeDeleted: [],
            isDeleteClass: false
        }
    }

    showEditIcon(id) {
        if (document.getElementById(id)) document.getElementById(id).style.visibility = 'visible';
    }

    hideEditIcon(id) {
        if (document.getElementById(id)) document.getElementById(id).style.visibility = 'hidden';
    }

    showEditTemplate(btn1, btn2, classIndex, currentClass) {
        if (!document.getElementById(btn1) || !document.getElementById(btn1)) return;
        document.getElementById(btn1).style.display = 'block';
        document.getElementById(btn2).style.display = 'block';
        classListHelper.getEditTemplate.bind(this)(currentClass)
        const classrooms = this.props.state.classrooms;
        classrooms.splice(classIndex, 1, { ...classrooms[classIndex], edit: true })
        this.props.setClassroomState(classrooms)
        this.setState({ activeButtons: [btn1, btn2] })
    }

    hideEditTemplate(e, btn1, btn2, classIndex) {
        e.stopPropagation();
        document.getElementById(btn1).style.display = 'none';
        document.getElementById(btn2).style.display = 'none';
        const classrooms = this.props.state.classrooms;
        classrooms.splice(classIndex, 1, { ...classrooms[classIndex], edit: false })
        this.props.setClassroomState(classrooms)
        this.setState({ activeButtons: [] })
    }

    editStaff(e, sub, index) {
        let selectedStaff = e.target.value,
            selectedSubject = sub.subject._id,
            selectedClass = this.state.currentEditingClass;

        let subjects = selectedClass.subjects.map(e => ({
            subject: e.subject._id,
            staff: e.staff._id
        }))

        let subIndex = subjects.findIndex(e => e.subject == selectedSubject)
        if (subIndex !== -1) {
            subjects.splice(subIndex, 1, {
                subject: selectedSubject,
                staff: selectedStaff
            })
        }
        this.setState({ editFormData: { subjects } })
    }

    async editClassSubmit(e) {
        e.preventDefault();
        let classId = this.state.currentEditingClass._id;
        let userId = this.props.state.userDetails._id;
        let data = this.state.editFormData;
        const res = await editClass(classId, userId, data)
        this.updateClass.bind(this)(res.data)
    }

    updateClass(updatedClass) {
        let classrooms = this.props.state.classrooms;
        if (!classrooms.length) return;
        let classIndex = classrooms.findIndex(c => c._id == updatedClass._id);
        if (classIndex !== -1) classrooms.splice(classIndex, 1, { ...updatedClass, edit: false })
        this.props.setClassroomState(classrooms)
        this.hideActiveDeleteFormButtons();
    }

    toggleClassDelete(e, isCancel) {
        if (isCancel) return this.showDeleteBtn();
        this.showDeleteSubmitBtn();
    }


    showDeleteSubmitBtn() {
        this.changeCss('class-delete-btn', 'display', 'none')
        this.changeCss('class-delete-submit-btn', 'display', 'block')
        this.changeCss('class-delete-cancel-btn', 'display', 'block')
        this.setState({ isDeleteClass: true })
    }

    showDeleteBtn() {
        this.changeCss('class-delete-btn', 'display', 'block')
        this.changeCss('class-delete-submit-btn', 'display', 'none')
        this.changeCss('class-delete-cancel-btn', 'display', 'none')
        this.setState({ isDeleteClass: false })
    }

    changeCss(id, attr, value) {
        if (!document.getElementById(id)) return;
        document.getElementById(id).style[attr] = value;
    }

    addToClassToBeDeleted(classId) {
        let classesToBeDeleted = this.state.classesToBeDeleted;
        classesToBeDeleted.push(classId)
        this.setState({ classesToBeDeleted })
    }

    removeFromClassToBeDeleted(classId) {
        let classesToBeDeleted = this.state.classesToBeDeleted;
        let index = classesToBeDeleted.findIndex(id => id == classId);
        if(index !== -1) classesToBeDeleted.splice(index, 1)
        this.setState({ classesToBeDeleted })
    }

    deleteClass(e, classId) {
        if(e.target.checked) return this.addToClassToBeDeleted(classId);
        this.removeFromClassToBeDeleted(classId);
    }

    hideActiveDeleteFormButtons() {
        if (this.state.activeButtons.length) {
            this.state.activeButtons.forEach(btnId => {
                document.getElementById(btnId).style.display = 'none';
            })
        }
    }

    deleteClassSubmit(e) {
        this.hideActiveDeleteFormButtons();
        let classIds = this.state.classesToBeDeleted;
        if (!classIds.length) return alert('Select Class To Be deleted!');
        deleteClasses(this.props.state.userDetails._id, classIds)
        this.removeClassesFromState(classIds)
    }

    removeClassesFromState(classIds) {
        let classrooms = this.props.state.classrooms;
        if(!classrooms.length) return classrooms;
        classrooms = classrooms.filter(classroom => !classIds.includes(classroom._id))
        this.props.setClassroomState(classrooms)
        this.setState({ isDeleteClass: false })
    }

    render() {
        return (
            <div>
                <h1 style={{marginLeft:'30px'}}>Class List</h1>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                        
                        marginRight: '100px'
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            marginRight: '30px',
                        }}
                    >
                        <button
                            onClick={(e) => this.toggleClassDelete(e, false)}
                            id={'class-delete-btn'}
                            className='edit-class-btn'
                            class="btn btn-light"
                            style={{ display: 'flex', margin: '10px 10px 10px 30px',height:'50px',textAlign:'center',padding:'15px 10px', color:'white', background:'#362A41',boxShadow: '0px 16px 32px rgba(54, 42, 65, 0.32)'}}
                            onMouseOver={(e) => {
                                e.target.style.backgroundColor = '#f00'
                                e.target.style.color = '#fff'
                                e.target.style.border = '1px solid #1a3f48'
                            }}
                            onMouseOut={(e) => {
                                e.target.style.backgroundColor = '#ccc'
                                e.target.style.color = '#000'
                                e.target.style.border = '1px solid #1a3f48'
                            }}
                        >
                            Delete
                        </button>
                        <button
                            id={'class-delete-submit-btn'}
                            className='edit-class-btn'
                            style={{
                                display: 'none',
                                width: '60px',
                                height: '30px',
                                alignSelf: 'flex-end',
                                backgroundColor: '#ccc',
                                color: '#000',
                                border: '1px solid #ccc'
                            }}
                            onMouseOver={(e) => {
                                e.target.style.backgroundColor = '#fff'
                                e.target.style.color = 'green'
                                e.target.style.border = '1px solid green'
                            }}
                            onMouseOut={(e) => {
                                e.target.style.backgroundColor = '#ccc'
                                e.target.style.color = '#000'
                                e.target.style.border = '1px solid #ccc'
                            }}
                            onClick={this.deleteClassSubmit.bind(this)}
                        >
                            Submit
                        </button>
                        <button
                            onClick={(e) => this.toggleClassDelete(e, true)}
                            id={'class-delete-cancel-btn'}
                            className='edit-class-btn'
                            style={{
                                display: 'none',
                                width: '60px',
                                height: '30px',
                                alignSelf: 'flex-end',
                                backgroundColor: '#ccc',
                                color: '#000',
                                border: '1px solid #ccc'
                            }}
                            onMouseOver={(e) => {
                                e.target.style.backgroundColor = '#fff'
                                e.target.style.color = 'red'
                                e.target.style.border = '1px solid red'
                            }}
                            onMouseOut={(e) => {
                                e.target.style.backgroundColor = '#ccc'
                                e.target.style.color = '#000'
                                e.target.style.border = '1px solid #ccc'
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <div className="limiter" style={{ marginTop: '50px' }}>
                            <div className="container-table100">
                                <div className="wrap-table100">
                                    <div className="table100 ver5 m-b-110 table table-responsive" style={{boxShadow: '0px 16px 32px rgba(54, 42, 65, 0.32)'}}>
                                        <table style={{marginLeft:'0px'}}>
                                            <thead>
                                                <tr>
                                                    {
                                                        this.state.isDeleteClass
                                                            ?
                                                            <th>

                                                            </th>
                                                            :
                                                            null
                                                    }
                                                    <th>
                                                        S.No
                                                    </th>
                                                    <th>
                                                        Class Name
                                                    </th>
                                                    <th>
                                                        Department
                                                    </th>
                                                    <th>
                                                        Year
                                                    </th>
                                                    <th>
                                                        Subject Name
                                                    </th>
                                                    <th>
                                                        Subject Code
                                                    </th>
                                                    <th>
                                                        Staffs
                                                    </th>
                                                    <th>
                                                        Students
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.props.state.classrooms.length
                                                        ?
                                                        this.props.state.classrooms.map((currentClass, index) => (
                                                            <tr
                                                                onMouseOver={() => this.showEditIcon(`${currentClass._id}-icon-${index}`)}
                                                                onMouseOut={() => this.hideEditIcon(`${currentClass._id}-icon-${index}`)}
                                                            >
                                                                {
                                                                    this.state.isDeleteClass
                                                                        ?
                                                                        <td>
                                                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                                <div>
                                                                                    <input
                                                                                        onChangeCapture={(e) => this.deleteClass(e, currentClass._id)}
                                                                                        type="checkbox"
                                                                                        style={{
                                                                                            width: '25px',
                                                                                            height: '25px'
                                                                                        }}
                                                                                        defaultChecked={false}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        :
                                                                        null
                                                                }
                                                                <td>{index + 1}</td>
                                                                <td>{currentClass.name}</td>
                                                                <td>{currentClass.department}</td>
                                                                <td>{currentClass.year}</td>
                                                                <td>
                                                                    <SubjectNameList
                                                                        subjects={currentClass.subjects}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <SubjectCodeList
                                                                        subjects={currentClass.subjects}
                                                                    />
                                                                </td>
                                                                <td
                                                                    id={`${currentClass._id}-staff-cell-${index}`}
                                                                    style={{ display: 'flex', flexDirection: 'column' }}
                                                                    onClick={() => this.showEditTemplate(`${currentClass._id}-submit-btn-${index}`, `${currentClass._id}-cancel-btn-${index}`, index, currentClass)}
                                                                    onMouseOver={(e) => e.target.style.cursor = 'pointer'}
                                                                    onMouseOut={(e) => e.target.style.cursor = 'default'}
                                                                >
                                                                    <div
                                                                        id={`${currentClass._id}-icon-${index}`}
                                                                        style={{ alignSelf: 'flex-end', marginBottom: '10px', visibility: 'hidden', position: 'absolute', right: '20px', zIndex: 1 }}
                                                                    >
                                                                        <FontAwesomeIcon
                                                                            className="fa-sm"
                                                                            icon={faPencilAlt}
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        {
                                                                            currentClass.edit
                                                                                ?
                                                                                <EditStaffName
                                                                                    currentEditingClass={this.state.currentEditingClass}
                                                                                    subjects={this.state.currentEditingStaffList}
                                                                                    editStaff={this.editStaff.bind(this)}
                                                                                />
                                                                                :
                                                                                <StaffNameList
                                                                                    subjects={currentClass.subjects}
                                                                                />
                                                                        }
                                                                    </div>
                                                                    <div style={{ display: 'inline-flex' }}>
                                                                        <button
                                                                            id={`${currentClass._id}-submit-btn-${index}`}
                                                                            type='submit'
                                                                            className='edit-class-btn'
                                                                            style={{ backgroundColor: '#1a3f48', display: 'none', color: '#fff', border: '1px solid #1a3f48' }}
                                                                            onMouseOver={(e) => {
                                                                                e.target.style.backgroundColor = '#fff'
                                                                                e.target.style.color = '#000'
                                                                                e.target.style.border = '1px solid #1a3f48'
                                                                            }}
                                                                            onMouseOut={(e) => {
                                                                                e.target.style.backgroundColor = '#1a3f48'
                                                                                e.target.style.color = '#fff'
                                                                                e.target.style.border = '1px solid #1a3f48'
                                                                            }}
                                                                            onClick={
                                                                                (e) => this.editClassSubmit(e)
                                                                            }
                                                                        >
                                                                            submit
                                                                        </button>
                                                                        <button
                                                                            id={`${currentClass._id}-cancel-btn-${index}`}
                                                                            className='edit-class-btn'
                                                                            onClick={(e) => this.hideEditTemplate(e, `${currentClass._id}-submit-btn-${index}`, `${currentClass._id}-cancel-btn-${index}`, index)}
                                                                            style={{ backgroundColor: '#1a3f48', display: 'none', color: '#fff', border: '1px solid #1a3f48' }}
                                                                            onMouseOver={(e) => {
                                                                                e.target.style.backgroundColor = '#fff'
                                                                                e.target.style.color = '#000'
                                                                                e.target.style.border = '1px solid #1a3f48'
                                                                            }}
                                                                            onMouseOut={(e) => {
                                                                                e.target.style.backgroundColor = '#1a3f48'
                                                                                e.target.style.color = '#fff'
                                                                                e.target.style.border = '1px solid #1a3f48'
                                                                            }}
                                                                        >
                                                                            cancel
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div style={{ display: 'grid', placeItems: 'center' }}>
                                                                        <p>{currentClass.students.length ? currentClass.students.length : 0}</p>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))
                                                        :
                                                        <></>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ClassList;