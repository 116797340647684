
import axios from 'axios';

export const createTimeTable = async (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/xakal/table`, data)
        .then((res) => {
            if (!res.data.ok) {
                console.log({ error: res.data })
                return alert('Error creating timetable details!')
            }
            return res.data;
        })
        .catch(error => alert(error.response.data))
}