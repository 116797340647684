import React, { Component } from 'react';
import AddInternalDetails from '../staff-portal/assessments/add-internal-details';
import EditInternalDetails from '../staff-portal/assessments/edit-internal-details';
import AddSemesterDetails from "../management-portal/add-semester-details";
import ViewSemesterDetails from "../staff-portal/assessments/view-semester-details";
import "../../styles/classnote.css";

class Assessment extends Component {

  constructor(props) {
    super(props);
    this.state = {
        onType: false,
        onAction: false,
        onTypeSelected: '',
        onActionSelected: '',
        background: '',
        isFocussed: '',
        isActionFocussed: '',
        onFocus: false,
        onActionFocus: false,
        searchAllowed: false,
    };
  }

  onDropDownFocus() {
    this.setState({ isFocussed: 'is-focused', onFocus: true, onActionFocus: false, background: 'is-shown' });
  }

  onActionDropDownFocus() {
    this.setState({ isActionFocussed: 'is-focused', onActionFocus: true, onFocus: false, background: 'is-shown' });
  }

  onTypeSelect(event) {
    this.setState({ onTypeSelected: event.target.id, searchAllowed: false, onFocus: false, background: 'is-hidden' });
  }

  onActionSelect(event) {
    this.setState({ onActionSelected: event.target.id, background: 'is-hidden' });
  }

  getResult() {
    if (this.state.onTypeSelected !== '') {
      this.setState({ searchAllowed: true })
    } else {
      alert('Please select the values');
      this.setState({ searchAllowed: false })
    }
  }

  render() {
    return (
       <div className="container-fluid background bk">
        <div className="d-sm-flex  mb-4"/>
        <nav className="navbar navbar-expand-lg  navprac bar" style={{ width: '1050px', marginTop: '-1px' }} >
          <div className="title">Assesments</div>
          <form className="form-inline my-2 my-lg-0"> 
      <input  style={{width:'500px',marginLeft:'120px',backgroundColor:'rgba(54, 42, 65, 0.04)',height:'45px',fontStyle:'italic'}}className="form-control mr-sm-2 " type="search" placeholder="Search....." aria-label="Search"></input>
    </form>
        <label
            className="input-text head pass-name"
            style={{ marginLeft: '-530px', marginTop: '18%' }}
        >
        Type:
        </label>
        <input
            onKeyPress={(e) => e.preventDefault()}
            autoComplete="off"
            onFocus={this.onDropDownFocus.bind(this)}
            className="new-xakal-input box background height resp"
            type="text"
            value={this.state.onTypeSelected}
            style={{ marginLeft: '-40px', width: '305px', marginTop: '30%' }}
        />
        {this.state.onFocus ? (
        <div className="mdl-menu__container is-upgraded dropdown-list is-visible" style={{ width: '368px', marginLeft: '95px' }} >
            <div className="mdl-menu__outline  dropdown-div" style={{ width: '308px', marginLeft: '110px', marginTop: '120px' }} >
                <ul className="scrollable-menu  ul-list" style={{ width: '368%' }} >
                    <li
                    className="mdl-menu__item animation label-weight"
                    id="Add Internal Details" style={{ width: '100%' }}
                    onClick={this.onTypeSelect.bind(this)}
                    >
                    Add Internal Details
                    </li>
                    <li
                    className="mdl-menu__item animation label-weight"
                    id="Edit Internal Details" style={{ width: '100%' }}
                    onClick={this.onTypeSelect.bind(this)}
                    >
                    Edit Internal Details
                    </li>
                    <li
                    className="mdl-menu__item animation label-weight"
                    id="Add Semester Details" style={{ width: '100%' }}
                    onClick={this.onTypeSelect.bind(this)}
                    >
                    Add Semester Details
                    </li>
                    <li
                    className="mdl-menu__item animation1 label-weight"
                    id="View Semester Details"
                    onClick={this.onTypeSelect.bind(this)}
                    >
                    View Semester Details
                    </li>
                </ul>
            </div>
        </div>
        ):(
            <></>
        )}
            <button
            type="button"
            onClick={this.getResult.bind(this)}
            className="getresult heading-weight button-size"
            style={{ marginTop: '300px', width: '120px', marginLeft: '20px' }}
            >
            GO
            </button>
        </nav>
        <div>        
        { this.state.searchAllowed ? (
        <div>
          { this.state.onTypeSelected === "Edit Internal Details" ? (
            <div><EditInternalDetails/></div>
          ):(
            <div></div>
          )}
          { this.state.onTypeSelected === "Add Internal Details" ? (
            <div><AddInternalDetails/></div>
          ):(
            <div></div>
          )}
          { this.state.onTypeSelected === "Add Semester Details" ? (
            <div><AddSemesterDetails/></div>
          ):(
            <div></div>
          )}
          { this.state.onTypeSelected === "View Semester Details" ? (
            <div><ViewSemesterDetails/></div>
          ):(
            <div></div>
          )}
        </div>
        ): (
            <p></p>
        )}
        </div>
        </div>
    )
  }
}

export default Assessment
