import React, { Component } from "react";
import "../../styles/table.css";
import "../../minified-css/material-min.css";
import "../../styles/dropdowns.css";
import "../../styles/theme-style.css";
import '../../styles/classnote.css'

import axios from "axios";
import moment from "moment";

class CreateStaffTimetable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      column1: "",
      column2: "",
      selectedSemester: "",
      searchAllowed: false,
      absenceList: [],
      background: "",
      isFocussed: "",
      onFocus: false,
      userID: "",
      isManagementPortal: false,
    };
    this.baseState = this.state;
  }

  componentDidMount() {
    this.getPortal();
    if (this.props && this.props.location && this.props.location.userID) {
      const userID = this.props.location.userID;
      this.setState({ userID: userID.userID });
    }
    this.unlisten = this.props.history.listen((location, action) => {
      this.setState(this.baseState);
      this.getPortal();
    });
  }

  /**
   * Checks for portal logged in
   */
  getPortal() {
    const pathArray = this.props.location.pathname.split("/");
    if (
      pathArray.includes("management-portal") ||
      pathArray.includes("hod-portal")
    ) {
      this.setState({ isManagementPortal: true });
      this.fetchDepartmentDetails();
    } else {
      this.setState({ isManagementPortal: false });
    }
  }

  componentWillUnmount() {
    this.unlisten();
  }

  /**
   * Adds the hover class when date is hovered
   */
  dateHover(event) {
    var element = event.target.className;
    if (element === "column100 column2 ") {
      this.setState({ column1: "hov-column-head-ver5" });
    }
  }

  /**
   * Adds the hover class when reason is hovered
   */
  reasonHover(event) {
    var element = event.target.className;
    if (element === "column100 column3 ") {
      this.setState({ column2: "hov-column-head-ver5" });
    }
  }

  typeHover(event) {
    var element = event.target.className;
    if (element === "column100 column4 ") {
      this.setState({ column2: "hov-column-head-ver5" });
    }
  }

  actionHover(event) {
    var element = event.target.className;
    if (element === "column100 column5 ") {
      this.setState({ column2: "hov-column-head-ver5" });
    }
  }

  /**
   * Adds the hover class when userID is hovered
   */
  userHover(event) {
    var element = event.target.className;
    if (element === "column100 column4 ") {
      this.setState({ column3: "hov-column-head-ver5" });
    }
  }

  /**
   * Resets the state variables when hover is removed
   */
  hoverOff() {
    this.setState({
      column1: "",
      column2: "",
      column3: "",
      column4: "",
      column5: "",
    });
  }

  /**
   * Sets the semester selected
   */
  onDropDownSelect(event) {
    this.setState({
      selectedSemester: event.target.id,
      onFocus: false,
      background: "is-hidden",
      hasSemesterValue: true,
    });
    if (this.state.searchAllowed) {
      this.setState({ searchAllowed: false });
    }
  }

  onDropDownFocus() {
    this.setState({
      isFocussed: "is-focused",
      onFocus: true,
      background: "is-shown",
    });
    if (this.state.hasDepartmentValue) {
      this.setState({
        onDepartmentFocus: false,
        backgroundDepartment: "is-hidden",
        isDepartmentFocussed: "is-focused",
      });
    } else {
      this.setState({
        onDepartmentFocus: false,
        backgroundDepartment: "is-hidden",
        isDepartmentFocussed: "is-hidden",
      });
    }
  }

  /**
   * Allows the grid to display the values
   */
  getResult() {
    if (
      this.state.selectedSemester !== "" &&
      this.state.selectedDepartment !== ""
    ) {
      this.fetchAbsenceDetails();
    } else {
      alert("Please select department and semester");
      this.setState({ searchAllowed: false });
    }
  }

  /**
   * Fetches the date of absence based on semester selected
   */
  fetchAbsenceDetails() {
    var userID;
    this.setState({ searchAllowed: true });
    var semester = this.state.selectedSemester;
    if (this.state.isManagementPortal === true) {
      userID = { userID: this.state.selectedStudent };
    } else {
      userID = { userID: this.state.userID };
    }
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/attendance/studentleave/${semester}`, { params: userID })
      .then((response) => {
        console.log("al", response.data);
        this.setState({ absenceList: response.data });
      });
  }

  /**
   * Fetches all department
   */
  fetchDepartmentDetails() {
    axios.get(`${process.env.PUBLIC_URL}/xakal/departmentdetail`).then((response) => {
      this.setState({ departmentDetails: response.data.data });
    });
  }

  /**
   * Triggers when department dropdown is focused
   */
  onDeptDropDownFocus() {
    this.setState({
      isDepartmentFocussed: "is-focused",
      onDepartmentFocus: true,
      backgroundDepartment: "is-shown",
    });
    if (this.state.hasSemesterValue) {
      this.setState({
        onFocus: false,
        background: "is-hidden",
        isFocussed: "is-focused",
      });
    } else {
      this.setState({
        onFocus: false,
        background: "is-hidden",
        isFocussed: "is-hidden",
      });
    }
  }

  /**
   * Triggers when the department is changed and stores the values in state
   * @param event form values
   */
  handleDepartmentChange(event) {
    this.setState({
      selectedDepartment: event.target.id,
      onDepartmentFocus: false,
      backgroundDepartment: "is-hidden",
      background: "is-hidden",
      hasDepartmentValue: true,
    });
    this.fetchStudentDetailsByDept(event.target.id);
  }

  /**
   * Displays the list of department based on the API response
   */
  displayDepartment() {
    if (
      this.state &&
      this.state.departmentDetails &&
      this.state.departmentDetails.length
    ) {
      return this.state.departmentDetails.map((singleDepartment, index) => {
        return (
          <li className="mdl-menu__item animation" key={index}>
            <button
              id={singleDepartment.name}
              name={singleDepartment.name}
              onClick={this.handleDepartmentChange.bind(this)}
            >
              {singleDepartment.name}
            </button>
          </li>
        );
      });
    }
  }

  /**
   * Fetches all students for selected department
   */
  fetchStudentDetailsByDept(departmentName) {
    this.setState({ studentDetails: [] });
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/studentdetail/department/${departmentName}`)
      .then((response) => {
        this.setState({ studentDetails: response.data });
      });
  }

  /**
   * Removes the selected row
   * @param index selected row index
   */
  removeClick(singleData, i) {
    if (moment(new Date(singleData.fromDate)).isBefore(moment(new Date()))) {
      alert("Cannot cancel this leave. Please contact admin.");
    } else {
      if (window.confirm("Are you sure to cancel this leave?")) {
        let absenceList = 1;
        absenceList.splice(i, 1);
        this.setState({ absenceList });
        axios
          .put(`${process.env.PUBLIC_URL}/xakal/attendance/cancelLeave/${singleData._id}`)
          .then((response) => {
            // this.setState({ studentDetails: response.data });
          });
      }
    }
  }

  onDropDownFocusIn(event) {
    if (event.target) {
      event.target.parentNode.classList.add("is-focused");
      event.target.nextSibling.classList.add("is-shown");
    }
  }

  onFocusOut(event) {
    if (event.target) {
      if (event.target.value === "") {
        event.target.parentNode.classList.remove("is-focused");
      }
      event.target.nextSibling.classList.remove("is-shown");
    }
  }

  /**
   * Displays the list of notes based on the API response
   */

  tt = [
    {
      one: "Physics 1",
      two: "chemistry 1",
      three: "Maths 1",
      four: "EVS 1",
      five: "OOAD 1",
    },
    {
      one: "Physics 2",
      two: "chemistry 2",
      three: "Maths 2",
      four: "EVS 2",
      five: "OOAD 2",
    },
    {
      one: "Physics 3",
      two: "chemistry 3",
      three: "Maths 3",
      four: "EVS 3",
      five: "OOAD 3",
    },
    {
      one: "Physics 4",
      two: "chemistry 4",
      three: "Maths 4",
      four: "EVS 4",
      five: "OOAD 4",
    },
    {
      one: "Physics 5",
      two: "chemistry 5",
      three: "Maths 5",
      four: "EVS 5",
      five: "OOAD 5",
    },
    {
      one: "Physics 6",
      two: "chemistry 6",
      three: "Maths 6",
      four: "EVS 6",
      five: "OOAD 6",
    },
    {
      one: "Physics 7",
      two: "chemistry 7",
      three: "Maths 7",
      four: "EVS 7",
      five: "OOAD 7",
    },
    {
      one: "Physics 8",
      two: "chemistry 8",
      three: "Maths 8",
      four: "EVS 8",
      five: "OOAD 8",
    },
  ];

  displayTable() {
    // let counter = 0;
    return this.state.absenceList.map((singleData, index) => {
      // const fromDate = moment(new Date(singleData.fromDate)).format(
      //   "MM/DD/YYYY"
      // );
      // const toDate = moment(new Date(singleData.toDate)).format("MM/DD/YYYY");
      return (
        <>
          {this.tt.map((i) => (
            <tr className="row100">
              <td className="column100 column1" data-column="column1">
                {++index}
              </td>
              <td
                className={"column100 column2 "}
                onMouseEnter={this.userHover.bind(this)}
                onMouseLeave={this.hoverOff.bind(this)}
              >
                {i.one}
              </td>
              <td
                className={"column100 column2 "}
                onMouseEnter={this.dateHover.bind(this)}
                onMouseLeave={this.hoverOff.bind(this)}
              >
                {i.two}
              </td>
              <td
                className={"column100 column3 "}
                onMouseEnter={this.reasonHover.bind(this)}
                onMouseLeave={this.hoverOff.bind(this)}
              >
                {i.three}
              </td>
              <td
                className={"column100 column4 "}
                onMouseEnter={this.typeHover.bind(this)}
                onMouseLeave={this.hoverOff.bind(this)}
              >
                {i.four}
              </td>
              <td
                className={"column100 column5 "}
                onMouseEnter={this.typeHover.bind(this)}
                onMouseLeave={this.hoverOff.bind(this)}
              >
                {i.five}
              </td>
            </tr>
          ))}
        </>
      );
    });
  }

  /**
   * Triggers when student is focused
   */
  onStudentFocussed() {
    this.setState({
      isStudentFocussed: "is-focused",
      onFocus: false,
      onStudentFocus: true,
      backgroundStudent: "is-shown",
    });
  }

  /**
   * Triggers when the student is changed and stores the values in state
   * @param event form values
   */
  handleStudentChange(event) {
    this.setState({
      selectedStudentName: event.target.name,
      selectedStudent: event.target.id,
      onStudentFocus: false,
      backgroundStudent: "is-hidden",
    });
  }

  /**
   * Displays the list of student based on the API response
   */
  displayStudent() {
    if (
      this.state &&
      this.state.studentDetails &&
      this.state.studentDetails.length
    ) {
      return this.state.studentDetails.map((singleStudent, index) => {
        return (
          <li className="mdl-menu__item animation" key={index}>
            <button
              id={singleStudent.userID}
              name={singleStudent.name}
              onClick={this.handleStudentChange.bind(this)}
            >
              {singleStudent.name}
            </button>
          </li>
        );
      });
    }
  }

  tt = [
    {
      name: "Monday",
    },
    {
      name: "Tuesday",
    },
    {
      name: "Wednesday",
    },
    {
      name: "Thursday",
    },
    {
      name: "Friday",
    },
  ];

  resetForm() {
    this.setState({
      values: [
        {
          name: "",
          designation: "",
          qualification: "",
          contact: "",
          email: "",
          departmentName: "",
          emergencyContact: "",
          joiningDate: moment(new Date()).format("YYYY-MM-DD"),
        },
      ],
    });
  }

  render() {
    return (
      <div className="container-fluid background">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <nav className="navbar navbar-expand-lg  navprac bar "style={{marginTop:'-70px'}}>
         <div className="title">Timetable</div>
  <div className="collapse navbar-collapse">
    <form className="form-inline my-2 my-lg-0"> 
      <input  style={{width:'500px',marginLeft:'120px',backgroundColor:'rgba(54, 42, 65, 0.04)',fontStyle:'italic', height:'45px'}}className="form-control mr-sm-2 " type="search" placeholder="Search....." aria-label="Search"></input>
    </form>
  </div>
</nav>
<div className="row"style={{color:'#714F90'}}>
           <h4 style={{marginLeft:'-1125px',color:'#714F90',marginTop:'150px'}}>Create Staff Timetable</h4>

            {/* <h1 className="h3 mb-0 text-gray-800 m-t-20">Notes</h1> */}
          </div>
          {/* <h1 className="h3 mb-0 text-gray-800 m-t-20 create-table">
            Create Staff Timetable
          </h1> */}
        </div>
        <div className="row m-r-20">
          <div className="col-sm-12">
            <div>
              <div className="card-body row">
                {this.state.isManagementPortal ? (
                  <div className="col-lg-4 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width  " +
                        this.state.isDepartmentFocussed
                      }
                    >
                       <label
                       style={{marginLeft:'10%'}}
                        className="input-text"
                      >
                        Department
                      </label>
                      <input
                        name="selectedDepartment"
                        onKeyPress={(e) => e.preventDefault()}
                        autoComplete="off"
                        onFocus={this.onDeptDropDownFocus.bind(this)}
                        className="new-xakal-input background height"
                        type="text"
                        id={`department`}
                        value={this.state.selectedDepartment}
                        onChange={this.handleDepartmentChange.bind(this)}
                        style={{marginLeft:'10%',width:'300px'}}

                      />
                     
                      {this.state.onDepartmentFocus ? (
                       <div className="mdl-menu__container is-upgraded dropdown-list is-visible" style={{ width: '200px' ,marginLeft:'105px'}} >
                       <div className="mdl-menu__outline  dropdown-div" style={{ width: '300px',marginLeft:'-76px' }} >
                         <ul className="scrollable-menu  ul-list" style={{ width: '100%' }} >   
                            {this.displayDepartment()}
                            </ul>
                          </div>
                        </div>
                      ) : (
                        <p></p>
                      )}
                    </div>
                  </div>
                ) : (
                  <p></p>
                )}
                <div className="col-lg-4 p-t-20">
                  <div
                    className={
                      "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width " +
                      this.state.isFocussed
                    }
                  >
                     <label
                      style={{marginLeft:'-2%'}}
                      className="input-text"
                    >
                     Semester Name
                    </label>
                    <input
                      autoComplete="off"
                      onKeyPress={(e) => e.preventDefault()}
                      onFocus={this.onDropDownFocus.bind(this)}
                      className="new-xakal-input background height"
                      type="text"
                      id="sample2"
                      value={this.state.selectedSemester}
                      style={{marginLeft:'-2%',width:'300px'}}

                    />
                    
                    {this.state.onFocus ? (
                     <div className="mdl-menu__container is-upgraded dropdown-list is-visible" style={{ width: '200px' ,marginLeft:'105px'}} >
                     <div className="mdl-menu__outline  dropdown-div" style={{ width: '300px',marginLeft:'-111px' }} >
                       <ul className="scrollable-menu  ul-list" style={{ width: '100%' }} >   
                          <li
                              className="mdl-menu__item animation"
                              id="Semester 1"
                              onClick={this.onDropDownSelect.bind(this)}
                            >
                              Semester 1
                            </li>
                            <li
                              className="mdl-menu__item animation1"
                              id="Semester 2"
                              onClick={this.onDropDownSelect.bind(this)}
                            >
                              Semester 2
                            </li>
                            <li
                              className="mdl-menu__item animation2"
                              id="Semester 3"
                              onClick={this.onDropDownSelect.bind(this)}
                            >
                              Semester 3
                            </li>
                            <li
                              className="mdl-menu__item animation"
                              id="Semester 4"
                              onClick={this.onDropDownSelect.bind(this)}
                            >
                              Semester 4
                            </li>
                            <li
                              className="mdl-menu__item animation1"
                              id="Semester 5"
                              onClick={this.onDropDownSelect.bind(this)}
                            >
                              Semester 5
                            </li>
                            <li
                              className="mdl-menu__item animation2"
                              id="Semester 6"
                              onClick={this.onDropDownSelect.bind(this)}
                            >
                              Semester 6
                            </li>
                            <li
                              className="mdl-menu__item animation"
                              id="Semester 7"
                              onClick={this.onDropDownSelect.bind(this)}
                            >
                              Semester 7
                            </li>
                            <li
                              className="mdl-menu__item animation1"
                              id="Semester 8"
                              onClick={this.onDropDownSelect.bind(this)}
                            >
                              Semester 8
                            </li>
                          </ul>
                        </div>
                      </div>
                    ) : (
                      <p></p>
                    )}
                  </div>
                </div>
                <div className="col-lg-4 p-t-20">
                  <button
                    type="button"
                    onClick={this.getResult.bind(this)}
                    className=" m-t-44 m-l-4 getresult"
                    style={{marginLeft:'-40px'}}
                    
                  >
                    Get Results!
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.searchAllowed ? (
          <>
            {this.tt.map((i) => (
              <>
                <h1 className="h3 mb-0 text-gray-800 m-t-20 m-l-20">
                  {i.name}
                </h1>
                <div className="card-body row staff-row">
                  <div className="col-lg-1.5 p-r-5">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                      }
                    >
                      <input
                        // value={el.name}
                        autoComplete="off"
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocusIn.bind(this)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        id="Period 1"
                        // onChange={this.handleFormChange.bind(this, i)}
                        name="Period 1"
                      />
                      <label className={"mdl-textfield__label "}>
                        Period 1
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-1.5  p-r-5">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                      }
                    >
                      <input
                        // value={el.name}
                        autoComplete="off"
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocusIn.bind(this)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        id="Period 2"
                        // onChange={this.handleFormChange.bind(this, i)}
                        name="Period 2"
                      />
                      <label className={"mdl-textfield__label "}>
                        Period 2
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-1.5  p-r-5">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                      }
                    >
                      <input
                        // value={el.name}
                        autoComplete="off"
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocusIn.bind(this)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        id="Period 3"
                        // onChange={this.handleFormChange.bind(this, i)}
                        name="Period 3"
                      />
                      <label className={"mdl-textfield__label "}>
                        Period 3
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-1.5  p-r-5">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                      }
                    >
                      <input
                        // value={el.name}
                        autoComplete="off"
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocusIn.bind(this)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        id="Period 4"
                        // onChange={this.handleFormChange.bind(this, i)}
                        name="Period 4"
                      />
                      <label className={"mdl-textfield__label "}>
                        Period 4
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-1.5  p-r-5">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                      }
                    >
                      <input
                        // value={el.name}
                        autoComplete="off"
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocusIn.bind(this)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        id="Period 5"
                        // onChange={this.handleFormChange.bind(this, i)}
                        name="Period 5"
                      />
                      <label className={"mdl-textfield__label "}>
                        Period 5
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-1.5  p-r-5">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                      }
                    >
                      <input
                        // value={el.name}
                        autoComplete="off"
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocusIn.bind(this)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        id="Period 6"
                        // onChange={this.handleFormChange.bind(this, i)}
                        name="Period 6"
                      />
                      <label className={"mdl-textfield__label "}>
                        Period 6
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-1.5  p-r-5">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                      }
                    >
                      <input
                        // value={el.name}
                        autoComplete="off"
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocusIn.bind(this)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        id="Period 7"
                        // onChange={this.handleFormChange.bind(this, i)}
                        name="Period 7"
                      />
                      <label className={"mdl-textfield__label "}>
                        Period 7
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-1.5  p-r-5">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                      }
                    >
                      <input
                        // value={el.name}
                        autoComplete="off"
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocusIn.bind(this)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        id="Period 8"
                        // onChange={this.handleFormChange.bind(this, i)}
                        name="Period 8"
                      />
                      <label className={"mdl-textfield__label "}>
                        Period 8
                      </label>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </>
        ) : (
          <p></p>
        )}

        {this.state.searchAllowed ? (
          <div className="card-body row staff-row">
            <div className="col-sm-8 p-t-20">
              <button
                type="button"
                onClick={this.resetForm.bind(this)}
                className="btn  m-t-15 m-l-30"
                style={{backgroundColor:"#362A41",color:"#fff"}}
              >
                Add
              </button>
              <button
                type="button"
                onClick={this.resetForm.bind(this)}
                className="btn m-t-15 m-l-30"
                style={{backgroundColor:"#362A41",color:"#fff"}}
              >
                Submit
              </button>
              <button
                type="button"
                onClick={this.resetForm.bind(this)}
                className="btn m-t-15 m-l-30"
                style={{backgroundColor:"#362A41",color:"#fff"}}
              >
                Cancel
              </button>
            </div>
          </div>
        ) : (
          <p></p>
        )}
      </div>
    );
  }
}

export default CreateStaffTimetable;
