import React, { Component } from "react";
import "../../../styles/table.css";
import "../../../styles/dropdown.css";
import "../../../styles/course-dropdown.css";
import "../../../styles/classnote.css";

import axios from "axios";
import Pagination from "../../../utils/pagination";

class EditInternalDetails extends Component {
  constructor(props) {
    super(props);
    this.marksArray = [];
    this.studentID = [];
    this.state = {
      column1: "",
      column2: "",
      column3: "",
      column4: "",
      column5: "",
      selectedSemester: "",
      selectedCourse: "",
      searchAllowed: false,
      uploadAllowed: false,
      courseList: [],
      marksList: [],
      background: "",
      backgroundCourse: "",
      backgroundModel: "",
      isFocussed: "",
      isCourseFocussed: "",
      onFocus: false,
      onCourseFocus: false,
      file: null,
      selectedModel: "",
      userID: "",
      isEdit: false,
      internals: 0,
      currentPage: 1,
      postsPerPage: 5,
    };
    this.courseChange = this.onCourseChange.bind(this);
    this.baseState = this.state;
    this.paginate = this.paginate.bind(this);
  }

  // componentDidMount() {
  //   if (this.props && this.props.location && this.props.location.userID) {
  //     const userID = this.props.location.userID;
  //     this.setState({ userID: userID.userID });
  //   }
  //   this.unlisten = this.props.history.listen((location, action) => {
  //     this.setState(this.baseState);
  //   });
  // }

  // componentWillUnmount() {
  //   this.unlisten();
  // }

  /**
   * Adds the hover class when description is hovered
   */
  userIDHover(event) {
    var element = event.target.className;
    if (element === "column100 column2 ") {
      this.setState({ column1: "hov-column-head-ver5" });
    }
  }

  /**
   * Adds the hover class when download is hovered
   */
  marksHover(event) {
    var element = event.target.className;
    if (element === "column100 column3 ") {
      this.setState({ column2: "hov-column-head-ver5" });
    }
  }

  /**
   * Adds the hover class when update date is hovered
   */
  uploadDateHover(event) {
    var element = event.target.className;
    if (element === "column100 column4 ") {
      this.setState({ column3: "hov-column-head-ver5" });
    }
  }

  /**
   * Adds the hover class when upload by is hovered
   */
  uploadByHover(event) {
    var element = event.target.className;
    if (element === "column100 column5 ") {
      this.setState({ column4: "hov-column-head-ver5" });
    }
  }

  /**
   * Adds the hover class when upload by is hovered
   */
  actionHover(event) {
    var element = event.target.className;
    if (element === "column100 column6 ") {
      this.setState({ column5: "hov-column-head-ver5" });
    }
  }

  /**
   * Resets the state variables when hover is removed
   */
  hoverOff() {
    this.setState({
      column1: "",
      column2: "",
      column3: "",
      column4: "",
      column5: "",
    });
  }

  /**
   * Triggers the API call for course, based on the semester selected
   */
  onDropDownSelect(event) {
    this.setState({
      selectedSemester: event.target.id,
      selectedCourse: "",
      onFocus: false,
      background: "is-hidden",
    });
    var semester = event.target.id;
    axios.get(`${process.env.PUBLIC_URL}/xakal/class-notes/course/${semester}`).then((response) => {
      this.setState({ courseList: response.data });
    });
    if (this.state.searchAllowed) {
      this.setState({ searchAllowed: false });
    } else if (this.state.uploadAllowed) {
      this.setState({ uploadAllowed: false });
    }
  }

  onDropDownFocus() {
    this.setState({
      isFocussed: "is-focused",
      onFocus: true,
      onCourseFocus: false,
      background: "is-shown",
      backgroundCourse: "is-hidden",
      backgroundModel: "is-hidden",
    });
    if (this.state.selectedCourse === "") {
      this.setState({ isCourseFocussed: "" });
    }
  }

  onCourseDropDownFocus() {
    this.setState({
      isCourseFocussed: "is-focused",
      onCourseFocus: true,
      onFocus: false,
      background: "is-hidden",
      backgroundCourse: "is-shown",
      backgroundModel: "is-hidden",
    });
  }

  onModelFocus() {
    this.setState({
      isModelFocussed: "is-focused",
      onCourseFocus: false,
      onFocus: false,
      onModelFocus: true,
      background: "is-hidden",
      backgroundCourse: "is-hidden",
      backgroundModel: "is-shown",
    });
  }

  /**
   * Updates the selected course name
   */
  onCourseChange(event) {
    this.setState({
      selectedCourse: event.target.id,
      onCourseFocus: false,
      backgroundCourse: "is-hidden",
      background: "is-hidden",
    });
    if (this.state.searchAllowed) {
      this.setState({ searchAllowed: false });
    } else if (this.state.uploadAllowed) {
      this.setState({ uploadAllowed: false });
    }
  }

  onDescriptionChanged(event) {
    this.setState({
      selectedModel: event.target.id,
      onModelFocus: false,
      backgroundCourse: "is-hidden",
      background: "is-hidden",
      backgroundModel: "is-hidden",
    });
    if (this.state.searchAllowed) {
      this.setState({ searchAllowed: false });
    } else if (this.state.uploadAllowed) {
      this.setState({ uploadAllowed: false });
    }
  }

  /**
   * Allows the grid to display the values based on routing
   */
  getDetails() {
    if (
      this.state.selectedSemester !== "" &&
      this.state.selectedCourse !== "" &&
      this.state.selectedModel !== ""
    ) {
      var params = {
        semester: this.state.selectedSemester.toLowerCase(),
        course: this.state.selectedCourse,
      };
      axios
        .get(`${process.env.PUBLIC_URL}/xakal/assessment/internaldetail`, { params: params })
        .then((response) => {
          this.setState({ marksList: response.data, searchAllowed: true });
        });
    } else {
      alert("Please select the values");
      this.setState({ searchAllowed: false, uploadAllowed: false });
    }
  }

  redirect() {
    this.setState({ isEdit: true });
  }

  paginate(num) { 
    this.setState({currentPage: num})
  }

  /**
   * Displays the list of notes based on the API response
   */
  displayTable() {
    const indexOfLastPost = this.state.currentPage * this.state.postsPerPage;
    const indexOfFirstPost = indexOfLastPost - this.state.postsPerPage;
    const currentPost = this.state.marksList.slice(indexOfFirstPost, indexOfLastPost) 

    const selectedModel = this.state.selectedModel;
    const modelName = selectedModel.toLowerCase().replace(/ /g, ""); //remove white spaces
    return currentPost.map((singleData, index) => {
      return (
        <tr className="row100" key={index++}>
          <td className="column100 column1" data-column="column1">
            {index}
          </td>
          <td
            className={"column100 column2 "}
            key={index++}
            onMouseEnter={this.userIDHover.bind(this)}
            onMouseLeave={this.hoverOff.bind(this)}
          >
            {singleData.userID}
          </td>
          <td
            className={"column100 column3 "}
            key={index++}
            onMouseEnter={this.marksHover.bind(this)}
            onMouseLeave={this.hoverOff.bind(this)}
          >
            {singleData[modelName]}
          </td>
          <td
            className={"column100 column4 "}
            key={index++}
            onMouseEnter={this.uploadDateHover.bind(this)}
            onMouseLeave={this.hoverOff.bind(this)}
          >
            {singleData.uploadedDate}
          </td>
          <td
            className={"column100 column5 "}
            key={index++}
            onMouseEnter={this.uploadByHover.bind(this)}
            onMouseLeave={this.hoverOff.bind(this)}
          >
            {singleData.uploadedBy}
          </td>
          <td
            className={"column100 column6 "}
            key={index++}
            onMouseEnter={this.actionHover.bind(this)}
            onMouseLeave={this.hoverOff.bind(this)}
          >
            <button onClick={this.redirect.bind(this)}>Edit</button>
          </td>
        </tr>
      );
    });
  }

  /**
   * Reverts back to the original state
   */
  discardChanges() {
    this.studentID = [];
    this.marksArray = [];
    this.setState({ isEdit: false });
    this.displayTable();
  }

  updatedMarks(singleElement, context) {
    const userID = singleElement._id;
    if (this.marksArray.length) {
      this.marksArray.forEach((element) => {
        if (element.id === userID) {
          element.marksObtained = context.target.value;
        } else {
          if (!this.studentID.includes(userID)) {
            this.insertUpdatedMarks(userID, context);
          }
        }
      });
    } else {
      if (!this.studentID.includes(userID)) {
        this.insertUpdatedMarks(userID, context);
      }
    }
    if (this.state.selectedModel === "Model 3") {
      this.calculateInternalMarks(singleElement);
    }
  }

  insertUpdatedMarks(userID, context) {
    this.studentID.push(userID);
    this.marksArray.push({
      id: userID,
      marksObtained: context.target.value,
    });
  }

  /**
   * Calculates the internal marks
   * Attendance marks - To be defined
   */
  calculateInternalMarks(singleElement) {
    const assessment1 = (singleElement.model1 / 100) * 5;
    const assessment2 = (singleElement.model2 / 100) * 5;
    const assessment3 = (singleElement.model3 / 100) * 5;
    const totalInternals = assessment1 + assessment2 + assessment3 + 5;
    this.setState({ internals: totalInternals });
  }

  updateMarks() {
    let isUpdated = false;
    if (this.marksArray && this.marksArray.length) {
      this.marksArray.forEach((element) => {
        const params = {
          marksObtained: element.marksObtained,
          semester: this.state.selectedSemester.toLowerCase(),
          course: this.state.selectedCourse,
          model: this.state.selectedModel.toLowerCase(),
          uploadedBy: this.state.userID.toUpperCase(),
          uploadedDate: new Date(Date.now()).toLocaleString(),
          internals: this.state.internals,
        };
        axios
          .put(`${process.env.PUBLIC_URL}/xakal/assessment/internaldetail/update/${element.id}`, params)
          .then(() => {
            if (!isUpdated) {
              alert("Updated Successfully");
            }
            isUpdated = true;
            this.setState({ searchAllowed: false, isEdit: false });
          })
          .catch((err) => console.log(err));
      });
    }
  }

  /**
   * Inline table edit
   */
  editTable() {
    const indexOfLastPost = this.state.currentPage * this.state.postsPerPage;
    const indexOfFirstPost = indexOfLastPost - this.state.postsPerPage;
    const currentPost = this.state.marksList.slice(indexOfFirstPost, indexOfLastPost) 

    const selectedModel = this.state.selectedModel;
    const modelName = selectedModel.toLowerCase().replace(/ /g, ""); //remove white spaces
    return currentPost.map((singleData, index) => {
      return (
        <tr className="row100" key={index++}>
          <td className="column100 column1" data-column="column1">
            {index}
          </td>
          <td
            className={"column100 column2 "}
            key={index++}
            onMouseEnter={this.userIDHover.bind(this)}
            onMouseLeave={this.hoverOff.bind(this)}
          >
            {singleData.userID}
          </td>
          <td
            className={"column100 column3 "}
            key={index++}
            onMouseEnter={this.marksHover.bind(this)}
            onMouseLeave={this.hoverOff.bind(this)}
          >
            <input
              type="number"
              className="add-border"
              onChange={this.updatedMarks.bind(this, singleData)}
              defaultValue={singleData[modelName]}
            ></input>
          </td>
          <td
            className={"column100 column4 "}
            key={index++}
            onMouseEnter={this.uploadDateHover.bind(this)}
            onMouseLeave={this.hoverOff.bind(this)}
          >
            {singleData.uploadedDate}
          </td>
          <td
            className={"column100 column5 "}
            key={index++}
            onMouseEnter={this.uploadByHover.bind(this)}
            onMouseLeave={this.hoverOff.bind(this)}
          >
            {singleData.uploadedBy}
          </td>
          <td
            className={"column100 column6 "}
            key={index++}
            onMouseEnter={this.actionHover.bind(this)}
            onMouseLeave={this.hoverOff.bind(this)}
          >
            <button onClick={this.redirect.bind(this)}>Add / Edit</button>
          </td>
        </tr>
      );
    });
  }

  /**
   * Displays the list of courses based on the API response
   */
  displayCourse() {
    if (this.state && this.state.courseList && this.state.courseList.length) {
      return this.state.courseList.map((singleCourse, index) => {
        return (
          <li className="mdl-menu__item animation" key={index}>
            <button id={singleCourse.course} onClick={this.courseChange}>
              {singleCourse.course}
            </button>
          </li>
        );
      });
    }
  }

  render() {
    return (
      <div className="container-fluid background">
        <div className="d-sm-flex  mb-4">
        <div>
            <h4 className="head" style={{ marginLeft: '-30%',width:'200px', marginTop: '70px' }}> Edit Internal Details</h4>

            {/* <h1 className="h3 mb-0 text-gray-800 m-t-20">Notes</h1> */}
          </div>
       
<div className="row">

            {/* <h1 className="h3 mb-0 text-gray-800 m-t-20">Notes</h1> */}
        </div>
          <div className="col-sm-12">
              <div className="row">
                <div className="col-lg-2 p-t-20">
                  <div
                    className={
                      // "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width " +
                      this.state.isFocussed
                    }
                  >
                    <label
                      className="input-text labell resp1"
                    style={{marginLeft:'-220px',marginTop:"150px"}}
                    >
                      Semester Number
                    </label>
                    <input
                      onKeyPress={(e) => e.preventDefault()}
                      onFocus={this.onDropDownFocus.bind(this)}
                      autoComplete="off"
                      className="new-xakal-input  background height resp1"
                      type="text"
                      id="sample2"
                      value={this.state.selectedSemester}
                      style={{marginLeft:'-220px',width:'250px'}}
                    />
                    
                    {this.state.onFocus ? (
                      <div className="mdl-menu__container is-upgraded dropdown-list is-visible edit-res"style={{width:'250px'}}>
                        <div className="mdl-menu__outline edit-res"style={{marginLeft:'-210px',width:'250px',marginTop:'180px'}}>
                          <ul className="scrollable-menu  ul-list"style={{width:'250px'}}>
                            <li
                              className="mdl-menu__item animation"
                              id="Semester 1"
                              onClick={this.onDropDownSelect.bind(this)}
                            >
                              Semester 1
                            </li>
                            <li
                              className="mdl-menu__item animation1"
                              id="Semester 2"
                              onClick={this.onDropDownSelect.bind(this)}
                            >
                              Semester 2
                            </li>
                            <li
                              className="mdl-menu__item animation2"
                              id="Semester 3"
                              onClick={this.onDropDownSelect.bind(this)}
                            >
                              Semester 3
                            </li>
                            <li
                              className="mdl-menu__item animation"
                              id="Semester 4"
                              onClick={this.onDropDownSelect.bind(this)}
                            >
                              Semester 4
                            </li>
                            <li
                              className="mdl-menu__item animation1"
                              id="Semester 5"
                              onClick={this.onDropDownSelect.bind(this)}
                            >
                              Semester 5
                            </li>
                            <li
                              className="mdl-menu__item animation2"
                              id="Semester 6"
                              onClick={this.onDropDownSelect.bind(this)}
                            >
                              Semester 6
                            </li>
                            <li
                              className="mdl-menu__item animation"
                              id="Semester 7"
                              onClick={this.onDropDownSelect.bind(this)}
                            >
                              Semester 7
                            </li>
                            <li
                              className="mdl-menu__item animation1"
                              id="Semester 8"
                              onClick={this.onDropDownSelect.bind(this)}
                            >
                              Semester 8
                            </li>
                          </ul>
                        </div>
                      </div>
                    ) : (
                      <p></p>
                    )}
                  </div>
                </div>
                <div className="col-lg-2 p-t-20">
                  <div
                    className={
                      "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width " +
                      this.state.isCourseFocussed
                    }
                  >
                     <label
                      className="input-text labell resp1"
                      style={{marginLeft:'-110px',marginTop:"130px"}}  
                      
                    >
                      Course
                    </label>
                    <input
                      onKeyPress={(e) => e.preventDefault()}
                      onFocus={this.onCourseDropDownFocus.bind(this)}
                      autoComplete="off"
                      className="new-xakal-input background height resp1"
                      type="text"
                      id="sample2"
                      style={{marginLeft:'-110px',width:'250px'}}     
                     value={this.state.selectedCourse}
                    />
                   
                    {this.state.onCourseFocus ? (
                      <div className="mdl-menu__container is-upgraded dropdown-list is-visible edit-res1"style={{width:'250px'}}>
                        <div className="mdl-menu__outline edit-res1"style={{marginLeft:'-110px',width:'250px',marginTop:'160px'}}>
                          <ul className="scrollable-menu  ul-list"style={{width:'250px'}}>
                            {this.displayCourse()}
                          </ul>
                        </div>
                      </div>
                    ) : (
                      <p></p>
                    )}
                  </div>
                </div>
                <div className=" col-lg-2 p-t-20">
                  <div
                    className={
                      "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width " +
                      this.state.isModelFocussed
                    }
                  >
                    <label
                      className="input-text labell resp1"
                      style={{marginLeft:'2%',marginTop:'130px'}}  
                    >
                      Model
                    </label>
                    <input
                      onKeyPress={(e) => e.preventDefault()}
                      onFocus={this.onModelFocus.bind(this)}
                      autoComplete="off"
                      className="new-xakal-input background height resp1"
                      type="text"
                      id="sample2"
                      value={this.state.selectedModel}
                      style={{marginLeft:'0%',width:'250px'}}  
                    />
                    
                    {this.state.onModelFocus ? (
                       <div className="mdl-menu__container is-upgraded dropdown-list is-visible edit-res1"style={{width:'250px'}}>
                       <div className="mdl-menu__outline edit-res1"style={{marginLeft:'-1%',width:'250px',marginTop:'160px'}}>
                         <ul className="scrollable-menu  ul-list"style={{width:'250px'}}>
                            <li
                              className="mdl-menu__item animation"
                              id="Model 1"
                              onClick={this.onDescriptionChanged.bind(this)}
                            >
                              Model 1
                            </li>
                            <li
                              className="mdl-menu__item animation1"
                              id="Model 2"
                              onClick={this.onDescriptionChanged.bind(this)}
                            >
                              Model 2
                            </li>
                            <li
                              className="mdl-menu__item animation2"
                              id="Model 3"
                              onClick={this.onDescriptionChanged.bind(this)}
                            >
                              Model 3
                            </li>
                          </ul>
                        </div>
                      </div>
                    ) : (
                      <p></p>
                    )}
                  </div>
                </div>
                </div>
                <div className="col-lg-12">
               
                <div className="col-lg-6">
                  <button
                   style={{marginLeft:'-25%',marginTop:'-10px'}}
                    type="button"
                    onClick={this.getDetails.bind(this)}
                    // className="btn btn-primary m-t-15 m-l-30"
                    className="getresult resp1"
                  >
                    Get Results
                  </button>
                </div>
                </div>
                {/* <div className="col-sm-3 p-t-20 m-t-24">
                  <button
                   style={{marginLeft:'87%'}}
                    type="button"
                    onClick={this.getDetails.bind(this)}
                    // className="btn btn-primary m-t-15 m-l-30"
                    className="getresult m-t-22"
                  >
                    Get Results!
                  </button>
                </div> */}
              </div>
            
          </div>
        {this.state.searchAllowed ? (
          <div className="limiter align1 background">
            <div className="container align1 background">
              <div className="col-md-12 align1 background">
                <div class="row">
                  <table class="table vertical-border-head align1 background"> 
                    <thead>
                      <tr className="row100 head">
                        <th
                        style={{color:'#B8B3BC'}}
                          className="column100 column1 vertical-border-head heading-weight heading-size  background"
                          data-column="column1"
                        >SL.No</th>
                        <th style={{color:'#B8B3BC'}}
                          className={"column100 column2  background" + this.state.column1}
                          onMouseEnter={this.userIDHover.bind(this)}
                          onMouseLeave={this.hoverOff.bind(this)}
                        >
                          Student ID
                        </th>
                        <th
                        style={{color:'#B8B3BC'}}
                          className={"column100 column3  background" + this.state.column2}
                          onMouseEnter={this.marksHover.bind(this)}
                          onMouseLeave={this.hoverOff.bind(this)}
                        >
                          Marks Obtained
                        </th>
                        <th
                        // style={{color:'#B8B3BC'}}
                        style={{backgroundColor:'#E5E5E5',color:'#B8B3BC'}}
                          className={"column100 column4  background " + this.state.column3}
                          onMouseEnter={this.uploadDateHover.bind(this)}
                          onMouseLeave={this.hoverOff.bind(this)}
                        >
                          Uploaded DateTime
                        </th>
                        <th
                        style={{color:'#B8B3BC'}}
                          className={"column100 column5  background" + this.state.column4}
                          onMouseEnter={this.uploadByHover.bind(this)}
                          onMouseLeave={this.hoverOff.bind(this)}
                        >
                          Uploaded By
                        </th>
                        <th
                        style={{color:'#B8B3BC'}}
                          className={"column100 column6  background" + this.state.column5}
                          onMouseEnter={this.actionHover.bind(this)}
                          onMouseLeave={this.hoverOff.bind(this)}
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    {this.state.isEdit ? (
                      <tbody>{this.editTable()}</tbody>
                    ) : (
                      <tbody>{this.displayTable()}</tbody>
                    )}
                  </table>
                </div>
                {this.state.isEdit ? (
                  <Pagination postPerPage={this.state.postsPerPage} totalPost={this.state.marksList.length} paginate={this.paginate}/>
                ) : (
                  <Pagination postPerPage={this.state.postsPerPage} totalPost={this.state.marksList.length} paginate={this.paginate}/>
                )}
              </div>
            </div>
          </div>
        ) : (
          <span></span>
        )}
        {this.state.isEdit && this.state.searchAllowed ? (
          <div className="right p-t-20 m-r-100">
            {/* <td>
                        <a href="edit_professor.html"
                            class="btn btn-primary btn-xs">
                            <i class="fa fa-pencil"></i>
                        </a>
                        <a href="javasctipt().html"
                            class="btn btn-danger btn-xs">
                            <i class="fa fa-trash-o "></i>
                        </a>
                    </td> */}
            <button
              type="button"
              onClick={this.updateMarks.bind(this)}
              className="btn btn-primary m-t-15 m-l-30"
            >
              Save Details
            </button>
            <button
              type="button"
              onClick={this.discardChanges.bind(this)}
              className="btn btn-primary m-t-15 m-l-30"
            >
              Cancel
            </button>
          </div>
        ) : (
          <p></p>
        )}
      </div>
     
    );
  }
}

export default EditInternalDetails;
