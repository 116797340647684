import React, { Component } from "react";
import "../../styles/table.css";
import "../../minified-css/material-min.css";
import "../../styles/dropdowns.css";
import "../../styles/theme-style.css";
import "../../styles/classnote.css";

import axios from "axios";
import moment from "moment";
// import { Link } from "react-router-dom";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pagination from "../../utils/pagination";

class Attendance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      column1: "",
      column2: "",
      column3: "",
      column4: "",
      column5: "",
      column6: "",
      column7: "",
      column8: "",
      selectedSemester: "",
      searchAllowed: false,
      absenceList: [],
      background: "",
      isFocussed: "",
      onFocus: false,
      userID: "",
      isManagementPortal: false,
      currentPage: 1,
      postsPerPage: 10,
    };
    this.paginate = this.paginate.bind(this);
    this.baseState = this.state;
  }

  componentDidMount() {
    this.getPortal();
    if (this.props && this.props.location && this.props.location.userID) {
      const userID = this.props.location.userID;
      this.setState({ userID: userID.userID });
    }
    this.unlisten = this.props.history.listen((location, action) => {
      this.setState(this.baseState);
      this.getPortal();
    });
  }

  /**
   * Checks for portal logged in
   */
  getPortal() {
    const pathArray = this.props.location.pathname.split("/");
    if (
      pathArray.includes("management-portal") ||
      pathArray.includes("hod-portal")
    ) {
      this.setState({ isManagementPortal: true });
      this.fetchDepartmentDetails();
    } else {
      this.setState({ isManagementPortal: false });
    }
  }

  componentWillUnmount() {
    this.unlisten();
  }

  /**
   * Adds the hover class when userID is hovered
   */
  userHover(event) {
    var element = event.target.className;
    if (element === "column100 column2 ") {
      this.setState({ column2: "hov-column-head-ver5" });
    }
  }

  /**
   * Adds the hover class when date is hovered
   */
  fromDateHover(event) {
    var element = event.target.className;
    if (element === "column100 column3 ") {
      this.setState({ column3: "hov-column-head-ver5" });
    }
  }

  toDateHover(event) {
    var element = event.target.className;
    if (element === "column100 column4 ") {
      this.setState({ column4: "hov-column-head-ver5" });
    }
  }

  /**
   * Adds the hover class when reason is hovered
   */
  reasonHover(event) {
    var element = event.target.className;
    if (element === "column100 column5 ") {
      this.setState({ column5: "hov-column-head-ver5" });
    }
  }

  typeHover(event) {
    var element = event.target.className;
    if (element === "column100 column6 ") {
      this.setState({ column6: "hov-column-head-ver5" });
    }
  }

  fileHover(event) {
    var element = event.target.className;
    if (element === "column100 column7 ") {
      this.setState({ column7: "hov-column-head-ver5" });
    }
  }

  actionHover(event) {
    var element = event.target.className;
    if (element === "column100 column8 ") {
      this.setState({ column8: "hov-column-head-ver5" });
    }
  }

  /**
   * Resets the state variables when hover is removed
   */
  hoverOff() {
    this.setState({
      column1: "",
      column2: "",
      column3: "",
      column4: "",
      column5: "",
      column6: "",
      column7: "",
    });
  }

  /**
   * Sets the semester selected
   */
  onDropDownSelect(event) {
    this.setState({
      selectedSemester: event.target.id,
      onFocus: false,
      background: "is-hidden",
      hasSemesterValue: true,
    });
    if (this.state.searchAllowed) {
      this.setState({ searchAllowed: false });
    }
  }

  onDropDownFocus() {
    this.setState({
      isFocussed: "is-focused",
      onFocus: true,
      background: "is-shown",
    });
    if (this.state.hasDepartmentValue) {
      this.setState({
        onDepartmentFocus: false,
        backgroundDepartment: "is-hidden",
        isDepartmentFocussed: "is-focused",
      });
    } else {
      this.setState({
        onDepartmentFocus: false,
        backgroundDepartment: "is-hidden",
        isDepartmentFocussed: "is-hidden",
      });
    }
  }

  /**
   * Allows the grid to display the values
   */
  getResult() {
    if (
      this.state.selectedSemester !== "" &&
      this.state.selectedDepartment !== ""
    ) {
      this.fetchAbsenceDetails();
    } else {
      alert("Please select department and semester");
      this.setState({ searchAllowed: false });
    }
  }

  /**
   * Fetches the date of absence based on semester selected
   */
  fetchAbsenceDetails() {
    var userID;
    this.setState({ searchAllowed: true });
    var semester = this.state.selectedSemester;
    if (this.state.isManagementPortal === true) {
      userID = { userID: this.state.selectedStudent };
    } else {
      userID = { userID: this.state.userID };
    }
    axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/attendance/studentleave/${semester}`,
        { params: userID }
      )
      .then((response) => {
        console.log(response);
        this.setState({ absenceList: response.data });
        // console.log(typeof(this.state.absenceList[1].files))
      });
  }

  /**
   * Fetches all department
   */
  fetchDepartmentDetails() {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/departmentdetail`)
      .then((response) => {
        this.setState({ departmentDetails: response.data });
      });
  }

  /**
   * Triggers when department dropdown is focused
   */
  onDeptDropDownFocus() {
    this.setState({
      isDepartmentFocussed: "is-focused",
      onDepartmentFocus: true,
      backgroundDepartment: "is-shown",
    });
    if (this.state.hasSemesterValue) {
      this.setState({
        onFocus: false,
        background: "is-hidden",
        isFocussed: "is-focused",
      });
    } else {
      this.setState({
        onFocus: false,
        background: "is-hidden",
        isFocussed: "is-hidden",
      });
    }
  }

  /**
   * Triggers when the department is changed and stores the values in state
   * @param event form values
   */
  handleDepartmentChange(event) {
    this.setState({
      selectedDepartment: event.target.id,
      onDepartmentFocus: false,
      backgroundDepartment: "is-hidden",
      background: "is-hidden",
      hasDepartmentValue: true,
    });
    this.fetchStudentDetailsByDept(event.target.id);
  }

  /**
   * Displays the list of department based on the API response
   */
  displayDepartment() {
    if (
      this.state &&
      this.state.departmentDetails &&
      this.state.departmentDetails.length
    ) {
      return this.state.departmentDetails.map((singleDepartment, index) => {
        return (
          <li className="mdl-menu__item animation label-weight" key={index}>
            <button
              id={singleDepartment.name}
              name={singleDepartment.name}
              onClick={this.handleDepartmentChange.bind(this)}
            >
              {singleDepartment.name}
            </button>
          </li>
        );
      });
    }
  }

  /**
   * Fetches all students for selected department
   */
  fetchStudentDetailsByDept(departmentName) {
    this.setState({ studentDetails: [] });
    axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/studentdetail/department/${departmentName}`
      )
      .then((response) => {
        this.setState({ studentDetails: response.data });
      });
  }

  /**
   * Removes the selected row
   * @param index selected row index
   */
  removeClick(singleData, i) {
    console.log(singleData._id);
    if (moment(new Date(singleData.fromDate)).isBefore(new Date())) {
      alert("Cannot cancel this leave. Please contact admin.");
    } else {
      if (window.confirm("Are you sure to cancel this leave?")) {
        let absenceList = [...this.state.absenceList];
        absenceList.splice(i, 1);
        this.setState({ absenceList });
        axios
          .put(
            `${process.env.PUBLIC_URL}/xakal/attendance/cancelLeave/${singleData._id}`
          )
          .then((response) => {
            // console.log(response)
            // this.setState({ studentDetails: response.data });
          });
      }
    }
  }

  approveClick(singleData, i) {
    // if (moment(new Date(singleData.fromDate)).isBefore(new Date())) {
    //   alert("Cannot cancel this leave. Please contact admin.");
    // } else {
    if (window.confirm("Are you sure to approve this leave?")) {
      let absenceList = [...this.state.absenceList];
      absenceList.splice(i, 1);
      this.setState({ absenceList });
      axios
        .put(
          `${process.env.PUBLIC_URL}/xakal/attendance/approveLeave/${singleData._id}`
        )
        .then((response) => {
          // this.setState({ studentDetails: response.data });
        });
    }
    // }
  }

  /**
   * Displays the list of notes based on the API response
   */
  displayTable() {
    const indexOfLastPost = this.state.currentPage * this.state.postsPerPage;
    const indexOfFirstPost = indexOfLastPost - this.state.postsPerPage;
    const currentPost = this.state.absenceList.slice(
      indexOfFirstPost,
      indexOfLastPost
    );

    let counter = 0;
    return currentPost.map((singleData, index) => {
      const fromDate = moment(new Date(singleData.fromDate)).format(
        "MM/DD/YYYY"
      );
      const toDate = moment(new Date(singleData.toDate)).format("MM/DD/YYYY");
      return (
        <tr className="row100">
          <td
            className="column100 grid-cont vertical-border column1"
            key={counter++}
            data-column="column1"
          >
            {++index}
          </td>
          <td
            className={"column100 grid-cont vertical-border column2 "}
            key={counter++}
            onMouseEnter={this.userHover.bind(this)}
            onMouseLeave={this.hoverOff.bind(this)}
          >
            {singleData.userID}
          </td>
          <td
            className={"column100 grid-cont vertical-border column3 "}
            key={counter++}
            onMouseEnter={this.fromDateHover.bind(this)}
            onMouseLeave={this.hoverOff.bind(this)}
          >
            {fromDate}
          </td>
          <td
            className={"column100 grid-cont vertical-border column4 "}
            key={counter++}
            onMouseEnter={this.toDateHover.bind(this)}
            onMouseLeave={this.hoverOff.bind(this)}
          >
            {toDate}
          </td>
          <td
            className={"column100 grid-cont vertical-border column5 "}
            key={counter++}
            onMouseEnter={this.reasonHover.bind(this)}
            onMouseLeave={this.hoverOff.bind(this)}
          >
            {singleData.reason}
          </td>
          <td
            className={"column100 grid-cont vertical-border column6 "}
            key={counter++}
            onMouseEnter={this.typeHover.bind(this)}
            onMouseLeave={this.hoverOff.bind(this)}
          >
            {singleData.type}
          </td>
          <td
            className={"column100 grid-cont vertical-border column6 "}
            key={counter++}
            onMouseEnter={this.typeHover.bind(this)}
            onMouseLeave={this.hoverOff.bind(this)}
          >
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={singleData.url ? singleData.url : ""}
            >
              <FontAwesomeIcon
                className="fa-sm vertical-border download"
                icon={faDownload}
              />
            </a>
          </td>
          {this.state.isManagementPortal ? (
            <td
              className={"column100 vertical-border column7 "}
              key={counter++}
              onMouseEnter={this.actionHover.bind(this)}
              onMouseLeave={this.hoverOff.bind(this)}
            >
              <button
                type="button"
                onClick={(i) => this.removeClick(singleData, (index -= 4))}
                className="btn btn-danger m-t-4 m-r-2"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={(i) => this.approveClick(singleData, (index -= 4))}
                className="btn btn-success m-t-4 m-l-2"
              >
                Approve
              </button>
            </td>
          ) : (
            <></>
          )}
        </tr>
      );
    });
  }

  paginate(num) {
    this.setState({ currentPage: num });
  }

  /**
   * Triggers when student is focused
   */
  onStudentFocussed() {
    this.setState({
      isStudentFocussed: "is-focused",
      onFocus: false,
      onStudentFocus: true,
      backgroundStudent: "is-shown",
    });
  }

  /**
   * Triggers when the student is changed and stores the values in state
   * @param event form values
   */
  handleStudentChange(event) {
    this.setState({
      selectedStudentName: event.target.name,
      selectedStudent: event.target.id,
      onStudentFocus: false,
      backgroundStudent: "is-hidden",
    });
  }

  /**
   * Displays the list of student based on the API response
   */
  displayStudent() {
    if (
      this.state &&
      this.state.studentDetails &&
      this.state.studentDetails.length
    ) {
      return this.state.studentDetails.map((singleStudent, index) => {
        return (
          <li className="mdl-menu__item animation label-weight" key={index}>
            <button
              id={singleStudent.userID}
              name={singleStudent.name}
              onClick={this.handleStudentChange.bind(this)}
            >
              {singleStudent.name}
            </button>
          </li>
        );
      });
    }
  }

  render() {
    return (
      <div className="container-fluid background bk resp">
        <div className="d-sm-flex">
          <nav className="navbar navbar-expand-lg  navprac1 bar attre">
            <div className="title">Attendance</div>
            <div className="collapse navbar-collapse">
              <form className="form-inline my-2 my-lg-0">
                <input
                  style={{
                    width: "500px",
                    marginLeft: "120px",
                    backgroundColor: "rgba(54, 42, 65, 0.04)",
                    height: "45px",
                    fontStyle: "italic",
                  }}
                  className="form-control mr-sm-2 "
                  type="search"
                  placeholder="Search....."
                  aria-label="Search"
                ></input>
                {/* <i class="fal fa-search"></i> */}
              </form>
            </div>
          </nav>
          <div className="row">
            <h4
              className="head attress"
              style={{
                marginLeft: "200px",
                marginTop: "150px",
                width: "160px",
              }}
            >
              Check Attendance{" "}
            </h4>
          </div>
          {/* <h1 className="h3 mb-0 text-gray-800 m-t-20">Notes</h1> */}

          <div className="row m-r-20">
            <div className="col-sm-12">
              <div>
                <div>
                  {/* {this.state.isManagementPortal ? (
                    <div className="col-sm-3 p-t-20">
                      <div
                        style={{ marginTop: "150px", marginLeft: "-130px" }}
                        className={
                          "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width " +
                          this.state.isDepartmentFocussed
                        }
                      >
                        <label className="input-text">Department</label>
                        <input
                          name="selectedDepartment"
                          onKeyPress={(e) => e.preventDefault()}
                          autoComplete="off"
                          onFocus={this.onDeptDropDownFocus.bind(this)}
                          className="new-xakal-input"
                          type="text"
                          id={`department`}
                          value={this.state.selectedDepartment}
                          onChange={this.handleDepartmentChange.bind(this)}
                        />

                        {this.state.onDepartmentFocus ? (
                          <div
                            className="mdl-menu__container is-upgraded dropdown-list is-visible"
                            style={{ marginTop: "20px" }}
                          >
                            <div className="mdl-menu__outline mdl-menu--bottom-left dropdown-div">
                              <ul className="scrollable-menu mdl-menu mdl-menu--bottom-left mdl-js-menu ul-list">
                                {this.displayDepartment()}
                              </ul>
                            </div>
                          </div>
                        ) : (
                          <p></p>
                        )}
                      </div>
                    </div>
                  ) : (
                    <p></p>
                  )} */}
                  <div className="col-sm-3 p-t-20 ">
                    <div className="is-focused">
                      <div
                        className={
                          // "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width " +
                          this.state.isFocussed
                        }
                      >
                        <label
                          className="input-text labell attres att-max-sem"
                          style={{ marginLeft: "-125px", marginTop: "140px" }}
                        >
                          Semester Number
                        </label>
                        <input
                          onKeyPress={(e) => e.preventDefault()}
                          autoComplete="off"
                          onFocus={this.onDropDownFocus.bind(this)}
                          className="new-xakal-input background height attres att-max "
                          type="text"
                          id="Semester"
                          value={this.state.selectedSemester}
                          style={{ marginLeft: "-125px", width: "368px" }}
                        />

                        {this.state.onFocus ? (
                          <div
                            className="mdl-menu__container is-upgraded dropdown-list is-visible dropd"
                            style={{
                              width: "368px",
                              marginLeft: "-170%",
                              marginTop: "140%",
                            }}
                          >
                            <div
                              className="mdl-menu__outline  dropdown-div dropd"
                              style={{
                                width: "368px",
                                marginLeft: "63px",
                                marginTop: "10px",
                              }}
                            >
                              <ul
                                className="scrollable-menu  ul-list"
                                style={{ width: "368%" }}
                              >
                                <li
                                  className="mdl-menu__item animation"
                                  id="Semester 1"
                                  style={{ width: "100%" }}
                                  onClick={this.onDropDownSelect.bind(this)}
                                >
                                  Semester 1
                                </li>
                                <li
                                  className="mdl-menu__item animation1"
                                  id="Semester 2"
                                  onClick={this.onDropDownSelect.bind(this)}
                                >
                                  Semester 2
                                </li>
                                <li
                                  className="mdl-menu__item animation2"
                                  id="Semester 3"
                                  onClick={this.onDropDownSelect.bind(this)}
                                >
                                  Semester 3
                                </li>
                                <li
                                  className="mdl-menu__item animation"
                                  id="Semester 4"
                                  style={{ width: "200px" }}
                                  onClick={this.onDropDownSelect.bind(this)}
                                >
                                  Semester 4
                                </li>
                                <li
                                  className="mdl-menu__item animation1"
                                  id="Semester 5"
                                  onClick={this.onDropDownSelect.bind(this)}
                                >
                                  Semester 5
                                </li>
                                <li
                                  className="mdl-menu__item animation2"
                                  id="Semester 6"
                                  onClick={this.onDropDownSelect.bind(this)}
                                >
                                  Semester 6
                                </li>
                                <li
                                  className="mdl-menu__item animation"
                                  id="Semester 7"
                                  onClick={this.onDropDownSelect.bind(this)}
                                >
                                  Semester 7
                                </li>
                                <li
                                  className="mdl-menu__item animation1"
                                  id="Semester 8"
                                  onClick={this.onDropDownSelect.bind(this)}
                                >
                                  Semester 8
                                </li>
                              </ul>
                            </div>
                          </div>
                        ) : (
                          <p></p>
                        )}
                      </div>
                    </div>
                  </div>
                  {this.state.isManagementPortal ? (
                    <div>
                      <div className={this.state.isStudentFocussed}>
                        {/* <label
                        className="input-text"
                      >
                        Student
                      </label>
                      <input
                        onKeyPress={(e) => e.preventDefault()}
                        onFocus={this.onStudentFocussed.bind(this)}
                        autoComplete="off"
                        className="new-xakal-input"
                        type="text"
                        id="selectedStudent"
                        value={this.state.selectedStudentName}
                        onChange={this.handleStudentChange.bind(this)}
                        name="selectedStudent"
                      /> */}

                        {this.state.onStudentFocus ? (
                          <div className="mdl-menu__container is-upgraded dropdown-list is-visible">
                            <div
                              className="mdl-menu__outline mdl-menu--bottom-left dropdown-div"
                              style={{ marginLeft: "-" }}
                            >
                              <ul className="scrollable-menu mdl-menu mdl-menu--bottom-left mdl-js-menu ul-list">
                                {this.displayStudent()}
                              </ul>
                            </div>
                          </div>
                        ) : (
                          <p></p>
                        )}
                      </div>
                    </div>
                  ) : (
                    <p></p>
                  )}
                  <div className="col-sm-3  p-t-20 ">
                    <button
                      type="button"
                      onClick={this.getResult.bind(this)}
                      className="getresult heading-weight button-size attres att-max "
                      style={{
                        marginTop: "-80px",
                        marginLeft: "260px",
                        height: "40px",
                      }}
                    >
                      Get Results
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {this.state.searchAllowed ? (
            <>
              <div className=" cards">
                <div className="row bk">
                  <div className="col-sm-3 box-card box-res">
                    <h4 className="card-text">Student ID</h4>
                    <h2 className="text-cont">13IT125</h2>
                  </div>
                  <div className="col-sm-3  b-card1 box-res1">
                    <h4 className="card-text">
                      No. of working days in the semester
                    </h4>
                    <h2 className="text-cont">138</h2>
                  </div>
                  <div className="col-sm-3 box-card2 box-res2">
                    <h4 className="card-text">No. of leaves</h4>
                    <h2 className="text-cont">2</h2>
                  </div>
                  <div className="col-sm-3  box-card3 box-res3">
                    <h4 className="card-text">No. of ODs</h4>
                    <h2 className="text-cont">1</h2>
                  </div>
                </div>
              </div>
              <div className="bk">
                <div className="container">
                  <table
                    class="table"
                    style={{
                      marginTop: "300px",
                      marginLeft: "-1800px",
                      width: "1070px",
                    }}
                  >
                    <thead style={{ color: "#B8B3BC" }}>
                      <tr className="row100 head">
                        <th
                          className=" t-res column100 vertical-border-head heading-weight heading-size column1"
                          data-column="column1"
                        >
                          S. No..
                        </th>
                        <th
                          className={
                            " t-res column100 vertical-border-head heading-weight heading-size column2 " +
                            this.state.column2
                          }
                          onMouseEnter={this.userHover.bind(this)}
                          onMouseLeave={this.hoverOff.bind(this)}
                        >
                          Student ID
                        </th>
                        <th
                          className={
                            "column100 vertical-border-head heading-weight heading-size column3 " +
                            this.state.column3
                          }
                          onMouseEnter={this.fromDateHover.bind(this)}
                          onMouseLeave={this.hoverOff.bind(this)}
                        >
                          From Date
                        </th>
                        <th
                          className={
                            "column100 vertical-border-head heading-weight heading-size column4 " +
                            this.state.column4
                          }
                          onMouseEnter={this.toDateHover.bind(this)}
                          onMouseLeave={this.hoverOff.bind(this)}
                        >
                          To Date
                        </th>
                        <th
                          className={
                            "column100 vertical-border-head heading-weight heading-size column5 " +
                            this.state.column5
                          }
                          onMouseEnter={this.reasonHover.bind(this)}
                          onMouseLeave={this.hoverOff.bind(this)}
                        >
                          Reason
                        </th>
                        <th
                          className={
                            "column100 vertical-border-head heading-weight heading-size column6 " +
                            this.state.column6
                          }
                          onMouseEnter={this.typeHover.bind(this)}
                          onMouseLeave={this.hoverOff.bind(this)}
                        >
                          Type
                        </th>
                        <th
                          className={
                            "column100 vertical-border-head heading-weight heading-size column7 " +
                            this.state.column7
                          }
                          onMouseEnter={this.fileHover.bind(this)}
                          onMouseLeave={this.hoverOff.bind(this)}
                        >
                          File
                        </th>
                        {this.state.isManagementPortal ? (
                          <th
                            className={
                              "column100 vertical-border-head heading-weight heading-size column8 " +
                              this.state.column7
                            }
                            onMouseEnter={this.actionHover.bind(this)}
                            onMouseLeave={this.hoverOff.bind(this)}
                          >
                            Action
                          </th>
                        ) : (
                          <></>
                        )}
                      </tr>
                    </thead>
                    <tbody>{this.displayTable()}</tbody>
                  </table>
                </div>

                <div className="left">
                  <Pagination
                    postPerPage={this.state.postsPerPage}
                    totalPost={this.state.absenceList.length}
                    paginate={this.paginate}
                  />
                </div>
              </div>
            </>
          ) : (
            <span></span>
          )}
        </div>
      </div>
    );
  }
}

export default Attendance;
