import React, { Component } from "react";
// import axios from "axios";
// import moment from "moment";
import "../../../../styles/table.css";
import "../../../../minified-css/material-min.css";
import "../../../../styles/dropdowns.css";
import "../../../../styles/theme-style.css";

class UpdateBusTemplate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ...this.props.state
        }
    }

    componentDidMount() {

    }

    handleUpdateBusRoutes(update) {
        this.props.handleAddBusRoutes(true, this.props.bus.busStops, [1], update);
    }

    handleAddBus() {
        this.props.handleAddBus();
    }

    handleCancelUpdate() {
        this.props.cancelUpdate(this.props.bus._id);
    }

    updateBus() {
        this.props.updateBus(this.props.bus._id);
    }

    render() {
        return (
            <div class="item col-md-3" style={{ margin: 10, backgroundColor: '#002933' }}>
                <div className="center">
                    <input onChange={this.props.changeBusNumber.bind(this)} placeholder="Enter Bus Number" style={{ textAlign: 'center', marginTop: 20 }} />
                </div>
                <div className="center" style={{ marginTop: 50 }}>
                    <h4 style={{ textAlign: 'center' }}>Update Bus Route</h4>
                    <img onClick={() => this.handleUpdateBusRoutes(true)} style={{ marginTop: 20, width: 50, height: 50, objectFit: 'contain', borderRadius: '50%' }} className="hover" alt="vector location icon" src="https://png.pngtree.com/png-clipart/20190619/original/pngtree-vector-location-icon-png-image_3997521.jpg" />
                </div>
                <div style={{ marginTop: 50, padding: 5 }} className="bus-section">
                    {
                        this.props.selectedBusStops && this.props.selectedBusStops.length > 0
                            ?
                            <span>
                                <span>College -&nbsp;</span>
                                {
                                    this.props.selectedBusStops.map((stop, i) => (
                                        (i !== this.props.selectedBusStops.length - 1)
                                            ?
                                            <span>{stop.name} -&nbsp;</span>
                                            :
                                            <span>{stop.name}</span>
                                    )
                                    )
                                }
                            </span>
                            :
                            <span>
                                <span>College -&nbsp;</span>
                                {
                                    this.props.bus && this.props.bus.busStops.length > 0 ? this.props.bus.busStops.map((stop, i) => (
                                        (i !== this.props.bus.busStops.length - 1)
                                            ?
                                            <span>{stop.name} -&nbsp;</span>
                                            :
                                            <span>{stop.name}</span>
                                    )
                                    )
                                        : <></>
                                }
                            </span>
                    }
                </div>
                <div style={{ marginTop: 50, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <button
                        type="button"
                        className="btn btn-dark m-t-15 m-l-30"
                        onClick={this.handleCancelUpdate.bind(this)}
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary m-t-15 m-l-30"
                        onClick={this.updateBus.bind(this)}
                    >
                        Save
                    </button>
                </div>
            </div>
        )
    }
}

export default UpdateBusTemplate;