import React, { Component } from 'react';

class StaffName extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div>
                {
                    this.props.subjects
                        ?
                        this.props.subjects.map(sub =>
                            sub.staff.length
                                ?
                                sub.staff.length > 1
                                    ?
                                    <table>
                                        <tr>
                                            <td>
                                                <select onChangeCapture={(e) => this.props.handleChangeInput(e, sub._id, true)} style={{ border: '0px', outline: '0px', width: '60%' }}>
                                                    {
                                                        sub.staff.map((stf, i) => (
                                                            <option value={stf._id} selected={i == 1 ? true : false}>{stf.name}</option>)
                                                        )
                                                    }
                                                </select>
                                            </td>
                                        </tr>
                                    </table>
                                    :
                                    <table>
                                        <tr>
                                            <td>
                                                {sub.staff[0].name}
                                            </td>
                                        </tr>
                                    </table>
                                :
                                <table>
                                    <tr>
                                        <td>Not Assigned</td>
                                    </tr>
                                </table>
                        )
                        :
                        <></>
                }
            </div>
        );
    }
}

export default StaffName;