import React, { Component } from "react";
import "../../../styles/table.css";
import "../../../minified-css/material-min.css";
import "../../../styles/dropdowns.css";
import "../../../styles/theme-style.css";
import "../../../styles/classnote.css";

import axios from "axios";
import moment from "moment";
import { Redirect } from "react-router-dom";
import CreatedTimetable from "./created-timetable";
import { getAllDepartments } from '../../../api/department'
import { addSubjects, getClassByQuery } from '../../../api/classroom'
import { createTimeTable } from '../../../api/timetable'

class CreateStudentTimetable extends Component {
  constructor(props) {
    super(props);

    this.onChangeSelectedSemester = this.onChangeSelectedSemester.bind(this);
    this.onChangeForm = this.onChangeForm.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.validation_name = this.validation_name.bind(this);
    this.validation_code = this.validation_code.bind(this);
    this.validation_per_week = this.validation_per_week.bind(this);
    this.validation_per_day = this.validation_per_day.bind(this);

    this.state = {
      column1: "",
      column2: "",
      number_of_rows: 0,
      days_in_a_week: '',
      periods_in_a_day: '',
      selected_practical_in_day: "",
      table_values: [
        {
          subjectName: null,
          subjectCode: null,
          isPractical: false,
          numOfPeriodsPerWeek: null,
          minPeriodsPerDay: null
        },
      ],
      subject_name: [],
      fruit_array: [
        { a: "mango", name: "M" },
        { a: "apple", name: "A" },
        { a: "cherry", name: "M" },
      ],
      subject_code: "",
      practical: "",
      periods_per_week: "",
      min_periods: "",
      validate_name: "",
      validate_code: "",
      validate_per_week: "",
      validate_per_day: "",
      searchAllowed: false,
      absenceList: [],
      background: "",
      isFocussed: "",
      onFocus: false,
      userID: "",
      isManagementPortal: false,
      toDashboard: false,
      name: "jivika",
      availableDepartments: [],
      department: {},
      availableClasses: [],
      classTemplate: [],
      currentSubject: {
        subjectName: "",
        subjectCode: "",
        isPractical: false,
        numOfPeriodsPerWeek: 0,
        minPeriodsPerDay: 0,
      },
      subjects: [],
      availableSubjects: [],
      subjectsTemplate: [],
      selectedSubjectCodes: []
    };
    // this.baseState = this.state;
  }

  componentDidUpdate() {
    if (this.state.table_values) {
      console.log('table_values', this.state.table_values)
    }
    if (this.state.subjectsTemplates) {
      console.log('subjectsTemplates', this.state.subjectsTemplates)
    }
    if (this.state.selectedSubjectCodes) {
      console.log('selectedSubjectCodes', this.state.selectedSubjectCodes)
    }
  }

  onChangeSelectedSemester(e) {
    this.setState({ selectedSemester: e.target.value });
  }
  onChangeSubjectName(e) {
    this.setState({ subject_name: e.target.value });
  }

  onChangeSubjectNameMulti(i, e) {
    let table_values = [...this.state.table_values];
    table_values[i][e.target.name] = e.target.value;
    this.setState({ table_values });
    // console.log( this.state.table_values );
  }

  onChangeForm(event, index) {
    if (event.target.name == 'department') return this.getClassDetails(event.target.value)
    if (event.target.name == 'className') return this.getSubjectDetails(event.target.value)
    if (event.target.name == 'subjectName') this.getSubjectCode(event.target.value)
    let form = this.state.table_values;
    console.log('form', form)
    if (index) {
      let subjectCode = this.state.selectedSubjectCodes[index]
      form[index][event.target.name] = event.target.value;
      form[index]['subjectCode'] = subjectCode;
    }
    this.setState({
      table_values: form
    });
  }

  async onSubmit(e) {
    e.preventDefault();
    const data = {
      department: this.state.department,
      className: this.state.className,
      days_in_a_week: this.state.days_in_a_week,
      periods_in_a_day: this.state.periods_in_a_day,
      subjects: this.state.table_values
    };
    console.log('step1 form', data)
    const result = await createTimeTable(data)
    alert(result.msg)
    this.props.history.push({
      pathname: "/management-portal/created-timetable",
      state: data
    });
  }

  componentDidMount() {
    this.getPortal();
    this.generate_departments()
    if (this.props && this.props.location && this.props.location.userID) {
      const userID = this.props.location.userID;
      this.setState({ userID: userID.userID });
    }
    this.unlisten = this.props.history.listen((location, action) => {
      this.setState(this.baseState);
      this.getPortal();
    });
  }

  /**
   * Checks for portal logged in
   */
  getPortal() {
    const pathArray = this.props.location.pathname.split("/");
    if (
      pathArray.includes("management-portal") ||
      pathArray.includes("hod-portal")
    ) {
      this.setState({ isManagementPortal: true });
      this.fetchDepartmentDetails();
    } else {
      this.setState({ isManagementPortal: false });
    }
  }

  componentWillUnmount() {
    this.unlisten();
  }

  /**
   * Adds the hover class when date is hovered
   */
  dateHover(event) {
    var element = event.target.className;
    if (element === "column100 column2 ") {
      this.setState({ column1: "hov-column-head-ver5" });
    }
  }

  /**
   * Adds the hover class when reason is hovered
   */
  reasonHover(event) {
    var element = event.target.className;
    if (element === "column100 column3 ") {
      this.setState({ column2: "hov-column-head-ver5" });
    }
  }

  typeHover(event) {
    var element = event.target.className;
    if (element === "column100 column4 ") {
      this.setState({ column2: "hov-column-head-ver5" });
    }
  }

  actionHover(event) {
    var element = event.target.className;
    if (element === "column100 column5 ") {
      this.setState({ column2: "hov-column-head-ver5" });
    }
  }

  /**
   * Adds the hover class when userID is hovered
   */
  userHover(event) {
    var element = event.target.className;
    if (element === "column100 column4 ") {
      this.setState({ column3: "hov-column-head-ver5" });
    }
  }

  /**
   * Resets the state variables when hover is removed
   */
  hoverOff() {
    this.setState({
      column1: "",
      column2: "",
      column3: "",
      column4: "",
      column5: "",
    });
  }

  /**
   * Sets the semester selected
   */
  onDropDownSelect(event) {
    this.setState({
      selectedSemester: event.target.id,
      onFocus: false,
      background: "is-hidden",
      hasSemesterValue: true,
    });
    if (this.state.searchAllowed) {
      this.setState({ searchAllowed: false });
    }
  }

  onDropDownFocus() {
    this.setState({
      isFocussed: "is-focused",
      onFocus: true,
      background: "is-shown",
    });
    if (this.state.hasDepartmentValue) {
      this.setState({
        onDepartmentFocus: false,
        backgroundDepartment: "is-hidden",
        isDepartmentFocussed: "is-focused",
      });
    } else {
      this.setState({
        onDepartmentFocus: false,
        backgroundDepartment: "is-hidden",
        isDepartmentFocussed: "is-hidden",
      });
    }
  }

  /**
   * Allows the grid to display the values
   */
  getResult() {
    if (
      this.state.selectedSemester !== "" &&
      this.state.selectedDepartment !== ""
    ) {
      this.fetchAbsenceDetails();
    } else {
      alert("Please select department and semester");
      this.setState({ searchAllowed: false });
    }
  }

  /**
   * Fetches the date of absence based on semester selected
   */
  fetchAbsenceDetails() {
    var userID;
    this.setState({ searchAllowed: true });
    var semester = this.state.selectedSemester;
    if (this.state.isManagementPortal === true) {
      userID = { userID: this.state.selectedStudent };
    } else {
      userID = { userID: this.state.userID };
    }
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/attendance/studentleave/${semester}`, { params: userID })
      .then((response) => {
        console.log("al", response.data);
        this.setState({ absenceList: response.data });
      });
  }

  /**
   * Fetches all department
   */
  fetchDepartmentDetails() {
    axios.get(`${process.env.PUBLIC_URL}/xakal/departmentdetail`).then((response) => {
      this.setState({ departmentDetails: response.data.data });
    });
  }

  /**
   * Triggers when department dropdown is focused
   */
  onDeptDropDownFocus() {
    this.setState({
      isDepartmentFocussed: "is-focused",
      onDepartmentFocus: true,
      backgroundDepartment: "is-shown",
    });
    if (this.state.hasSemesterValue) {
      this.setState({
        onFocus: false,
        background: "is-hidden",
        isFocussed: "is-focused",
      });
    } else {
      this.setState({
        onFocus: false,
        background: "is-hidden",
        isFocussed: "is-hidden",
      });
    }
  }

  /**
   * Triggers when the department is changed and stores the values in state
   * @param event form values
   */
  handleDepartmentChange(event) {
    this.setState({
      selectedDepartment: event.target.id,
      onDepartmentFocus: false,
      backgroundDepartment: "is-hidden",
      background: "is-hidden",
      hasDepartmentValue: true,
    });
    this.fetchStudentDetailsByDept(event.target.id);
  }

  /**
   * Displays the list of department based on the API response
   */
  displayDepartment() {
    if (
      this.state &&
      this.state.departmentDetails &&
      this.state.departmentDetails.length
    ) {
      return this.state.departmentDetails.map((singleDepartment, index) => {
        return (
          <li className="mdl-menu__item animation" key={index}>
            <button
              id={singleDepartment.name}
              name={singleDepartment.name}
              onClick={this.handleDepartmentChange.bind(this)}
            >
              {singleDepartment.name}
            </button>
          </li>
        );
      });
    }
  }

  /**
   * Fetches all students for selected department
   */
  fetchStudentDetailsByDept(departmentName) {
    this.setState({ studentDetails: [] });
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/studentdetail/department/${departmentName}`)
      .then((response) => {
        this.setState({ studentDetails: response.data });
      });
  }

  /**
   * Removes the selected row
   * @param index selected row index
   */
  removeClick(singleData, i) {
    if (moment(new Date(singleData.fromDate)).isBefore(moment(new Date()))) {
      alert("Cannot cancel this leave. Please contact admin.");
    } else {
      if (window.confirm("Are you sure to cancel this leave?")) {
        let absenceList = 1;
        absenceList.splice(i, 1);
        this.setState({ absenceList });
        axios
          .put(`${process.env.PUBLIC_URL}/xakal/attendance/cancelLeave/${singleData._id}`)
          .then((response) => {
            // this.setState({ studentDetails: response.data });
          });
      }
    }
  }

  onDropDownFocusIn(event) {
    if (event.target) {
      event.target.parentNode.classList.add("is-focused");
      event.target.nextSibling.classList.add("is-shown");
    }
  }

  onFocusOut(event) {
    if (event.target) {
      if (event.target.value === "") {
        event.target.parentNode.classList.remove("is-focused");
      }
      event.target.nextSibling.classList.remove("is-shown");
    }
  }

  /**
   * Displays the list of notes based on the API response
   */

  tt = [
    {
      one: "Physics 1",
      two: "chemistry 1",
      three: "Maths 1",
      four: "EVS 1",
      five: "OOAD 1",
    },
    {
      one: "Physics 2",
      two: "chemistry 2",
      three: "Maths 2",
      four: "EVS 2",
      five: "OOAD 2",
    },
    {
      one: "Physics 3",
      two: "chemistry 3",
      three: "Maths 3",
      four: "EVS 3",
      five: "OOAD 3",
    },
    {
      one: "Physics 4",
      two: "chemistry 4",
      three: "Maths 4",
      four: "EVS 4",
      five: "OOAD 4",
    },
    {
      one: "Physics 5",
      two: "chemistry 5",
      three: "Maths 5",
      four: "EVS 5",
      five: "OOAD 5",
    },
    {
      one: "Physics 6",
      two: "chemistry 6",
      three: "Maths 6",
      four: "EVS 6",
      five: "OOAD 6",
    },
    {
      one: "Physics 7",
      two: "chemistry 7",
      three: "Maths 7",
      four: "EVS 7",
      five: "OOAD 7",
    },
    {
      one: "Physics 8",
      two: "chemistry 8",
      three: "Maths 8",
      four: "EVS 8",
      five: "OOAD 8",
    },
  ];

  displayTable() {
    // let counter = 0;
    return this.state.absenceList.map((singleData, index) => {
      // const fromDate = moment(new Date(singleData.fromDate)).format(
      //   "MM/DD/YYYY"
      // );
      // const toDate = moment(new Date(singleData.toDate)).format("MM/DD/YYYY");
      return (
        <>
          {this.tt.map((i) => (
            <tr className="row100">
              <td className="column100 column1" data-column="column1">
                {++index}
              </td>
              <td
                className={"column100 column2 "}
                onMouseEnter={this.userHover.bind(this)}
                onMouseLeave={this.hoverOff.bind(this)}
              >
                {i.one}
              </td>
              <td
                className={"column100 column2 "}
                onMouseEnter={this.dateHover.bind(this)}
                onMouseLeave={this.hoverOff.bind(this)}
              >
                {i.two}
              </td>
              <td
                className={"column100 column3 "}
                onMouseEnter={this.reasonHover.bind(this)}
                onMouseLeave={this.hoverOff.bind(this)}
              >
                {i.three}
              </td>
              <td
                className={"column100 column4 "}
                onMouseEnter={this.typeHover.bind(this)}
                onMouseLeave={this.hoverOff.bind(this)}
              >
                {i.four}
              </td>
              <td
                className={"column100 column5 "}
                onMouseEnter={this.typeHover.bind(this)}
                onMouseLeave={this.hoverOff.bind(this)}
              >
                {i.five}
              </td>
            </tr>
          ))}
        </>
      );
    });
  }

  /**
   * Triggers when student is focused
   */
  onStudentFocussed() {
    this.setState({
      isStudentFocussed: "is-focused",
      onFocus: false,
      onStudentFocus: true,
      backgroundStudent: "is-shown",
    });
  }

  /**
   * Triggers when the student is changed and stores the values in state
   * @param event form values
   */
  handleStudentChange(event) {
    this.setState({
      selectedStudentName: event.target.name,
      selectedStudent: event.target.id,
      onStudentFocus: false,
      backgroundStudent: "is-hidden",
    });
  }

  /**
   * Displays the list of student based on the API response
   */
  displayStudent() {
    if (
      this.state &&
      this.state.studentDetails &&
      this.state.studentDetails.length
    ) {
      return this.state.studentDetails.map((singleStudent, index) => {
        return (
          <li className="mdl-menu__item animation" key={index}>
            <button
              id={singleStudent.userID}
              name={singleStudent.name}
              onClick={this.handleStudentChange.bind(this)}
            >
              {singleStudent.name}
            </button>
          </li>
        );
      });
    }
  }

  tt = [
    {
      name: "Monday",
    },
    {
      name: "Tuesday",
    },
    {
      name: "Wednesday",
    },
    {
      name: "Thursday",
    },
    {
      name: "Friday",
    },
  ];

  period = [
    {
      name: "Period 1",
      stime: "Period 1 Starting Time",
      etime: "Period 1 Ending Time",
    },
    {
      name: "Period 2",
      stime: "Period 2 Starting Time",
      etime: "Period 2 Ending Time",
    },
    {
      name: "Period 3",
      stime: "Period 3 Starting Time",
      etime: "Period 3 Ending Time",
    },
    {
      name: "Period 4",
      stime: "Period 4 Starting Time",
      etime: "Period 4 Ending Time",
    },
    {
      name: "Period 5",
      stime: "Period 5 Starting Time",
      etime: "Period 5 Ending Time",
    },
    {
      name: "Period 6",
      stime: "Period 6 Starting Time",
      etime: "Period 6 Ending Time",
    },
  ];

  resetForm() {
    this.setState({
      values: [
        {
          name: "",
          designation: "",
          qualification: "",
          contact: "",
          email: "",
          departmentName: "",
          emergencyContact: "",
          joiningDate: moment(new Date()).format("hh:mm A"),
        },
      ],
    });
  }

  handleFormChange(i, event) {
    if (event && event.target) {
      let values = [...this.state.values];
      const { name, value } = event.target;
      values[i][name] = value;
      this.setState({ values });
    }
  }

  handleCheckClick = (i, event) => {
    let values = [...this.state.values];
    values[i]["isElective"] = event.target.checked;
    this.setState({ values });
  };

  handleSubmit = () => {
    this.setState(() => ({ toDashboard: true }));
  };

  generate_departments = async () => {
    try {
      let departments = await getAllDepartments();
      departments = departments.map(dept => ({ _id: dept._id, name: dept.name }))
      let deptTemplate = []
      for (const dept of departments) {
        deptTemplate.push(<option value={dept.name}>{dept.name}</option>);
      }
      this.setState({ availableDepartments: deptTemplate })
    } catch (err) {
      console.log(err)
    }
  };

  async getClassDetails(department) {
    try {
      let classes = await getClassByQuery({ department });
      if (!classes.length) return this.setState({ availableClasses: [] })
      console.log('class', classes)
      let classTemplate = []
      for (const cls of classes) {
        classTemplate.push(<option value={cls._id}>{cls.name}</option>);
      }
      this.setState({ availableClasses: classes, classTemplate })
    } catch (err) {
      console.log(err)
    }
  }

  async getSubjectDetails(classId) {
    try {
      if (!classId) return;
      console.log({ classId, availableClasses: this.state.availableClasses })
      let selectedClass = this.state.availableClasses.find(c => c._id.toString() == classId.toString())
      if (!selectedClass) return console.log('selectedClass', selectedClass)
      let availableSubjects = selectedClass.subjects;
      console.log({ availableSubjects, selectedClass })
      let subjectsTemplate = []
      for (const sub of availableSubjects) {
        subjectsTemplate.push(<option value={sub._id} data-subject-id={sub.subject._id} data-subject-staff-id={sub.staff._id} data-subject-staff-name={sub.staff.name}>{sub.subject.name}</option>);
      }
      this.setState({ availableSubjects, subjectsTemplate })
    } catch (err) {
      console.log(err)
    }
  }

  async getSubjectCode(subjectId) {
    try {
      if (!subjectId) return;
      let selectedSubject = this.state.availableSubjects.find(c => c._id.toString() == subjectId.toString())
      console.log('selectedSubject', selectedSubject)
      if (!selectedSubject) return;
      let selectedSubjectCodes = this.state.selectedSubjectCodes;
      selectedSubjectCodes.push(selectedSubject.subject.code)
      console.log('selectedSubjectCodes', selectedSubjectCodes)
      this.setState({ selectedSubjectCodes })
    } catch (err) {
      console.log(err)
    }
  }

  generate_days = () => {
    const days = [];
    for (var i = 1; i < 6; i++) {
      days.push(<option value={i}>{` ${i} Days in a week`} </option>);
    }
    return days;
  };

  generate_periods = () => {
    const periods = [];
    for (let i = 1; i <= 20; i++) {
      periods.push(<option value={i}>{` ${i} Periods in a day`} </option>);
    }
    return periods;
  };

  generate_practical_periods = () => {
    const practical_periods = [];
    for (let i = 1; i <= 20; i++) {
      practical_periods.push(
        <option value={i}>{` ${i} Practical Periods`} </option>
      );
    }
    return practical_periods;
  };

  add_row = (e) => {
    e.preventDefault();
    this.setState({ number_of_rows: this.state.number_of_rows + 1 });
    this.setState((prevState) => ({
      table_values: [
        ...prevState.table_values,
        {
          subjectName: null,
          subjectCode: null,
          isPractical: null,
          numOfPeriodsPerWeek: null,
          minPeriodsPerDay: null,
        },
      ],
      currentSubject: {}
    }));
  };

  generate_row = () => {
    const row = [];
    for (let i = 0; i < this.state.number_of_rows; i++) {
      row.push(
        <tr key={i}>
          <td>
            <select defaultValue={'subject name'} name='subjectName' onChange={(e) => this.onChangeForm(e)}>
              <option value={'Subject Name'}>Subject Name</option>
              {this.state.subjectsTemplates.length > 0 && this.state.subjectsTemplates[i]}
            </select>
          </td>

          <td>
            <input type='text' disabled value={this.state.selectedSubjectCodes.length > 0 ? this.state.selectedSubjectCodes[i] : ''} />
          </td>
          <td>
            {" "}
            <input
              type="checkbox"
              name="isPractical"
              onChange={this.collectSubjectDetails.bind(this)}
            />{" "}
          </td>
          <td>
            {" "}
            <input
              type="text"
              name="numOfPeriodsPerWeek"
              onChange={this.collectSubjectDetails.bind(this)}
            />{" "}
          </td>
          <td>
            {" "}
            <input
              type="text"
              name="minPeriodsPerDay"
              onChange={this.collectSubjectDetails.bind(this)}
            />{" "}
          </td>
          <td>
            {" "}
            <button className="btn btn-primary"> x </button>{" "}
          </td>
        </tr>
      );
    }
    return row;
  };

  validation_name = (e) => {
    if (e.target.value.length >= 2 && e.target.value.length <= 25) {
      this.setState({ subject_name: e.target.value });
      this.setState({ validate_name: "entered input is correct" });
    } else {
      this.setState({ validate_name: "entered input is not correct" });
    }
  };

  validation_code = (e) => {
    if (e.target.value.length >= 2 && e.target.value.length <= 10) {
      this.setState({ subject_code: e.target.value });
      this.setState({ validate_code: "entered input is correct" });
    } else {
      this.setState({ validate_code: "entered input is not correct" });
    }
  };

  validation_per_week = (e) => {
    if (e.target.value.length >= 1 && e.target.value.length <= 20) {
      this.setState({ periods_per_week: e.target.value });
      this.setState({ validate_per_week: "entered input is correct" });
    } else {
      this.setState({ validate_per_week: "entered input is not correct" });
    }
  };

  validation_per_day = (e) => {
    if (e.target.value.length >= 1 && e.target.value.length <= 6) {
      this.setState({ min_periods: e.target.value });
      this.setState({ validate_per_day: "entered input is correct" });
    } else {
      this.setState({ validate_per_day: "entered input is not correct" });
    }
  };

  collectSubjectDetails(e) {
    const currentSubject = {
      ...this.state.currentSubject,
      [e.target.name]: e.target.type == 'checkbox' ? e.target.checked : e.target.value
    }
    console.log('currentSubject', currentSubject)
    this.setState({
      currentSubject
    })
    if ((currentSubject.subjectName)
      &&
      (currentSubject.subjectCode)
      &&
      (currentSubject.numOfPeriodsPerWeek)
      &&
      (currentSubject.minPeriodsPerDay)
    ) {
      let isValid = this.validateMinPeriodsPerDay(currentSubject)
      if (!isValid) return this.setState({ validate_per_day: `Min Periods Condition Not Satisfied` });
      let table_values = this.state.table_values;
      let current_index = this.state.number_of_rows;
      table_values.splice(current_index, 1, currentSubject)
      this.setState({ table_values, validate_per_day: `` })
    }
  }

  validateMinPeriodsPerDay(sub) {
    let daysInAWeek = this.state.days_in_a_week;
    if (Number(sub.numOfPeriodsPerWeek) / Number(daysInAWeek) < Number(sub.minPeriodsPerDay)) return false;
    return true;
  }

  render() {
    if (this.state.toDashboard) {
      return (
        <Redirect
          to={{
            pathname: "./created-timetable",
            state: {
              table_id: "table-101",
            },
          }}
          Component={CreatedTimetable}
        />
      );
    }

    return (
      <div className="container-fluid  background">

        <form>
          <div>
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
              <nav className="navbar navbar-expand-lg  navprac bar" style={{ marginTop: '-6%' }}>
                <div className="title">TimeTable</div>
                <div className="collapse navbar-collapse" >
                  <form className="form-inline my-2 my-lg-0">
                    <input style={{ width: '500px', marginLeft: '120px', backgroundColor: 'rgba(54, 42, 65, 0.04)', fontStyle: 'italic', height: '45px' }} className="form-control mr-sm-2 " type="search" placeholder="Search....." aria-label="Search"></input>
                  </form>
                </div>
              </nav>
              <div className="row" style={{ color: '#714F90' }}>
                <h4 style={{ marginLeft: '-1155px', color: '#714F90', marginTop: '150px' }}>  Create Student Time Table</h4>

                {/* <h1 className="h3 mb-0 text-gray-800 m-t-20">Notes</h1> */}
              </div>
              {/* <h1 className="h3 mb-0 text-gray-800 m-t-20 m-l-20">
                Create Student Time Table
              </h1> */}
            </div>

            <div className="row">
              <div className="col-sm-12 col-max-th">
                <div className="card-box-student max-card">
                  <div className="card-body row">
                    <div className="col-lg-12 p-r-5 " style={{ marginBottom: 10 }}>
                      <label for='department' style={{ marginRight: 120 }}>Department</label>
                      <select
                        id="department"
                        name="department"
                        onChange={this.onChangeForm}
                        
                      >
                        <option>
                          select
                        </option>
                        {this.state.availableDepartments}
                      </select>
                    </div>
                    <div className="col-lg-12 p-r-5" style={{ marginBottom: 10 }}>
                      <label for='className' style={{ marginRight: 156 }}>Class</label>
                      <select
                        id="className"
                        name="className"
                        onChange={this.onChangeForm}
                      >
                        <option>
                          select
                        </option>
                        {this.state.classTemplate}
                      </select>
                    </div>
                    <div className="col-lg-12 p-r-5">
                      <label for='days_in_a_week' style={{ marginRight: 20 }}>Number Of Days In A Week</label>
                      <select
                        id="days_in_a_week"
                        name="days_in_a_week"
                        onChange={this.onChangeForm}
                      >
                        <option>
                          select
                        </option>
                        {this.generate_days()}
                      </select>
                    </div>
                    <div className="col-lg-5 p-t-20" style={{ marginBottom: 10 }}>
                      <label for='periods_in_a_day' style={{ marginRight: 15 }}>Number Of Periods In A Day</label>
                      <select
                        id="periods_in_a_day"
                        name="periods_in_a_day"
                        onChange={this.onChangeForm}
                      >
                        <option value="periods">
                          select
                        </option>
                        {this.generate_periods()}
                      </select>
                    </div>

                    <div className="col-lg-12 p-t-10">
                      <select
                        name="selected_practical_in_day"
                        onChange={this.onChangeForm}
                      >
                        <option value="periods">
                          1 Practical session = _______ Theory Sessions
                        </option>
                        {this.generate_practical_periods()}
                      </select>
                    </div>
                  </div>

                  <table
                    class="table table-striped"
                    style={{ backgroundColor: "whitesmoke", marginLeft: '2%' }}
                  >
                    <thead>
                      <tr>
                        <th>Subject Name</th>
                        <th>Subject Code</th>
                        <th>Practical</th>
                        <th>number of periods per week</th>
                        <th>min number of periods per day</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.table_values.map((value, index) => (
                        <tr>
                          <td>
                            <select defaultValue={'subject name'} name='subjectName' onChange={(e) => this.onChangeForm(e, index)}>
                              <option value={'Subject Name'}>Subject Name</option>
                              {this.state.subjectsTemplate.length > 0 && this.state.subjectsTemplate}
                            </select>
                          </td>

                          <td>
                            <input type='text' name='subjectCode' disabled value={this.state.selectedSubjectCodes.length > 0 ? this.state.selectedSubjectCodes[index] : ''} onChange={(e) => this.onChangeForm(e, index)} />
                          </td>

                          <td>
                            {" "}
                            <input
                              type="checkbox"
                              name="isPractical"
                              onChange={this.collectSubjectDetails.bind(this)}
                            />{" "}
                          </td>

                          <td>
                            {" "}
                            <input
                              type="text"
                              name="numOfPeriodsPerWeek"
                              onChange={this.collectSubjectDetails.bind(this)}
                            />{" "}
                            <br />
                            <p style={{ color: "red" }}>
                              {this.state.validate_per_week}
                            </p>
                          </td>

                          <td>
                            {" "}
                            <input
                              type="text"
                              name="minPeriodsPerDay"
                              onChange={this.collectSubjectDetails.bind(this)}
                            />
                            <br />
                            <p style={{ color: "red" }}>
                              {this.state.validate_per_day}
                            </p>
                          </td>

                          <td>
                            {" "}
                            <button
                              className="btn btn-primary"
                              onClick={this.add_row}
                            >
                              {" "}
                              +{" "}
                            </button>{" "}
                          </td>
                        </tr>
                      ))}
                      {/* {this.generate_row()} */}
                    </tbody>
                  </table>
                  <button className="save" style={{
                    marginLeft: '2%', boxShadow: '0px 16px 32px rgba(54, 42, 65, 0.32)'
                  }} onClick={this.onSubmit}>
                    {" "}
                    Save{" "}
                  </button>

                  <br />
                  <br />
                  {/*
            name  :  {this.state.subject_name}:
            name 0 :  {this.state.subject_name0}:
            name 1 :  {this.state.subject_name1}:
            name 2 :  {this.state.subject_name2}:
            code : {this.state.subject_code} :
            practical :  {this.state.practical} :
            per_Week:  {this.state.periods_per_week}
            min : {this.state.min_periods}
            rows  : {this.state.number_of_rows}
*/}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default CreateStudentTimetable;
