import React, { Component } from "react";
import axios from "axios";
import moment from "moment";
import { BrowserRouter as Router } from "react-router-dom";

class AddStudentDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showClassNotes: false,
      assessments: false,
      routerLink: "",
      degreeDetails: [],
      departmentDetails: [],
      userID: null,
      enabledAdd: false,
      values: [
        {
          userID: "",
          password: "",
          collegeCode: "",
          collegeName: "",
          selectedDepartment: "",
          degreeName: "",
          studentName: "",
          admissionDate: moment(new Date()).format("YYYY-MM-DD"),
          admissionYear: 2020,
          email: "",
          contact: "",
          emergencyContact: "",
          parentName: "",
          address: "",
          gender: "",
          dob: moment(new Date()).format("YYYY-MM-DD"),
          motherTongue: "",
          bloodGroup: "",
          class: "",
        },
      ],
    };
    this.baseState = this.state;
  }

  componentDidMount() {
    let values = [...this.state.values];
    values.forEach((e) => {
      e.password = this.generatePassword();
    });
    this.setState({ values });

    this.fetchDepartmentDetails();
    this.fetchPaperDetails();
  }

  fetchPaperDetails() {
    this.setState({ searchAllowed: true });
    this.deleteArray = [];
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/degreecoursedetail`, {
        withCredentials: true,
      })
      .then((response) => {
        this.setState({ degreeDetails: response.data });
      });
  }

  /**
   * Fetches all department
   */
  fetchDepartmentDetails() {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/departmentdetail`)
      .then((response) => {
        this.setState({ departmentDetails: response.data });
      });
  }

  logout() {
    axios
      .post(`${process.env.PUBLIC_URL}/xakal/logout`)
      .then(() => (window.location.href = "/"))
      .catch((e) => (window.location.href = "/"));
  }

  generatePassword() {
    let length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (let i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  onSemesterDropDownFocus(i) {
    this.setState({
      isFocussed: "is-focused",
      selectedIndex: i,
      onFocus: true,
      background: "is-shown",
    });
    this.handleDepartmentFocus();
  }

  /**
   * Resets the department focus based on the value selected
   */
  handleDepartmentFocus() {
    if (this.state.hasDepartmentValue === true) {
      this.setState({
        isDepartmentFocussed: "is-focused",
        onDepartmentFocus: false,
        backgroundDepartment: "is-hidden",
      });
    } else {
      this.setState({
        onDepartmentFocus: false,
        backgroundDepartment: "is-hidden",
      });
    }
  }

  /**
   * Displays the list of department based on the API response
   */
  displayDepartment() {
    if (
      this.state &&
      this.state.departmentDetails &&
      this.state.departmentDetails.data &&
      this.state.departmentDetails.data.length
    ) {
      return this.state.departmentDetails.data.map(
        (singleDepartment, index) => {
          return (
            <li className="mdl-menu__item animation" key={index}>
              <button
                id={singleDepartment.name}
                name={singleDepartment.name}
                onClick={this.handleDepartmentChange.bind(
                  this,
                  singleDepartment.name,
                  index
                )}
              >
                {singleDepartment.name}
              </button>
            </li>
          );
        }
      );
    }
  }

  handleFormChange(i, event) {
    console.log("asdsa",i,event.target.value);

    if (event && event.target) {
      let values = [...this.state.values];
      console.log(values[i],"values(")
      const { name, value } = event.target;
      console.log(values,"values")
      if (event.target.value !== "") {
        values[i][name] = value;
        this.setState({ values });
      } else {
        let values = [...this.state.values];
        const { name } = event.target;
        values[i][name] = "";
        this.setState({ ...values });
      }
      if (event.target.name === "admissionDate") {
        const admissionYear = new Date(event.target.value).getFullYear();
        values[i]["admissionYear"] = admissionYear;
        this.setState({ ...values });
      }
      console.log("values", values);
    }
  }

  // gender change //
  onGenderDropDownFocus(i, event) {
    this.setState({
      isGenderFocussed: "is-focused",
      selectedGenderIndex: i,
      onGenderFocus: true,
      backgroundGender: "is-shown",
    });
  }

  /**
   * Adds the empty form element
   */
  addClick() {
    this.setState((prevState) => ({
      values: [
        ...prevState.values,
        {
          userID: "",
          password: this.generatePassword(),
          collegeCode: "",
          collegeName: "",
          selectedDepartment: "",
          degreeName: "",
          studentName: "",
          admissionDate: moment(new Date()).format("YYYY-MM-DD"),
          admissionYear: 2020,
          email: "",
          contact: "",
          emergencyContact: "",
          parentName: "",
          address: "",
          gender: "",
          dob: moment(new Date()).format("YYYY-MM-DD"),
          motherTongue: "",
          bloodGroup: "",
          class: "",
        },
      ],
    }));

    setTimeout(() => {
      this.fillUserID();
    }, 1000);
  }

  fillUserID() {
    let values = this.state.values;
    values.forEach((e) => {
      let previous = values[values.indexOf(e) - 1];

      if (!previous) {
        e.userID =
          this.state.collegeCode +
          "ST" +
          `${Math.floor(1000 + Math.random() * 9000)}`;
      } else {
        e.userID =
          this.state.collegeCode +
          "ST" +
          (Number(previous.userID.slice(-4)) + 1);
      }

      this.setState({ values });
    });
  }

  /**
   * Removes the selected row
   * @param i selected row index
   */
  removeClick(i) {
    let values = [...this.state.values];
    values.splice(i, 1);
    this.setState({ values });

    setTimeout(() => {
      this.fillUserID();
    }, 400);
  }

  /**
   * Resets to base state
   */
  resetForm() {
    this.setState({
      values: [
        {
          collegeCode: "",
          collegeName: "",
          selectedDepartment: "",
          degreeName: "",
          studentName: "",
          admissionDate: moment(new Date()).format("YYYY-MM-DD"),
          admissionYear: 2020,
          email: "",
          contact: "",
          emergencyContact: "",
          parentName: "",
          address: "",
          gender: "",
          dob: moment(new Date()).format("YYYY-MM-DD"),
          motherTongue: "",
          bloodGroup: "",
        },
      ],
    });
  }

  /**
   * Triggers when the department is changed and stores the values in state
   * @param event form values
   */
  handleDepartmentChange(name, index, event) {
    this.setState({
      selectedDepartment: name,
      departmentName: name,
      selectedDepartmentIndex: index,
      onDepartmentFocus: false,
      backgroundDepartment: "is-hidden",
      background: "is-hidden",
      hasDepartmentValue: true,
    });
    event.target = { value: name, name: "selectedDepartment" };
    this.handleFormChange(0, event);
  }

  /**
   * Displays the list of degree based on the API response
   */
  displayDegree() {
    if (
      this.state &&
      this.state.degreeDetails &&
      this.state.degreeDetails.length
    ) {
      return this.state.degreeDetails.map((singleDegree, index) => {
        return (
          <li className="mdl-menu__item animation" key={index}>
            <button
              id={singleDegree.name}
              name={singleDegree.name}
              onClick={this.handleDegreeChange.bind(
                this,
                singleDegree.name,
                index
              )}
            >
              {singleDegree.name}
            </button>
          </li>
        );
      });
    }
  }

  /**
   * Triggers when the Degree is changed and stores the values in state
   * @param event form values
   */
  handleDegreeChange(name, index, event) {
    this.setState({
      degreeName: name,
      selectedDegreeIndex: index,
      onDegreeFocus: false,
      backgroundDegree: "is-hidden",
      background: "is-hidden",
      hasDegreeValue: true,
    });
    event.target = { value: name, name: "degreeName" };
    this.handleFormChange(0, event);
  }

  /**
   * Triggers when the Degree is changed and stores the values in state
   * @param event form values
   */
  onBloodGroupSelect(index, event) {
    this.setState({
      bloodGroup: event.target.id,
      onBloodFocus: false,
      backgroundBlood: "is-hidden",
      background: "is-hidden",
      hasBloodValue: true,
    });
    event.target = { value: event.target.id, name: "bloodGroup" };
    this.handleFormChange(index, event);
  }

  onGenderGroupSelect(index, event) {
    console.log(event);
    this.setState({
      gender: event.target.id,
      onGenderFocus: false,
      backgroundGender: "is-hidden",
      background: "is-hidden",
    });
    event.target = { value: event.target.id, name: "gender" };
    this.handleFormChange(index, event);
  }

  onDropDownFocus(event) {
    // if (event.target) {
    //   event.target.parentNode.classList.add("is-focused");
    //   event.target.nextSibling.classList.add("is-shown");
    // }
  }

  onFocusOut(event) {
    if (event.target) {
      // if (event.target.value === "") {
      //   event.target.parentNode.classList.remove("is-focused");
      // }
      // event.target.nextSibling.classList.remove("is-shown");
    }
  }

  /**
   * Triggers when the form is submitted
   * Checks whether the values are entered properly
   */
  /**
   * Triggers when the form is submitted
   * Checks whether the values are entered properly
   */
  formSubmit() {
    if (this.state.values && this.state.values.length > 0) {
      this.state.values.forEach((element) => {
        if (
          element.userID &&
          element.password &&
          this.state.collegeCode &&
          this.state.collegeName
        ) {
          const params = {
            userID: element.userID.toUpperCase(),
            password: element.password,
            userRole: "student",
            updatedBy: element.userID.toUpperCase(),
            updatedDate: new Date(Date.now()).toLocaleString(),
            collegeCode: this.state.collegeCode,
            collegeName: this.state.collegeName,
            registerNr: "",
          };
          axios
            .post(`${process.env.PUBLIC_URL}/xakal/adduser`, params)
            .then((res) => {
              if (res.data.ok) {
                this.insertStudentDetails();
                this.setState({
                  collegeCode: "",
                  collegeName: "",
                });
              } else {
                alert("Something Went Wrong!");
              }
            })
            .catch((err) => console.log(err));
        } else {
          alert("Please give all the details");
        }
      });
      //department/Courses/ Colleges/ Class room/ Bus stop
      let isUpdated = false;
      if (
        this.state.userID &&
        this.state.studentName &&
        this.state.degreeName &&
        this.state.departmentName &&
        this.state.email &&
        this.state.bloodGroup &&
        this.state.contact &&
        this.state.emergencyContact &&
        this.state.parentName &&
        this.state.admissionDate
      ) {
        const params = {
          userID: this.state.userID,
          name: this.state.studentName,
          course: this.state.degreeName,
          updatedBy: this.state.userID.toUpperCase(),
          updatedDate: new Date(Date.now()).toLocaleString(),
          branch: this.state.departmentName,
          email: this.state.email,
          bloodGroup: this.state.bloodGroup,
          contact: this.state.contact,
          emergencyContact: this.state.emergencyContact,
          parentName: this.state.parentName,
          admissionDate: this.state.admissionDate,
          admissionYear: this.state.admissionYear,
        };
        axios
          .post(`${process.env.PUBLIC_URL}/xakal/studentdetail`, params)
          .then(() => {
            if (!isUpdated) {
              alert("Updated Successfully");
            }
            isUpdated = true;
            this.setState(this.baseState);
          })
          .catch((err) => console.log(err));
      }
    }
  }

  insertStudentDetails() {
    let isUpdated = false;
    if (this.state.values && this.state.values.length > 0) {
      this.state.values.forEach((element) => {
        if (
          element.studentName &&
          element.degreeName &&
          element.selectedDepartment
        ) {
          const params = {
            userID: String(element.userID).toUpperCase(),
            name: element.studentName,
            course: element.degreeName,
            branch: element.selectedDepartment,
            email: element.email,
            bloodGroup: element.bloodGroup,
            contact: element.contact,
            emergencyContact: element.emergencyContact,
            parentName: element.parentName,
            admissionDate: element.admissionDate,
            admissionYear: element.admissionYear,
            updatedBy: String(this.state.userID).toUpperCase(),
            updatedDate: new Date(Date.now()).toLocaleString(),
            gender: element.gender,
            motherTongue: element.motherTongue,
            address: element.address,
            dob: element.dob,
          };
          axios
            .post(`${process.env.PUBLIC_URL}/xakal/studentdetail`, params)
            .then(() => {
              if (!isUpdated) {
                alert("Updated Successfully");
              }
              isUpdated = true;
              this.setState(this.baseState);
              this.resetForm();
            })
            .catch((err) => console.log(err));
        } else {
          alert("Please give all the details");
        }
      });
    }
  }

  /**
   * Triggers when department dropdown is focused
   */
  onDeptDropDownFocus(i) {
    this.setState({
      isDepartmentFocussed: "is-focused",
      onDepartmentFocus: true,
      backgroundDepartment: "is-shown",
      selectedDepartmentIndex: i,
    });
  }

  /**
   * Triggers when degree dropdown is focused
   */
  onDegreeDropDownFocus(i) {
    this.setState({
      selectedDegreeIndex: i,
      isDegreeFocussed: "is-focused",
      onDegreeFocus: true,
      backgroundDegree: "is-shown",
    });
  }

  /**
   * Triggers when blood group dropdown is focused
   */
  onBloodGroupDropDownFocus(i) {
    this.setState({
      selectedBGIndex: i,
      isBloodFocussed: "is-focused",
      onBloodFocus: true,
      backgroundBlood: "is-shown",
    });
  }

  collegeDetailsChange(event) {
    if (
      event.nativeEvent.inputType === "deleteContentBackward" &&
      event.target.value.length > 2
    ) {
      setTimeout(() => {
        this.fillUserID();
      }, 300);
    }

    if (
      String(event.target.name) === "collegeCode" &&
      event.target.value.length > 2
    ) {
      this.setState({ enabledAdd: true });
      setTimeout(() => {
        this.fillUserID();
      }, 300);
    } else {
      this.setState({ enabledAdd: false });
    }

    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    return (
      <Router>
        <div className="container-fluid background">
          <div>
            <nav
              className="navbar navbar-expand-lg bar"
              style={{ marginTop: "30px",marginLeft:"40px" }}
            >
              <div className="title">Student</div>
              <div className="collapse navbar-collapse">
                <form className="form-inline my-2 my-lg-0">
                  <input
                    style={{
                      width: "500px",
                      marginLeft: "120px",
                      backgroundColor: "rgba(54, 42, 65, 0.04)",
                      fontStyle: "italic",
                      height: "45px",
                    }}
                    className="form-control mr-sm-2 "
                    type="search"
                    placeholder="Search....."
                    aria-label="Search"
                  ></input>
                </form>
              </div>
            </nav>
            <div style={{ color: "#714F90", marginTop: "-10px" }}>
              {/* <h4 style={{marginLeft:'-1110px',color:'#714F90',marginTop:'150px'}}> Add User Details</h4> */}

              {/* <h1 className="h3 mb-0 text-gray-800 m-t-20">Notes</h1> */}
            </div>
            <div id="content">
              {this.props &&
              this.props.location &&
              this.props.location.state &&
              this.props.location.state.userDetails ? (
                <>
                  <p className="logout m-t-30 m-r-40 resp">
                    {this.props.location.state.userDetails.userRole
                      .charAt(0)
                      .toUpperCase() +
                      this.props.location.state.userDetails.userRole.slice(
                        1
                      )}{" "}
                    Dashboard
                  </p>
                  <p className="logout m-t-30 m-r-40 ">
                    {this.props.location.state.userDetails.userID}
                  </p>
                </>
              ) : (
                <></>
              )}
              <div>
                <div className="m-l-30">
                  <h4 className="h3 mb-0  m-t-20 m-l-20 head h4-res max-head">
                    Add User Details
                  </h4>
                </div>
                <div className="row row-max card-body">
                  <div className="col-sm-12">
                    <div className="">
                      <div className="card-body row">
                        <div className="col-lg-6 p-t-20">
                          <div
                            className={
                              "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                            }
                          >
                            <label
                              style={{ marginLeft: "25px" }}
                              className="input-text labell col-name"
                            >
                              College Code
                            </label>
                            <input
                              autoComplete="off"
                              onBlur={this.onFocusOut.bind(this)}
                              onChange={this.collegeDetailsChange.bind(this)}
                              onFocus={this.onDropDownFocus.bind(this)}
                              className="new-xakal-input background col-name "
                              type="text"
                              id={`collegeCode`}
                              value={this.state.collegeCode || ""}
                              name="collegeCode"
                              style={{
                                textTransform: "uppercase",
                                marginLeft: "25px",
                                height: "40px",
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 p-t-20">
                          <div
                            className={
                              "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                            }
                          >
                            <label className="input-text labell col-name">
                              College Name
                            </label>
                            <input
                              autoComplete="off"
                              onBlur={this.onFocusOut.bind(this)}
                              onChange={this.collegeDetailsChange.bind(this)}
                              onFocus={this.onDropDownFocus.bind(this)}
                              className="new-xakal-input background col-name"
                              type="text"
                              id={`collegeName`}
                              value={this.state.collegeName || ""}
                              name="collegeName"
                              style={{ height: "40px" }}
                            />
                          </div>
                        </div>
                      </div>
                      {this.state.values.map((el, i) => (
                        <div className="card-body row" key={i}>
                          <div className="row">
                            <div className="col-lg-3 p-t-20">
                              <div
                                style={{ marginLeft: "35px" }}
                                className={
                                  "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                                }
                              >
                                <label className="input-text labell ">
                                  User ID
                                </label>
                                <input
                                  autoComplete="off"
                                  onBlur={this.onFocusOut.bind(this)}
                                  onFocus={this.onDropDownFocus.bind(this)}
                                  className="new-xakal-input background id-name"
                                  type="text"
                                  readOnly
                                  id={`name${i}`}
                                  value={el.userID || ""}
                                  onChange={this.handleFormChange.bind(this, i)}
                                  name="userID"
                                  style={{ height: "40px" }}
                                />
                              </div>
                            </div>
                            <div className="col-lg-3 p-t-20">
                              <div
                                className={
                                  "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                                }
                              >
                                <label
                                  style={{ marginLeft: "22px" }}
                                  className="input-text labell pass-name"
                                >
                                  Password
                                </label>
                                <input
                                  autoComplete="off"
                                  onBlur={this.onFocusOut.bind(this)}
                                  onFocus={this.onDropDownFocus.bind(this)}
                                  className="new-xakal-input background pass-name"
                                  readOnly
                                  id={`password${i}`}
                                  value={el.password || ""}
                                  onChange={this.handleFormChange.bind(this, i)}
                                  name="password"
                                  style={{ height: "40px", marginLeft: "22px" }}
                                />
                              </div>
                            </div>
                            <div className="col-lg-3 p-t-20">
                              <div
                                className={
                                  "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                                }
                              >
                                <label
                                  style={{ marginLeft: "10px" }}
                                  className="input-text labell pass-name"
                                >
                                  Name
                                </label>
                                <input
                                  //  style={{width:"90%"}}
                                  autoComplete="off"
                                  onBlur={this.onFocusOut.bind(this)}
                                  onFocus={this.onDropDownFocus.bind(this)}
                                  className="new-xakal-input background pass-name"
                                  type="text"
                                  id="sample2"
                                  value={el.studentName || ""}
                                  onChange={this.handleFormChange.bind(this, i)}
                                  name="studentName"
                                  style={{ height: "40px", marginLeft: "10px" }}
                                />
                              </div>
                            </div>
                            <div className="col-lg-3 p-t-20">
                              <div
                                className={
                                  "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width " +
                                  this.state.isDepartmentFocussed
                                }
                              >
                                <label className="input-text labell pass-name">
                                  Department
                                </label>
                                <input
                                  // style={{width:"90%"}}
                                  name="selectedDepartment"
                                  style={{ height: "40px" }}
                                  onKeyPress={(e) => e.preventDefault()}
                                  autoComplete="off"
                                  onFocus={this.onDeptDropDownFocus.bind(
                                    this,
                                    i
                                  )}
                                  className="new-xakal-input background pass-name"
                                  type="text"
                                  id={`department`}
                                  value={el.selectedDepartment || ""}
                                  onChange={this.handleFormChange.bind(this, i)}
                                />

                                {this.state.onDepartmentFocus &&
                                this.state.selectedDepartmentIndex === i ? (
                                  <div className="mdl-menu__container is-upgraded dropdown-list is-visible">
                                    <div
                                      className="mdl-menu__outline mdl-menu--bottom-left dropdown-div"
                                      style={{
                                        width: "180px",
                                        marginTop: "-9px",
                                      }}
                                    >
                                      <ul className="scrollable-menu  ul-list">
                                        {this.displayDepartment(i)}
                                      </ul>
                                    </div>
                                  </div>
                                ) : (
                                  <p></p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-3 p-t-20">
                              <div
                                style={{ marginLeft: "35px" }}
                                className={
                                  "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width " +
                                  this.state.isDegreeFocussed
                                }
                              >
                                <label className="input-text labell  deg-max-th">
                                  Degree
                                </label>
                                <input
                                  name="degreeName"
                                  onKeyPress={(e) => e.preventDefault()}
                                  autoComplete="off"
                                  onFocus={this.onDegreeDropDownFocus.bind(
                                    this,
                                    i
                                  )}
                                  className="new-xakal-input background deg-max-th "
                                  type="text"
                                  id={`degree`}
                                  value={el.degreeName || ""}
                                  onChange={this.handleFormChange.bind(this, i)}
                                  style={{ height: "40px" }}
                                />

                                {this.state.onDegreeFocus &&
                                this.state.selectedDegreeIndex === i ? (
                                  <div className="mdl-menu__container is-upgraded dropdown-list is-visible">
                                    <div
                                      className="mdl-menu__outline mdl-menu--bottom-left dropdown-div"
                                      style={{
                                        width: "180px",
                                        marginTop: "-9px",
                                      }}
                                    >
                                      <ul className="scrollable-menu  ul-list">
                                        {this.displayDegree(i)}
                                      </ul>
                                    </div>
                                  </div>
                                ) : (
                                  <p></p>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-3 p-t-20">
                              <div
                                style={{ marginLeft: "22px" }}
                                className={
                                  "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                                }
                              >
                                <label className="input-text labell cls-name">
                                  Class Name
                                </label>
                                <input
                                  autoComplete="off"
                                  onBlur={this.onFocusOut.bind(this)}
                                  onFocus={this.onDropDownFocus.bind(this)}
                                  className="new-xakal-input background cls-name"
                                  type="text"
                                  id="sample2"
                                  value={el.class || ""}
                                  onChange={this.handleFormChange.bind(this, i)}
                                  name="class"
                                  style={{ height: "40px" }}
                                />
                              </div>
                            </div>
                            <div className="col-lg-3 p-t-20">
                              <div
                                className={
                                  "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                                }
                              >
                                <label
                                  style={{ marginLeft: "10px" }}
                                  className="input-text labell pass-name"
                                >
                                  Email
                                </label>
                                <input
                                  autoComplete="off"
                                  onBlur={this.onFocusOut.bind(this)}
                                  onFocus={this.onDropDownFocus.bind(this)}
                                  className="new-xakal-input background pass-name"
                                  type="text"
                                  id="sample2"
                                  value={el.email || ""}
                                  onChange={this.handleFormChange.bind(this, i)}
                                  name="email"
                                  style={{ height: "40px", marginLeft: "10px" }}
                                />
                              </div>
                            </div>
                            <div className="col-lg-3 p-t-20">
                              <div
                                className={
                                  "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                                }
                              >
                                <label className="input-text labell pass-name">
                                  Mobile
                                </label>
                                <input
                                  autoComplete="off"
                                  onBlur={this.onFocusOut.bind(this)}
                                  onFocus={this.onDropDownFocus.bind(this)}
                                  className="new-xakal-input background pass-name"
                                  type="text"
                                  id="sample2"
                                  value={el.contact || ""}
                                  onChange={this.handleFormChange.bind(this, i)}
                                  name="contact"
                                  style={{ height: "40px" }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row col-lg-12">
                            <div
                              style={{ marginLeft: "26px" }}
                              className="col-lg-3 p-t-20"
                            >
                              <div
                                className={
                                  "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                                }
                              >
                                <label className="input-text labell deg-max-th">
                                  Emergency Contact
                                </label>
                                <input
                                  autoComplete="off"
                                  onBlur={this.onFocusOut.bind(this)}
                                  onFocus={this.onDropDownFocus.bind(this)}
                                  className="new-xakal-input background deg-max-th"
                                  type="text"
                                  id="sample2"
                                  value={el.emergencyContact || ""}
                                  onChange={this.handleFormChange.bind(this, i)}
                                  name="emergencyContact"
                                  style={{ height: "40px" }}
                                />
                              </div>
                            </div>

                            <div className="col-lg-3 p-t-20">
                              <div
                                style={{ marginLeft: "-50px" }}
                                className={
                                  "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                                }
                              >
                                <label
                                  style={{ marginLeft: "-20px" }}
                                  className="input-text labell parent-name"
                                >
                                  Parents / Guardian Name
                                </label>
                                <input
                                  autoComplete="off"
                                  onBlur={this.onFocusOut.bind(this)}
                                  onFocus={this.onDropDownFocus.bind(this)}
                                  className="new-xakal-input height background parent-name"
                                  type="text"
                                  id="sample2"
                                  value={el.parentName || ""}
                                  onChange={this.handleFormChange.bind(this, i)}
                                  name="parentName"
                                  style={{ marginLeft: "-20px" }}
                                />
                              </div>
                            </div>

                            <div className="col-lg-3 p-t-20">
                              <div
                                className={
                                  "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width is-focused"
                                }
                              >
                                <label
                                  style={{ marginLeft: "-140px" }}
                                  className="input-text labell add-date"
                                >
                                  Admission Date
                                </label>
                                <input
                                  value={
                                    el.admissionDate ||
                                    moment(new Date()).format("YYYY-MM-DD")
                                  }
                                  onBlur={this.onFocusOut.bind(this)}
                                  onFocus={this.onDropDownFocus.bind(this)}
                                  autoComplete="off"
                                  className="new-xakal-input background height add-date"
                                  type="date"
                                  id="sample2"
                                  onChange={this.handleFormChange.bind(this, i)}
                                  name="admissionDate"
                                  style={{ marginLeft: "-140px" }}
                                />
                              </div>
                            </div>

                            <div className="col--3 p-t-20">
                              <div
                                className={
                                  "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                                }
                              >
                                <label
                                  style={{ marginLeft: "-200px" }}
                                  className="input-text labell pass-name"
                                >
                                  Address
                                </label>
                                <input
                                  autoComplete="off"
                                  onBlur={this.onFocusOut.bind(this)}
                                  onFocus={this.onDropDownFocus.bind(this)}
                                  className="new-xakal-input background height pass-name"
                                  type="text"
                                  id="address"
                                  value={el.address || ""}
                                  onChange={this.handleFormChange.bind(this, i)}
                                  name="address"
                                  style={{
                                    marginLeft: "-200px",
                                    marginTop: "- 10px",
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="row">
                              <div className="col-lg-3 p-t-20">
                                <div
                                  className={
                                    "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width is-focused"
                                  }
                                >
                                  <label
                                    style={{ marginLeft: "50px" }}
                                    className="input-text labell con-name"
                                  >
                                    dob
                                  </label>
                                  <input
                                    value={
                                      el.dob ||
                                      moment(new Date()).format("YYYY-MM-DD")
                                    }
                                    onBlur={this.onFocusOut.bind(this)}
                                    onFocus={this.onDropDownFocus.bind(this)}
                                    autoComplete="off"
                                    className="new-xakal-input background con-name"
                                    type="date"
                                    id="dob"
                                    onChange={this.handleFormChange.bind(
                                      this,
                                      i
                                    )}
                                    name="dob"
                                    style={{
                                      height: "40px",
                                      marginLeft: "50px",
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3 p-t-20">
                                <div>
                                  <div>
                                    <div
                                      className={
                                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width " +
                                        this.state.isGenderFocussed
                                      }
                                    >
                                      <label
                                        style={{ marginLeft: "30px" }}
                                        className="input-text labell dob-name "
                                      >
                                        Gender
                                      </label>
                                      <input
                                        name="gender"
                                        onKeyPress={(e) => e.preventDefault()}
                                        autoComplete="off"
                                        onFocus={this.onGenderDropDownFocus.bind(
                                          this,
                                          i
                                        )}
                                        className="new-xakal-input background dob-name"
                                        type="text"
                                        id={`Gender`}
                                        value={el.gender || ""}
                                        onChange={this.handleFormChange.bind(
                                          this,
                                          i
                                        )}
                                        style={{
                                          height: "40px",
                                          marginLeft: "30px",
                                        }}
                                      />

                                      {this.state.onGenderFocus &&
                                      this.state.selectedGenderIndex === i ? (
                                        <div className="mdl-menu__container is-upgraded dropdown-list is-visible">
                                          <div
                                            className="mdl-menu__outline mdl-menu--bottom-left dropdown-div"
                                            style={{
                                              width: "180px",
                                              marginTop: "-9px",
                                              marginLeft: "30px",
                                            }}
                                          >
                                            <ul className="scrollable-menu ul-list">
                                              <li
                                                className="mdl-menu__item animation"
                                                id="Male"
                                                onClick={this.onGenderGroupSelect.bind(
                                                  this,
                                                  i
                                                )}
                                              >
                                                Male
                                              </li>
                                              <li
                                                className="mdl-menu__item animation1"
                                                id="Female"
                                                onClick={this.onGenderGroupSelect.bind(
                                                  this,
                                                  i
                                                )}
                                              >
                                                Female
                                              </li>
                                              <li
                                                className="mdl-menu__item animation2"
                                                id="Others"
                                                onClick={this.onGenderGroupSelect.bind(
                                                  this,
                                                  i
                                                )}
                                              >
                                                Others
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      ) : (
                                        <p></p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 p-t-20">
                                <div
                                  className={
                                    "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                                  }
                                >
                                  <label
                                    style={{ marginLeft: "8px" }}
                                    className="input-text labell dob-name"
                                  >
                                    Mother Tongue
                                  </label>
                                  <input
                                    autoComplete="off"
                                    onBlur={this.onFocusOut.bind(this)}
                                    onFocus={this.onDropDownFocus.bind(this)}
                                    className="new-xakal-input background dob-name"
                                    type="text"
                                    id="motherTongue"
                                    value={el.motherTongue || ""}
                                    onChange={this.handleFormChange.bind(
                                      this,
                                      i
                                    )}
                                    name="motherTongue"
                                    style={{
                                      height: "40px",
                                      marginLeft: "8px",
                                    }}
                                  />
                                </div>
                              </div>

                              <div className="col-lg-2 p-t-20">
                                <div
                                  className={
                                    "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width " +
                                    this.state.isBloodFocussed
                                  }
                                >
                                  <label
                                    style={{ marginLeft: "-10px" }}
                                    className="input-text labell dob-name"
                                  >
                                    Blood Group
                                  </label>
                                  <input
                                    name="bloodGroup"
                                    onKeyPress={(e) => e.preventDefault()}
                                    autoComplete="off"
                                    onFocus={this.onBloodGroupDropDownFocus.bind(
                                      this,
                                      i
                                    )}
                                    className="new-xakal-input background dob-name"
                                    type="text"
                                    id={`Blood`}
                                    value={el.bloodGroup || ""}
                                    onChange={this.handleFormChange.bind(
                                      this,
                                      i
                                    )}
                                    style={{
                                      height: "40px",
                                      marginLeft: "-10px",
                                    }}
                                  />

                                  {this.state.onBloodFocus &&
                                  this.state.selectedBGIndex === i ? (
                                    <div className="mdl-menu__container is-upgraded dropdown-list is-visible">
                                      <div
                                        className="mdl-menu__outline mdl-menu--bottom-left dropdown-div"
                                        style={{
                                          width: "180px",
                                          marginTop: "-9px",
                                          marginLeft: "-10px",
                                        }}
                                      >
                                        <ul className="scrollable-menu ul-list">
                                          <li
                                            className="mdl-menu__item animation"
                                            id="A+"
                                            onClick={this.onBloodGroupSelect.bind(
                                              this,
                                              i
                                            )}
                                          >
                                            A+
                                          </li>
                                          <li
                                            className="mdl-menu__item animation1"
                                            id="A-"
                                            onClick={this.onBloodGroupSelect.bind(
                                              this,
                                              i
                                            )}
                                          >
                                            A-
                                          </li>
                                          <li
                                            className="mdl-menu__item animation2"
                                            id="B+"
                                            onClick={this.onBloodGroupSelect.bind(
                                              this,
                                              i
                                            )}
                                          >
                                            B+
                                          </li>
                                          <li
                                            className="mdl-menu__item animation"
                                            id="B-"
                                            onClick={this.onBloodGroupSelect.bind(
                                              this,
                                              i
                                            )}
                                          >
                                            B-
                                          </li>
                                          <li
                                            className="mdl-menu__item animation1"
                                            id="O+"
                                            onClick={this.onBloodGroupSelect.bind(
                                              this,
                                              i
                                            )}
                                          >
                                            O+
                                          </li>
                                          <li
                                            className="mdl-menu__item animation2"
                                            id="O-"
                                            onClick={this.onBloodGroupSelect.bind(
                                              this,
                                              i
                                            )}
                                          >
                                            O-
                                          </li>
                                          <li
                                            className="mdl-menu__item animation"
                                            id="AB+"
                                            onClick={this.onBloodGroupSelect.bind(
                                              this,
                                              i
                                            )}
                                          >
                                            AB+
                                          </li>
                                          <li
                                            className="mdl-menu__item animation1"
                                            id="AB-"
                                            onClick={this.onBloodGroupSelect.bind(
                                              this,
                                              i
                                            )}
                                          >
                                            AB-
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  ) : (
                                    <p></p>
                                  )}
                                </div>
                              </div>
                              <div className="col-sm-1 p-t-20">
                                {this.state.values.length !== 1 && (
                                  <button
                                    type="button"
                                    onClick={this.removeClick.bind(this, i)}
                                    className="btn btn-primary m-t-15 m-l-30"
                                    style={{
                                      backgroundColor: "#362A41",
                                      marginTop: "4rem",
                                      marginLeft: "20%",
                                    }}
                                  >
                                    X
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>

                          {/* <div className="col-lg-3 p-t-20">
                              <div
                                className={
                                  "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width is-focused"
                                }
                              > */}
                          {/* <label className="input-text">
                                  DOB
                                </label>
                                <input
                                  value={
                                    el.dob ||
                                    moment(new Date()).format("YYYY-MM-DD")
                                  }
                                  onBlur={this.onFocusOut.bind(this)}
                                  onFocus={this.onDropDownFocus.bind(this)}
                                  autoComplete="off"
                                  className="new-xakal-input"
                                  type="date"
                                  id="dob"
                                  onChange={this.handleFormChange.bind(this, i)}
                                  name="dob"
                                /> */}
                        </div>
                        // </div>
                      ))}
                      <div className="col-sm-8 p-t-20">
                        <button
                          type="button"
                          onClick={this.addClick.bind(this)}
                          style={{ marginLeft: "43px" }}
                          // disabled={!this.state.enabledAdd}
                          className="cancel resp"
                        >
                          Add
                        </button>
                        <button
                          type="button"
                          onClick={this.formSubmit.bind(this)}
                          className="save m-l-25 resp"
                          style={{
                            boxShadow: "0px 16px 32px rgba(54, 42, 65, 0.32)",
                          }}
                        >
                          Save
                        </button>
                        <button
                          type="button"
                          onClick={this.resetForm.bind(this)}
                          className="cancel resp"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Router>
    );
  }
}

export default AddStudentDetails;
