import React, { Component } from "react";
import "../../../styles/table.css";
import "../../../minified-css/material-min.css";
import "../../../styles/dropdowns.css";
import "../../../styles/theme-style.css";
import axios from "axios";
// import moment from "moment";
import NewBusTemplate from "./Bus/NewBusTemplate";
import ShowBusTemplate from "./Bus/ShowBusTemplate";
import AddBusRoutes from "./AddBusRoutes";
import NewBusStopTemplate from "./BusStation/NewBusStopTemplate";
import ShowBusStopTemplate from "./BusStation/ShowBusStopTemplate";
import UpdateBusTemplate from "./Bus/UpdateBusTemplate";
import StudentsTable from "./StudentsTable";

class ManagementTransport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createBusColorClass: 'hover gray',
      configBusColorClass: 'hover gray',
      createBusStationColorClass: 'hover gray',
      toggleAction: '',
      addButtonSize: 23,
      buses: [],
      lockAddButton: false,
      lockAddBusStopButton: false,
      displayStudentsTable: false,
      selectedStudents: [],
      busNumber: '',
      displayBusRoutesConfig: false,
      selectedBusStops: [],
      busStopsCount: [1],
      busStops: [],
      updateBus: false,
      currentBus: {},
      adminId: '5dd6ed68c0052a052cbdb09c'
    };
  }

  componentDidMount() {
    this.getAllBuses();
    this.getAllBusStops();
    console.log('selected students', this.state.selectedStudents)
  }

  getAllBusStops() {
    axios
      .get(`/xakal/transport/admin/getAllBusStops/${this.state.adminId}`)
      .then((response) => {
        if (response.status !== 200) {
          return alert(response.data.msg)
        }
        this.setBusStops(response.data.data);
        this.setState({
          lockAddBusStopButton: false
        })
      })
      .catch(e => alert(e))
  }

  setBusStops(data) {
    this.setState({
      busStops: data
    })
  }

  getAllBuses() {
    axios
      .get(`/xakal/transport/admin/getAllBus/${this.state.adminId}`)
      .then((response) => {
        if (response.status !== 200) {
          return alert(response.data.msg)
        }
        this.setBuses(response.data.data);
        this.setState({
          lockAddButton: false
        })
      })
      .catch(e => alert(e))
  }

  setBuses(data) {
    if (data.length) {
      this.setState({
        buses: data
      })
    }
  }

  handleSelectedMenu(e) {
    if (e.target.id === "create-bus") {
      this.setState({
        createBusColorClass: 'hover active',
        configBusColorClass: 'hover gray',
        createBusStationColorClass: 'hover gray',
        toggleAction: 'createBus'
      })
      this.getAllBuses();
    } else if (e.target.id === "config-bus") {
      this.setState({
        createBusColorClass: 'hover gray',
        configBusColorClass: 'hover active',
        createBusStationColorClass: 'hover gray',
        toggleAction: 'configBus'
      })
    } else if (e.target.id === "create-bus-stations") {
      this.setState({
        createBusColorClass: 'hover gray',
        configBusColorClass: 'hover gray',
        createBusStationColorClass: 'hover active',
        toggleAction: 'createBusStations'
      })
      this.getAllBusStops();
    } else if(e.target.id === 'dashboard') {
      this.props.setTransport(false)
    }
  }

  handleAddNewBusTemplate() {
    if (!this.state.lockAddButton) {
      if (this.state.addButtonSize === 23) {
        this.setState({
          addButtonSize: this.state.addButtonSize * 2
        })
      } else {
        this.setState({
          addButtonSize: this.state.addButtonSize / 2
        })
      }
    }
  }

  handleAddNewBusStopTemplate() {
    if (!this.state.lockAddBusStopButton) {
      if (this.state.addButtonSize === 23) {
        this.setState({
          addButtonSize: this.state.addButtonSize * 2
        })
      } else {
        this.setState({
          addButtonSize: this.state.addButtonSize / 2
        })
      }
    }
  }

  addNewBusStopTemplate() {
    if (!this.state.lockAddBusStopButton) {
      this.setState({
        busStops: [...this.state.busStops, { newBusStopTemplate: true }],
        lockAddBusStopButton: true
      })
    }
  }

  addNewBusTemplate() {
    if (!this.state.lockAddButton) {
      this.setState({
        buses: [...this.state.buses, { newBusTemplate: true }],
        lockAddButton: true
      })
    }
  }

  handleAddBusButton(val) {
    this.setState({
      lockAddButton: val
    })
  }

  handleUpdateBus(id) {
    const buses = this.state.buses;
    const targetIndex = buses.findIndex((b, i) => b._id === id);
    if (targetIndex !== -1) {
      const targetBus = buses.find((b, i) => b._id === id);
      targetBus.isUpdate = true;
      buses.splice(targetIndex, 1, targetBus);
      console.log('updat bus', buses)
      this.setState({
        buses
      })
    }
  }

  changeBusNumber(e) {
    this.setState({
      busNumber: e.target.value
    })
  }

  handleAddBusRoutes(val, selectedBusStops, busStopsCount, updateBus = false) {
    if (selectedBusStops && selectedBusStops.length && busStopsCount) {
      return this.setState({
        displayBusRoutesConfig: val,
        selectedBusStops,
        busStopsCount,
        updateBus
      })
    }
    this.setState({
      displayBusRoutesConfig: val,
      updateBus
    })
  }

  deleteBusStop(id) {
    const userConfirmed = window.confirm('Are you sure you want to delete bus stop?');
    if (id && userConfirmed) {
      axios.delete(`/xakal/transport/admin/deleteBusStop/${id}/${this.state.adminId}`)
        .then(res => {
          this.getAllBusStops();
          alert(res.data.msg)
        })
        .catch(e => {
          console.log('Error::deleteBusStop()', e)
        })
    }
  }

  addBus(cancel) {
    if (cancel) {
      const lastIndex = this.state.buses.length - 1;
      const buses = this.state.buses;
      buses.splice(lastIndex, 1);
      this.setState({
        buses,
        lockAddButton: false
      })
      return;
    }
    if (!this.state.busNumber || this.state.selectedBusStops.length === 0) {
      return alert('Please enter valid inputs!')
    }
    axios
      .post(`/xakal/transport/admin/createBus/${this.state.adminId}`, {
        busNumber: this.state.busNumber,
        busStops: this.state.selectedBusStops.map(e => e._id)
      })
      .then((response) => {
        console.log('add Bus', response)
        if (response.status !== 201) {
          return alert(response.data.msg)
        }
        alert(response.data.msg)
        this.getAllBuses()
      })
      .catch(e => alert(e))
  }

  deleteBus(id) {
    const userConfirmed = window.confirm('Are you sure you want to delete the bus?');
    if (id && userConfirmed) {
      axios.delete(`/xakal/transport/admin/deleteBus/${id}/${this.state.adminId}`)
        .then(res => {
          this.getAllBuses();
          alert(res.data.msg)
        })
        .catch(e => {
          console.log('Error::deleteBusStop()', e)
        })
    }
  }

  cancelUpdate(id) {
    const buses = this.state.buses;
    const index = buses.findIndex(b => b._id === id);
    if (index !== -1) {
      const bus = buses.find(b => b._id === id);
      bus.isUpdate = false;
      buses.splice(index, 1, bus)
      this.setState({
        buses
      })
    }
  }

  updateBus(id) {
    if (!this.state.busNumber || this.state.selectedBusStops.length === 0) {
      return alert('Please enter valid inputs!')
    }
    axios
      .patch(`/xakal/transport/admin/updateBus/${id}/${this.state.adminId}`, {
        busNumber: this.state.busNumber,
        busStops: this.state.selectedBusStops.map(e => e._id)
      })
      .then((response) => {
        console.log('update Bus', response)
        if (response.status !== 200) {
          return alert(response.data.msg)
        }
        alert(response.data.msg)
        this.getAllBuses()
      })
      .catch(e => alert(e))
  }

  displayStudents(val, currentBus) {
    this.setState({
      displayStudentsTable: val,
      currentBus: currentBus || {}
    })
  }

  render() {
    return (
      <div className="trans" style={{ padding: 0, paddingTop: 10, margin: 0 ,backgroundColor:'#E5E5E5',height:'100%'}}>
        <div className="d-sm-flex align-items-center justify-content-between mb-4" style={{ paddingTop: 10, marginTop: -10, backgroundColor: '#eee' }}>
          <h1 className="h3 mb-0 text-gray-800 m-t-20 m-l-20" style={{ paddingBottom: 20 }}>Transport</h1>
          <div className="row" style={{ justifyContent: 'space-between' }}>
            <div id="dashboard" className='hover' style={{ marginRight: 80 }} onClick={this.handleSelectedMenu.bind(this)}>
              Dashboard
            </div>
            <div id="create-bus-stations" className={this.state.createBusStationColorClass} style={{ marginRight: 80 }} onClick={this.handleSelectedMenu.bind(this)}>
              Manage Bus Stations
            </div>
            <div id="create-bus" className={this.state.createBusColorClass} style={{ marginRight: 80 }} onClick={this.handleSelectedMenu.bind(this)}>
              Manage Bus
            </div>
            {/* <div id="config-bus" className={this.state.configBusColorClass} style={{ marginRight: 50 }} onClick={this.handleSelectedMenu.bind(this)}>
              Config Bus
            </div> */}
          </div>
        </div>
        {
          this.state.toggleAction === 'createBus'
          &&
          <div style={{ position: 'relative' }}>
            <div className="d-sm-flex align-items-center justify-content-between mb-4" style={{ marginTop: 30 }}>
              <h2 className="h3 mb-0 text-800 m-t-20 m-l-20">
                Add Bus
              </h2>
            </div>
            <div className="d-sm-flex align-items-center justify-content-between mb-4" style={{ paddingTop: 10 }}>
              <div class="row" style={{ marginLeft: 8, justifyContent: 'center' }}>
                {this.state.buses.length && this.state.buses.map(bus => {
                  return (
                    (bus.newBusTemplate) ?
                      <NewBusTemplate
                        handleAddBus={this.addBus.bind(this)}
                        selectedBusStops={this.state.selectedBusStops}
                        state={this.state}
                        changeBusNumber={this.changeBusNumber.bind(this)}
                        handleAddBusRoutes={this.handleAddBusRoutes.bind(this)}
                      />
                      :
                      (bus.isUpdate) ?
                        <UpdateBusTemplate
                          bus={bus}
                          handleAddBus={this.addBus.bind(this)}
                          selectedBusStops={this.state.selectedBusStops}
                          changeBusNumber={this.changeBusNumber.bind(this)}
                          handleAddBusRoutes={this.handleAddBusRoutes.bind(this)}
                          cancelUpdate={this.cancelUpdate.bind(this)}
                          updateBus={this.updateBus.bind(this)}
                        />
                        :
                        <ShowBusTemplate
                          bus={bus}
                          deleteBus={this.deleteBus.bind(this)}
                          handleUpdateBus={this.handleUpdateBus.bind(this)}
                          displayStudents={this.displayStudents.bind(this)}
                        />
                  )
                })}
                {/*add bus component*/}
                <div class="item col-md-3 add-bus-container grid" style={{ placeItems: 'center', margin: 10, backgroundColor: '#f5f5ef' }} onMouseOver={this.handleAddNewBusTemplate.bind(this)} onMouseOut={this.handleAddNewBusTemplate.bind(this)} onClick={this.addNewBusTemplate.bind(this)}>
                  <div style={{ color: '#74a5f2', fontSize: this.state.addButtonSize }}>
                    +
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        {
          this.state.toggleAction === 'createBusStations'
          &&
          <div style={{ position: 'relative' }}>
            <div className="d-sm-flex align-items-center justify-content-between mb-4" style={{ marginTop: 30 }}>
              <h2 className="h3 mb-0 text-800 m-t-20 m-l-20">
                Add Bus Station
              </h2>
            </div>
            <div className="d-sm-flex align-items-center justify-content-between mb-4" style={{ paddingTop: 10 }}>
              <div class="row" style={{ marginLeft: 8, justifyContent: 'center' }}>
                {this.state.busStops.length && this.state.busStops.map(busStop => {
                  return (
                    busStop.newBusStopTemplate ?
                      <NewBusStopTemplate
                        getAllBusStops={this.getAllBusStops.bind(this)}
                      />
                      :
                      <ShowBusStopTemplate
                        busStop={busStop}
                        deleteBusStop={this.deleteBusStop.bind(this)}
                      />
                  )
                })}
                {/*add bus component*/}
                <div class="item col-md-3 add-bus-container grid" style={{ placeItems: 'center', margin: 10, backgroundColor: '#f5f5ef' }} onMouseOver={this.handleAddNewBusTemplate.bind(this)} onMouseOut={this.handleAddNewBusTemplate.bind(this)} onClick={this.addNewBusStopTemplate.bind(this)}>
                  <div style={{ color: '#74a5f2', fontSize: this.state.addButtonSize }}>
                    +
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        {
          this.state.displayBusRoutesConfig
          &&
          <AddBusRoutes
            handleSaveBusRoutes={this.handleAddBusRoutes.bind(this)}
            selectedBusStops={this.state.selectedBusStops}
            busStopsCount={this.state.busStopsCount}
            updateBus={this.state.updateBus}
          />
        }
        {
          this.state.displayStudentsTable
          &&
          <StudentsTable
            bus={this.state.currentBus}
            displayStudents={this.displayStudents.bind(this)}
          />
        }
      </div>
    );
  }
}

export default ManagementTransport;
