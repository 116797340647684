import { createClass, getAllClass, getClassById } from '../../../api/classroom';

export function handleChangeInput(e) {
    this.setState({ currentClass: getCurrentClass.bind(this)(e.target.name, e.target.value) });
}

export function getCurrentClass(key, value) {
    const data = this.state.currentClass || {};
    if (key == "department") {
        data.unprocessedSubjects = this.props.state.subjects.filter(
            (e) => e.department.toLowerCase() == value.toLowerCase()
        );
        data.department = value.toUpperCase();
    } else {
        data[key] = key == 'className' ? value.toUpperCase() : value;
    }
    return data;
}

function getStaffId(subject) {
    return subject.staff.map(e => e._id);
}

export function getStaffNameForSubject(staffId, subId) {
    if (this.state.currentClass.unprocessedSubjects) {
        const currentClass = this.state.currentClass;
        let subjects = currentClass.unprocessedSubjects
            .map(s => ({
                subject: s._id,
                staff: getStaffId(s),
            }));
        let subIndex = subjects.findIndex(sub => sub.subject == subId)
        if (subIndex !== -1) subjects.splice(subIndex, 1, { subject: subId, staff: staffId })
        //converting staff arr to staff obj
        subjects = subjects
            .map(s => ({
                subject: s.subject,
                staff: s.staff instanceof Array ? s.staff[0] : s.staff
            }))
            .filter(s => s.staff)
        currentClass.subjects = subjects;
        currentClass.dataProcessed = true;
        this.setState({ currentClass })
    }
}

export function initStaffAndSub() {
    if(this.state.currentClass.unprocessedSubjects) {
        const currentClass = this.state.currentClass;
        const subjects = this.state.currentClass.unprocessedSubjects.map(sub => ({
            subject: sub._id,
            staff: getStaffId(sub)
        }))
        .map(s => ({
            subject: s.subject,
            staff: s.staff instanceof Array ? s.staff[0] : s.staff
        }))
        .filter(s => s.staff)
        currentClass.subjects = subjects;
        return currentClass;
    }
}

export async function handleSubmit() {
    if(this.state.currentClass.department) {
        let classroom = this.state.currentClass;
        classroom.name = classroom.className;
        if(!this.state.currentClass.dataProcessed) {
            classroom = initStaffAndSub.bind(this)();
        }
        const classId = await createClass(classroom, this.props.state.userDetails._id)
        const newClass = await getClassById(classId, this.props.state.userDetails._id)
        addNewClassToList.bind(this)(newClass.data)
    }
}

function addNewClassToList(newClass) {
    let cumulativeData = [];
    if(this.props.state.classrooms.length) cumulativeData = [...this.props.state.classrooms]
    cumulativeData = [...cumulativeData, newClass]
    this.props.addClassToRoom(cumulativeData)
}

export async function getAllClasses() {
    const res = await getAllClass(this.state.userDetails._id)
    // let classrooms = res?.data;
    let classrooms = res&&res.data?res.data:[];
    if(classrooms.length) this.setState({ classrooms })
    // console.log('all classes', res.data[res.data.length - 1])
}


