import {
    addSubjects
} from '../../../api/classroom';

export function handleAddSub(event, document) {
    event.preventDefault()
    const formEl = document.querySelector('#add-sub-form')
    const form = new FormData(formEl)
    const data = {
        name: form.get('name'),
        code: form.get('code'),
        semester: form.get('semester'),
        department: form.get('department'),
        departmentID: form.get('departmentID')
    }
    addSubjects(this.state.userDetails._id, data)
    const subjects = [...this.state.subjects, data];
    this.setState({ subjects: this.sortSubjectsByField(subjects) })
}

export function sortSubjectsByField(data, field = 'department') {
    const alpha = 'abcdefghijklmnopqrstuvwxyz';
    return data
        .filter(item => (item.department && item.name && item.code && item.semester))
        .map(item => ({
            data: item,
            meta: alpha.indexOf(item[field].charAt(0).toLowerCase())
        }))
        .sort((a, b) => a.meta - b.meta)
        .map(item => item.data)
}

export function clearSubInputFields(e, document) {
    e.preventDefault();
    this.setState({
        name: '',
        code: '',
        semester: ''
    })
    const fields = ['name', 'code', 'semester']
    fields.forEach(name => {
        document.getElementsByName(name)[0].value = '';
    })
}