export function getEditTemplate(currentClass) {
    const existingStaffs = currentClass.subjects.map(e => e.staff._id)
    const currentEditingStaffList = this.props.state.subjects.filter(e => e.staff.length).map(sub => ({
        subject: {
            _id: sub._id,
            name: sub.name
        },
        staff: sub.staff.map(stf => ({
            ...stf,
            isExistingStaff: existingStaffs.includes(stf._id)
        }))
        .sort((a, b) => b.isExistingStaff - a.isExistingStaff)
    }))
    this.setState({ currentEditingStaffList, currentEditingClass: currentClass })
}