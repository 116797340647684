import React from 'react'
import "../styles/classnote.css";

  const  AttendanceSummary = () => {

  return (
    <div className="background leave-resp" >

  
        <div className="row">
                    <div className="frame">
                      <p className="text-leave-req"> Leave request for John Doe</p>
                      <form class="form-in">
  <div class="col-6">
    <label class="visually-hidden" for="autoSizingInput">From date:</label>
    <input type="text" class="form-control" />
  </div>
  <div class="col-6">
    <label class="visually-hidden" for="autoSizingInputGroup">To date:</label>
    <input type="text" class="form-control" />

    </div>
  <div class="col-12">
    <label for="inputAddress" class=" visually-hidden">Leave type:</label>
    <input type="text" class="form-control" id="inputAddress" />
  </div>
  <div class="col-12">
    <label for="inputAddress2" class="visually-hidden">Reason:</label>
    <textarea type="text" class="form-control" id="inputAddress2"/>
  </div>
  <div class="col-md-6">
    <label for="inputCity" class="visually-hidden validation">Validation:</label>
    <div type="text" class=" text-val validation1">Upload a doc as a proof or validation</div>
  </div>
  <div class="col-md-4">
    <button type="submit" class="cancel form-but-upload">Upload </button>
  </div>
  
  <div class="col-12">
   
  </div>
  <div class="col-12">
    <button type="submit" class="getresult form-but-leave">Apply Leave</button>
  </div>
</form>
                      </div>
                      </div>
    </div>
    
  )
}
export default AttendanceSummary;
