import React, { Component } from "react";
// import axios from "axios";
// import moment from "moment";
import "../../../../styles/table.css";
import "../../../../minified-css/material-min.css";
import "../../../../styles/dropdowns.css";
import "../../../../styles/theme-style.css";

class ShowBusTemplate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            displayStudents: false
        }
    }

    componentDidMount() {

    }

    arrangeStudents() {

    }

    render() {
        return (
            <div class="item col-md-3" style={{ margin: 10, backgroundColor: '#002933' }}>
                <div>
                    <h4 style={{ textAlign: 'center' }}>Bus Number &nbsp;&nbsp;<span class="busNumber">{this.props.bus.busNumber}</span></h4>
                </div>
                <div class="totalStudents" style={{ marginTop: 50 }}>
                    <h4 style={{ textAlign: 'center', paddingTop: 20 }}>Total Students</h4>
                    {/* <div className="center">
                        <img className="hover" width="30" src="https://smallimg.pngkey.com/png/small/126-1269278_add-student-icon-png-groups-first-baptist-cleveland.png" />
                    </div> */}
                    <h4 style={{ textAlign: 'center' }}>{this.props.bus.totalStudents}</h4>
                </div>
                {/* <h4 style={{ textAlign: 'center', paddingTop: 20 }}>Bus Route</h4> */}
                <div className="center" style={{ marginTop: 50 }}>
                    <img onClick={() => this.props.displayStudents(true, this.props.bus)} style={{ width: 50, height: 50, objectFit: 'contain', borderRadius: '50%' }} className="hover" alt="vector location icon" src="https://png.pngtree.com/png-clipart/20190619/original/pngtree-vector-location-icon-png-image_3997521.jpg" />
                </div>
                <div style={{ marginTop: 50, padding: 5 }} className="bus-section">
                    <span>
                        <span>College -&nbsp;</span>
                        {
                            this.props.bus.busStops && this.props.bus.busStops.length ? this.props.bus.busStops.map((stop, i) => (
                                (i !== this.props.bus.busStops.length - 1)
                                    ?
                                    <span>{stop.name} -&nbsp;</span>
                                    :
                                    <span>{stop.name}</span>
                            )
                            )
                                : <></>
                        }
                    </span>
                </div>
                <div style={{ display: 'inline' }}>
                    <div style={{ float: 'left', marginTop: 50 }}>
                        <button
                            type="button"
                            className="btn btn-danger m-t-15 m-l-30"
                            onClick={() => this.props.deleteBus(this.props.bus._id)}
                            style={{ marginLeft: 30 }}
                        >
                            Delete
                        </button>
                    </div>
                    <div style={{ float: 'right', marginTop: 50 }}>
                        <button
                            type="button"
                            className="btn btn-primary m-t-15 m-l-30"
                            onClick={() => this.props.handleUpdateBus(this.props.bus._id)}
                            style={{ marginRight: 30 }}
                        >
                            Update
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default ShowBusTemplate;