import React, { Component } from 'react';
import '../../../styles/table.css';
import '../../../minified-css/material-min.css';
import '../../../styles/dropdown-style.css';
import '../../../styles/theme-style.css';
import '../../../styles/classnote.css';
import Pagination from '../../../utils/pagination'
import axios from 'axios';

class InternalDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      column1: '',
      column2: '',
      column3: '',
      column4: '',
      column5: '',
      selectedSemester: '',
      searchAllowed: false,
      notesList: [],
      background: '',
      isFocussed: '',
      onFocus: false,
      userID: '',
      currentPage: 1,
      postsPerPage: 5,
    };
    this.paginate = this.paginate.bind(this)
    this.baseState = this.state;

  }

  // componentDidMount() {
  //   if (this.props && this.props.location && this.props.location.userID) {
  //     const userID = this.props.location.userID;
  //     this.setState({ userID: userID.userID });
  //   }
  //   this.unlisten = this.props.history.listen((location, action) => {
  //     this.setState(this.baseState);
  //   });
  // }

  // componentWillUnmount() {
  //   this.unlisten();
  // }

  /**
   * Adds the hover class when course is hovered
   */
  courseHover(event) {
    var element = event.target.className;
    if (element === 'column100 column2 ') {
      this.setState({ column1: 'hov-column-head-ver5' })
    }
  }

  /**
   * Adds the hover class when model 1 is hovered
   */
  modelHover1(event) {
    var element = event.target.className;
    if (element === 'column100 column3 ') {
      this.setState({ column2: 'hov-column-head-ver5' })
    }
  }

  /**
   * Adds the hover class when model 2 is hovered
   */
  modelHover2(event) {
    var element = event.target.className;
    if (element === 'column100 column4 ') {
      this.setState({ column3: 'hov-column-head-ver5' })
    }
  }


  /**
   * Adds the hover class when model 3 is hovered
   */
  modelHover3(event) {
    var element = event.target.className;
    if (element === 'column100 column5 ') {
      this.setState({ column4: 'hov-column-head-ver5' })
    }
  }


  /**
   * Adds the hover class when internals is hovered
   */
  internalsHover(event) {
    var element = event.target.className;
    if (element === 'column100 column6 ') {
      this.setState({ column5: 'hov-column-head-ver5' })
    }
  }

  /**
   * Resets the state variables when hover is removed
   */
  hoverOff() {
    this.setState({
      column1: '',
      column2: '',
      column3: '',
      column4: '',
      column5: ''
    })
  }

  /**
   * Sets the semester selected
   */
  onDropDownSelect(event) {
    this.setState({ selectedSemester: event.target.id, onFocus: false, background: 'is-hidden' });
    if (this.state.searchAllowed) {
      this.setState({ searchAllowed: false })
    }
  }

  onDropDownFocus() {
    this.setState({ isFocussed: 'is-focused', onFocus: true, background: 'is-shown' });
  }

  /**
   * Allows the grid to display the values
   */
  getResult() {
    if (this.state.selectedSemester !== '') {
      this.fetchInternalDetails();
    } else {
      alert('Please select the values');
      this.setState({ searchAllowed: false })
    }

  }


  /**
   * Fetches the internals mark based on semester selected
   */
  fetchInternalDetails() {
    this.setState({ searchAllowed: true });
    var semester = this.state.selectedSemester;
    var userID = { userID: this.state.userID };
    axios.get(`${process.env.PUBLIC_URL}/xakal/assessment/internaldetail/${semester}`, { params: userID })
      .then((response) => {
        this.setState({ notesList: response.data });
      });
  }

  /**
   * Displays the list of notes based on the API response
   */
  displayTable() {
    const indexOfLastPost = this.state.currentPage * this.state.postsPerPage;
    const indexOfFirstPost = indexOfLastPost - this.state.postsPerPage;
    const currentPost = this.state.notesList.slice(indexOfFirstPost, indexOfLastPost)

    return currentPost.map((singleData, index) => {
      return (
        <tr className="row100">
          <td className="column100 grid-cont vertical-border column1" data-column="column1">{++index}</td>
          <td className={"column100 grid-cont vertical-border column2 "} onMouseEnter={this.courseHover.bind(this)} onMouseLeave={this.hoverOff.bind(this)}>{singleData.course}</td>
          <td className={"column100 grid-cont vertical-border column3 "} onMouseEnter={this.modelHover1.bind(this)} onMouseLeave={this.hoverOff.bind(this)}>{singleData.model1}</td>
          <td className={"column100 grid-cont vertical-border column4 "} onMouseEnter={this.modelHover2.bind(this)} onMouseLeave={this.hoverOff.bind(this)}>{singleData.model2}</td>
          <td className={"column100 grid-cont vertical-border column5 "} onMouseEnter={this.modelHover3.bind(this)} onMouseLeave={this.hoverOff.bind(this)}>{singleData.model3}</td>
          <td className={"column100 grid-cont vertical-border column6 "} onMouseEnter={this.internalsHover.bind(this)} onMouseLeave={this.hoverOff.bind(this)}>{singleData.internals}</td>
        </tr>
      )
    })
  }

  paginate(num) { this.setState({ currentPage: num }) }

  render() {
    return (
      <div className="container-fluid background" >
        <div className="d-sm-flex  mb-4 " >
          {/* <h1 className="h3 mb-0 text-gray-800 m-t-100 m-l-25">Internal Details</h1> */}
        </div>

        {/* <nav className="navbar navbar-expand-lg  lineassement " style={{ marginLeft: '1%' }}>
          <a className="navbar-brand" href="#">Internals</a>
          <a className="navbar-brand" href="#">Semester</a>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
          </div>
        </nav> */}
        <div className="d-sm-flex  mb-4 ">
          <div className="head intrnalheadres" style={{ marginLeft: '8px', marginTop: '0px' }}>Internals</div>

          {/* <h1 className="h3 mb-0 text-gray-800 m-t-20">Notes</h1> */}
        </div>

        <div className="row  m-t-10">
          <div className="col-sm-12 ">
            <div className="card-box-class ">
              <div className="card-body row ">
                <div className="col-lg-4  ">
                  <div
                    className={
                      // "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width " +
                      this.state.isFocussed
                    }
                  >
                    <label
                      className="input-text labell intrnalres"
                      style={{ marginLeft: '1px', marginTop: '1%', }}

                    >
                      Semester Number:
                    </label>
                    <input
                      onKeyPress={(e) => e.preventDefault()}
                      autoComplete="off"
                      onFocus={this.onDropDownFocus.bind(this)}
                      className="new-xakal-input background height intrnalres"
                      type="text"
                      // id="Semester"
                      value={this.state.selectedSemester}
                      style={{ marginLeft: '-1px', width: '368px', marginTop: '1%' }}
                    />

                    {this.state.onFocus ? (
                      <div className="mdl-menu__container is-upgraded dropdown-list is-visible dropsemester1" style={{ width: '368px' }} >
                        <div className="mdl-menu__outline  dropdown-div dropsemester1" style={{ width: '368px', marginLeft: '2%', marginTop: '3%' }} >
                          <ul className="scrollable-menu  ul-list" style={{ width: '368%' }} >
                            <li
                              className="mdl-menu__item animation label-weight"
                              id="Semester 1" style={{ width: '100%' }}
                              onClick={this.onDropDownSelect.bind(this)}
                            >
                              Semester 1
                            </li>
                            <li
                              className="mdl-menu__item animation1 label-weight"
                              id="Semester 2"
                              onClick={this.onDropDownSelect.bind(this)}
                            >
                              Semester 2
                            </li>
                            <li
                              className="mdl-menu__item animation2 label-weight"
                              id="Semester 3"
                              onClick={this.onDropDownSelect.bind(this)}
                            >
                              Semester 3
                            </li>
                            <li
                              className="mdl-menu__item animation label-weight"
                              id="Semester 4" style={{ width: '200px' }}
                              onClick={this.onDropDownSelect.bind(this)}
                            >
                              Semester 4
                            </li>
                            <li
                              className="mdl-menu__item animation1 label-weight"
                              id="Semester 5"
                              onClick={this.onDropDownSelect.bind(this)}
                            >
                              Semester 5
                            </li>
                            <li
                              className="mdl-menu__item animation2 label-weight"
                              id="Semester 6"
                              onClick={this.onDropDownSelect.bind(this)}
                            >
                              Semester 6
                            </li>
                            <li
                              className="mdl-menu__item animation label-weight"
                              id="Semester 7"
                              onClick={this.onDropDownSelect.bind(this)}
                            >
                              Semester 7
                            </li>
                            <li
                              className="mdl-menu__item animation1 label-weight"
                              id="Semester 8"
                              onClick={this.onDropDownSelect.bind(this)}
                            >
                              Semester 8
                            </li>
                          </ul>
                        </div>
                      </div>
                    ) : (
                      <p></p>
                    )}
                  </div>
                </div>

                <div className="col-lg-4 m-t-0">
                  <button
                    type="button"
                    onClick={this.getResult.bind(this)}
                    className="getresult heading-weight button-size intrnalres"
                    style={{ marginTop: '40px', width: '150px', marginLeft: '21%' }}

                  >
                    Get Results
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>


        {this.state.searchAllowed ? <div className="limiter align background">
          <div class="container align background">
            <div class="col-md-12">
              <div class="row">
                <table class="table align background tables">
                  <thead >
                    <tr className="row100 head">
                      <th data-column="column1">SL No</th>
                      {/* <th className={" " + this.state.column1} onMouseEnter={this.courseHover.bind(this)} onMouseLeave={this.hoverOff.bind(this)}>S.No</th> */}

                      <th className={"column100 vertical-border-head heading-weight heading-size column1 " + this.state.column1} onMouseEnter={this.courseHover.bind(this)} onMouseLeave={this.hoverOff.bind(this)}>Course</th>
                      <th className={"column100 vertical-border-head heading-weight heading-size column1 " + this.state.column2} onMouseEnter={this.modelHover1.bind(this)} onMouseLeave={this.hoverOff.bind(this)}>Model 1</th>
                      <th className={"column100 vertical-border-head heading-weight heading-size column1 " + this.state.column3} onMouseEnter={this.modelHover2.bind(this)} onMouseLeave={this.hoverOff.bind(this)}>Model 2</th>
                      <th className={"column100 vertical-border-head heading-weight heading-size column1 " + this.state.column4} onMouseEnter={this.modelHover3.bind(this)} onMouseLeave={this.hoverOff.bind(this)}>Model 3</th>
                      <th className={"column100 vertical-border-head heading-weight heading-size column1 " + this.state.column5} onMouseEnter={this.internalsHover.bind(this)} onMouseLeave={this.hoverOff.bind(this)}>Internals</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.displayTable()}
                  </tbody>
                </table>
              </div>
              <div style={{ marginLeft: '-117px' }}>
                <Pagination postPerPage={this.state.postsPerPage} totalPost={this.state.notesList.length} paginate={this.paginate} />
              </div>
            </div>
          </div>
        </div> : <span></span>}
      </div>

    )
  }
}

export default InternalDetails;
