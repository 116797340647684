
import axios from 'axios';

export const getAllDepartments = async () => {
    return axios
        .get(`/xakal/departmentdetail`)
        .then((res) => {
            if (!res.data.ok) {
                console.log({ error: res.data })
                return alert('Error fetching department details!')
            }
            return res.data.data;
        })
        .catch(error => alert(error.response.data))
}