import React, { Component } from "react";
import "../../styles/classroom.css";
import AddSubjects from "./addSubjects";
import AllocateStaffs from "./allocateStaffs";
import CreateClass from "./createClass";
import ClassList from "./classList";
import * as addSubHelper from "./helpers/addSubjectsHelper";
import * as allocateSubHelper from "./helpers/allocateSubHelper";
import * as classHelper from "./helpers/classHelper";
class Classroom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetails: this.props.state.location.state.userDetails,
      userID: this.props.state.location.state.userID,
      userRole: this.props.state.location.state.userRole,
      name: "",
      code: "",
      semester: "",
      department: "",
      departmentID: "",
      subjects: [],
      availableStaffs: [],
      rendered: false,
      allDepartments: [],
      subsAndStaffsForm: [],
      displaySubsAndStaffs: [],
      classInfo: [],
      classrooms: [],
      subAndStaffList: [],
      ref: React.createRef()
    };
  }

  componentDidMount() {
    allocateSubHelper.fetchAllSubjects.bind(this)();
    classHelper.getAllClasses.bind(this)()
  }

  scrollToBottom() {
    this.ref.scrollIntoView({
      behavior: 'smooth'
    })
  }

  fetchAllSubjects() {
    allocateSubHelper.fetchAllSubjects.bind(this)();
  }

  sortSubjectsByField(data, field) {
    return addSubHelper.sortSubjectsByField.bind(this)(data, field);
  }

  allocateStaffs(e, subId) {
    allocateSubHelper.allocateStaffs.bind(this)(e, subId);
  }

  allocateStaffsSubmit(e) {
    allocateSubHelper.allocateStaffsSubmit.bind(this)(e);
  }

  clearSubInputFields(e) {
    addSubHelper.clearSubInputFields.bind(this)(e, document);
  }

  handleAddSub(event) {
    addSubHelper.handleAddSub.bind(this)(event, document);
  }

  addStaffSelector(subIndex) {
    allocateSubHelper.addStaffSelector.bind(this)(subIndex)
  }

  removeStaffSelector(subIndex, staffIndex, staffId, subId, singleStaff) {
    allocateSubHelper.removeStaffSelector.bind(this)(subIndex, staffIndex, staffId, subId, singleStaff)
  }

  addClassToRoom(classrooms) {
    this.setState({ classrooms })
    this.scrollToBottom()
  }

  setClassroomState(classrooms) {
    this.setState({ classrooms })
  }

  render() {
    return (
      <>
        <div className="classroom_container">
          <div className="d-sm-flex align-items-center justify-content-between mb-4" style={{ paddingTop: 10, marginTop: -10, float: 'right' }}>
            <div className="row" style={{ justifyContent: 'space-between', float: 'right', fontSize: '18px', marginRight: '130px' }}>
              <div id="dashboard" className='dashboard' onClick={() => this.props.setClassroom(false)}>
                Dashboard
              </div>
            </div>
          </div>

          <AddSubjects
            handleAddSub={this.handleAddSub.bind(this)}
            clearSubInputFields={this.clearSubInputFields.bind(this)}
          />
          <div className={"spacing"}></div>
          <AllocateStaffs
            allocateStaffs={this.allocateStaffs.bind(this)}
            allocateStaffsSubmit={this.allocateStaffsSubmit.bind(this)}
            addStaffSelector={this.addStaffSelector.bind(this)}
            removeStaffSelector={this.removeStaffSelector.bind(this)}
            state={this.state}
          />
          <div className={"spacing"}></div>
          <CreateClass
            state={this.state}
            fetchAllSubjects={this.fetchAllSubjects.bind(this)}
            addClassToRoom={(data) => this.addClassToRoom(data)}
          />
          <div className={"spacing"}></div>
          <ClassList
            state={this.state}
            setClassroomState={(classrooms) => this.setClassroomState(classrooms)}
          />
          <div className={"spacing"}></div>
          <div style={{ float: "left", clear: "both" }}
            ref={(el) => { this.ref = el; }}>
          </div>
        </div>
      </>
    );
  }
}

export default Classroom;
