import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import 'bootstrap/dist/css/bootstrap.min.css'
import { Route, BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import StudentsPortal from "./components/students-portal";
import * as serviceWorker from "./serviceWorker";
import StaffPortal from "./components/staff-portal";
import ManagementPortal from "./components/management-portal";
import HODPortal from "./components/hod-portal";
import NonTeachingPortal from "./components/non-teaching-portal";
import XakalAdminPortal from "./components/xakal-admin-portal";
import SignUp from "./components/signup";
import Forget from "./components/forgetpassword";
import NewPassword from './components/newpassword';
import Settings from './components/setting';
import ChangePassword from './components/changePassword';
import Dashboard from './components/newDashboard';
import SDashboard from './components/students-portal/dashboard';
import axios from "axios";
import './fonts/VisbyCF/VisbyCF-Regular.otf';
import "./fonts/VisbyCF/VisbyCF-Thin.otf";
import "./fonts/VisbyCF/VisbyCF-Heavy.otf";
import "./fonts/VisbyCF/VisbyCF-Light.otf";
import "./fonts/VisbyCF/VisbyCF-Heavy.otf";
import Signup from "./components/signup";
import NewDashboard from "./components/newDashboard";

const getCsrfToken = async () => {
  const { data } = await axios.get(`${process.env.PUBLIC_URL}/xakal/csrf-token`);
  axios.defaults.headers.post["X-CSRF-Token"] = data.csrfToken;
  axios.defaults.headers.put["X-CSRF-Token"] = data.csrfToken;
  console.log(data);
};
getCsrfToken();

const routing = (
  <Router>
    <div>
      <Route exact path="/" component={App} />
      <Route exact path="/linkedin" component={LinkedInCallback} />
      <Route path="/students-portal" component={StudentsPortal} />
      <Route path="/staff-portal" component={StaffPortal} />
      <Route path="/management-portal" component={ManagementPortal} />
      <Route path="/hod-portal" component={HODPortal} />
      <Route path="/non-teaching-portal" component={NonTeachingPortal} />
      <Route path="/xakal-admin" component={XakalAdminPortal} />
      <Route path="/signup" component={SignUp} />
      <Route path="/forget-password" component={Forget} />
      <Route path="/new-password" component={NewPassword} />
      <Route path="/students-portal/dashboard" component={SDashboard }></Route>
      <Route path="/setting" component={Settings}></Route>
      <Route path="/changepassword" component={ChangePassword}></Route>
    </div>
  </Router>
);
ReactDOM.render(routing, document.getElementById("root"));

serviceWorker.unregister();
