import React, { Component } from "react";
import axios from "axios";
import "../../styles/classnote.css";

class AddDepartmentDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userID: "",
      studentName: "",
      departmentName: "",
      staffDetails: [],
      name: "",
      studentCapacity: "",
      startingYear: "",
      email: "",
      contact: "",
      hodName: "",
    };
    this.baseState = this.state;
  }

  componentDidMount() {
    if (this.props && this.props.location && this.props.location.userID) {
      this.setState({
        routerLink: this.props.location.pathname,
        loginUserID: this.props.location.userID.userID,
      });
    }
    setTimeout(() => {
      this.fillUserID();
    }, 400);
    this.fetchStaffDetails();
  }

  /**
   * Fetches all staff
   */
  fetchStaffDetails() {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/staffdetail`)
      .then((response) => {
        this.setState({ staffDetails: response.data });
      });
  }

  onDropDownFocus(event) {
    if (event.target) {
      // event.target.parentNode.classList.add("is-focused");
      // event.target.nextSibling.classList.add("is-shown");
    }
  }

  onFocusOut(event) {
    if (event.target) {
      if (event.target.value === "") {
        // event.target.parentNode.classList.remove("is-focused");
      }
      // event.target.nextSibling.classList.remove("is-shown");
    }
  }

  /**
   * Triggers when the form is changed and stores the values in state
   * @param event form values
   */
  handleFormChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  /**
   * Triggers when the form is submitted
   * Checks whether the values are entered properly
   */
  formSubmit() {
    let isUpdated = false;
    if (
      this.state.name === "" ||
      this.state.startingYear === "" ||
      this.state.email === "" ||
      this.state.studentCapacity === "" ||
      this.state.contact === "" ||
      this.state.hodName === "" ||
      this.state.qualification === "" ||
      this.state.designation === "" ||
      this.state.hodEmail === "" ||
      this.state.hodContact === "" ||
      this.state.hodEmergencyContact === "" ||
      this.state.joiningDate
    ) {
      const params = {
        name: this.state.name,
        headOfDepartment: this.state.hodName,
        // updatedBy: this.state.loginUserID.toUpperCase(),
        updatedDate: new Date(Date.now()).toLocaleString(),
        email: this.state.email,
        contact: this.state.contact,
        startingYear: this.state.startingYear,
        studentCapacity: this.state.studentCapacity,
      };
      axios
        .post(`${process.env.PUBLIC_URL}/xakal/departmentdetail`, params)
        .then(() => {
          if (!isUpdated) {
            alert("Updated Successfully");
          }
          isUpdated = true;
        })
        .catch((err) => console.log(err));
      this.addUserDetails();
    } else {
      alert("Please give all the details");
    }
  }

  addUserDetails() {
    const params = {
      userID: this.state.userID.toUpperCase(),
      password: this.state.password,
      userRole: "hod",
      // updatedBy: this.state.loginUserID.toUpperCase(),
      updatedDate: new Date(Date.now()).toLocaleString(),
      collegeCode: "",
      collegeName: "",
      registerNr: "",
    };
    axios
      .post(`${process.env.PUBLIC_URL}/xakal/adduser`, params)
      .then((res) => {
        if (res.data.ok) {
          this.insertHODDetails();
        } else {
          alert("Something Went Wrong!");
        }
      })
      .catch((err) => console.log(err));
  }

  /**
   * Inserts hod staff detailss
   */
  insertHODDetails() {
    const params = {
      userID: this.state.userID.toUpperCase(),
      name: this.state.hodName,
      qualification: this.state.qualification,
      // uploadedBy: this.state.loginUserID.toUpperCase(),
      uploadedDate: new Date(Date.now()).toLocaleString(),
      designation: this.state.designation,
      email: this.state.hodEmail,
      bloodGroup: "NA",
      contact: this.state.hodContact,
      emergencyContact: this.state.hodEmergencyContact,
      parentSpouse: "NA",
      joiningDate: this.state.joiningDate,
      departmentName: this.state.name,
    };
    axios
      .post(`${process.env.PUBLIC_URL}/xakal/staffdetail`, params)
      .then(() => {
        console.log("inserted");
        this.setState({
          name: "",
          studentCapacity: "",
          startingYear: "",
          email: "",
          contact: "",
          hodName: "",
          joiningDate:"",
          hodEmergencyContact:"",
          hodContact:"",
          designation:"",
          qualification:"",
          hodEmail:""
        });
      })
      .catch((err) => console.log(err));
  }

  fillUserID() {
    this.setState({
      userID: "H" + `${Math.floor(1000 + Math.random() * 9000)}`,
      password: this.generatePassword(),
    });
  }

  generatePassword() {
    let length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (let i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }
  resetForm(){
    this.setState({
      name: "",
      studentCapacity: "",
      startingYear: "",
      email: "",
      contact: "",
      hodName: "",
      joiningDate:"",
      hodEmergencyContact:"",
      hodContact:"",
      designation:"",
      qualification:"",
      hodEmail:""
    });
  }
  render() {
    return (
      <div className="container-fluid background bk">
        <div className="d-sm-flex align-items-center justify-content-between">
          <nav
            className="navbar navbar-expand-lg bar"
            style={{ marginTop: "-7%" }}
          >
            <div className="title">Department</div>
            <div className="collapse navbar-collapse">
              <form className="form-inline my-2 my-lg-0">
                <input
                  style={{
                    width: "500px",
                    marginLeft: "120px",
                    backgroundColor: "rgba(54, 42, 65, 0.04)",
                    fontStyle: "italic",
                    height: "45px",
                  }}
                  className="form-control mr-sm-2 "
                  type="search"
                  placeholder="Search....."
                  aria-label="Search"
                ></input>
              </form>
            </div>
          </nav>
          <div className="row" style={{ color: "#714F90" }}>
            <h4
              className="head resp max-dept-th"
              style={{ marginLeft: "-820px", marginTop: "150px" }}
            >
              {" "}
              Add Department
            </h4>

            {/* <h1 className="h3 mb-0 text-gray-800 m-t-20">Notes</h1> */}
          </div>
        </div>
        <div className="row m-r-10 card-max">
          <div className="col-sm-12">
            <div className="background">
              <div className="card-body row ">
                <div className="col-lg-3 p-t-20">
                  <div
                    className={
                      "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                    }
                  >
                    <label
                      style={{ marginLeft: "30px" }}
                      className="input-text labell pass-name"
                    >
                      Name
                    </label>
                    <input
                      autoComplete="off"
                      onBlur={this.onFocusOut.bind(this)}
                      onFocus={this.onDropDownFocus.bind(this)}
                      className="new-xakal-input height background pass-name"
                      type="text"
                      id="name"
                      onChange={this.handleFormChange.bind(this)}
                      name="name"
                      style={{ marginLeft: "30px" }}
                      value={this.state.name || ""}

                    />
                  </div>
                </div>
                <div className="col-lg-3 p-t-20">
                  <div
                    className={
                      "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                    }
                  >
                    <label
                      style={{ marginLeft: "25px" }}
                      className="input-text labell pass-name"
                    >
                      Mobile
                    </label>
                    <input
                      autoComplete="off"
                      onBlur={this.onFocusOut.bind(this)}
                      onFocus={this.onDropDownFocus.bind(this)}
                      className="new-xakal-input background height pass-name"
                      type="text"
                      id="contact"
                      onChange={this.handleFormChange.bind(this)}
                      name="contact"
                      style={{ marginLeft: "10%" }}
                      value={this.state.contact || ""}

                    />
                  </div>
                </div>
                <div className="col-lg-3 p-t-20">
                  <div
                    className={
                      "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                    }
                  >
                    <label
                      style={{ marginLeft: "5%" }}
                      className="input-text labell pass-name "
                    >
                      Email
                    </label>
                    <input
                      autoComplete="off"
                      onBlur={this.onFocusOut.bind(this)}
                      onFocus={this.onDropDownFocus.bind(this)}
                      className="new-xakal-input background height pass-name"
                      type="text"
                      id="email"
                      onChange={this.handleFormChange.bind(this)}
                      name="email"
                      style={{ marginLeft: "5%" }}
                      value={this.state.email || ""}

                    />
                  </div>
                </div>

                <div className="col-lg-3 p-t-20">
                  <div
                    className={
                      "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                    }
                  >
                    <label className="input-text labell pass-name">
                      Starting Year
                    </label>
                    <input
                      autoComplete="off"
                      onBlur={this.onFocusOut.bind(this)}
                      onFocus={this.onDropDownFocus.bind(this)}
                      className="new-xakal-input background height pass-name"
                      type="number"
                      id="startingYear"
                      onChange={this.handleFormChange.bind(this)}
                      name="startingYear"
                      value={this.state.startingYear || ""}

                    />
                  </div>
                </div>
                <div className="col-lg-3 p-t-20">
                  <div
                    className={
                      "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                    }
                  >
                    <label
                      style={{ marginLeft: "10%" }}
                      className="input-text labell pass-name"
                    >
                      Student Capacity
                    </label>
                    <input
                      autoComplete="off"
                      onBlur={this.onFocusOut.bind(this)}
                      onFocus={this.onDropDownFocus.bind(this)}
                      className="new-xakal-input background height pass-name"
                      type="number"
                      id="studentCapacity"
                      onChange={this.handleFormChange.bind(this)}
                      name="studentCapacity"
                      value={this.state.studentCapacity || ""}
                      style={{ marginLeft: "10%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row card-max">
          <h4 className="head max-dept-th1" style={{ marginLeft: "40px" }}>
            Add HOD details
          </h4>
          <div className="col-sm-12">
            <div className="background" style={{ marginLeft: "3%" }}>
              <div className="card-body row">
                <div className="col-lg-3 p-t-20">
                  <div
                    className={
                      "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                    }
                  >
                    <label className="input-text labell pass-name">
                      User ID
                    </label>
                    <input
                      autoComplete="off"
                      onBlur={this.onFocusOut.bind(this)}
                      onFocus={this.onDropDownFocus.bind(this)}
                      className="new-xakal-input background height pass-name"
                      type="text"
                      readOnly
                      id={`userID`}
                      value={this.state.userID}
                      onChange={this.handleFormChange.bind(this)}
                      name="userID"
                    />
                  </div>
                </div>
                <div className="col-lg-3 p-t-20">
                  <div
                    className={
                      "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                    }
                  >
                    <label
                      style={{ marginLeft: "-12px" }}
                      className="input-text labell pass-name"
                    >
                      Password
                    </label>
                    <input
                      autoComplete="off"
                      onBlur={this.onFocusOut.bind(this)}
                      onFocus={this.onDropDownFocus.bind(this)}
                      className="new-xakal-input background height pass-name"
                      readOnly
                      id={`password`}
                      onChange={this.handleFormChange.bind(this)}
                      name="password"
                      value={this.state.password}
                      style={{ marginLeft: "-12px" }}
                    />
                  </div>
                </div>
                <div className="col-lg-3 p-t-20">
                  <div
                    className={
                      "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                    }
                  >
                    <label
                      style={{ marginLeft: "-19px" }}
                      className="input-text labell pass-name"
                    >
                      Name
                    </label>
                    <input
                      autoComplete="off"
                      onBlur={this.onFocusOut.bind(this)}
                      onFocus={this.onDropDownFocus.bind(this)}
                      className="new-xakal-input  background height pass-name"
                      type="text"
                      id="name"
                      onChange={this.handleFormChange.bind(this)}
                      name="hodName"
                      style={{ marginLeft: "-19px" }}
                      value={this.state.hodName || ""}

                    />
                  </div>
                </div>
                <div className="col-lg-3 p-t-20">
                  <div
                    className={
                      "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                    }
                  >
                    <label
                      style={{ marginLeft: "-30px" }}
                      className="input-text labell pass-name"
                    >
                      Designation
                    </label>
                    <input
                      autoComplete="off"
                      onBlur={this.onFocusOut.bind(this)}
                      onFocus={this.onDropDownFocus.bind(this)}
                      className="new-xakal-input  background height pass-name"
                      type="text"
                      id="designation"
                      onChange={this.handleFormChange.bind(this)}
                      name="designation"
                      style={{ marginLeft: "-30px" }}
                      value={this.state.designation || ""}

                    />
                  </div>
                </div>

                <div className="col-lg-3 p-t-20">
                  <div
                    className={
                      "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                    }
                  >
                    <label className="input-text labell pass-name">
                      Qualification
                    </label>
                    <input
                      autoComplete="off"
                      onBlur={this.onFocusOut.bind(this)}
                      onFocus={this.onDropDownFocus.bind(this)}
                      className="new-xakal-input  background height pass-name"
                      type="text"
                      id="qualification"
                      onChange={this.handleFormChange.bind(this)}
                      name="qualification"
                      value={this.state.qualification || ""}

                    />
                  </div>
                </div>
                <div className="col-lg-3 p-t-20">
                  <div
                    className={
                      "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                    }
                  >
                    <label
                      style={{ marginLeft: "-10px" }}
                      className="input-text labell pass-name"
                    >
                      Mobile
                    </label>
                    <input
                      autoComplete="off"
                      onBlur={this.onFocusOut.bind(this)}
                      onFocus={this.onDropDownFocus.bind(this)}
                      className="new-xakal-input  background height pass-name"
                      type="number"
                      id="contact"
                      onChange={this.handleFormChange.bind(this)}
                      name="hodContact"
                      style={{ marginLeft: "-10px" }}
                      value={this.state.hodContact || ""}

                    />
                  </div>
                </div>
                <div className="col-lg-3 p-t-20">
                  <div
                    className={
                      "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                    }
                  >
                    <label
                      style={{ marginLeft: "-19px" }}
                      className="input-text labell pass-name"
                    >
                      Email
                    </label>

                    <input
                      autoComplete="off"
                      onBlur={this.onFocusOut.bind(this)}
                      onFocus={this.onDropDownFocus.bind(this)}
                      className="new-xakal-input  background height pass-name"
                      type="email"
                      id="email"
                      onChange={this.handleFormChange.bind(this)}
                      name="hodEmail"
                      style={{ marginLeft: "-19px" }}
                      value={this.state.hodEmail||""}
                    />
                  </div>
                </div>
                <div className="col-lg-3 p-t-20">
                  <div
                    className={
                      "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                    }
                  >
                    <label
                      style={{ marginLeft: "-30px" }}
                      className="input-text labell pass-name"
                    >
                      Emergency contact
                    </label>
                    <input
                      autoComplete="off"
                      onBlur={this.onFocusOut.bind(this)}
                      onFocus={this.onDropDownFocus.bind(this)}
                      className="new-xakal-input  background height pass-name"
                      type="text"
                      id="emergencyContact"
                      onChange={this.handleFormChange.bind(this)}
                      name="hodEmergencyContact"
                      style={{ marginLeft: "-30px" }}
                      value={this.state.hodEmergencyContact || ""}
                    />
                  </div>
                </div>
                <div className="col-lg-3 p-t-20">
                  <div
                    className={
                      "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width is-focused"
                    }
                  >
                    <label
                      htmlFor="joiningDate"
                      className="input-text labell pass-name"
                    >
                      Joining date
                    </label>
                    <input
                      autoComplete="off"
                      placeholder=""
                      onBlur={this.onFocusOut.bind(this)}
                      onFocus={this.onDropDownFocus.bind(this)}
                      className="new-xakal-input  background height pass-name"
                      type="date"
                      id="joiningDate"
                      onChange={this.handleFormChange.bind(this)}
                      name="joiningDate"
                      value={this.state.joiningDate || ""}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-8 p-t-20" style={{ marginBottom: "1rem" }}>
                <button
                  type="button"
                  onClick={this.formSubmit.bind(this)}
                  className="resp save m-t-15 m-l-30"
                  style={{
                    boxShadow: "0px 16px 32px rgba(54, 42, 65, 0.32",
                  }}
                >
                  Save
                </button>
                <button type="button" className="ressp m-t-15  cancel " onClick={this.resetForm.bind(this)}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddDepartmentDetails;
