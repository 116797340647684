import React, { Component } from "react";
import axios from "axios";
import "../../styles/classnote.css";
import Pagination from "../../utils/pagination";

class StudentDetailsMaintain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      studentDetails: [],
      isEdit: false,
      isDelete: false,
      userID: "",
      selectedYear: "",
      departmentDetails: [],
      hasDepartmentValue: false,
      selectedDepartment: "",
      managementPortal: false,
      hodPortal:false,
      currentPage: 1,
      postsPerPage: 5,
    };
    this.baseState = this.state;
    this.studentsArray = [];
    this.studentID = [];
    this.paginate = this.paginate.bind(this);
  }
  deleteArray = [];
  componentDidMount() {
    if (this.props && this.props.location && this.props.location.userID) {
      this.setState({
        routerLink: this.props.location.pathname,
        userID: this.props.location.userID.userID,
      });
    }
    if (
      // this.props.location.pathname === "/hod-portal/view-student-details" ||
      this.props.location.pathname === "/staff-portal/view-student-details"
    ) {
      this.fetchStaffDetails();
    } else {
      this.setState({ managementPortal: true,hodPortal: true });
      this.fetchDepartmentDetails();
    }
  }

  fetchStaffDetails() {
    if (
      this.props.location &&
      this.props.location.userID &&
      this.props.location.userID.userID
    ) {
      axios
        .get(
          `${process.env.PUBLIC_URL}/xakal/staffdetail/${this.props.location.userID.userID}`
        )
        .then((response) => {
          this.setState({
            staffDetails: response.data.user,
            values: response.data,
            selectedDepartment: response.data.user.departmentName,
          });
        });
    }
  }

  fetchStudentDetails() {
    this.deleteArray = [];
    axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/studentdetail/department/${this.state.selectedDepartment}`
      )
      .then((response) => {
        this.setState({ studentDetails: response.data, values: response.data });
      });
  }

  fetchYearwiseStudentDetails() {
    this.deleteArray = [];
    axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/studentdetail/yearwise/${this.state.selectedYear}/${this.state.selectedDepartment}`
      )
      .then((response) => {
        this.setState({ studentDetails: response.data, values: response.data });
      });
  }

  displayStudentDetails() {
    const indexOfLastPost = this.state.currentPage * this.state.postsPerPage;
    const indexOfFirstPost = indexOfLastPost - this.state.postsPerPage;
    const currentPost = this.state.studentDetails.slice(
      indexOfFirstPost,
      indexOfLastPost
    );

    return currentPost.map((singleData, index) => {
      return (
        <tr className="odd gradeX" key={index++}>
          <td className={"left grid-cont vertical-border "} key={index++}>
            {singleData.userID}
          </td>
          <td className={"left grid-cont vertical-border "} key={index++}>
            {singleData.name}
          </td>
          <td className={"left grid-cont vertical-border "} key={index++}>
            {singleData.course}
          </td>
          <td className={"left grid-cont vertical-border "} key={index++}>
            {singleData.branch}
          </td>
          <td className={"left grid-cont vertical-border "} key={index++}>
            {singleData.email}
          </td>
          <td className={"left grid-cont vertical-border "} key={index++}>
            {singleData.contact}
          </td>
          <td className={"left grid-cont vertical-border "} key={index++}>
            {singleData.emergencyContact}
          </td>
          <td className={"left grid-cont vertical-border "} key={index++}>
            {singleData.parentName}
          </td>
          <td className={"left grid-cont vertical-border "} key={index++}>
            {singleData.admissionDate}
          </td>
          <td className={"left grid-cont vertical-border "} key={index++}>
            {singleData.bloodGroup}
          </td>
        </tr>
      );
    });
  }

  /**
   * Adds remove element in the end
   */
  deleteStudentDetails() {
    const indexOfLastPost = this.state.currentPage * this.state.postsPerPage;
    const indexOfFirstPost = indexOfLastPost - this.state.postsPerPage;
    const currentPost = this.state.studentDetails.slice(
      indexOfFirstPost,
      indexOfLastPost
    );

    return currentPost.map((singleData, index) => {
      return (
        <tr className="odd gradeX" key={index++}>
          <td className={"left vertical-border"} key={index++}>
            {singleData.userID}
          </td>
          <td className={"left vertical-border"} key={index++}>
            {singleData.name}
          </td>
          <td className={"left vertical-border"} key={index++}>
            {singleData.course}
          </td>
          <td className={"left vertical-border"} key={index++}>
            {singleData.branch}
          </td>
          <td className={"left vertical-border"} key={index++}>
            {singleData.email}
          </td>
          <td className={"left vertical-border"} key={index++}>
            {singleData.contact}
          </td>
          <td className={"left vertical-border"} key={index++}>
            {singleData.emergencyContact}
          </td>
          <td className={"left vertical-border"} key={index++}>
            {singleData.parentName}
          </td>
          <td className={"left vertical-border"} key={index++}>
            {singleData.admissionDate}
          </td>
          <td className={"left vertical-border"} key={index++}>
            {singleData.bloodGroup}
          </td>
          <td className="vertical-border">
            {" "}
            <button
              type="button"
              onClick={(i) => this.removeClick(singleData, (index -= 11))}
              className="btn btn-danger m-t-4 m-l-30"
            >
              X
            </button>
          </td>
        </tr>
      );
    });
  }

  /**
   * Removes the selected row
   * @param index selected row index
   */
  removeClick(singleData, i) {
    this.deleteArray.push(singleData);
    let studentDetails = [...this.state.studentDetails];
    studentDetails.splice(i, 1);
    this.setState({ studentDetails });
  }

  /**
   * Reverts back to the original state
   */
  discardChanges() {
    this.studentsArray = [];
    this.studentID = [];
    this.setState({
      isEdit: false,
      isDelete: false,
      studentDetails: this.state.values,
    });
    this.displayStudentDetails();
    this.fetchStudentDetails();
  }

  onEdit(singleElement, changedField, context) {
    const userID = singleElement._id;
    if (this.studentsArray.length) {
      this.studentsArray.forEach((element) => {
        if (element._id === userID) {
          if (changedField === "branchCourse") {
            const result = context.target.value.split("-");
            element.course = result[0];
            element.branch = result[1];
          } else {
            element[changedField] = context.target.value;
          }
          if (changedField === "admissionDate") {
            const admissionYear = new Date(context.target.value).getFullYear();
            element["admissionYear"] = admissionYear;
          }
        } else {
          if (!this.studentID.includes(userID)) {
            this.insertUpdatedDetails(
              userID,
              singleElement,
              changedField,
              context
            );
          }
        }
      });
    } else {
      if (!this.studentID.includes(userID)) {
        this.insertUpdatedDetails(userID, singleElement, changedField, context);
      }
    }
  }

  insertUpdatedDetails(userID, singleElement, changedField, context) {
    this.studentID.push(userID);
    this.studentsArray.push(singleElement);
    this.studentsArray.forEach((element) => {
      if (element._id === userID) {
        if (changedField === "branchCourse") {
          const result = context.target.value.split("-");
          element.course = result[0];
          element.branch = result[1];
        } else {
          if (changedField === "branchCourse") {
            const result = context.target.value.split("-");
            element.course = result[0];
            element.branch = result[1];
          } else {
            element[changedField] = context.target.value;
          }
        }
        if (changedField === "admissionDate") {
          const admissionYear = new Date(context.target.value).getFullYear();
          element["admissionYear"] = admissionYear;
        }
      }
    });
  }

  /**
   * sets the edit flag to true
   */
  redirect() {
    this.setState({ isEdit: true, isDelete: false });
  }

  /**
   * sets the delete flag to true
   */
  deleteRedirect() {
    this.setState({ isDelete: true, isEdit: false });
  }

  updateDetails() {
    let isUpdated = false;
    if (this.state.isEdit && this.studentsArray && this.studentsArray.length) {
      this.studentsArray.forEach((element) => {
        const params = {
          name: element.name,
          course: element.course,
          branch: element.branch,
          email: element.email.toLowerCase(),
          uploadedBy: this.state.userID.toUpperCase(),
          uploadedDate: new Date(Date.now()).toLocaleString(),
          bloodGroup: element.bloodGroup,
          contact: element.contact,
          emergencyContact: element.emergencyContact,
          parentName: element.parentName,
          admissionDate: element.admissionDate,
          admissionYear: element.admissionYear,
        };
        axios
          .put(
            `${process.env.PUBLIC_URL}/xakal/studentdetail/update/${element._id}`,
            params
          )
          .then(() => {
            if (!isUpdated) {
              alert("Updated Successfully");
            }
            isUpdated = true;
            this.setState({ isEdit: false });
          })
          .catch((err) => console.log(err));
      });
    } else if (
      this.state.isDelete &&
      this.deleteArray &&
      this.deleteArray.length
    ) {
      this.deleteArray.forEach((element) => {
        axios
          .delete(
            `${process.env.PUBLIC_URL}/xakal/studentdetail/${element._id}`
          )
          .then(() => {
            if (!isUpdated) {
              alert("Deleted Successfully");
            }
            isUpdated = true;
            this.setState({ isDelete: false });
            this.getResult();
          })
          .catch((err) => console.log(err));
      });
    }
  }

  paginate(num) {
    this.setState({ currentPage: num });
  }

  editStudentDetails() {
    const indexOfLastPost = this.state.currentPage * this.state.postsPerPage;
    const indexOfFirstPost = indexOfLastPost - this.state.postsPerPage;
    const currentPost = this.state.studentDetails.slice(
      indexOfFirstPost,
      indexOfLastPost
    );

    return currentPost.map((singleData, index) => {
      return (
        <tr className="odd gradeX" key={index++}>
          <td className={"left"} key={index++}>
            {singleData.userID}
          </td>
          <td className={"left"} key={index++}>
            <input
              type="text"
              className="add-border"
              onChange={this.onEdit.bind(this, singleData, "name")}
              defaultValue={singleData.name}
            ></input>
          </td>
          <td className={"left"} key={index++}>
            <input
              type="text"
              className="add-border"
              onChange={this.onEdit.bind(this, singleData, "course")}
              defaultValue={singleData.course}
            ></input>
          </td>
          <td className={"left"} key={index++}>
            <input
              type="text"
              className="add-border"
              onChange={this.onEdit.bind(this, singleData, "branch")}
              defaultValue={singleData.branch}
            ></input>
          </td>
          <td className={"left"} key={index++}>
            <input
              type="text"
              className="add-border"
              onChange={this.onEdit.bind(this, singleData, "email")}
              defaultValue={singleData.email}
            ></input>
          </td>
          <td className={"left"} key={index++}>
            <input
              type="number"
              className="add-border"
              onChange={this.onEdit.bind(this, singleData, "contact")}
              defaultValue={singleData.contact}
            ></input>
          </td>
          <td className={"left"} key={index++}>
            <input
              type="number"
              className="add-border"
              onChange={this.onEdit.bind(this, singleData, "emergencyContact")}
              defaultValue={singleData.emergencyContact}
            ></input>
          </td>
          <td className={"left"} key={index++}>
            <input
              type="text"
              className="add-border"
              onChange={this.onEdit.bind(this, singleData, "parentName")}
              defaultValue={singleData.parentName}
            ></input>
          </td>
          <td className={"left"} key={index++}>
            <input
              type="text"
              className="add-border"
              onChange={this.onEdit.bind(this, singleData, "admissionDate")}
              defaultValue={singleData.admissionDate}
            ></input>
          </td>
          <td className={"left"} key={index++}>
            <input
              type="text"
              className="add-border"
              onChange={this.onEdit.bind(this, singleData, "bloodGroup")}
              defaultValue={singleData.bloodGroup}
            ></input>
          </td>
        </tr>
      );
    });
  }

  onYearFocus() {
    this.setState({
      isYearFocussed: "is-focused",
      onFocus: false,
      onYearFocus: true,
      yearBackground: "is-shown",
      background: "is-hidden",
      onDepartmentFocus: false,
      backgroundDepartment: "is-hidden",
    });
  }

  /**
   * Gets the previous 5 years
   */
  getYear() {
    const year = new Date().getFullYear();
    const years = Array.from(new Array(5), (val, index) => -(index - year));
    return years.map((year, index) => {
      return (
        <li
          id={year}
          key={index++}
          className="mdl-menu__item animation"
          onClick={this.onYearSelect.bind(this)}
        >
          {year}
        </li>
      );
    });
  }

  /**
   * Sets the year selected
   */
  onYearSelect(event) {
    this.setState({
      selectedYear: event.target.id,
      onYearFocus: false,
      yearBackground: "is-hidden",
    });
    if (this.state.searchAllowed) {
      this.setState({ searchAllowed: false });
    }
  }

  /**
   * Allows the grid to display the values
   */
  getResult() {
    if (this.state.managementPortal === false && this.state.hodPortal === false) {
      if (this.state.selectedYear === "") {
        this.fetchStudentDetails();
        this.setState({ searchAllowed: true });
      } else {
        this.fetchYearwiseStudentDetails();
        this.setState({ searchAllowed: true });
      }
    } else {
      if (
        this.state.selectedYear !== "" &&
        this.state.selectedDepartment !== ""
      ) {
        this.fetchStudentDetails();
        this.setState({ searchAllowed: true });
      } else {
        alert("Please select year and department");
      }
    }
  }

  /**
   * Fetches all department
   */
  fetchDepartmentDetails() {
    debugger
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/departmentdetail`)
      .then((response) => {
        this.setState({ departmentDetails: response.data });
      });
  }

  /**
   * Displays the list of department based on the API response
   */
  displayDepartment(i) {
    if (
      this.state &&
      this.state.departmentDetails &&
      this.state.departmentDetails.data &&
      this.state.departmentDetails.data.length
    ) {
      return this.state.departmentDetails.data.map(
        (singleDepartment, index) => {
          return (
            <li className="mdl-menu__item animation" key={index}>
              <button
                id={singleDepartment.name}
                name={singleDepartment.name}
                onClick={this.handleDepartmentChange.bind(this, i)}
              >
                {singleDepartment.name}
              </button>
            </li>
          );
        }
      );
    }
  }

  /**
   * Triggers when department dropdown is focused
   */
  onDeptDropDownFocus() {
    this.setState({
      isDepartmentFocussed: "is-focused",
      onDepartmentFocus: true,
      backgroundDepartment: "is-shown",
      onYearFocus: false,
      yearBackground: "is-hidden",
    });
  }

  /**
   * Resets the department focus based on the value selected
   */
  handleDepartmentFocus() {
    if (this.state.hasDepartmentValue === true) {
      this.setState({
        isDepartmentFocussed: "is-focused",
        onDepartmentFocus: false,
        backgroundDepartment: "is-hidden",
      });
    } else {
      this.setState({
        onDepartmentFocus: false,
        backgroundDepartment: "is-hidden",
      });
    }
  }

  /**
   * Triggers when the department is changed and stores the values in state
   * @param event form values
   */
  handleDepartmentChange(i, event) {
    this.setState({
      selectedDepartment: event.target.id,
      onDepartmentFocus: false,
      backgroundDepartment: "is-hidden",
      background: "is-hidden",
      hasDepartmentValue: true,
    });
    if (this.state.searchAllowed) {
      this.setState({ searchAllowed: false });
    }
  }

  render() {
    return (
      <div className="container-fluid background bk">
        <div className="d-sm-flex  mb-4">
          <nav className="navbar navbar-expand-lg  navprac bar assres">
            <div className="title1">Student</div>
            <div className="collapse navbar-collapse">
              <form className="form-inline my-2 my-lg-0">
                <input
                  style={{
                    width: "700px",
                    marginLeft: "200px",
                    backgroundColor: "rgba(54, 42, 65, 0.04)",
                    height: "45px",
                    fontStyle: "italic",
                    marginLeft: "30%",
                  }}
                  className="form-control mr-sm-2 "
                  type="search"
                  placeholder="Search....."
                  aria-label="Search"
                ></input>
                {/* <i class="fal fa-search"></i> */}
              </form>
            </div>
          </nav>
        </div>
        <div className="d-sm-flex  mb-4 ">
          <h4 className=" head resp" style={{ marginLeft: '0.5%', marginTop: '40px', width: '150px' }}>Student Details</h4>

          {/* <h1 className="h3 mb-0 text-gray-800 m-t-20">Notes</h1> */}
        </div>

        <div className="row" style={{ color: "#714F90" }}>
          {/* <h1 className="h3 mb-0 m-t-20" style={{marginTop:'40%'}}>All Students</h1> */}
          {/* <div className="row"> */}
          <div className="col-lg-3 p-t-20">
            <div
              className={
                // " mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width " +
                this.state.isYearFocussed
              }
              style={{ marginLeft: "1rem", marginTop: "1px" }}
            >
              <label
                style={{ marginLeft: '' }}
                className="input-text labell resp"
              >
                Year
              </label>
              <input
                onKeyPress={(e) => e.preventDefault()}
                autoComplete="off"
                onFocus={this.onYearFocus.bind(this)}
                className="new-xakal-input background resp"
                type="text"
                id="sample2"
                value={this.state.selectedYear}
                style={{ marginLeft: '%', marginTop: '3%', height: '40px', width: '368px' }}
              />
              {this.state.onYearFocus ? (
                <div
                  className="mdl-menu__container is-upgraded dropdown-list is-visible year-drop "
                  style={{ width: "368px", marginLeft: "-95px" }}
                >
                  <div
                    className="mdl-menu__outline year-drop"
                    style={{
                      width: "368px",
                      marginLeft: "55%",
                      marginTop: "1px",
                    }}
                  >
                    <ul
                      className="scrollable-menu  ul-list"
                      style={{ width: "368%" }}
                    >
                      {this.getYear()}
                    </ul>
                  </div>
                </div>
              ) : (
                <p></p>
              )}
            </div>
          </div>
          {/* </div> */}
          <div class="col-lg-3">
            {/* <div className="row"> */}

            {this.state.routerLink ===
            "/management-portal/view-student-details" ? (
              <div
                className="col-lg-3 p-t-20"
                style={{ marginTop: "-20px", marginLeft: "60px" }}
              >
                <div
                  className={
                    // "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width " +
                    this.state.isDepartmentFocussed
                  }
                >
                  <label
                    className="input-text labell deptres"
                    style={{ marginLeft: '', marginTop: '25px' }}
                  >
                    Department
                  </label>
                  <input
                    name="selectedDepartment"
                    onKeyPress={(e) => e.preventDefault()}
                    autoComplete="off"
                    onFocus={this.onDeptDropDownFocus.bind(this)}
                    className="new-xakal-input background deptres"
                    searc
                    type="text"
                    id={`department`}
                    value={this.state.selectedDepartment}
                    style={{ marginTop: '10%', height: '40px', width: '368px', marginLeft: '' }}
                  />

                  {this.state.onDepartmentFocus ? (
                    <div
                      className="mdl-menu__container is-upgraded dropdown-list is-visible dept-drop"
                      style={{ width: "368px" }}
                    >
                      <div
                        className="mdl-menu__outline mdl-menu--bottom-left dept-drop"
                        style={{
                          width: "368px",
                          marginTop: "18px",
                          marginLeft: "132px",
                        }}
                      >
                        <ul
                          className="scrollable-menu  ul-list"
                          style={{ width: "368px" }}
                        >
                          {this.displayDepartment()}
                        </ul>
                      </div>
                    </div>
                  ) : (
                    <p></p>
                  )}
                </div>
              </div>
             ) : (
              <></>
            )} 
       {this.state.routerLink ===
            "/hod-portal/view-student-details" ? (
              <div
                className="col-lg-3 p-t-20"
                style={{ marginTop: "-20px", marginLeft: "60px" }}
              >
                <div
                  className={
                    // "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width " +
                    this.state.isDepartmentFocussed
                  }
                >
                  <label
                    className="input-text labell deptres"
                    style={{ marginLeft: "120px", marginTop: "25px" }}
                  >
                    Department
                  </label>
                  <input
                    name="selectedDepartment"
                    onKeyPress={(e) => e.preventDefault()}
                    autoComplete="off"
                    onFocus={this.onDeptDropDownFocus.bind(this)}
                    className="new-xakal-input background deptres"
                    searc
                    type="text"
                    id={`department`}
                    value={this.state.selectedDepartment}
                    style={{
                      marginTop: "10%",
                      height: "40px",
                      width: "368px",
                      marginLeft: "120px",
                    }}
                  />

                  {this.state.onDepartmentFocus ? (
                    <div
                      className="mdl-menu__container is-upgraded dropdown-list is-visible dept-drop"
                      style={{ width: "368px" }}
                    >
                      <div
                        className="mdl-menu__outline mdl-menu--bottom-left dept-drop"
                        style={{
                          width: "368px",
                          marginTop: "18px",
                          marginLeft: "132px",
                        }}
                      >
                        <ul
                          className="scrollable-menu  ul-list"
                          style={{ width: "368px" }}
                        >
                          {this.displayDepartment()}
                        </ul>
                      </div>
                    </div>
                  ) : (
                    <p></p>
                  )}
                </div>
              </div>
             ) : (
              <></>
            )} 
            {/* </div> */}
          </div>
          <div class="col-lg-3 col-sm-12">
            <div className="col-sm-4 p-t-20">
              <button
                type="button"
                onClick={this.getResult.bind(this)}
                // className="btn btn-primary m-t-15 m-l-30"
                className="getresult  heading-weight button-size resp resp-th"
                style={{
                  marginLeft: "300px",
                  height: "40px",
                  marginTop: "30px",
                }}
              >
                Get Results!
              </button>
            </div>
          </div>
          {/* </div> */}
        </div>

        {
          this.state.searchAllowed === true ? (
            <div>
                <div className=""  style={{marginTop:"4%"}}>
                <table
                  className="table table-responsive table-hover table-checkable order-column"
                  
                >
                  <thead>
                    <tr>
                      <th className="vertical-border-head heading-weight heading-size"> Roll No </th>
                      <th className="vertical-border-head heading-weight heading-size"> Name </th>
                      <th className="vertical-border-head heading-weight heading-size"> Degree </th>
                      <th className="vertical-border-head heading-weight heading-size"> Department </th>
                      <th className="vertical-border-head heading-weight heading-size"> Email </th>
                      <th className="vertical-border-head heading-weight heading-size"> Mobile </th>
                      <th className="vertical-border-head heading-weight heading-size"> Emergency Contact </th>
                      <th className="vertical-border-head heading-weight heading-size"> Parents/Guardian </th>
                      <th className="vertical-border-head heading-weight heading-size"> Admission Date </th>
                      <th className="vertical-border-head heading-weight heading-size"> BG </th>
                      {this.state.isDelete ? (<th className="vertical-border-head heading-weight heading-size"> Action </th>):(<></>)}
                    </tr>
                  </thead>
                  {this.state.isEdit ? (
                    
                    <tbody>{this.editStudentDetails()}</tbody>
                  ) : this.state.isDelete ? (
                    <tbody>{this.deleteStudentDetails()}</tbody>
                  ) : (
                    <tbody>
                      {this.state.studentDetails &&
                        this.state.studentDetails.length ? (
                        this.displayStudentDetails()
                      ) : (
                        <></>
                      )}
                    </tbody>
                  )}
                </table>
              </div>
              {this.state.isEdit ? (
                <Pagination postPerPage={this.state.postsPerPage} totalPost={this.state.studentDetails.length} paginate={this.paginate} />
              ) : this.state.isDelete ? (
                <Pagination postPerPage={this.state.postsPerPage} totalPost={this.state.studentDetails.length} paginate={this.paginate} />
              ) : (
                <>
                  {this.state.studentDetails &&
                    this.state.studentDetails.length ? (
                      this.displayStudentDetails()
                    ) : (
                      <></>
                    )}
                  
            
                </>
              )}
            {/* {this.state.isEdit ? (
              <Pagination
                postPerPage={this.state.postsPerPage}
                totalPost={this.state.studentDetails.length}
                paginate={this.paginate}
              />
            ) : this.state.isDelete ? (
              <Pagination
                postPerPage={this.state.postsPerPage}
                totalPost={this.state.studentDetails.length}
                paginate={this.paginate}
              />
            ) : (
              <>
                {this.state.studentDetails &&
                this.state.studentDetails.length ? (
                  <Pagination
                    postPerPage={this.state.postsPerPage}
                    totalPost={this.state.studentDetails.length}
                    paginate={this.paginate}
                  />
                ) : (
                  <></>
                )}
              </>
            )} */}
            {this.state.routerLink ===
              "/management-portal/view-student-details" ||
            this.state.routerLink === "/hod-portal/view-student-details" ? (
              <div hidden={this.state.isEdit} className="p-t-20">
                <button
                  type="button"
                  onClick={this.deleteRedirect.bind(this)}
                  hidden={this.state.isDelete}
                  className="btn m-t-15 m-l-30"
                  style={{ backgroundColor: "#362A41", color: "#fff" }}
                >
                  Delete Details
                </button>
                <button
                  type="button"
                  onClick={this.redirect.bind(this)}
                  hidden={this.state.isEdit}
                  className="btn  m-t-15 m-l-30"
                  style={{ backgroundColor: "#362A41", color: "#fff" }}
                >
                  Edit Details
                </button>
                {this.state.isDelete ? (
                  <button
                    type="button"
                    onClick={this.updateDetails.bind(this)}
                    className="btn  m-t-15 m-l-30"
                    style={{ backgroundColor: "#362A41", color: "#fff" }}
                  >
                    Save
                  </button>
                ) : (
                  <p></p>
                )}
                {this.state.isDelete ? (
                  <button
                    type="button"
                    onClick={this.discardChanges.bind(this)}
                    className="btn  m-t-15 m-l-30"
                    style={{ backgroundColor: "#362A41", color: "#fff" }}
                  >
                    Cancel
                  </button>
                ) : (
                  <p></p>
                )}
              </div>
            ) : (
              <p></p>
            )}

            {this.state.routerLink ===
              "/management-portal/view-student-details" ||
            this.state.routerLink === "/hod-portal/view-student-details" ? (
              <div hidden={this.state.isDelete} className="p-t-20">
                {/* <button
                    type="button"
                    onClick={this.redirect.bind(this)}
                    hidden={this.state.isEdit}
                    className="btn  m-t-15 m-l-30"
                    style={{ backgroundColor: "#362A41", color: "#fff" }}
                  >
                    Edit Details
                  </button> */}
                {this.state.isEdit ? (
                  <button
                    type="button"
                    onClick={this.updateDetails.bind(this)}
                    className="btn m-t-15 m-l-30"
                    style={{ backgroundColor: "#362A41", color: "#fff" }}
                  >
                    Save
                  </button>
                ) : (
                  <p></p>
                )}
                {this.state.isEdit ? (
                  <button
                    type="button"
                    onClick={this.discardChanges.bind(this)}
                    className="btn  m-t-15 m-l-30"
                    style={{ backgroundColor: "#362A41", color: "#fff" }}
                  >
                    Cancel
                  </button>
                ) : (
                  <p></p>
                )}
              </div>
            ) : (
              <p></p>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

export default StudentDetailsMaintain;
