import React, { Component } from "react";
import NavBar from "./navbar";

class StudentsPortal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userID: (name) =>
        `; ${document.cookie}`.split(`; ${name}=`).pop().split(";").shift(),
    };
  }
  render() {
    return (
      <NavBar
        userID={this.props.location.state || this.state.userID("userid")}
        state={this.props}
      />
    );
  }
}

export default StudentsPortal;
