import React, { Component } from "react";
import axios from "axios";
// import moment from "moment";
import "../../../../styles/table.css";
import "../../../../minified-css/material-min.css";
import "../../../../styles/dropdowns.css";
import "../../../../styles/theme-style.css";

class NewBusTemplate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ...this.props.state
        }
    }

    componentDidMount() {

    }

    handleAddStudents() {
        this.props.handleAddStudents(true);
    }

    handleAddBusRoutes() {
        this.props.handleAddBusRoutes(true, [], [1]);
    }

    submit() {
        const stopName = document.getElementById('station-input').value;
        if(!stopName) {
            return alert('Please Enter A Stop Name!')
        }
        const adminId = '5dd6ed68c0052a052cbdb09c';
        axios
            .post(`/xakal/transport/admin/createBusStops/${adminId}`, {
                busStops: [
                    {
                        name: stopName
                    }
                ]
            })
            .then((response) => {
                // if (response.status !== 200) {
                //     return alert(response.data.msg)
                // }
                console.log('add bus stops', response.data.data)
                alert(response.data.msg);
                this.props.getAllBusStops();
            })
            .catch(e => alert(e))
    }

    render() {
        return (
            <div class="item col-md-3 add-bus-container" style={{ margin: 10, backgroundColor: '#e6fff9' }}>
                <div style={{ color: 'black', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', flexDirection: 'column', height: 200 }}>
                    <div className="m-t-15 m-l-30" >
                        <input id="station-input" placeholder="Enter station name" style={{ height: 50, backgroundColor: 'white', textAlign: 'center' }} />
                    </div>
                    <div>
                        <button
                            type="button"
                            className="btn btn-primary m-t-15 m-l-30"
                            onClick={this.submit.bind(this)}
                        >
                            Add
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default NewBusTemplate;