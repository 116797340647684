import React, { Component } from "react";
import "../../../styles/classroom.css";

class StaffSelector2 extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <div style={{ display: "flex" }}>
          <div
            onClick={() => this.props.addStaffSelector(this.props.i)}
            className="btn-add"
          >
            <span style={{ paddingBottom: "3px", color: "#fff" }}>+</span>
          </div>
          <div>
            <select
              id={`select-staff-${this.props.sub._id}-${
                this.props.sub.staff ? this.props.sub.staff.length + 1 : 1
              }`}
              onChangeCapture={(e) =>
                this.props.allocateStaffs(e, this.props.sub._id)
              }
            >
              <option selected disabled>
                select staff
              </option>
              {this.props.state.availableStaffs.length
                ? this.props.state.availableStaffs
                    .filter(
                      (item) => item.deptID == this.props.sub.departmentID
                    )
                    .map((item) => item.staffs)
                    .map((staffs) =>
                      staffs.map((staff) => (
                        <option value={staff._id}>{staff.name}</option>
                      ))
                    )
                : null}
            </select>
          </div>
          <div
            onClick={() =>
              this.props.removeStaffSelector(
                this.props.i,
                this.props.index,
                '',
                this.props.sub._id,
                true
              )
            }
            className="btn-close"
          >
            <span style={{ color: "#fff" }}>x</span>
          </div>
        </div>
      </div>
    );
  }
}

export default StaffSelector2;
