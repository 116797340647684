import React from "react";
import Logo from "../images/Mask group.png";
import { Link } from "react-router-dom";

function Setting() {
  return (
    <div>
      <div className="container-login100 waveBg">
        <img className="logo-in" src={Logo} alt="img" />
        <div className="wrap-login100 p-b-20">
          <h1 style={{ marginBottom: "50px" }}>SETTINGS</h1>
          <div className="container-login100-form-btn m-b-20">
            <Link to="./changepassword">
              <button className="login100-form-btn">Change Password</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Setting;
