import React, { Component } from "react";
import "../../../styles/table.css";
import "../../../minified-css/material-min.css";
import "../../../styles/dropdowns.css";
import "../../../styles/theme-style.css";
import axios from "axios";
import Pagination from "../../../utils/pagination";

class ViewSemesterDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      column1: "",
      column2: "",
      column3: "",
      column4: "",
      selectedSemester: "",
      searchAllowed: false,
      notesList: [],
      background: "",
      student: "",
      isFocussed: "",
      onFocus: false,
      userID: "",
      isStudentFocussed: "",
      backgroundStudent: "",
      currentPage: 1,
      postsPerPage: 5,
    };
    this.baseState = this.state;
    this.paginate = this.paginate.bind(this);
  }

  // componentDidMount() {
  //   if (this.props && this.props.location && this.props.location.userID) {
  //     const userID = this.props.location.userID;
  //     this.setState({ userID: userID.userID });
  //   }
  //   this.unlisten = this.props.history.listen((location, action) => {
  //     this.setState(this.baseState);
  //   });
  // }

  // componentWillUnmount() {
  //   this.unlisten();
  // }

  /**
   * Adds the hover class when ID is hovered
   */
  studentIDHover(event) {
    var element = event.target.className;
    if (element === "column100 column2 ") {
      this.setState({ column1: "hov-column-head-ver5" });
    }
  }

  /**
   * Adds the hover class when course is hovered
   */
  courseHover(event) {
    var element = event.target.className;
    if (element === "column100 column3 ") {
      this.setState({ column2: "hov-column-head-ver5" });
    }
  }

  /**
   * Adds the hover class when grade is hovered
   */
  gradeHover(event) {
    var element = event.target.className;
    if (element === "column100 column4 ") {
      this.setState({ column3: "hov-column-head-ver5" });
    }
  }

  /**
   * Adds the hover class when result is hovered
   */
  resultHover(event) {
    var element = event.target.className;
    if (element === "column100 column5 ") {
      this.setState({ column4: "hov-column-head-ver5" });
    }
  }

  /**
   * Resets the state variables when hover is removed
   */
  hoverOff() {
    this.setState({
      column1: "",
      column2: "",
      column3: "",
      column4: "",
    });
  }

  /**
   * Sets the semester selected
   */
  onDropDownSelect(event) {
    this.setState({
      selectedSemester: event.target.id,
      onFocus: false,
      background: "is-hidden",
      backgroundStudent: "is-hidden",
    });
    if (this.state.searchAllowed) {
      this.setState({ searchAllowed: false });
    }
  }

  onDropDownFocus() {
    this.setState({
      isFocussed: "is-focused",
      onFocus: true,
      background: "is-shown",
      backgroundStudent: "is-hidden",
    });
  }

  /**
   * Allows the grid to display the values
   */
  getResult() {
    if (this.state.selectedSemester !== "") {
      this.fetchSemesterDetails();
    } else {
      alert("Please select the values");
      this.setState({ searchAllowed: false });
    }
  }

  /**
   * Fetches the semester marks based on semester selected
   */
  fetchSemesterDetails() {
    this.setState({ searchAllowed: true });
    var semester = this.state.selectedSemester;
    const studentID = this.state.student
      ? this.state.student.toUpperCase()
      : "";
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/assessment/semesterdetail/${semester}?userID=${studentID}`)
      .then((response) => {
        this.setState({ notesList: response.data });
      });
  }

  onStudentChanged(event) {
    this.setState({
      student: event.target.value,
      backgroundCourse: "is-hidden",
      background: "is-hidden",
    });
    if (this.state.searchAllowed) {
      this.setState({ searchAllowed: false });
    } else if (this.state.uploadAllowed) {
      this.setState({ uploadAllowed: false });
    }
  }

  onStudentFocus() {
    this.setState({
      isFocussed: "is-focused",
      isStudentFocussed: "is-focused",
      onFocus: false,
      background: "is-hidden",
      backgroundStudent: "is-shown",
    });
  }

  paginate(num) { 
    this.setState({currentPage: num})
  }

  /**
   * Displays the list of notes based on the API response
   */
  displayTable() {
    const indexOfLastPost = this.state.currentPage * this.state.postsPerPage;
    const indexOfFirstPost = indexOfLastPost - this.state.postsPerPage;
    const currentPost = this.state.notesList.slice(indexOfFirstPost, indexOfLastPost) 

    return currentPost.map((singleData, index) => {
      return (
        <tr className="row100">
          <td className="column100 column1 vertical-border" data-column="column1">
            {++index}
          </td>
          <td
            className={"column100 column2 vertical-border"}
            onMouseEnter={this.studentIDHover.bind(this)}
            onMouseLeave={this.hoverOff.bind(this)}
          >
            {singleData.userID}
          </td>
          <td
            className={"column100 column3 vertical-border"}
            onMouseEnter={this.courseHover.bind(this)}
            onMouseLeave={this.hoverOff.bind(this)}
          >
            {singleData.course}
          </td>
          <td
            className={"column100 column4 vertical-border"}
            onMouseEnter={this.gradeHover.bind(this)}
            onMouseLeave={this.hoverOff.bind(this)}
          >
            {singleData.grade}
          </td>
          <td
            className={"column100 column5 vertical-border"}
            onMouseEnter={this.resultHover.bind(this)}
            onMouseLeave={this.hoverOff.bind(this)}
          >
            {singleData.result}
          </td>
        </tr>
      );
    });
  }

  render() {
    return (
      <div className="container-fluid  ">
        <div className="d-sm-flex mb-4">
          
          <div className="row">
          <div className="head resp1" style={{ marginLeft: '-48px', marginTop: '160px'}}>Semester</div>

            {/* <h1 className="h3 mb-0 text-gray-800 m-t-20">Notes</h1> */}
          </div>
          {/* <h1 className="h3 mb-0 text-gray-800 m-t-20">Semester Details</h1> */}
        </div>
        <div className="row m-r-20">
                  <div
                    className={
                      "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width " +
                      this.state.isFocussed
                    }
                  >
                    <label
                      className="input-text labell resp1"
                      style={{ marginLeft: '-51px', marginTop: '-11px'}}
                    >
                      Semester Number
                    </label>
                    <input
                      onKeyPress={(e) => e.preventDefault()}
                      onFocus={this.onDropDownFocus.bind(this)}
                      autoComplete="off"
                      className="new-xakal-input  background height resp1"
                      type="text"
                      id="sample2"
                      value={this.state.selectedSemester}
                      style={{ marginLeft: '-51px', width: '300px', marginTop: '1px' }}

                    />

                    {this.state.onFocus ? (
                      <div className="mdl-menu__container is-upgraded dropdown-list is-visible dro-down" style={{ width: '300px', marginLeft: '35px' }} >
                        <div className="mdl-menu__outline dro-down" style={{ width: '300px', marginLeft: '-96px', marginTop: '0px' }} >
                          <ul className="scrollable-menu  ul-list" style={{ width: '300%' }} >                            
                          <li
                            className="mdl-menu__item animation"
                            id="Semester 1"
                            onClick={this.onDropDownSelect.bind(this)}
                          >
                            Semester 1
                          </li>
                            <li
                              className="mdl-menu__item animation1"
                              id="Semester 2"
                              onClick={this.onDropDownSelect.bind(this)}
                            >
                              Semester 2
                            </li>
                            <li
                              className="mdl-menu__item animation2"
                              id="Semester 3"
                              onClick={this.onDropDownSelect.bind(this)}
                            >
                              Semester 3
                            </li>
                            <li
                              className="mdl-menu__item animation"
                              id="Semester 4"
                              onClick={this.onDropDownSelect.bind(this)}
                            >
                              Semester 4
                            </li>
                            <li
                              className="mdl-menu__item animation1"
                              id="Semester 5"
                              onClick={this.onDropDownSelect.bind(this)}
                            >
                              Semester 5
                            </li>
                            <li
                              className="mdl-menu__item animation2"
                              id="Semester 6"
                              onClick={this.onDropDownSelect.bind(this)}
                            >
                              Semester 6
                            </li>
                            <li
                              className="mdl-menu__item animation"
                              id="Semester 7"
                              onClick={this.onDropDownSelect.bind(this)}
                            >
                              Semester 7
                            </li>
                            <li
                              className="mdl-menu__item animation1"
                              id="Semester 8"
                              onClick={this.onDropDownSelect.bind(this)}
                            >
                              Semester 8
                            </li>
                          </ul>
                        </div>
                      </div>
                    ) : (
                      <p></p>
                    )}
                  </div>
                <div>
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label " +
                        this.state.isStudentFocussed
                      }
                    >
                      <label
                        className="input-text labell resp1"
                        style={{ marginLeft: '10%', marginTop: '3px' }}

                      >
                        Student ID
                      </label>
                      <input
                        onFocus={this.onStudentFocus.bind(this)}
                        autoComplete="off"
                        value={this.state.student}
                        className="new-xakal-input  background height idres"
                        type="text"
                        id="student"
                        onChange={this.onStudentChanged.bind(this)}
                        style={{ marginLeft: '9%',  width: '300px' }}

                      />

                    </div>
                  </div>
                <div className="col-sm-3 p-t-20 m-t-25">
                  <button
                    type="button"
                    onClick={this.getResult.bind(this)}
                    // className="btn btn-primary m-t-15 m-l-30"
                    className="getresult m-t-25 resp1"
                    style={{ marginLeft: '15%', marginTop: '5px' }}
                  >
                    Get Results!
                  </button>
                </div>
        </div>
        {this.state.searchAllowed ? (
          <div className="limiter"
          >
            <div className="container-table100 " 
            >
              <div className="wrap-table100">
                <div className="table100 ver5 m-b-110 table align" style={{marginLeft:"-100px"}}>
                  <table>
                    <thead>
                      <tr className="row100 head">
                        <th
                          style={{  color: '#B8B3BC' }}
                          className="column100 column1 "
                          data-column="column1"
                        >SL.No</th>
                        <th
                          style={{ color: '#B8B3BC' }}
                          className={"column100 column1  background " + this.state.column1}
                          onMouseEnter={this.studentIDHover.bind(this)}
                          onMouseLeave={this.hoverOff.bind(this)}
                        >
                          Student ID
                        </th>
                        <th
                          style={{ color: '#B8B3BC' }}
                          className={"column100 column1  background " + this.state.column2}
                          onMouseEnter={this.courseHover.bind(this)}
                          onMouseLeave={this.hoverOff.bind(this)}
                        >
                          Course
                        </th>
                        <th
                          style={{color: '#B8B3BC' }}
                          className={"column100 column1  background " + this.state.column3}
                          onMouseEnter={this.gradeHover.bind(this)}
                          onMouseLeave={this.hoverOff.bind(this)}
                        >
                          Grade
                        </th>
                        <th
                          style={{color: '#B8B3BC' }}
                          className={"column100 column1  background " + this.state.column4}
                          onMouseEnter={this.resultHover.bind(this)}
                          onMouseLeave={this.hoverOff.bind(this)}
                        >
                          Result
                        </th>
                      </tr>
                    </thead>
                    <tbody>{this.displayTable()}</tbody>
                  </table>
                </div>
                <Pagination postPerPage={this.state.postsPerPage} totalPost={this.state.notesList.length} paginate={this.paginate}/>
              </div>
            </div>
          </div>
        ) : (
          <span></span>
        )}
      </div>
    );
  }
}

export default ViewSemesterDetails;
