import React, { Component } from "react";
import "../../../styles/table.css";
import "../../../minified-css/material-min.css";
import "../../../styles/dropdowns.css";
import "../../../styles/theme-style.css";
import "../../../styles/classnote.css";

import axios from "axios";
import * as moment from "moment";
class AddSalaryDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchAllowed: false,
      salaryDetails: [],
      isFocussed: "",
      onFocus: false,
      onYearFocus: false,
      isYearFocussed: "",
      selectedMonth: "",
      selectedYear: "",
      background: "",
      yearBackground: "",
      selectedDepartment: "",
      userID: "",
      staffDetails: [],
      values: [
        {
          selectedYear: "",
          selectedMonth: "",
          uploadedFile: "",
          selectedStaff: "",
          selectedStaffName: "",
        },
      ],
    };
    this.onFileUpload = this.onFileUpload.bind(this);
    this.fileUpload = this.fileUpload.bind(this);
    this.baseState = this.state;
  }

  componentDidMount() {
    this.fetchDepartmentDetails();
    if (this.props && this.props.location && this.props.location.userID) {
      const userID = this.props.location.userID;
      this.setState({ userID: userID.userID });
    }
    this.unlisten = this.props.history.listen((location, action) => {
      this.setState(this.baseState);
      this.fetchDepartmentDetails();
    });
    this.fetchStaffDetailsByDept()
  }

  /**
   * Fetches all staff
   */
  fetchStaffDetailsByDept(departmentName) {
    this.setState({ staffDetails: [] });
    axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/staffdetail/department/${departmentName}`
      )
      .then((response) => {
        this.setState({ staffDetails: response.data });
      });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  /**
   * Sets the month selected
   */
  onMonthSelect(event) {
    this.setState({
      selectedMonth: event.target.id,
      onFocus: false,
      background: "is-hidden",
    });
    if (this.state.searchAllowed) {
      this.setState({ searchAllowed: false });
    }
  }

  /**
   * Triggers when month is focused
   */
  onMonthFocus(i) {
    this.setState({
      isFocussed: "is-focused",
      selectedMonthIndex: i,
      onFocus: true,
      onYearFocus: false,
      background: "is-shown",
    });
  }

  /**
   * Triggers when year is focused
   */
  onYearFocus(i) {
    this.setState({
      isYearFocussed: "is-focused",
      selectedIndex: i,
      onFocus: false,
      onYearFocus: true,
      yearBackground: "is-shown",
    });
  }

  /**
   * Triggers when staff is focused
   */
  onStaffFocus(i) {
    this.setState({
      isStaffFocussed: "is-focused",
      selectedStaffIndex: i,
      onFocus: false,
      onStaffFocus: true,
      backgroundStaff: "is-shown",
    });
  }

  /**
   * Fetches all the month name
   */
  getMonths(i) {
    return moment.months().map((name, index) => {
      return (
        <li
          id={name}
          key={index++}
          className="mdl-menu__item animation"
          onClick={this.handleMonthChange.bind(this, i)}
        >
          {name}
        </li>
      );
    });
  }

  /**
   * Gets the previous 10 years
   */
  getYear(i) {
    const year = new Date().getFullYear();
    const years = Array.from(new Array(10), (val, index) => -(index - year));
    return years.map((year, index) => {
      return (
        <li
          id={year}
          name="selectedYear"
          key={index++}
          className="mdl-menu__item animation"
          onClick={this.handleYearChange.bind(this, i)}
        >
          {year}
        </li>
      );
    });
  }

  /**
   * Adds the empty form element
   */
  addClick() {
    this.setState((prevState) => ({
      values: [
        ...prevState.values,
        {
          selectedYear: "",
          selectedMonth: "",
          uploadedFile: "",
          selectedStaff: "",
          selectedStaffName: "",
        },
      ],
    }));
  }

  /**
   * Removes the selected row
   * @param i selected row index
   */
  removeClick(i) {
    let values = [...this.state.values];
    values.splice(i, 1);
    this.setState({ values });
  }

  /**
   * Saves the records
   */
  onFileUpload(e) {
    e.preventDefault(); // Stop form submit
    if (this.state.values && this.state.values.length > 0) {
      this.state.values.forEach((element) => {
        this.fileUpload(element.uploadedFile, element);
      });
      this.resetForm();
    }
  }

  /**
   * Triggers when file is selected
   */
  onChange(e) {
    this.setState({
      file: e.target.files[0],
      background: "is-hidden",
      backgroundCourse: "is-hidden",
      backgroundDesc: "is-hidden",
    });
  }

  /**
   * Triggers when the form is changed and stores the values in state
   * @param event form values
   */
  handleFormChange(i, event) {
    if (event && event.target) {
      let values = [...this.state.values];
      const { name, files } = event.target;
      values[i][name] = files[0];
      this.setState({ values });
      this.onChange(event);
    }
  }

  /**
   * Triggers when the year is changed and stores the values in state
   * @param event form values
   */
  handleYearChange(i, event) {
    this.setState({ onYearFocus: false, yearBackground: "is-hidden" });
    if (event && event.target) {
      let values = [...this.state.values];
      const { id } = event.target;
      values[i]["selectedYear"] = id;
      this.setState({ values });
    }
  }

  /**
   * Triggers when the month is changed and stores the values in state
   * @param event form values
   */
  handleMonthChange(i, event) {
    this.setState({ onFocus: false, background: "is-hidden" });
    if (event && event.target) {
      let values = [...this.state.values];
      const { id } = event.target;
      values[i]["selectedMonth"] = id;
      this.setState({ values });
    }
  }

  /**
   * Triggers when the staff is changed and stores the values in state
   * @param event form values
   */
  handleStaffChange(i, event) {
    this.setState({ onStaffFocus: false, backgroundStaff: "is-hidden" });
    if (event && event.target) {
      let values = [...this.state.values];
      const { id, name } = event.target;
      values[i]["selectedStaff"] = id;
      values[i]["selectedStaffName"] = name;
      this.setState({ ...values });
    }
  }

  /**
   * Uploads the file to online URL
   */
  fileUpload(files, element) {
    let randomString = Math.random().toString(36);

    this.setState({
      theInputKey: randomString,
    });
    let firstTime = true;
    let _formData = new FormData();
    _formData.append("uploadedFile", files);

    axios
      .post(`${process.env.PUBLIC_URL}/xakal/files/`, _formData, {})
      .then((_res) => {
        if (_res.data.ok) {
          const params = {
            salaryStatus: "Salary credited",
            creditedDate: new Date(),
            userID: element.selectedStaff,
            salaryReceipt: files,
            salaryMonth: element.selectedMonth,
            salaryYear: element.selectedYear,
            url: _res.data.url ? _res.data.url : "",
          };
          axios
            .post(`${process.env.PUBLIC_URL}/xakal/salary/`, params)
            .then(() => {
              if (firstTime) {
                firstTime = false;
                alert("Updated successfully!");
                this.setState(this.baseState);
              }
            });
        }
      });
  }

  /**
   * Resets to base state
   */
  resetForm() {
    this.setState({
      values: [
        {
          selectedYear: "",
          selectedMonth: "",
          uploadedFile: "",
          selectedStaff: "",
          selectedStaffName: "",
        },
      ],
    });
  }

  /**
   * Displays the list of HOD based on the API response
   */

  displayStaff(i) {
    if (
      this.state &&
      this.state.staffDetails &&
      this.state.staffDetails.length
    ) {
      return this.state.staffDetails.map((singleStaff, index) => {
        return (
          <li className="mdl-menu__item animation" key={index}>
            <button
              id={singleStaff.userID}
              name={singleStaff.name}
              onClick={this.handleStaffChange.bind(this, i)}
            >
              {singleStaff.name}
            </button>
          </li>
        );
      });
    }
  }

  /**
   * Fetches all department
   */
  fetchDepartmentDetails() {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/departmentdetail`)
      .then((response) => {
        this.setState({ departmentDetails: response.data.data });
      });
  }

  /**
   * Displays the list of department based on the API response
   */
  displayDepartment() {
    if (
      this.state &&
      this.state.departmentDetails &&
      this.state.departmentDetails.length
    ) {
      return this.state.departmentDetails.map((singleDepartment, index) => {
        return (
          <li className="mdl-menu__item animation" key={index}>
            <button
              id={singleDepartment.name}
              name={singleDepartment.name}
              onClick={this.handleDepartmentChange.bind(this)}
            >
              {singleDepartment.name}
            </button>
          </li>
        );
      });
    }
  }

  /**
   * Triggers when department dropdown is focused
   */
  onDeptDropDownFocus() {
    this.setState({
      isDepartmentFocussed: "is-focused",
      onDepartmentFocus: true,
      backgroundDepartment: "is-shown",
    });
  }

  /**
   * Triggers when the department is changed and stores the values in state
   * @param event form values
   */
  handleDepartmentChange(event) {
    this.setState({
      selectedDepartment: event.target.id,
      onDepartmentFocus: false,
      backgroundDepartment: "is-hidden",
      background: "is-hidden",
      hasDepartmentValue: true,
    });
    this.fetchStaffDetailsByDept(event.target.id);
    this.resetForm();
  }

  render() {
    return (
      <div className="container-fluid background bk">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <nav
            className="navbar navbar-expand-lg  navprac bar"
            style={{ marginTop: "-70px" }}
          >
            <div className="title">Salary</div>
            <div className="collapse navbar-collapse">
              <form className="form-inline my-2 my-lg-0">
                <input
                  style={{
                    width: "500px",
                    marginLeft: "120px",
                    backgroundColor: "rgba(54, 42, 65, 0.04)",
                    fontStyle: "italic",
                    height: "45px",
                  }}
                  className="form-control mr-sm-2 "
                  type="search"
                  placeholder="Search....."
                  aria-label="Search"
                ></input>
              </form>
            </div>
          </nav>
          <div className="row">
            <h4
              className="head resp salary-max"
              style={{ marginLeft: "-760px", marginTop: "150px" }}
            >
              {" "}
              Add Salary Details
            </h4>

            {/* <h1 className="h3 mb-0 text-gray-800 m-t-20">Notes</h1> */}
          </div>
          {/* <h1 className="h3 mb-0 text-gray-800 m-t-20 add-salary">Add Salary Details</h1> */}
        </div>
        <div className="row m-r-20">
          <div className="col-sm-12">
            <div>
              <div className="card-body row">
                <div className="col-sm-8 p-t-20">
                  <div
                    className={
                      "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width " +
                      this.state.isDepartmentFocussed
                    }
                  >
                    <label
                      style={{ marginLeft: "28%" }}
                      className="input-text labell sal-input"
                    >
                      Department
                    </label>
                    <input
                      name="selectedDepartment"
                      onKeyPress={(e) => e.preventDefault()}
                      autoComplete="off"
                      onFocus={this.onDeptDropDownFocus.bind(this)}
                      className="new-xakal-input background height sal-input"
                      type="text"
                      id={`department`}
                      value={this.state.selectedDepartment}
                      onChange={this.handleDepartmentChange.bind(this)}
                      style={{ marginLeft: "28%", width: "300px" }}
                    />

                    {this.state.onDepartmentFocus ? (
                      <div
                        className="mdl-menu__container is-upgraded dropdown-list is-visible dep-drop"
                        style={{ width: "300px" }}
                      >
                        <div
                          className="mdl-menu__outline mdl-menu--bottom-left dropdown-div  dep-drop"
                          style={{
                            width: "300px",
                            marginLeft: "28%",
                            marginTop: "-8px",
                          }}
                        >
                          <ul
                            className="scrollable-menu  ul-list"
                            style={{ width: "300px" }}
                          >
                            {this.displayDepartment()}
                          </ul>
                        </div>
                      </div>
                    ) : (
                      <p></p>
                    )}
                  </div>
                </div>
              </div>
              {this.state.selectedDepartment !== "" ? (
                <div>
                  {this.state.values.map((el, i) => (
                    <div className="card-body row" key={i}>
                      <div className="col-lg-2 p-t-20">
                        <div
                          className={
                            "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width " +
                            this.state.isStaffFocussed
                          }
                        >
                          <label
                            className={
                              "input-text labell pass-name" +
                              this.state.backgroundStaff
                            }
                          >
                            Staff
                          </label>
                          <input
                            onKeyPress={(e) => e.preventDefault()}
                            onFocus={this.onStaffFocus.bind(this, i)}
                            autoComplete="off"
                            className="new-xakal-input background height pass-name"
                            type="text"
                            id="selectedStaff"
                            value={el.selectedStaffName || ""}
                            onChange={this.handleStaffChange.bind(this, i)}
                            name="selectedStaff"
                          />

                          {this.state.onStaffFocus &&
                          this.state.selectedStaffIndex === i ? (
                            <div
                              className="mdl-menu__container is-upgraded dropdown-list is-visible"
                              style={{ width: "180px" }}
                            >
                              <div
                                className="mdl-menu__outline mdl-menu--bottom-left dropdown-div"
                                style={{ width: "180px", marginTop: "-10px" }}
                              >
                                <ul
                                  className="scrollable-menu ul-list"
                                  style={{ width: "180px" }}
                                >
                                  {this.displayStaff(i)}
                                </ul>
                              </div>
                            </div>
                          ) : (
                            <p></p>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-2 p-t-20">
                        <div
                          className={
                            "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width " +
                            this.state.isFocussed
                          }
                        >
                          <label
                            style={{ marginLeft: "30px" }}
                            className={
                              "input-text labell  pass-name" +
                              this.state.backgroundStaff
                            }
                          >
                            Month
                          </label>
                          <input
                            onKeyPress={(e) => e.preventDefault()}
                            autoComplete="off"
                            onFocus={this.onMonthFocus.bind(this, i)}
                            className="new-xakal-input background height pass-name"
                            type="text"
                            id="sample2"
                            value={el.selectedMonth}
                            name="selectedMonth"
                            style={{ marginLeft: "30px" }}
                          />

                          {this.state.onFocus &&
                          this.state.selectedMonthIndex === i ? (
                            <div
                              className="mdl-menu__container is-upgraded dropdown-list is-visible"
                              style={{ width: "200px" }}
                            >
                              <div
                                className="mdl-menu__outline mdl-menu--bottom-left dropdown-div"
                                style={{
                                  width: "180px",
                                  marginLeft: "30px",
                                  marginTop: "-10px",
                                }}
                              >
                                <ul
                                  className="scrollable-menu  ul-list"
                                  style={{ width: "200px" }}
                                >
                                  {this.getMonths(i)}
                                </ul>
                              </div>
                            </div>
                          ) : (
                            <p></p>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-2 p-t-20">
                        <div
                          className={
                            "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width " +
                            this.state.isYearFocussed
                          }
                        >
                          <label
                            style={{ marginLeft: "60px" }}
                            className={
                              "input-text labell pass-name" +
                              this.state.backgroundStaff
                            }
                          >
                            Year
                          </label>
                          <input
                            onKeyPress={(e) => e.preventDefault()}
                            onFocus={this.onYearFocus.bind(this, i)}
                            autoComplete="off"
                            className="new-xakal-input background height pass-name"
                            type="text"
                            id="selectedYear"
                            value={el.selectedYear}
                            onChange={this.handleYearChange.bind(this, i)}
                            name="selectedYear"
                            style={{ marginLeft: "60px" }}
                          />

                          {this.state.onYearFocus &&
                          this.state.selectedIndex === i ? (
                            <div
                              className="mdl-menu__container is-upgraded dropdown-list is-visible"
                              style={{ width: "200px" }}
                            >
                              <div
                                className="mdl-menu__outline mdl-menu--bottom-left dropdown-div"
                                style={{
                                  width: "180px",
                                  marginLeft: "60px",
                                  marginTop: "-10px",
                                }}
                              >
                                <ul
                                  className="scrollable-menu  ul-list"
                                  style={{ width: "200px" }}
                                >
                                  {this.getYear(i)}
                                </ul>
                              </div>
                            </div>
                          ) : (
                            <p></p>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-4 p-t-20">
                        <div className="row">
                          <input
                            type="file"
                            name="uploadedFile"
                            className="col-sm-8 m-t-1 m-l-30 pass-name"
                            onChange={this.handleFormChange.bind(this, i)}
                            style={{marginLeft:'90px',marginTop:'50px'}}
                          />
                                                  <div>
                        {this.state.values.length !==1 && (
                        <button
                        type="button"
                        onClick={this.removeClick.bind(this, i)}
                        className=" col-sm-2 btn btn-primary m-t-1"
                        style={{marginLeft:'290px',marginTop:'-30px',backgroundColor:"#362A41"}}


                        >
                        X
                        </button>
                        )}
                        </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p></p>
              )}
              {this.state.selectedDepartment !== "" ? (
                <div className="card-body row">
                  <div className="col-sm-8 p-t-20">
                    <button
                      type="button"
                      onClick={this.addClick.bind(this)}
                      className="btn m-t-15"
                      style={{ backgroundColor: "#362A41", color: "#fff" }}
                    >
                      Add
                    </button>
                    <button
                      type="button"
                      onClick={this.onFileUpload.bind(this)}
                      key={this.state.theInputKey || ""}
                      className="btn m-t-15 m-l-30"
                      style={{ backgroundColor: "#362A41", color: "#fff" }}
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      onClick={this.resetForm.bind(this)}
                      className="btn m-t-15 m-l-30"
                      style={{ backgroundColor: "#362A41", color: "#fff" }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              ) : (
                <p></p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddSalaryDetails;
