import React, { Component } from 'react';

class SubjectName extends Component {
    constructor(props) {
        super(props)
        this.state = {
            subjects: this.props.subjects
        }
    }
    
    render() {
        return (
            <div>
                {
                    this.props.subjects
                        ?
                        this.props.subjects.map(e => (
                            <table>
                                <tr>
                                    <td style={{ height: '100%' }}>
                                        {e.name}
                                    </td>
                                </tr>
                            </table>
                        ))
                        :
                        <></>
                }
            </div>
        );
    }
}

export default SubjectName;