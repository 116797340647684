import React, { createContext, useState } from "react";
import Login from "./components/login2";
// import Login from "./components/login";

import SignUp from "./components/signup";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Switch,
  Link,
} from "react-router-dom";
function App() {
  return (
    <div>
      <Login />
    </div>
  );
}

export default App;
