import React, { Component } from "react";
import "../../styles/table.css";
import "../../minified-css/material-min.css";
import "../../styles/dropdowns.css";
import "../../styles/theme-style.css";
import "../../styles/dropdowns.css";
import "../../styles/theme-style.css";
import '../../styles/classnote.css';

import axios from "axios";
import moment from "moment";
class SalaryDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      column1: "",
      column2: "",
      column3: "",
      searchAllowed: false,
      salaryDetails: [],
      isFocussed: "",
      onFocus: false,
      onYearFocus: false,
      isYearFocussed: "",
      selectedMonth: "",
      selectedYear: "",
      background: "",
      yearBackground: "",
      userID: "",
    };
    this.baseState = this.state;
  }

  componentDidMount() {
    if (this.props && this.props.location && this.props.location.userID) {
      const userID = this.props.location.userID;
      this.setState({ userID: userID.userID });
    }
    this.unlisten = this.props.history.listen((location, action) => {
      this.setState(this.baseState);
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  /**
   * Adds the hover class when date is hovered
   */
  statusHover(event) {
    var element = event.target.className;
    if (element === "column100 column3 ") {
      this.setState({ column2: "hov-column-head-ver5" });
    }
  }

  /**
   * Adds the hover class when description is hovered
   */
  creditedstatusHover(event) {
    var element = event.target.className;
    if (element === "column100 column2 ") {
      this.setState({ column1: "hov-column-head-ver5" });
    }
  }

  /**
   * Adds the hover class when receipt is hovered
   */
  receiptHover(event) {
    var element = event.target.className;
    if (element === "column100 column4 ") {
      this.setState({ column3: "hov-column-head-ver5" });
    }
  }

  /**
   * Resets the state variables when hover is removed
   */
  hoverOff() {
    this.setState({
      column1: "",
      column2: "",
      column3: "",
    });
  }

  /**
   * Sets the month selected
   */
  onMonthSelect(name, event) {
    this.setState({
      selectedMonth: name,
      onFocus: false,
      background: "is-hidden",
    });
    if (this.state.searchAllowed) {
      this.setState({ searchAllowed: false });
    }
  }

  onMonthFocus() {
    this.setState({
      isFocussed: "is-focused",
      onFocus: true,
      onYearFocus: false,
      background: "is-shown",
    });
  }

  /**
   * Sets the year selected
   */
  onYearSelect(event) {
    this.setState({
      selectedYear: event.target.id,
      onYearFocus: false,
      yearBackground: "is-hidden",
    });
    if (this.state.searchAllowed) {
      this.setState({ searchAllowed: false });
    }
  }

  onYearFocus() {
    this.setState({
      isYearFocussed: "is-focused",
      onFocus: false,
      onYearFocus: true,
      yearBackground: "is-shown",
    });
  }

  /**
   * Allows the grid to display the values
   */
  getResult() {
    if (this.state.selectedMonth !== "" && this.state.selectedYear !== "") {
      this.fetchSalaryDetails();
    } else {
      alert("Please select the values");
      this.setState({ searchAllowed: false });
    }
  }

  /**
   * Fetches the details of Payment based on semester selected
   */
  fetchSalaryDetails() {
    this.setState({ searchAllowed: true });
    var params = {
      salaryMonth: this.state.selectedMonth,
      salaryYear: this.state.selectedYear,
      userID: this.state.userID.toUpperCase(),
    };
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/salary/salarydetail`, { params: params })
      .then((response) => {
        this.setState({ salaryDetails: response.data });
      });
  }

  /**
   * Fetches all the month name
   */
  getMonths() {
    return moment.months().map((name, index) => {
      return (
        <li
          id={name}
          key={index++}
          className="mdl-menu__item animation"
          onClick={this.onMonthSelect.bind(this, name)}
        >
          {name}
        </li>
      );
    });
  }

  /**
   * Gets the previous 10 years
   */
  getYear() {
    const year = new Date().getFullYear();
    const years = Array.from(new Array(10), (val, index) => -(index - year));
    return years.map((year, index) => {
      return (
        <li
          id={year}
          key={index++}
          className="mdl-menu__item animation"
          onClick={this.onYearSelect.bind(this)}
        >
          {year}
        </li>
      );
    });
  }

  /**
   * Displays the list of payment details based on the API response
   */
  displayTable() {
    return this.state.salaryDetails.map((singleData, index) => {
      var hrefValue =
        `../../../` +
        singleData.userID +
        "/" +
        singleData.salaryYear +
        "/" +
        singleData.salaryMonth +
        "/" +
        singleData.salaryReceipt;
      return (
        <tr className="row100">
          <td className="column100 grid-cont vertical-border column1" data-column="column1">
            {++index}
          </td>
          <td
            className={"column100 grid-cont vertical-border column2 "}
            onMouseEnter={this.creditedstatusHover.bind(this)}
            onMouseLeave={this.hoverOff.bind(this)}
          >
            {moment(new Date(singleData.creditedDate)).format("DD/MM/YYYY")}
          </td>
          <td
            className={"column100 grid-cont vertical-border column3 "}
            onMouseEnter={this.statusHover.bind(this)}
            onMouseLeave={this.hoverOff.bind(this)}
          >
            {singleData.salaryStatus}
          </td>
          <td
            className={"column100 grid-cont vertical-border  column4 "}
            onMouseEnter={this.receiptHover.bind(this)}
            onMouseLeave={this.hoverOff.bind(this)}
          >
           <a
              rel="noopener noreferrer"
              target="_blank"
              href={singleData.salaryReceiptUrl ? singleData.salaryReceiptUrl : ""}
            >
              Download File
            </a>
          </td>
        </tr>
      );
    });
  }

  render() {
    return (
      <div className="container-fluid  background bk">
        <div className="d-sm-flex  mb-4">
        <nav className="navbar navbar-expand-lg  navprac bar">
         <div className="title">Salary</div>
  <div className="collapse navbar-collapse">
    <form className="form-inline my-2 my-lg-0"> 
      <input  style={{width:'500px',marginLeft:'120px',backgroundColor:'rgba(54, 42, 65, 0.04)',fontStyle:'italic', height:'45px'}}className="form-control mr-sm-2 " type="search" placeholder="Search....." aria-label="Search"></input>
    </form>
  </div>
</nav>
<div className="row">
           <h4 className="head resp sal-max-th" style={{marginLeft:'-1110px',marginTop:'150px'}}> Salary Details</h4>

            {/* <h1 className="h3 mb-0 text-gray-800 m-t-20">Notes</h1> */}
          </div>
          {/* <h1 className="h3 mb-0 text-gray-800 m-t-20">Salary Details</h1> */}
        </div>
        <div className="row m-r-20">
          <div className="col-sm-12">
            <div >
              <div className="row">
                <div className="col-lg-4 p-t-20">
                  <div
                    className={
                      "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width " +
                      this.state.isFocussed
                    }
                  > 
                     <label
                      className="input-text labell salres"
                      style={{marginLeft:'28%',marginTop:'-10%'}}
                    >
                      Month
                    </label>
                    <input
                      autoComplete="off"
                      onKeyPress={(e) => e.preventDefault()}
                      onFocus={this.onMonthFocus.bind(this)}
                      className="new-xakal-input  background salres"
                      type="text"
                      id="sample2"
                      value={this.state.selectedMonth}
                      style={{marginLeft:'28%',height:'40px',width:'300px'}}

                    /> 
                    {this.state.onFocus ? (
                       <div className="mdl-menu__container is-upgraded dropdown-list is-visible dropres1" style={{ width: '200px' ,marginLeft:'105px'}} >
                       <div className="mdl-menu__outline  dropdown-div sal-drop-max" style={{ width: '300px',marginLeft:'-26px',marginTop: '-8px' }} >
                         <ul className="scrollable-menu  ul-list" style={{ width: '100%' }} >   
                            {this.getMonths()}
                          </ul>
                        </div>
                      </div>
                    ) : (
                      <p></p>
                    )}
                  </div>
                </div>
                <div className="col-lg-4 p-t-20">
                  <div
                    className={
                      "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width " +
                      this.state.isYearFocussed
                    }
                   >
                   
                       <label
                       style={{marginLeft:'38%'}}
                      className={"input-text labell salres" + this.state.yearBackground
                      
    
                        // "mdl-textfield__label " + this.state.yearBackground
                      }
                    >
                      Year
                    </label>

                     <input
                      autoComplete="off"
                      onKeyPress={(e) => e.preventDefault()}
                      onFocus={this.onYearFocus.bind(this)}
                      className="new-xakal-input  background salres"
                      type="text"
                      id="sample2"
                      value={this.state.selectedYear}
                      style={{marginLeft:'38%',height:'40px',width:'300px'}}

                    /> 
                    {this.state.onYearFocus ? (
                       <div className="mdl-menu__container is-upgraded dropdown-list is-visible dropres1" style={{ width: '200px' ,marginLeft:'105px'}} >
                       <div className="mdl-menu__outline  dropdown-div sal-drop-max" style={{ width: '300px',marginLeft:'0px',marginTop:'-4px' }} >
                         <ul className="scrollable-menu  ul-list" style={{ width: '100%' }} >
                            {this.getYear()}
                          </ul>
                        </div>
                      </div>
                    ) : (
                      <p></p>
                    )}
                  </div>
                </div>
                <div className="col-sm-4 p-t-20">
                  <button
                  className="getresult salres"
                    type="button"
                    onClick={this.getResult.bind(this)}
                    // className="btn btn-primary m-t-15 m-l-30"
                    style={{marginTop:'16%',marginLeft:'47%'}}
                  >
                    Get Results!
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.searchAllowed ? (
           <div className="limiter">
           <div class="container" style={{ marginTop: '0px', marginLeft: '87px' }}>
             <div class="col-md-12">
               <div class="row">
                 <table>
                   <thead style={{color: '#B8B3BC' }}>
                     <tr className="row100 head" >
                       <th
                         style={{color: '#B8B3BC' }}
                         className="column100  background  heading-weight heading-size  "
                        

                       ></th>
                        <th
                          className={"column100  background heading-weight heading-size  " + this.state.column1}
                          onMouseEnter={this.creditedstatusHover.bind(this)}
                          onMouseLeave={this.hoverOff.bind(this)}
                        >
                          Credited Date
                        </th>
                        <th
                          className={"column100 background vertical-border-head heading-weight heading-size " + this.state.column2}
                          onMouseEnter={this.statusHover.bind(this)}
                          onMouseLeave={this.hoverOff.bind(this)}
                        >
                          Salary Status
                        </th>
                        <th
                          className={"column100 background vertical-border-head heading-weight heading-size column4 " + this.state.column3}
                          onMouseEnter={this.receiptHover.bind(this)}
                          onMouseLeave={this.hoverOff.bind(this)}
                        >
                          Receipts / Acknowledgement
                        </th>
                      </tr>
                    </thead>
                    <tbody>{this.displayTable()}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          
        ) : (
          <span></span>
        )}
      </div>
    );
  }
}

export default SalaryDetails;
