import React, { Component } from "react";
import ManagementNavBar from "./management-navbar";
import Transport from "./management-portal/transports/transport";
import Classroom from "./classroom/index";

class ManagementPortal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transport: false,
      classroom: false,
      userID: (name) =>
        `; ${document.cookie}`.split(`; ${name}=`).pop().split(";").shift(),
    };
  }

  setTransport(val) {
    this.setState({
      transport: val,
    });
  }

  setClassroom(val) {
    this.setState({
      classroom: val,
    });
  }

  render() {
    return this.state.transport ? (
      <Transport
        state={this.props}
        setTransport={this.setTransport.bind(this)}
      />
    ) : (
      this.state.classroom ?
      <Classroom 
        state={this.props}
        setClassroom={this.setClassroom.bind(this)}
      />
      :
      (
        <ManagementNavBar
          userID={this.props.location.state || this.state.userID("userid")}
          state={this.props}
          setTransport={this.setTransport.bind(this)}
          setClassroom={this.setClassroom.bind(this)}
        />
      )
    );
  }
}

export default ManagementPortal;
