/* eslint-disable react/no-direct-mutation-state */
import moment from "moment";
import React, { Component } from "react";
import axios from "axios";
import "../../styles/classnote.css";

class AttendanceMaintain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      column1: "",
      column2: "",
      selectedSemester: "",
      description: "",
      searchAllowed: false,
      absenceList: [],
      selectedMonth: "",
      selectedType: "",
      background: "",
      isFocussed: "",
      onFocus: false,
      userID: "",
      isHODPortal: false,
      isManagementPortal: false,
      isStudentPortal: false,
      selectedStudentName: "",
      fileUpdate: "",
      oneDay: false,
      fromDate: moment(new Date()).format("YYYY-MM-DD"),
      toDate: moment(new Date()).format("YYYY-MM-DD"),
    };
    this.baseState = this.state;
  }
  handleReset = (e) => {
    this.setState({
      selectedSemester: "",
      selectedType: "",
      description: "",
      selectedStudent: "",
      fromDate: "",
      toDate: "",
      oneDay:false
    });
  };
  componentDidMount() {
    // console.log("props", this.props);
    this.getPortal();
    if (this.props && this.props.location && this.props.location.userID) {
      const userID = this.props.location.userID;
      this.setState({ userID: userID.userID });
    }
    this.unlisten = this.props.history.listen((location, action) => {
      this.setState(this.baseState);
      this.getPortal();
    });
  }

  /**
   * Checks for portal logged in
   */
  getPortal() {
    const pathArray = this.props.location.pathname.split("/");
    if (pathArray.includes("hod-portal")) {
      this.setState({ isHODPortal: true });
      this.fetchStaffDetails();
    } else if (pathArray.includes("management-portal")) {
      this.setState({ isManagementPortal: true });
      this.fetchDepartmentDetails();
    } else if (pathArray.includes("students-portal")) {
      this.setState({ isStudentPortal: true });
      this.fetchStudentDetailsById(this.props.location.userID.userID);
      // this.fetchStudentDetails();
    }
  }

  /**
   * Fetches all department
   */
  fetchDepartmentDetails() {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/departmentdetail`)
      .then((response) => {
        this.setState({ departmentDetails: response.data });
      });
  }

  /**
   * Triggers when department dropdown is focused
   */
  onDeptDropDownFocus() {
    this.setState({
      isDepartmentFocussed: "is-focused",
      onDepartmentFocus: true,
      backgroundDepartment: "is-shown",
    });
    if (this.state.hasSemesterValue) {
      this.setState({
        onFocus: false,
        background: "is-hidden",
        isFocussed: "is-focused",
      });
    } else {
      this.setState({
        onFocus: false,
        background: "is-hidden",
        isFocussed: "is-hidden",
      });
    }
  }

  /**
   * Triggers when the department is changed and stores the values in state
   * @param event form values
   */
  handleDepartmentChange(event) {
    this.setState({
      selectedDepartment: event.target.id,
      onDepartmentFocus: false,
      backgroundDepartment: "is-hidden",
      background: "is-hidden",
      hasDepartmentValue: true,
    });
    this.fetchStudentDetailsByDept(event.target.id);
  }

  /**
   * Displays the list of department based on the API response
   */
  displayDepartment() {
    if (
      this.state &&
      this.state.departmentDetails &&
      this.state.departmentDetails.length
    ) {
      return this.state.departmentDetails.map((singleDepartment, index) => {
        return (
          <li className="mdl-menu__item animation label-weight" key={index}>
            <button
              id={singleDepartment.name}
              name={singleDepartment.name}
              onClick={this.handleDepartmentChange.bind(this)}
            >
              {singleDepartment.name}
            </button>
          </li>
        );
      });
    }
  }

  /**
   * Gets the selected staff detail
   */
  fetchStaffDetails() {
    const userID = this.props.location.userID || this.props.location.state;
    if (userID) {
      axios
        .get(`${process.env.PUBLIC_URL}/xakal/staffdetail/${userID.userID}`)
        .then((response) => {
          this.setState({ staffDetails: response.data });
          this.fetchStudentDetailsByDept(response.data.departmentName);
        });
    }
  }

  /**
   * Fetches all students for selected department
   */
  fetchStudentDetailsByDept(departmentName) {
    this.setState({ studentDetails: [] });
    axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/studentdetail/department/${departmentName}`
      )
      .then((response) => {
        this.setState({ studentDetails: response.data });
        console.log("stdet", response.data);
      });
  }

  fetchStudentDetailsById(userID) {
    this.setState({ studentDetails: [] });
    // console.log("id", userID)
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/studentdetail/${userID}`)
      .then((response) => {
        this.setState({ studentDetails: [response.data] });
        // console.log("stdetid", response.data)
      });
  }

  /**
   * Sets the semester selected
   */
  onDropDownSelect(event) {
    this.setState({
      selectedSemester: event.target.id,
      onFocus: false,
      background: "is-hidden",
      hasSemesterValue: true,
    });
    if (this.state.searchAllowed) {
      this.setState({ searchAllowed: false });
    }
  }

  onDropDownFocus() {
    this.setState({
      isFocussed: "is-focused",
      onFocus: true,
      background: "is-shown",
      isFromFocussed: "is-hidden",
      fromBackground: "is-hidden",
      isToFocussed: "is-hidden",
      toBackground: "is-hidden",
    });
    this.descriptionFocus();
  }

  semesterFocus() {
    if (this.state.hasSemesterValue === true) {
      this.setState({ isFocussed: "is-focused", background: "is-hidden" });
    } else {
      this.setState({ background: "is-hidden", isFocussed: "is-hidden" });
    }
  }

  descriptionFocus() {
    if (this.state.hasDescValue === true) {
      this.setState({
        isDescriptionFocussed: "is-focused",
        backgroundDesc: "is-hidden",
      });
    } else {
      this.setState({
        backgroundDesc: "is-hidden",
        isDescriptionFocussed: "is-hidden",
      });
    }
  }

  onFromDateFocus() {
    this.setState({
      isFromFocussed: "is-focused",
      onFromFocus: true,
      fromBackground: "is-shown",
      isFocussed: "is-hidden",
      background: "is-hidden",
      isToFocussed: "is-hidden",
      toBackground: "is-hidden",
    });
    this.semesterFocus();
    this.descriptionFocus();

    this.monthFocus();
    this.yearFocus();
  }

  onToDateFocus() {
    this.setState({
      isFromFocussed: "is-hidden",
      fromBackground: "is-hidden",
      isFocussed: "is-hidden",
      background: "is-hidden",
      isToFocussed: "is-focused",
      onToFocus: true,
      toBackground: "is-shown",
    });
    this.semesterFocus();
    this.descriptionFocus();

    this.monthFocus();
    this.yearFocus();
  }

  onDescriptionFocus() {
    this.setState({
      isDescriptionFocussed: "is-focused",
      backgroundDesc: "is-shown",
      isFromFocussed: "is-hidden",
      fromBackground: "is-hidden",
      isFocussed: "is-hidden",
      background: "is-hidden",
      isToFocussed: "is-focused",
      toBackground: "is-hidden",
    });
    this.semesterFocus();
    this.onToDateFocus();
    this.monthFocus();
    this.yearFocus();
  }

  onDescriptionChanged(event) {
    this.setState({
      description: event.target.value,
      backgroundDescription: "is-hidden",
      hasDescValue: true,
    });
  }

  /**
   * Triggers when the form is changed and stores the values in state
   * @param event form values
   */
  handleFormChange(event) {
    if (event && event.target) {
      const name = event.target.id;
      this.setState({ [name]: event.target.value });
    }
  }

  onFocusOut(event) {
    if (event.target) {
      if (event.target.value === "") {
        event.target.parentNode.classList.remove("is-focused");
      }
      event.target.nextSibling.classList.remove("is-shown");
    }
  }

  monthFocus() {
    if (this.state.hasMonthValue === true) {
      this.setState({
        isMonthFocussed: "is-focused",
        monthBackground: "is-hidden",
      });
    } else {
      this.setState({
        monthBackground: "is-hidden",
        isMonthFocussed: "is-hidden",
      });
    }
  }

  yearFocus() {
    if (this.state.hasYearValue === true) {
      this.setState({
        isYearFocussed: "is-focused",
        yearBackground: "is-hidden",
      });
    } else {
      this.setState({
        yearBackground: "is-hidden",
        isYearFocussed: "is-hidden",
      });
    }
  }

  submitLeave() {
    let randomString = Math.random().toString(36);

    this.setState({
      theInputKey: randomString,
    });
    // unCheck()
    const departmentCheck = this.state.isManagementPortal
      ? this.state.selectedDepartment === ""
      : false;
    const oneDayCheck = this.state.isManagementPortal
      ? this.state.oneDay === ""
      : false;
    if (
      departmentCheck ||
      this.state.selectedSemester === "" ||
      this.state.description === "" ||
      this.state.selectedStudent === "" ||
      this.state.selectedType === "" ||
      oneDayCheck
    ) {
      alert("Please fill all the details");
    } else if (
      moment(new Date(this.state.fromDate)).isAfter(
        moment(new Date(this.state.toDate))
      )
    ) {
      alert("Select valid date range");
    } else {
      // insert the leave to db
      this.applyStudentLeave();
      
      // this.handleReset()
      // this.onUncheck()
    }
  }

  applyStaffLeave() {
    let isUpdated = false;
    const params = {
      userID: this.state.userID.toUpperCase(),
      month: this.state.selectedMonth,
      year: this.state.selectedYear,
      uploadedDate: new Date(Date.now()).toLocaleString(),
      fromDate: new Date(this.state.fromDate).toLocaleString(),
      toDate: new Date(this.state.toDate).toLocaleString(),
      reason: this.state.description,
    };
    axios
      .post(
        `${process.env.PUBLIC_URL}/xakal/staffattendance/staffleave`,
        params
      )
      .then(() => {
        if (!isUpdated) {
          alert("Updated Successfully");
          this.setState(this.baseState);
        }
        isUpdated = true;
      })
      .catch((err) => console.log(err));
  }

  applyStudentLeave() {
    const _this = this;
    let isUpdated = false;
    const fileFormData = new FormData();
    fileFormData.append("uploadedFile", this.state.fileUpdate);
    fileFormData.append("imgcheck", true);
    axios
      .post(`${process.env.PUBLIC_URL}/xakal/files/`, fileFormData)
      .then((res) => {
        if (!res.data.ok) {
          alert(res.data.msg);
          return false;
        }

        // return true;
        if (res) {
          const params = {
            userID: _this.state.selectedStudent.toUpperCase(),
            semester: _this.state.selectedSemester.toLowerCase(),
            uploadedDate: new Date(Date.now()).toLocaleString(),
            uploadedBy: _this.state.userID.toUpperCase(),
            type: _this.state.selectedType,
            fromDate: new Date(_this.state.fromDate).toLocaleString(),
            toDate: new Date(_this.state.toDate).toLocaleString(),
            reason: _this.state.description,
            files: _this.state.fileUpdate.name,
            url: res.data.url,
            status: "pending",
          };
          axios
            .post(
              `${process.env.PUBLIC_URL}/xakal/attendance/studentleave`,
              params
            )
            .then((res) => {
              if (res.data.status !== 400) {
                if (!isUpdated) {
                  alert("Updated Successfully");
                  this.setState(this.baseState);
                  this.setState({
                    oneDay:false
                  });
                }
                isUpdated = true;
                
              } else {
                alert(res.data.mssg);
              }
            })
            .catch((err) => console.log("lv err", err));
        }
      });
  }

  /**
   * Sets the month selected
   */
  onMonthSelect(event) {
    this.setState({
      selectedMonth: event.target.id,
      hasMonthValue: true,
      onFocus: false,
      monthBackground: "is-hidden",
    });
    if (this.state.searchAllowed) {
      this.setState({ searchAllowed: false });
    }
  }

  onMonthFocus() {
    this.setState({
      isMonthFocussed: "is-focused",
      onFocus: true,
      onYearFocus: false,
      yearBackground: "is-hidden",
      monthBackground: "is-shown",
    });
    this.yearFocus();
    this.descriptionFocus();
  }

  /**
   * Fetches all the month name
   */
  getMonths() {
    return moment.months().map((name, index) => {
      return (
        <li
          id={name}
          key={index++}
          className="mdl-menu__item animation label-weight"
          onClick={this.onMonthSelect.bind(this)}
        >
          {name}
        </li>
      );
    });
  }

  /**
   * Sets the year selected
   */
  onTypeSelect(event) {
    this.setState({
      selectedType: event.target.id,
      hasYearValue: true,
      onYearFocus: false,
      yearBackground: "is-hidden",
    });
  }

  onTypeFocus() {
    this.setState({
      isYearFocussed: "is-focused",
      onFocus: false,
      onYearFocus: true,
      yearBackground: "is-shown",
      monthBackground: "is-hidden",
    });
    this.monthFocus();
    this.descriptionFocus();
  }

  /**
   * Gets the previous 5 years
   */
  getYear() {
    return ["OD", "Leave"].map((year, index) => {
      return (
        <li
          id={year}
          key={index++}
          className="mdl-menu__item animation label-weight"
          onClick={this.onTypeSelect.bind(this)}
        >
          {year}
        </li>
      );
    });
  }

  /**
   * Triggers when student is focused
   */
  onStudentFocussed() {
    this.setState({
      isStudentFocussed: "is-focused",
      onFocus: false,
      onStudentFocus: true,
      backgroundStudent: "is-shown",
    });
  }

  /**
   * Triggers when the student is changed and stores the values in state
   * @param event form values
   */
  handleStudentChange(event) {
    this.setState({
      selectedStudentName: event.target.name,
      selectedStudent: event.target.id,
      onStudentFocus: false,
      backgroundStudent: "is-hidden",
    });
  }

  /**
   * Displays the list of student based on the API response
   */
  displayStudent() {
    if (
      this.state &&
      this.state.studentDetails &&
      this.state.studentDetails.length
    ) {
      console.log(
        "det",
        this.state.studentDetails,
        this.state.studentDetails.length
      );
      return this.state.studentDetails.map((singleStudent, index) => {
        return (
          <li className="mdl-menu__item animation label-weight" key={index}>
            <button
              id={singleStudent.user.userID}
              name={singleStudent.user.name}
              onClick={this.handleStudentChange.bind(this)}
            >
              {singleStudent.user.name}
            </button>
          </li>
        );
      });
    }
    // else{console.log("det",this.state.studentDetails,this.state.studentDetails.length)}
  }

  onFileChange(event) {
    // console.log(event)
    this.setState({ fileUpdate: event.target.files[0] });
    // console.log(event.target.files[0])
  }
 
  onCheckOneDay(event) {
    // this.setState({ oneDay: this.state.oneDay === false });
    this.setState({ oneDay: event.target.checked === true });
    // console.log(this.state.oneDay)
  }
  render() {
    return (
      <div className="container-fluid background">
        <div className="bar1 bk  background">
          <nav className="navbar navbar-expand-lg attenav">
            <div className="title">Leave</div>
            <div className="collapse navbar-collapse">
              <form className="form-inline my-2 my-lg-0">
                <input
                  style={{
                    width: "500px",
                    marginLeft: "120px",
                    backgroundColor: "rgba(54, 42, 65, 0.04)",
                    fontStyle: "italic",
                    height: "45px",
                  }}
                  className="form-control mr-sm-2 "
                  type="search"
                  placeholder="Search....."
                  aria-label="Search"
                ></input>
              </form>
            </div>
          </nav>
          <div className="row background">
            <h4 className="head max-head" style={{ marginLeft: "50px" }}>
              {" "}
              Apply Leave
            </h4>

            {/* <h1 className="h3 mb-0 text-gray-800 m-t-20">Notes</h1> */}
          </div>
          {/* <h1
            className="h3 mb-0 text-gray-800 m-t-20"
            style={{ paddingLeft: "0.5rem" }}
          >
            Apply Leave
          </h1> */}

          <div className="row m-r-20">
            <div className="col-sm-12">
              <div
                style={{
                  marginTop: "-10px",
                }}
              >
                <div className="card-body row" style={{ marginLeft: "40px" }}>
                  {/* {this.state.isStudentPortal ? */}
                  {this.state.isManagementPortal ? (
                    <div className="col-sm-3 p-t-20">
                      <div
                        className={
                          // "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width " +
                          this.state.isDepartmentFocussed
                        }
                      >
                        <label className="input-text">Department</label>
                        <input
                          name="selectedDepartment"
                          onKeyPress={(e) => e.preventDefault()}
                          autoComplete="off"
                          onFocus={this.onDeptDropDownFocus.bind(this)}
                          className="new-xakal-input"
                          type="text"
                          id={`department`}
                          value={this.state.selectedDepartment}
                          onChange={this.handleDepartmentChange.bind(this)}
                        />

                        {this.state.onDepartmentFocus ? (
                          <div className="mdl-menu__container is-upgraded dropdown-list is-visible">
                            <div className="mdl-menu__outline mdl-menu--bottom-left dropdown-div">
                              <ul className="scrollable-menu mdl-menu mdl-menu--bottom-left mdl-js-menu ul-list">
                                {this.displayDepartment()}
                              </ul>
                            </div>
                          </div>
                        ) : (
                          <p></p>
                        )}
                      </div>
                    </div>
                  ) : (
                    <p></p>
                  )}
                  <div className="row background">
                    <div className="col-lg-3 p-t-20">
                      <div
                        className={
                          " mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width " +
                          this.state.isFocussed
                        }
                      >
                        <label className="input-text labell leavesem">
                          Semester
                        </label>
                        <input
                          autoComplete="off"
                          onKeyPress={(e) => e.preventDefault()}
                          onFocus={this.onDropDownFocus.bind(this)}
                          className="new-xakal-input leavesem"
                          type="text"
                          id="sample2"
                          value={this.state.selectedSemester}
                          style={{ height: "40px" }}
                        />

                        {this.state.onFocus ? (
                          <div
                            className="mdl-menu__container is-upgraded dropdown-list is-visible"
                            style={{ width: "200px", marginTop: "-8px" }}
                          >
                            <div
                              className="mdl-menu__outline  dropdown-div"
                              style={{ width: "200px" }}
                            >
                              <ul
                                className="scrollable-menu ul-list"
                                style={{ width: "100%" }}
                              >
                                <li
                                  className="mdl-menu__item animation"
                                  id="Semester 1"
                                  onClick={this.onDropDownSelect.bind(this)}
                                >
                                  Semester 1
                                </li>
                                <li
                                  className="mdl-menu__item animation1"
                                  id="Semester 2"
                                  onClick={this.onDropDownSelect.bind(this)}
                                >
                                  Semester 2
                                </li>
                                <li
                                  className="mdl-menu__item animation2"
                                  id="Semester 3"
                                  onClick={this.onDropDownSelect.bind(this)}
                                >
                                  Semester 3
                                </li>
                                <li
                                  className="mdl-menu__item animation"
                                  id="Semester 4"
                                  onClick={this.onDropDownSelect.bind(this)}
                                >
                                  Semester 4
                                </li>
                                <li
                                  className="mdl-menu__item animation1"
                                  id="Semester 5"
                                  onClick={this.onDropDownSelect.bind(this)}
                                >
                                  Semester 5
                                </li>
                                <li
                                  className="mdl-menu__item animation2"
                                  id="Semester 6"
                                  onClick={this.onDropDownSelect.bind(this)}
                                >
                                  Semester 6
                                </li>
                                <li
                                  className="mdl-menu__item animation"
                                  id="Semester 7"
                                  onClick={this.onDropDownSelect.bind(this)}
                                >
                                  Semester 7
                                </li>
                                <li
                                  className="mdl-menu__item animation1"
                                  id="Semester 8"
                                  onClick={this.onDropDownSelect.bind(this)}
                                >
                                  Semester 8
                                </li>
                              </ul>
                            </div>
                          </div>
                        ) : (
                          <p></p>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-3 p-t-20">
                      <div
                        className={
                          "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width " +
                          this.state.isStudentFocussed
                        }
                      >
                        <label
                          style={{ marginLeft: "-50px" }}
                          className="input-text labell leavesem"
                        >
                          Student
                        </label>
                        <input
                          onKeyPress={(e) => e.preventDefault()}
                          onFocus={this.onStudentFocussed.bind(this)}
                          autoComplete="off"
                          className="new-xakal-input leavesem"
                          type="text"
                          id="selectedStudent"
                          value={this.state.selectedStudentName}
                          onChange={this.handleStudentChange.bind(this)}
                          name="selectedStudent"
                          style={{ height: "40px" }}
                        />

                        {this.state.onStudentFocus ? (
                          <div
                            className="mdl-menu__container is-upgraded dropdown-list is-visible"
                            style={{ width: "200px", marginTop: "-8px" }}
                          >
                            <div
                              className="mdl-menu__outline  dropdown-div"
                              style={{ width: "200px" }}
                            >
                              <ul
                                className="scrollable-menu ul-list"
                                style={{ width: "100%" }}
                              >
                                {" "}
                                {this.displayStudent()}
                              </ul>
                            </div>
                          </div>
                        ) : (
                          <p></p>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-3 p-t-20">
                      <div
                        className={
                          "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width " +
                          this.state.isYearFocussed
                        }
                      >
                        <label
                          style={{ marginLeft: "-100px" }}
                          className="input-text labell leavesem"
                        >
                          Type
                        </label>
                        <input
                          onKeyPress={(e) => e.preventDefault()}
                          autoComplete="off"
                          onFocus={this.onTypeFocus.bind(this)}
                          className="new-xakal-input leavesem"
                          type="text"
                          id="year"
                          value={this.state.selectedType}
                          style={{ height: "40px" }}
                        />

                        {this.state.onYearFocus ? (
                          <div
                            className="mdl-menu__container is-upgraded dropdown-list is-visible"
                            style={{ width: "200px", marginTop: "-8px" }}
                          >
                            <div
                              className="mdl-menu__outline  dropdown-div"
                              style={{ width: "200px" }}
                            >
                              <ul
                                className="scrollable-menu ul-list"
                                style={{ width: "100%" }}
                              >
                                {" "}
                                {this.getYear()}
                              </ul>
                            </div>
                          </div>
                        ) : (
                          <p></p>
                        )}
                      </div>
                    </div>{" "}
                    <br />
                    <div className="col-lg-3 p-t-20">
                      <div
                        className={"mdl-textfield leavesem  is-focused"}
                        style={{ marginBottom: "1.2rem" }}
                      >
                        <input
                          type="checkbox"
                          className="checkres max-head"
                          id="oneDay"
                          name="oneDay"
                          checked={this.state.oneDay}
                          // value="One Day"
                          style={{
                            display: "inline",
                            marginTop: "18%",
                            backgroundColor: "#F6F6F6",
                            marginLeft: "-150px",
                            marginTop: "15%",
                          }}
                          // value={this.state.oneDay}
                          onChange={(e) => this.onCheckOneDay(e)}
                        />
                        <label
                          for="oneDay"
                          className="labell oneres max-head"
                          style={{ paddingLeft: "1rem", marginLeft: "30px" }}
                        >
                          One Day
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className="col-lg-4 p-t-20"
                        style={{ marginTop: "-20px" }}
                      >
                        <div
                          className={
                            "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width is-focused"
                          }
                          // style={{marginLeft:"1rem"}}
                        >
                          <label
                            style={{ marginLeft: "12px" }}
                            // htmlFor="fromDate"
                            className="input-text labell leavesem"
                          >
                            {this.state.oneDay ? "Date" : "From Date" }
                          </label>
                          <input
                            value={
                              this.state.fromDate ||
                              moment(new Date()).format("YYYY-MM-DD")
                            }
                            autoComplete="off"
                            placeholder=""
                            onFocus={this.onFromDateFocus.bind(this)}
                            className="new-xakal-input date leavesem"
                            type="date"
                            id="fromDate"
                            onChange={this.handleFormChange.bind(this)}
                            name="fromDate"
                            style={{ height: "40px", marginLeft: "10px" }}
                          />
                        </div>
                      </div>

                      {this.state.oneDay === false ? (
                        <div
                          className="col-lg-4 p-t-20"
                          style={{ marginTop: "-20px" }}
                        >
                          <div
                            className={
                              "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width is-focused"
                            }
                            style={{ marginBottom: "1.2rem" }}
                          >
                            <label
                              htmlFor="toDate"
                              className="input-text"
                              style={{ marginLeft: "10px" }}
                            >
                              Until date
                            </label>
                            <input
                              value={
                                this.state.toDate ||
                                moment(new Date()).format("YYYY-MM-DD")
                              }
                              autoComplete="off"
                              placeholder=""
                              onFocus={this.onToDateFocus.bind(this)}
                              className="new-xakal-input leavesem"
                              type="date"
                              id="toDate"
                              onChange={this.handleFormChange.bind(this)}
                              name="toDate"
                              style={{ height: "40px", marginLeft: "10px" }}
                            />
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      <div
                        className="col-lg-4 p-t-20"
                        style={{ marginTop: "-20px" }}
                      >
                        <div
                          className={
                            "mdl-textfield mdl-js-textfield mdl-textfield--floating-label " +
                            this.state.isDescriptionFocussed
                          }
                          // style={{marginLeft:"3.8rem"}}
                        >
                          <label
                            className="input-text"
                            style={{ marginLeft: "10px" }}
                          >
                            Description
                          </label>
                          <input
                            autoComplete="off"
                            onFocus={this.onDescriptionFocus.bind(this)}
                            value={this.state.description}
                            className="new-xakal-input description1 leavesem"
                            type="text"
                            id="description"
                            onChange={this.onDescriptionChanged.bind(this)}
                            style={{ height: "40px", marginLeft: "10px" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label " +
                        this.state.isDescriptionFocussed
                      }
                    >
                      <input
                        autoComplete="off"
                        className="mdl-textfield__input display-border leavesem max-head"
                        type="file"
                        id="uploadfile"
                        onChange={this.onFileChange.bind(this)}
                        style={{ marginLeft: "5%" }}
                        key={this.state.theInputKey || ""}
                      />
                      {/* <label className={"mdl-textfield__label " + this.state.backgroundDesc}>file</label> */}
                    </div>
                  </div>
                  <div className="col-sm-1 p-t-20">
                    <button
                      type="button"
                      onClick={this.submitLeave.bind(this)}
                      className="btn btn-primary m-t-15 m-l-30"
                      style={{ backgroundColor: "#362A41", height: "40px" }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AttendanceMaintain;
