import React, { Component } from "react";
// import axios from "axios";
// import moment from "moment";
import "../../../../styles/table.css";
import "../../../../minified-css/material-min.css";
import "../../../../styles/dropdowns.css";
import "../../../../styles/theme-style.css";

class NewBusTemplate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ...this.props.state
        }
    }

    componentDidMount() {

    }

    handleAddBusRoutes() {
        this.props.handleAddBusRoutes(true, [], [1]);
    }

    render() {
        return (
            <div class="item col-md-3" style={{ margin: 10, backgroundColor: '#002933' }}>
                <div className="center">
                    <input onChange={this.props.changeBusNumber.bind(this)} placeholder="Enter Bus Number" style={{ textAlign: 'center', marginTop: 20 }} />
                </div>
                <div className="center" style={{ marginTop: 50 }}>
                    <h4 style={{ textAlign: 'center' }}>Add Bus Route</h4>
                    <img onClick={this.handleAddBusRoutes.bind(this)} style={{ width: 50, height: 50, objectFit: 'contain', borderRadius: '50%' }} className="hover" alt="vector location icon" src="https://png.pngtree.com/png-clipart/20190619/original/pngtree-vector-location-icon-png-image_3997521.jpg" />
                </div>
                <div style={{ marginTop: 20, padding: 5 }} className="bus-section">
                    <span>
                        <span>College -&nbsp;</span>
                        {
                            this.props.selectedBusStops && this.props.selectedBusStops.length ? this.props.selectedBusStops.map((stop, i) => (
                                (i !== this.props.selectedBusStops.length - 1)
                                    ?
                                    <span>{stop.name} -&nbsp;</span>
                                    :
                                    <span>{stop.name}</span>
                            )
                            )
                                : <></>
                        }
                    </span>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <button
                        type="button"
                        className="btn btn-dark m-t-15 m-l-30"
                        onClick={() => this.props.handleAddBus(true)}
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary m-t-15 m-l-30"
                        onClick={() => this.props.handleAddBus()}
                    >
                        Save
                    </button>
                </div>
            </div>
        )
    }
}

export default NewBusTemplate;