import React, { Component } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import "../styles/table.css";
import "../styles/responsive.css";
import "../styles/forum.css";
import "../styles/classnote.css";
import Select from "react-select";
import LoadingPage from "./LoadingPage";
import { Avatar } from "antd";
import {
  HeartOutlined,
  HeartFilled,
  CommentOutlined,
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  DownOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import likeoutlined from "../images/like.svg";
import likefilled from "../images/likefilled.svg";
import commentoutline from "../images/cmnt.svg";
import TextField from "@material-ui/core/TextField";
class Forum extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboarddetails: '',
      forumDetails: [],
      commentsList: [],
      pageNumer: 1,
      data: "",
      role: "",
      roleName: "",
      heartLikes: "fa fa-heart-o",
      likeButton: "",
      commentsLoaded: false,
    };
    this.baseState = this.state;
  }

  userId = undefined;
  roleD = undefined;
  dashboardName = '';
  searchList() {
    return this.state.forumDetails.map((item) => {
      return {
        value: item.fullName,
        label: item.fullName,
      };
    });
  }

  inputData(item) {
    this.setState({ data: item.value });
  }

  componentDidMount() {
    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.userID
    ) {
      const userID = this.props.location.state;
      console.log(userID)
      this.setState({
        location: userID,
        userID: userID.userID,
        userRole: userID.userRole,
        loggedInUser: userID.userDetails,
        routerLink: this.props.location.pathname,
      });
      this.dashboardName = userID.userRole;
      console.log(userID.userRole);

      this.userId = userID.userDetails.userID;
      this.roleD = userID.userDetails.userRole;

      this.fetchWallPostDetails();
    }
    this.unlisten = this.props.history.listen((location, action) => {
      this.setState(this.baseState);
      this.fetchWallPostDetails();
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  fetchUserDetails() {
    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.userID
    ) {
      const userID = this.props.location.state;
      if (userID.userRole === "student") {
        axios
          .get(`${process.env.PUBLIC_URL}/xakal/studentdetail/${userID.userID}`, {
            withCredentials: true,
          })
          .then((response) => {
            // console.log("response check", response.data.id );
            console.log("response check", response.data.user.userID);
            // this.setState(studentId = response.data.user.userID)
            // this.setState({studentData : response.data.user.userID})
            this.setState({ userDetails: response.data.user.userID });
          });
      } else {
        axios.get(`${process.env.PUBLIC_URL}/xakal/staffdetail/${userID.userID}`).then((response) => {
          if (response.data && response.data.ok) {
            this.setState({ userDetails: response.data });
          }
        });
      }
    }
  }

  fetchWallPostDetails() {
    this.fetchUserDetails();
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/forumdetail?page=${this.state.pageNumer}`)
      .then((response) => {
        setTimeout(this.setState({ commentsLoaded: true }), 300);

        this.setState({ forumDetails: response.data.eachone });
      });
  }

  /**
   * gets the comments based on the post selected
   */
  fetchComments(selectedPost) {
    axios.get(`${process.env.PUBLIC_URL}/xakal/comments/get/${selectedPost.postID}`).then((response) => {
      const sortedDetails = response.data.ok
        ? response.data.eachone.reverse()
        : [];
      this.setState({ commentsList: sortedDetails });
      return sortedDetails.length;
    });
  }

  /**
   * Triggers when the form is changed and stores the values in state
   * @param event form values
   */
  handleFormChange(event) {
    
      this.setState({ [event.target.name]: event.target.value });
      // console.log([event.target.name] + " : " + event.target.value);

  }

  handleCommentsChange(singleDetail, event) {
    if (event.target && event.target.value !== undefined) {
      const value = event.target.value;
      this.setState((prevState) => ({
        ...prevState,
        forumDetails: this.state.forumDetails.filter((element) => {
          if (element._id === singleDetail._id) {
            element.comments = value;
          }
          return element;
        }),
      }));
    }
  }

  handleClick = (singleDetail) => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/user/${singleDetail.userID.toUpperCase()}`)
      .then((response) => {
        if (response && response.data) {
          const isSameProfile =
            this.state.userID.toUpperCase() ===
            singleDetail.userID.toUpperCase();
          if (response.data.userRole === "student") {
            this.props.history.push("student-profile", {
              userID: singleDetail.userID.toUpperCase(),
              isSameProfile: isSameProfile,
              loginID: this.state.userID,
            });
          } else if (response.data.userRole === "staff") {
            this.props.history.push("staff-profile", {
              userID: singleDetail.userID.toUpperCase(),
              isSameProfile: isSameProfile,
              loginID: this.state.userID,
            });
          } else if (response.data.userRole === "management") {
            this.props.history.push("management-profile", {
              userID: singleDetail.userID.toUpperCase(),
              isSameProfile: isSameProfile,
              loginID: this.state.userID,
            });
          } else if (response.data.userRole === "hod") {
            this.props.history.push("hod-profile", {
              userID: singleDetail.userID.toUpperCase(),
              isSameProfile: isSameProfile,
              loginID: this.state.userID,
            });
          }
        }
      });
  };

  searchData(data) {
    if (
      this.state &&
      this.state.forumDetails &&
      this.state.forumDetails.length
    ) {
      return this.state.forumDetails.map((singleDetail, index) => {
        if (singleDetail.isVisible !== "isVisible") {
          singleDetail.isVisible = "isHidden";
        }
        if (
          this.state.location &&
          this.state.location.userID &&
          singleDetail.likedUsers &&
          singleDetail.likedUsers.length &&
          singleDetail.likedUsers.includes(
            this.state.location.userID.toUpperCase()
          )
        ) {
          singleDetail.isAlreadyLiked = "btn-danger";
        } else {
          singleDetail.isAlreadyLiked = "btn-default";
        }
        const valString = singleDetail.fullName || ""
        if (data === valString.toLowerCase()) {
          return (
            <div key={index} className="card" style={{ fontFamily: "VisbyCFThin", fontSize: "small", fontWeight: "bold" }}>
              <div className="card-header">
                <div className="p-2">
                  <img
                    src={require("../images/staffProfile.png")}
                    className="user-img-responsive"
                    height="30%"
                    width="30%"
                    alt=""
                  />
                  {/* <Avatar size={20} src={require("../images/staffProfile.png")} /> */}
                  <span
                    className="pt-2 ml-3 username mr-2"
                    style={{ marginLeft: "1rem", fontFamily: "VisbyCFMedium", fontSize: "larger", fontWeight: "bold" }}
                  >
                    <button
                      type="button"
                      onClick={this.handleClick.bind(this, singleDetail)}
                    >
                      {singleDetail.fullName}
                    </button>
                  </span>
                  <span
                    className="pt-2 ml-3 text-muted collagename"
                    style={{ marginLeft: "1rem", fontFamily: "VisbyCFThin", fontSize: "xx-small" }}
                  >
                    {singleDetail.collegeName} Role : {singleDetail.role}{" "}
                  </span>
                  <span
                    className="pt-2 ml-3 text-muted pass-year"
                    style={{ marginLeft: "1rem", fontFamily: "VisbyCFThin", fontSize: "xx-small" }}
                  >
                    {/* {moment(post.createdAt).fromNow()} */}
                    2020-2040
                  </span>
                </div>
              </div>
              <div>
                <hr className="line-hr" />
                <span className="pt-2 ml-3 text-muted time-size" style={{ fontFamily: "VisbyCFThin", fontSize: "x-small" }}>
                  {/* <i className="fa fa-clock-o" aria-hidden="true"></i> */}
                  {/* {this.timeConverter(singleDetail.postedTime)} */}
                </span>
              </div>

              {/* break point 1 */}
              <div className="card-body">
                <div classNmame="post-caption p-5" style={{ fontFamily: "VisbyCFMedium", fontSize: "inherit", color: "black" }}>
                  {singleDetail.caption}
                </div>
              </div>
              <hr className="line-hr" />

              <div className="group-line-button">
                <div className="d-flex pt-2">
                  {singleDetail.isAlreadyLiked === "btn-danger" ? (
                    <img
                      src={likefilled}
                      className="text-danger pb-2 h5  pr-3 ml-2"
                      onClick={this.updateLikes.bind(this, singleDetail)}
                    />
                  ) : (
                    <img
                      src={likeoutlined}
                      className="text-danger pb-2 h5  pr-3 ml-2"
                      onClick={this.updateLikes.bind(this, singleDetail)}
                    />
                  )}

                  <div className="pt-2 pl-1 pr-2">
                    {singleDetail.likedUsers.length}
                  </div>
                  <img
                    src={commentoutline}
                    onClick={this.visibleReplySection.bind(
                      this,
                      singleDetail
                    )}
                    className="text-danger pb-2 h5 pl-1"
                  />
                  <div className="pt-2 pl-1 ml-3">
                    {singleDetail.all_comments.length}
                  </div>
                </div>
              </div>

              <div className="card-footer">
                <div class="p-2">
                  <img
                    src={require("../images/staffProfile.png")}
                    className="cmnt-img-responsive"
                    height="30%"
                    width="30%"
                    alt=""
                  />
                </div>
                <div class="p-2 comment-input">
                  <input
                    type="text"
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        this.insertComments(singleDetail);
                      }
                    }}
                    value={singleDetail.comments}
                    onChange={this.handleCommentsChange.bind(
                      this,
                      singleDetail
                    )}
                    name="comments"
                    className="cmnt-input"
                  />
                </div>
                <div>
                  <button
                    onClick={this.insertComments.bind(this, singleDetail)}
                    className="btn btn-sm  button-cmnt"
                  >
                    Commentm
                  </button>
                </div>

                <div hidden={singleDetail.isVisible === "isHidden"}>
                  {this.displayComments(singleDetail.all_comments)}
                </div>
              </div>

              <br />
            </div>
          );
        }
      });
    }
  }

  roleData(role) {
    if (
      this.state &&
      this.state.forumDetails &&
      this.state.forumDetails.length
    ) {
      return this.state.forumDetails.map((singleDetail, index) => {
        if (singleDetail.isVisible !== "isVisible") {
          singleDetail.isVisible = "isHidden";
        }
        if (this.state.location && this.state.location.userID) {
          singleDetail.isAlreadyLiked = "btn-danger";
        } else {
          singleDetail.isAlreadyLiked = "btn-default";
        }
        if (
          role === singleDetail.role &&
          singleDetail.collegeName === "St. Joseph's College Of Engineering"
        ) {
          return (
            <div key={index} className="card" style={{ fontFamily: "VisbyCFThin", fontSize: "small", fontWeight: "bold" }}>
              <div className="card-header">
                <div className="p-2">
                  <img
                    src={require("../images/staffProfile.png")}
                    className="user-img-responsive"
                    height="30%"
                    width="30%"
                    alt=""
                  />
                  {/* <Avatar size={20} src={require("../images/staffProfile.png")} /> */}
                  <span
                    className="pt-2 ml-3 username mr-2"
                    style={{ marginLeft: "1rem", fontFamily: "VisbyCFMedium", fontSize: "larger", fontWeight: "bold" }}
                  >
                    <button
                      type="button"
                      onClick={this.handleClick.bind(this, singleDetail)}
                    >
                      {singleDetail.fullName}
                    </button>
                  </span>
                  <span
                    className="pt-2 ml-3 text-muted collagename"
                    style={{ marginLeft: "1rem", fontFamily: "VisbyCFThin", fontSize: "xx-small" }}
                  >
                    {singleDetail.collegeName} Role : {singleDetail.role}{" "}
                  </span>
                  <span
                    className="pt-2 ml-3 text-muted pass-year"
                    style={{ marginLeft: "1rem", fontFamily: "VisbyCFThin", fontSize: "xx-small" }}
                  >
                    {/* {moment(post.createdAt).fromNow()} */}
                    2020-2040
                  </span>
                </div>
              </div>
              <div>
                <hr className="line-hr" />
                <span className="pt-2 ml-3 text-muted time-size" style={{ fontFamily: "VisbyCFThin", fontSize: "x-small" }}>
                  {/* <i className="fa fa-clock-o" aria-hidden="true"></i> */}
                  {/* {this.timeConverter(singleDetail.postedTime)} */}
                </span>
              </div>

              {/* break point 1 */}
              <div className="card-body">
                <div classNmame="post-caption p-5" style={{ fontFamily: "VisbyCFMedium", fontSize: "inherit", color: "black" }}>
                  {singleDetail.caption}
                </div>
              </div>
              <hr className="line-hr" />

              <div className="group-line-button">
                <div className="d-flex pt-2">
                  {singleDetail.isAlreadyLiked === "btn-danger" ? (
                    <img
                      src={likefilled}
                      className="text-danger pb-2 h5  pr-3 ml-2"
                      onClick={this.updateLikes.bind(this, singleDetail)}
                    />
                  ) : (
                    <img
                      src={likeoutlined}
                      className="text-danger pb-2 h5  pr-3 ml-2"
                      onClick={this.updateLikes.bind(this, singleDetail)}
                    />
                  )}

                  <div className="pt-2 pl-1 pr-2">
                    {singleDetail.likedUsers.length}
                  </div>
                  <img
                    src={commentoutline}
                    onClick={this.visibleReplySection.bind(
                      this,
                      singleDetail
                    )}
                    className="text-danger pb-2 h5 pl-1"
                  />
                  <div className="pt-2 pl-1 ml-3">
                    {singleDetail.all_comments.length}
                  </div>
                </div>
              </div>

              <div className="card-footer">
                <div class="p-2">
                  <img
                    src={require("../images/staffProfile.png")}
                    className="cmnt-img-responsive"
                    height="30%"
                    width="30%"
                    alt=""
                  />
                </div>
                <div class="p-2">
                  <input
                    type="text"
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        this.insertComments(singleDetail);
                      }
                    }}
                    value={singleDetail.comments}
                    onChange={this.handleCommentsChange.bind(
                      this,
                      singleDetail
                    )}
                    name="comments"
                    className="cmnt-input"
                  />
                </div>
                <div>
                  <button
                    onClick={this.insertComments.bind(this, singleDetail)}
                    className="btn btn-sm  button-cmnt"
                  >
                    Comment
                  </button>
                </div>

                <div hidden={singleDetail.isVisible === "isHidden"}>
                  {this.displayComments(singleDetail.all_comments)}
                </div>
              </div>

              <br />
            </div>
          );
        }
      });
    }
  }

  displayPosts() {
    console.log(this.state.forumDetails);
    if (
      this.state &&
      this.state.forumDetails &&
      this.state.forumDetails.length &&
      this.state.commentsLoaded
    ) {
      return (
        <InfiniteScroll
          dataLength={this.state.forumDetails.length}
          next={this.state.forumDetails.length + 1}
          hasMore={true}
          style={{ height: "0px", overflow: "none", marginTop: '30px' }}
        >
          {this.state.forumDetails.map((singleDetail, index) => {
            if (singleDetail.isVisible !== "isVisible") {
              singleDetail.isVisible = "isHidden";
            }

            if (
              this.state.location &&
              this.state.location.userID &&
              singleDetail.likedUsers &&
              singleDetail.likedUsers.length &&
              singleDetail.likedUsers.includes(
                this.state.location.userID.toUpperCase()
              )
            ) {
              singleDetail.isAlreadyLiked = "btn-danger";
            } else {
              singleDetail.isAlreadyLiked = "btn-default";
            }
            if (
              singleDetail.collegeName === "St. Joseph's College Of Engineering"
            ) {
              return (
                <div key={index} className="card" style={{ height: "auto", fontFamily: "VisbyCFThin", fontSize: "small", fontWeight: "bold" }}>
                  <div className="card-header">
                    <div className="p-2">
                      <img
                        src={require("../images/staffProfile.png")}
                        className="user-img-responsive"
                        height="30%"
                        width="30%"
                        alt=""
                      />
                      {/* <Avatar size={20} src={require("../images/staffProfile.png")} /> */}
                      <span
                        className="pt-2 ml-3 username mr-2"
                        style={{ marginLeft: "1rem", fontFamily: "VisbyCFMedium", fontSize: "larger", fontWeight: "bold" }}
                      >
                        <button
                          type="button"
                          onClick={this.handleClick.bind(this, singleDetail)}
                        >
                          {singleDetail.fullName}
                        </button>
                      </span>
                      <span
                        className="pt-2 ml-3 text-muted collagename"
                        style={{ marginLeft: "1rem", fontFamily: "VisbyCFThin", fontSize: "xx-small" }}
                      >
                        {singleDetail.collegeName} Role : {singleDetail.role}{" "}
                      </span>
                      <span
                        className="pt-2 ml-3 text-muted pass-year"
                        style={{ marginLeft: "1rem", fontFamily: "VisbyCFThin", fontSize: "xx-small" }}
                      >
                        {/* {moment(post.createdAt).fromNow()} */}
                        2020-2040
                      </span>
                    </div>
                  </div>
                  <div>
                    <hr className="line-hr" />
                    <span className="pt-2 ml-3 text-muted time-size" style={{ fontFamily: "VisbyCFThin", fontSize: "x-small" }}>
                      {/* <i className="fa fa-clock-o" aria-hidden="true"></i> */}
                      {/* {this.timeConverter(singleDetail.postedTime)} */}
                    </span>
                  </div>

                  {/* break point 1 */}
                  <div className="card-body">
                    <div classNmame="post-caption p-5" style={{ fontFamily: "VisbyCFMedium", fontSize: "inherit", color: "black" }}>
                      {singleDetail.caption}
                    </div>
                  </div>
                  <hr className="line-hr" />

                  <div className="group-line-button">
                    <div className="d-flex pt-2">
                      {singleDetail.isAlreadyLiked === "btn-danger" ? (
                        <img
                          src={likefilled}
                          className="text-danger pb-2 h5  pr-3 ml-2"
                          onClick={this.updateLikes.bind(this, singleDetail)}
                        />
                      ) : (
                        <img
                          src={likeoutlined}
                          className="text-danger pb-2 h5  pr-3 ml-2"
                          onClick={this.updateLikes.bind(this, singleDetail)}
                        />
                      )}

                      <div className="pt-2 pl-1 pr-2">
                        {singleDetail.likedUsers.length}
                      </div>
                      <img
                        src={commentoutline}
                        onClick={this.visibleReplySection.bind(
                          this,
                          singleDetail
                        )}
                        className="text-danger pb-2 h5 pl-1"
                      />
                      <div className="pt-2 pl-1 ml-3">
                        {singleDetail.all_comments.length}
                      </div>
                    </div>
                  </div>

                  <div className="card-footer">
                    <div class="p-2">
                      <img
                        src={require("../images/staffProfile.png")}
                        className="cmnt-img-responsive"
                        height="30%"
                        width="30%"
                        alt=""
                      />
                    </div>
                    <div class="p-2">
                      <input
                        type="text"
                        onKeyDown={(event) => {
                          if (event.key === "Enter") {
                            this.insertComments(singleDetail);
                          }
                        }}
                        value={singleDetail.comments}
                        onChange={this.handleCommentsChange.bind(
                          this,
                          singleDetail
                        )}
                        name="comments"
                        className="cmnt-input"
                      />
                    </div>
                    <div>
                      <button
                        onClick={this.insertComments.bind(this, singleDetail)}
                        className="btn btn-sm  button-cmnt"
                      >
                        Comment
                      </button>
                    </div>

                    <div hidden={singleDetail.isVisible === "isHidden"}>
                      {this.displayComments(singleDetail.all_comments)}
                    </div>
                  </div>

                  <br />


                </div>

                // <div class="white-box"></div>


              );
            }
          })}
        </InfiniteScroll>
      );
    }
  }

  displayComments(all_comments) {
    if (all_comments && all_comments.length) {
      return all_comments.map((singleDetail, index) => {
        return (
          <div key={index} className="p-t-10 m-l-5 ">
            <div className="col-md-2 block bottom">
              <img
                src={require("../images/staffProfile.png")}
                className="img-responsive"
                style={{ height: '70px', width: '60px' }}

                alt=""
              />
            </div>
            <div className="col-md-7 block p-l-1">
              <p className="block name">{singleDetail.comments}</p>
            </div>
            <div className="col-md-3 block">
              <p className="block text-xs">
                <img
                  style={{ height: '90px', width: '60px' }}
                  src={likefilled}
                  className="text-danger pb-2 h5  pr-3 ml-2"
                />
              </p>
            </div>
          </div>
        );
      });
    }
  }

  /**
   * Triggers when the form is submitted
   * Checks whether the values are entered properly
   */

  SettingRole(event) {
    this.setState({ roleName: event.target.value });
  }
  changeRole() {
    this.setState({ role: this.state.roleName });
  }

  formSubmit() {
    let isUpdated = false;
    this.changeRole();
    if (this.state.caption) {
      const params = {
        userID: this.state.userID.toUpperCase(),
        collegeName: this.state.loggedInUser.collegeName,
        // fullName: this.state.userDetails.name,
        likes: 0,
        postedTime: Math.floor(Date.now() / 1000),
        caption: this.state.caption,
        role: this.state.roleName,
        postID: `${this.state.userID.toUpperCase()}-${this.randomId()}`,
      };
      axios
        .post(`${process.env.PUBLIC_URL}/xakal/forumdetail`, params)
        .then(() => {
          if (!isUpdated) {
            alert("Updated Successfully");
            this.setState({ caption: "" });
            this.fetchWallPostDetails();
          }
          isUpdated = true;
        })
        .catch((err) => console.log(err));
    } else {
      alert("Please give the captions");
    }
  }

  // Generate random Id for images
  randomId() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  visibleReplySection(singleDetail) {
    this.fetchComments(singleDetail);

    this.setState((prevState) => ({
      ...prevState,
      forumDetails: this.state.forumDetails.filter((element) => {
        if (element._id === singleDetail._id) {
          if (element.isVisible === "isVisible") {
            element.isVisible = "inVisible";
          } else {
            element.isVisible = "isVisible";
          }
        }
        return element;
      }),
    }));
  }

  /**
   * Updates the likes for selected photo
   * @param singleDetail likes photo reference
   */
  updateLikes(singleDetail) {
    let params = {};
    let currUserid = this.state.userID.toUpperCase();

    if (singleDetail.isAlreadyLiked === "btn-default") {
      // true
      console.log("Liked");
      if (!singleDetail["likedUsers"]) {
        let temp = [currUserid];
        singleDetail["likedUsers"] = temp;
      } else {
        singleDetail.likedUsers.push(currUserid);
      }

      this.setState({ heartLikes: "fa fa-heart" });

      params = {
        likes: singleDetail.likedUsers.length + 1,
        likedUsers: singleDetail.likedUsers,
      };
      this.setState((prevState) => ({
        ...prevState,
        forumDetails: this.state.forumDetails.filter((element) => {
          if (element._id === singleDetail._id) {
            element.likes = element.likes + 1;
            element.isAlreadyLiked = "btn-danger";
            element.likedUsers = singleDetail.likedUsers;
          }
          return element;
        }),
      }));
    } else if (singleDetail.isAlreadyLiked === "btn-danger") {
      // false

      if (singleDetail.likedUsers.includes(currUserid)) {
        console.log("Un-Liked");

        this.setState({ heartLikes: "fa fa-heart-o" });

        singleDetail.likedUsers = singleDetail.likedUsers.filter(
          (e) => e !== currUserid
        );

        params = {
          likes: singleDetail.likes - 1,
          likedUsers: singleDetail.likedUsers,
        };

        this.setState((prevState) => ({
          ...prevState,
          forumDetails: this.state.forumDetails.filter((element) => {
            if (element._id === singleDetail._id) {
              element.likes = element.likes - 1;
              element.isAlreadyLiked = "btn-default";
            }
            return element;
          }),
        }));
      }
    }
    // params = {
    //   likes: 0,
    //   likedUsers: [],
    // };
    axios
      .put(`${process.env.PUBLIC_URL}/xakal/forumdetail/updatelikes/${singleDetail._id}`, params, {
        withCredentials: true,
      })
      .then(() => { })
      .catch((err) => console.log(err));
  }

  insertComments = (singleDetail) => {
    if (!singleDetail.comments) return;
    let params;
    params = {
      postID: singleDetail.postID,
      userID: this.state.userID.toUpperCase(),
      comments: singleDetail.comments,
      postedTime: Math.floor(Date.now() / 1000),
    };
    console.log(params);
    const commentParams = {
      commentsCount: singleDetail.all_comments.length + 1,
    };
    axios
      .post(`${process.env.PUBLIC_URL}/xakal/comments`, params)
      .then(() => {
        axios
          .put(
            `${process.env.PUBLIC_URL}/xakal/forumdetail/updatecomments/${singleDetail._id}`,
            commentParams
          )
          .then(() => {
            this.setState((prevState) => ({
              ...prevState,
              forumDetails: this.state.forumDetails.filter((element) => {
                if (element._id === singleDetail._id) {
                  element.comments_length = element.comments_length + 1;
                  element.all_comments.unshift(params);
                }
                return element;
              }),
            }));
          })
          .catch((err) => console.log(err));
        this.setState((prevState) => ({
          ...prevState,
          forumDetails: this.state.forumDetails.filter((element) => {
            if (element._id === singleDetail._id) {
              element.comments = "";
              this.fetchComments(element);
            }
            return element;
          }),
        }));
      })
      .catch((err) => console.log(err));
  };

  /**
   * Converts timestamp to readable format
   * @param timestamp response from API
   */
  timeConverter(timestamp) {
    let a = new Date(timestamp * 1000);
    let seconds = Math.floor((new Date() - a) / 1000);

    let interval = Math.floor(seconds / 31536000);
    if (interval > 1) {
      return interval + " year" + this.pluralCheck(interval);
    }
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
      return interval + " month" + this.pluralCheck(interval);
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
      return interval + " day" + this.pluralCheck(interval);
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
      return interval + " hour" + this.pluralCheck(interval);
    }
    interval = Math.floor(seconds / 60);
    if (interval > 1) {
      return interval + " minute" + this.pluralCheck(interval);
    }
    return Math.floor(seconds) + " second" + this.pluralCheck(seconds);
  }

  /**
   * Checks for plural
   */
  pluralCheck(interval) {
    if (interval === 1) {
      return " ago";
    } else {
      return "s ago";
    }
  }

  todoList() {
    if (this.state.data.length > 0) {
      return this.state.forumDetails.map((value) => {
        const varStr = value.fullName || ""
        if (this.state.data === varStr.toLowerCase()) {
          return (
            <div className="row">
              <div className="col-md-12">
                <ul className="activity-list">
                  {this.searchData(this.state.data)}
                </ul>
              </div>
            </div>
          );
        }
      });
    } else {
      return (
        <div className="row">
          <div className="col-md-12">
            <ul className="activity-list">{this.displayPosts()}</ul>
          </div>
        </div>
      );
    }
  }

  render() {
    return (
      <>
        {!this.state.commentsLoaded ? <LoadingPage /> : <></>}
        <div className="page-content-wrapper ">
          {/* <div className="page-content"> */}
          {/* <div className="row"> */}
          {/* <div className="col-md-12"> */}
          <div className="profile-content">
            {/* <div className="row"> */}
            {/* <div className="col-md-7"> */}
            <div className="col-md-12">
              <div className="topnav d-flex flex-row mb-3">
                <div className="p-2 textwhite-title font-face-white-title">
                  <header>White Board</header>
                </div>
                <div className="searchbox">
                  <Select
                    value={this.state.data}
                    options={this.searchList()}
                    onChange={this.inputData.bind(this)}
                    placeholder="search..."
                    openMenuOnClick={false}
                  />
                </div>
                <div className="rightnav">
                  <div className="p-2 img-profile">
                    <img
                      src={require("../images/staffProfile.png")}
                      className="img-profile"
                      // height="15%"
                      // width="35%"
                      alt=""
                    />
                  </div>
                  <div className="p-2 user-pic">
                    <span className="active-user">{
                      this.state.userRole

                    } Dashboard</span><br />
                    <span className="active-user1">{
                      (this.userId)

                    }</span>
                  </div>
                </div>
              </div>
              <div className='postcontainer '  >
                <div className="leftPostContainer">
                  <div className="imgContainer">
                    <img
                      src={require("../images/staffProfile.png")}
                      className="img-profile-post "
                      // height="15%"
                      // width="35%"
                      alt=""
                    />
                  </div>



                  <form className='form-container-control'>
                    <input
                      className="form-control input-post p-text-are"
                      value={this.state.caption}
                      onChange={this.handleFormChange.bind(this)}
                      name="caption"
                      placeholder="Scribble anything in your mind..."
                      style={{ fontFamily: "VisbyCFThin", fontSize: "small", fontWeight: "bold", marginLeft: '10%' }}
                    ></input>
                    <EyeOutlined className="eye-outline" />
                    <DownOutlined className="down-outline" />
                    <button
                      type="button"
                      onClick={this.formSubmit.bind(this)}
                      className="btn btn-post pull-right"
                    >
                      Post
                    </button>
                  </form>


                </div>

              </div>
              </div>
              <div className="">
                <div className="row shadow">
                  <div className="col-md-12">
                    <div className="">
                      {/* <img className="image" src="https://img.freepik.com/free-vector/abstract-website-banner-with-modern-shapes_1361-1738.jpg?size=626&ext=jpg" alt=""/> */}
                    </div>
                  </div>
                </div>
                <div className="row radioButton">
                  <div className="col-md-12 mb-5">
                    <footer className="panel-footer shadow" style={{ fontFamily: "VisbyCF-Heavy", fontSize: "small", fontWeight: "bold", padding: "1.5%" }}>
                      <div className="form-check-inline" >
                        <input
                          type="radio"
                          className="form-check-input"
                          id="radio1"
                          name="role"
                          value="global"
                          // checked
                        />
                        Global
                        {/* onClick={this.SettingRole.bind(this)} */}
                      </div>

                      <div className="form-check-inline">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="radio2"
                          name="role"
                          value="class-room"
                        />
                        Class Room
                        {/* onClick={this.SettingRole.bind(this)} */}

                      </div>

                      <div className="form-check-inline">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="radio3"
                          name="role"
                          value="department"
                        />
                        Department
                        {/* onClick={this.SettingRole.bind(this)} */}
                      </div>

                      <div className="form-check-inline">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="radio4"
                          name="role"
                          value="college"
                        />
                        College
                        {/* onClick={this.SettingRole.bind(this)} */}
                      </div>


                    </footer>
                  </div>
                </div>


              </div>




              <div className="today-div" >
                <p className="today" style={{ marginTop: '10%', fontFamily: "VisbyCF-Heavy", fontSize: "small", fontWeight: "bold" }}>T O D A Y</p>
              </div>
              <div className="to-do">{this.todoList()}</div>
            {/* </div> */}
          </div>
          {/* //{" "} */}
        </div>
      </>
    );
  }
}

export default Forum;
