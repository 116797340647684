import React, { Component } from "react";
import axios from "axios";
import "../../styles/classnote.css";

class AddCourseDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userID: "",
      studentName: "",
      departmentName: "",
    };
    this.baseState = this.state;
  }

  onDropDownFocus(event) {
    if (event.target) {
      // event.target.parentNode.classList.add("is-focused");
      // event.target.nextSibling.classList.add("is-shown");
    }
  }

  onFocusOut(event) {
    if (event.target) {
      if (event.target.value === "") {
        // event.target.parentNode.classList.remove("is-focused");
      }
      // event.target.nextSibling.classList.remove("is-shown");
    }
  }

  /**
   * Triggers when the form is changed and stores the values in state
   * @param event form values
   */
  handleFormChange(event) {
    if (event.target.value) {
      this.setState({ [event.target.name]: event.target.value });
    }
  }

  /**
   * Triggers when the form is submitted
   * Checks whether the values are entered properly
   */
  formSubmit() {
    let isUpdated = false;
    if (
      this.state.courseName &&
      this.state.duration &&
      this.state.startingYear &&
      this.state.studentCapacity
    ) {
      const params = {
        name: this.state.courseName,
        duration: this.state.duration,
        updatedBy: this.state.userID.toUpperCase(),
        updatedDate: new Date(Date.now()).toLocaleString(),
        startingYear: this.state.startingYear,
        studentCapacity: this.state.studentCapacity,
      };
      axios
        .post(`${process.env.PUBLIC_URL}/xakal/degreecoursedetail`, params)
        .then(() => {
          if (!isUpdated) {
            alert("Updated Successfully");
          }
          this.setState({
            name: "",
            duration: "",
            startingYear: "",
            studentCapacity: "",
          });
          isUpdated = true;
        })
        .catch((err) => console.log(err));
    } else {
      alert("Please give all the details");
    }
  }
  resetform(){
    this.setState({
      name: "",
      duration: "",
      startingYear: "",
      studentCapacity: "",
    });
  }
  render() {
    return (
      <div className="container-fluid background bk">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <nav
            className="navbar navbar-expand-lg  navprac bar"
            style={{ marginTop: "-7%" }}
          >
            <div className="title">Courses</div>
            <div className="collapse navbar-collapse">
              <form className="form-inline my-2 my-lg-0">
                <input
                  style={{
                    width: "500px",
                    marginLeft: "120px",
                    backgroundColor: "rgba(54, 42, 65, 0.04)",
                    fontStyle: "italic",
                    height: "45px",
                  }}
                  className="form-control mr-sm-2 "
                  type="search"
                  placeholder="Search....."
                  aria-label="Search"
                ></input>
              </form>
            </div>
          </nav>
          <div className="row">
            <h4
              className="head resp course-max"
              style={{ marginLeft: "-800px", marginTop: "150px" }}
            >
              {" "}
              Add Course
            </h4>

            {/* <h1 className="h3 mb-0 text-gray-800 m-t-20">Notes</h1> */}
          </div>
          {/* <h1 className="h3 mb-0 text-gray-800 m-t-20 add-course">Add Course</h1> */}
        </div>
        <div className="row m-r-20">
          <div className="col-sm-12">
            <div className="background">
              <div className="card-body row">
                <div className="col-lg-4 p-t-20">
                  <div
                    className={
                      "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                    }
                  >
                    <label className="input-text labell">Name</label>
                    <input
                      autoComplete="off"
                      onBlur={this.onFocusOut.bind(this)}
                      onFocus={this.onDropDownFocus.bind(this)}
                      value={this.state.name}
                      className="new-xakal-input background height"
                      type="text"
                      id="sample2"
                      onChange={this.handleFormChange.bind(this)}
                      name="courseName"
                    />
                  </div>
                </div>
                <div className="col-lg-4 p-t-20">
                  <div
                    className={
                      "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                    }
                  >
                    <label className="input-text labell">
                      Duration (in years)
                    </label>
                    <input
                      autoComplete="off"
                      onBlur={this.onFocusOut.bind(this)}
                      onFocus={this.onDropDownFocus.bind(this)}
                      className="new-xakal-input background height"
                      type="number"
                      id="sample2"
                      value={this.state.duration}
                      onChange={this.handleFormChange.bind(this)}
                      name="duration"
                    />
                  </div>
                </div>
              </div>
              <div className="card-body row">
                <div className="col-lg-4 p-t-20">
                  <div
                    className={
                      "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                    }
                  >
                    <label className="input-text labell">Starting year</label>
                    <input
                      autoComplete="off"
                      onBlur={this.onFocusOut.bind(this)}
                      onFocus={this.onDropDownFocus.bind(this)}
                      className="new-xakal-input background height"
                      type="number"
                      id="sample2"
                      value={this.state.startingYear}
                      onChange={this.handleFormChange.bind(this)}
                      name="startingYear"
                    />
                  </div>
                </div>
                <div className="col-lg-4 p-t-20">
                  <div
                    className={
                      "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                    }
                  >
                    <label className="input-text labell ">
                      Student capacity
                    </label>
                    <input
                      autoComplete="off"
                      onBlur={this.onFocusOut.bind(this)}
                      onFocus={this.onDropDownFocus.bind(this)}
                      className="new-xakal-input background height "
                      type="number"
                      value={this.state.studentCapacity}
                      id="sample2"
                      onChange={this.handleFormChange.bind(this)}
                      name="studentCapacity"
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-8 p-t-20 m-l-22">
                <button
                  type="button"
                  onClick={this.formSubmit.bind(this)}
                  className=" ressp m-t-15  save "
                >
                  Save
                </button>
                <button type="button" className="ressp m-t-15  cancel " onClick={this.resetform.bind(this)}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddCourseDetails;
