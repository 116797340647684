import React from 'react';
import "../styles/login.css";
import { Link } from "react-router-dom";

function Modal (onClick) {
    
    return(
        <div className='success'>
            <div>
            <div className="content2">
            <p >Changing the password will logout your account from all other devices.</p>
            <p>Are you sure you want to proceed?</p>
            <div style={{display:"flex",justifyContent:"center"}}>
            <Link to="/students-portal/dashboard"><button className="modal-btn">YES</button></Link>
            <Link to="/changepassword"><button className="modal-btn2" onClick>NO</button></Link>
            </div>
        </div>
        </div>
        </div>
    )
}

export default Modal