import React, { Component } from "react";
import "../styles/navbar.css";
import { BrowserRouter as Router } from "react-router-dom";
import "../styles/table.css";
import "../minified-css/material-min.css";
import "../styles/dropdowns.css";
import "../styles/theme-style.css";
import axios from "axios";
import moment from "moment";

class XakalAdminPortal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showClassNotes: false,
      assessments: false,
      routerLink: "",
      degreeDetails: [],
      selectedRole: "nothing",
      departmentDetails: [],
      userID: null,
      enabledAdd: false,
      values: [
        {
          qualifications: "",
          departmentName: "",
          desgination: "",
          userID: "",
          password: "",
          collegeCode: "",
          collegeName: "",
          selectedDepartment: "",
          degreeName: "",
          joiningDate: moment(new Date()).format("YYYY-MM-DD"),
          userName: "",
          admissionDate: moment(new Date()).format("YYYY-MM-DD"),
          admissionYear: 2020,
          email: "",
          contact: "",
          emergencyContact: "",
          parentName: "",
          address: "",
          gender: "",
          dob: moment(new Date()).format("YYYY-MM-DD"),
          motherTongue: "",
          bloodGroup: "",
        },
      ],
    };

    this.baseState = this.state;
  }
  fetchPaperDetails() {
    this.setState({ searchAllowed: true });
    this.deleteArray = [];
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/degreecoursedetail`, {
        withCredentials: true,
      })
      .then((response) => {
        this.setState({ degreeDetails: response.data });
      });
  }

  logout() {
    axios
      .post(`${process.env.PUBLIC_URL}/xakal/logout`)
      .then(() => (window.location.href = "/"))
      .catch((e) => (window.location.href = "/"));
  }

  generatePassword() {
    let length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (let i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  loggedIn() {
    const isLoggedin = (name) =>
      `; ${document.cookie}`.split(`; ${name}=`).pop().split(";").shift();

    return isLoggedin("userid");
  }

  componentDidMount() {
    let values = [...this.state.values];
    values.forEach((e) => {
      e.password = this.generatePassword();
    });
    this.setState({ values });

    if (
      this.props.location &&
      this.props.location.state &&
      !this.props.location.state.userID &&
      this.isLoggedin()
    ) {
      alert("Please login again! Session expired!");
      this.logout();
    } else {
      this.fetchPaperDetails();
      this.fetchDepartmentDetails();
      if (
        this.props &&
        this.props.state &&
        this.props.state.location &&
        this.props.state.location.state
      ) {
        if (this.props.state.location.state.userRole === "student") {
          this.setState({ routerLink: "/students-portal" });
        } else if (this.props.state.location.state.userRole === "staff") {
          this.setState({ routerLink: "/staff-portal" });
        } else if (
          this.props.state.location.state.userRole === "non-teaching"
        ) {
          this.setState({ routerLink: "/non-teaching-portal" });
        }
      }
    }
  }

  componentWillUnmount() {
    // this.unlisten();
  }

  /**
   * Triggers when semester dropdown is focused
   */
  onSemesterDropDownFocus(i) {
    this.setState({
      isFocussed: "is-focused",
      selectedIndex: i,
      onFocus: true,
      background: "is-shown",
    });
    this.handleDepartmentFocus();
  }

  /**
   * on role select
   */
  onRoleSelect(e) {
    this.resetForm();
    this.setState({ selectedRole: e.target.value });

    let values = [...this.state.values];
    values.forEach((e) => {
      e.password = this.generatePassword();
    });
    this.setState({ values });

    if (!this.state.collegeCode) return;
    this.fillUserID();
  }

  /**
   * Resets the department focus based on the value selected
   */
  handleDepartmentFocus() {
    if (this.state.hasDepartmentValue === true) {
      this.setState({
        isDepartmentFocussed: "is-focused",
        onDepartmentFocus: false,
        backgroundDepartment: "is-hidden",
      });
    } else {
      this.setState({
        onDepartmentFocus: false,
        backgroundDepartment: "is-hidden",
      });
    }
  }

  /**
   * Triggers when name dropdown is focused
   */
  onDropDownFocus(event) {
    if (event.target) {
      event.target.parentNode.classList.add("is-focused");
      event.target.nextSibling.classList.add("is-shown");
    }
    this.handleDepartmentFocus();
  }

  onFocusOut(event) {
    if (event.target) {
      if (event.target.value === "") {
        event.target.parentNode.classList.remove("is-focused");
      }
      event.target.nextSibling.classList.remove("is-shown");
    }
  }

  /**
   * Triggers when the form is changed and stores the values in state
   * @param event form values
   */
  handleFormChange(i, event) {
    if (event && event.target) {
      let values = [...this.state.values];
      const { name, value } = event.target;
      if (event.target.value !== "") {
        values[i][name] = value;
        this.setState({ values });
      } else {
        let values = [...this.state.values];
        const { name } = event.target;
        values[i][name] = "";
        this.setState({ values });
      }
      if (event.target.name === "admissionDate") {
        const admissionYear = new Date(event.target.value).getFullYear();
        values[i]["admissionYear"] = admissionYear;
        this.setState({ values });
      }
    }
  }

  /**
   * Adds the empty form element
   */
  addClick() {
    this.setState((prevState) => ({
      values: [
        ...prevState.values,
        {
          userID: "",
          password: this.generatePassword(),
          collegeCode: "",
          collegeName: "",
          selectedDepartment: "",
          degreeName: "",
          userName: "",
          admissionDate: moment(new Date()).format("YYYY-MM-DD"),
          admissionYear: 2020,
          email: "",
          contact: "",
          emergencyContact: "",
          parentName: "",
          address: "",
          gender: "",
          dob: moment(new Date()).format("YYYY-MM-DD"),
          motherTongue: "",
          bloodGroup: "",
        },
      ],
    }));

    setTimeout(() => {
      this.fillUserID();
      let values = [...this.state.values];
      values.forEach((e) => {
        e.password = this.generatePassword();
      });
      this.setState({ values });
    }, 1000);
  }

  fillUserID() {
    // if (this.state.selectedRole !== "student") return;

    axios.get(`${process.env.PUBLIC_URL}/xakal/getstudents/` + this.state.collegeCode).then((res) => {
      if (res.data.ok) {
      }
    });

    let values = this.state.values;
    values.forEach((e) => {
      let previous = values[values.indexOf(e) - 1];

      if (!previous) {
        e.userID = this.state.collegeCode + "100";
      } else {
        e.userID =
          this.state.collegeCode + (Number(previous.userID.slice(-3)) + 1);
      }

      this.setState({ values });
    });
  }

  /**
   * Removes the selected row
   * @param i selected row index
   */
  removeClick(i) {
    let values = [...this.state.values];
    values.splice(i, 1);
    this.setState({ values });

    setTimeout(() => {
      this.fillUserID();
    }, 400);
  }

  /**
   * Resets to base state
   */
  resetForm() {
    this.setState({
      values: [
        {
          qualifications: "",
          departmentName: "",
          desgination: "",
          userID: "",
          password: "",
          collegeCode: "",
          collegeName: "",
          selectedDepartment: "",
          degreeName: "",
          userName: "",
          admissionDate: moment(new Date()).format("YYYY-MM-DD"),
          admissionYear: 2020,
          email: "",
          contact: "",
          emergencyContact: "",
          parentName: "",
          address: "",
          gender: "",
          dob: moment(new Date()).format("YYYY-MM-DD"),
          motherTongue: "",
          bloodGroup: "",
        },
      ],
    });
  }

  /**
   * Triggers when the form is submitted
   * Checks whether the values are entered properly
   */
  formSubmit() {
    if (this.state.values && this.state.values.length > 0) {
      this.state.values.forEach((element) => {
        if (element.userID) {
          const params = {
            userID: element.userID.toUpperCase(),
            password: element.password,
            userRole: this.state.selectedRole,
            updatedBy: element.userID.toUpperCase(),
            updatedDate: new Date(Date.now()).toLocaleString(),
            collegeCode: this.state.collegeCode,
            collegeName: this.state.collegeName,
            registerNr: "",
          };
          axios
            .post(`${process.env.PUBLIC_URL}/xakal/adduser`, params)
            .then((res) => {
              if (res.data.ok) {
                switch (this.state.selectedRole) {
                  case "student":
                    this.insertStudentDetails();
                    break;

                  case "teacher":
                    this.insertStaffDetails();
                    break;

                  case "management":
                    this.insertManagementDetails();
                    break;

                  case "non-teaching":
                    this.insertNonStaffDetails();
                    break;

                  default:
                    alert("Select a role first");
                    break;
                }
              } else {
                alert(res.data.message);
                return;
              }
            })
            .catch((err) => console.log(err));
          return;
        } else {
          alert("Please give all the details");
          return;
        }
      });
    } else {
      alert("Please give atleast one record to proceed");
    }
  }

  insertManagementDetails() {
    let isUpdated = false;
    if (this.state.values && this.state.values.length > 0) {
      this.state.values.forEach((element) => {
        if (element.userName && element.qualifications) {
          const params = {
            name: element.userName,
            qualification: element.qualifications,
            uploadedBy: String(element.userID).toUpperCase(),
            updatedDate: new Date(Date.now()).toLocaleString(),
            desgination: element.desgination,
            userID: element.userID,
            email: element.email,
            departmentName: element.departmentName,
            bloodGroup: element.bloodGroup,
            contact: element.contact,
            emergencyContact: element.emergencyContact,
            parentName: element.parentName,
            joiningDate:
              element.joiningDate || moment(new Date()).format("YYYY-MM-DD"),
            gender: element.gender,
          };
          console.log(params);
          axios
            .post(`${process.env.PUBLIC_URL}/xakal/managementdetail`, params)
            .then(() => {
              if (!isUpdated) {
                alert("Updated Successfully");
              }
              isUpdated = true;
              this.setState(this.baseState);
            })
            .catch((err) => console.log(err));
          return;
        } else {
          alert("Please give all the details");
          return;
        }
      });
      this.resetForm();
    }
  }

  insertNonStaffDetails() {
    let isUpdated = false;
    if (this.state.values && this.state.values.length > 0) {
      this.state.values.forEach((element) => {
        if (element.userName && element.qualifications) {
          const params = {
            name: element.userName,
            qualification: element.qualifications,
            uploadedBy: String(element.userID).toUpperCase(),
            updatedDate: new Date(Date.now()).toLocaleString(),
            desgination: element.desgination,
            userID: element.userID,
            email: element.email,
            departmentName: element.departmentName,
            bloodGroup: element.bloodGroup,
            contact: element.contact,
            emergencyContact: element.emergencyContact,
            parentName: element.parentName,
            joiningDate:
              element.joiningDate || moment(new Date()).format("YYYY-MM-DD"),
            gender: element.gender,
          };
          axios
            .post(`${process.env.PUBLIC_URL}/xakal/nonteaching`, params)
            .then(() => {
              if (!isUpdated) {
                alert("Updated Successfully");
              }
              isUpdated = true;
              this.setState(this.baseState);
            })
            .catch((err) => console.log(err));
          return;
        } else {
          alert("Please give all the details");
          return;
        }
      });
      this.resetForm();
    }
  }

  insertStaffDetails() {
    let isUpdated = false;
    if (this.state.values && this.state.values.length > 0) {
      this.state.values.forEach((element) => {
        if (element.userName && element.qualifications) {
          const params = {
            name: element.userName,
            qualification: element.qualifications,
            uploadedBy: String(element.userID).toUpperCase(),
            updatedDate: new Date(Date.now()).toLocaleString(),
            desgination: element.desgination,
            userID: element.userID,
            email: element.email,
            bloodGroup: element.bloodGroup,
            contact: element.contact,
            emergencyContact: element.emergencyContact,
            parentName: element.parentName,
            joiningDate:
              element.joiningDate || moment(new Date()).format("YYYY-MM-DD"),
            gender: element.gender,
          };
          axios
            .post(`${process.env.PUBLIC_URL}/xakal/staffdetail`, params)
            .then(() => {
              if (!isUpdated) {
                alert("Updated Successfully");
              }
              isUpdated = true;
              this.setState(this.baseState);
            })
            .catch((err) => console.log(err));
          return;
        } else {
          alert("Please give all the details");
          return;
        }
      });
      this.resetForm();
    }
  }

  insertStudentDetails() {
    if (this.state.values && this.state.values.length > 0) {
      this.state.values.forEach((element) => {
        if (element.userName && element.admissionDate) {
          const params = {
            userID: String(element.userID).toUpperCase(),
            name: element.userName,
            course: element.degreeName,
            uploadedBy: String(this.state.userID).toUpperCase(),
            uploadedDate: new Date(Date.now()).toLocaleString(),
            branch: element.selectedDepartment,
            email: element.email,
            bloodGroup: element.bloodGroup,
            contact: element.contact,
            emergencyContact: element.emergencyContact,
            parentName: element.parentName,
            admissionDate: element.admissionDate,
            admissionYear: element.admissionYear,
            gender: element.gender,
            motherTongue: element.motherTongue,
            address: element.address,
            dob: element.dob,
          };
          axios
            .post(`${process.env.PUBLIC_URL}/xakal/studentdetail`, params)
            .then(() => {
              alert("Updated Successfully");
              this.setState(this.baseState);
            })
            .catch((err) => console.log(err));
        } else {
          alert("Please give all the details");
        }
      });
      this.resetForm();
    }
  }

  /**
   * Triggers when degree dropdown is focused
   */
  onDegreeDropDownFocus(i) {
    this.setState({
      isDegreeFocussed: "is-focused",
      selectedDegreeIndex: i,
      onDegreeFocus: true,
      backgroundDegree: "is-shown",
    });
  }

  /**
   * Triggers when blood group dropdown is focused
   */
  onBloodGroupDropDownFocus(i) {
    this.setState({
      isBloodFocussed: "is-focused",
      selectedBGIndex: i,
      onBloodFocus: true,
      backgroundBlood: "is-shown",
    });
  }

  onGenderDropDownFocus(i) {
    this.setState({
      isGenderFocussed: "is-focused",
      selectedGenderIndex: i,
      onGenderFocus: true,
      backgroundGender: "is-shown",
    });
  }

  displayDegree(i) {
    if (
      this.state &&
      this.state.degreeDetails &&
      this.state.degreeDetails.length
    ) {
      return this.state.degreeDetails.map((singleDegree, index) => {
        return (
          <li className="mdl-menu__item animation" key={index}>
            <button
              id={singleDegree.name}
              name={singleDegree.name}
              onClick={this.handleDegreeChange.bind(this, i)}
            >
              {singleDegree.name}
            </button>
          </li>
        );
      });
    }
  }

  /**
   * Triggers when the Degree is changed and stores the values in state
   * @param event form values
   */
  handleDegreeChange(i, event) {
    this.setState({
      degreeName: event.target.id,
      onDegreeFocus: false,
      backgroundDegree: "is-hidden",
      background: "is-hidden",
      hasDegreeValue: true,
    });
    let values = [...this.state.values];
    values[i]["degreeName"] = event.target.name;
    this.setState({ values });
  }

  /**
   * Triggers when the Degree is changed and stores the values in state
   * @param event form values
   */
  onBloodGroupSelect(i, event) {
    this.setState({
      bloodGroup: event.target.id,
      onBloodFocus: false,
      backgroundBlood: "is-hidden",
      background: "is-hidden",
      hasBloodValue: true,
    });
    let values = [...this.state.values];
    values[i]["bloodGroup"] = event.target.id;
    this.setState({ values });
  }

  onGenderGroupSelect(i, event) {
    this.setState({
      gender: event.target.id,
      onGenderFocus: false,
      backgroundGender: "is-hidden",
      backgroundBlood: "is-hidden",
      hasGenderValue: true,
    });
    let values = [...this.state.values];
    values[i]["gender"] = event.target.id;
    this.setState({ values });
  }

  /**
   * Fetches all department
   */
  fetchDepartmentDetails() {
    axios.get(`${process.env.PUBLIC_URL}/xakal/departmentdetail`).then((response) => {
      this.setState({ departmentDetails: response.data });
    });
  }

  /**
   * Displays the list of department based on the API response
   */
  displayDepartment(i) {
    if (
      this.state &&
      this.state.departmentDetails &&
      this.state.departmentDetails.length
    ) {
      return this.state.departmentDetails.map((singleDepartment, index) => {
        return (
          <li className="mdl-menu__item animation" key={index}>
            <button
              id={singleDepartment.name}
              name={singleDepartment.name}
              onClick={this.handleDepartmentChange.bind(this, i)}
            >
              {singleDepartment.name}
            </button>
          </li>
        );
      });
    }
  }

  /**
   * Triggers when the department is changed and stores the values in state
   * @param event form values
   */
  handleDepartmentChange(i, event) {
    this.setState({
      selectedDepartment: event.target.id,
      onDepartmentFocus: false,
      backgroundDepartment: "is-hidden",
      background: "is-hidden",
      hasDepartmentValue: true,
    });
    let values = [...this.state.values];
    values[i]["selectedDepartment"] = event.target.name;
    this.setState({ values });
  }

  /**
   * Triggers when department dropdown is focused
   */
  onDeptDropDownFocus(i) {
    this.setState({
      isDepartmentFocussed: "is-focused",
      selectedDepartmentIndex: i,
      onDepartmentFocus: true,
      backgroundDepartment: "is-shown",
    });
  }

  // TODO test this api

  uploadfile(e) {
    const files = e.target.files[0];
    const formData = new FormData();
    formData.append("uploadedFile", files);

    axios.post(`${process.env.PUBLIC_URL}/xakal/testfiles`, formData).then((e) => console.log(e.data));
  }

  collegeDetailsChange(event) {
    if (
      event.nativeEvent.inputType === "deleteContentBackward" &&
      event.target.value.length > 3
    ) {
      setTimeout(() => {
        this.fillUserID();
      }, 300);
    }

    if (event.target.name === "collegeCode" && event.target.value.length > 3) {
      this.setState({ enabledAdd: true });
      setTimeout(() => {
        this.fillUserID();
      }, 300);
    } else if (
      event.target.name === "collegeCode" &&
      event.target.value.length < 3
    ) {
      this.setState({ enabledAdd: false });
    }

    this.setState({ [event.target.name]: event.target.value });
  }

  renderTeacherRole() {
    return (
      <div>
        <div className="row">
          <div className="col-sm-12">
            <div className="card-box">
              <div className="card-body row">
                <div className="col-lg-2 p-t-20">
                  <div
                    className={
                      "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                    }
                  >
                    <input
                      autoComplete="off"
                      onBlur={this.onFocusOut.bind(this)}
                      onChange={this.collegeDetailsChange.bind(this)}
                      onFocus={this.onDropDownFocus.bind(this)}
                      className="mdl-textfield__input display-border"
                      type="text"
                      id={`collegeCode`}
                      value={this.state.collegeCode || ""}
                      name="collegeCode"
                      style={{ textTransform: "uppercase" }}
                    />
                    <label className={"mdl-textfield__label "}>
                      College Code
                    </label>
                  </div>
                </div>
                <div className="col-lg-2 p-t-20">
                  <div
                    className={
                      "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                    }
                  >
                    <input
                      autoComplete="off"
                      onBlur={this.onFocusOut.bind(this)}
                      onChange={this.collegeDetailsChange.bind(this)}
                      onFocus={this.onDropDownFocus.bind(this)}
                      className="mdl-textfield__input display-border"
                      type="text"
                      id={`collegeName`}
                      value={this.state.collegeName || ""}
                      name="collegeName"
                    />
                    <label className={"mdl-textfield__label "}>
                      College Name
                    </label>
                  </div>
                </div>
              </div>
              {this.state.values.map((el, i) => (
                <div className="card-body row" key={i}>
                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                      }
                    >
                      <input
                        autoComplete="off"
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocus.bind(this)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        readOnly
                        id={`name${i}`}
                        value={el.userID || ""}
                        onChange={this.handleFormChange.bind(this, i)}
                        name="userID"
                      />
                      <label className={"mdl-textfield__label "}>User ID</label>
                    </div>
                  </div>
                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                      }
                    >
                      <input
                        autoComplete="off"
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocus.bind(this)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        readOnly
                        id={`password${i}`}
                        value={el.password || ""}
                        onChange={this.handleFormChange.bind(this, i)}
                        name="password"
                      />
                      <label className={"mdl-textfield__label "}>
                        Password
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                      }
                    >
                      <input
                        autoComplete="off"
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocus.bind(this)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        id="sample2"
                        value={el.userName || ""}
                        onChange={this.handleFormChange.bind(this, i)}
                        name="userName"
                      />
                      <label className={"mdl-textfield__label "}>Name</label>
                    </div>
                  </div>
                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                      }
                    >
                      <input
                        autoComplete="off"
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocus.bind(this)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        id={`name${i}`}
                        value={el.qualifications || ""}
                        onChange={this.handleFormChange.bind(this, i)}
                        name="qualifications"
                      />
                      <label className={"mdl-textfield__label "}>
                        Qualifications
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                      }
                    >
                      <input
                        autoComplete="off"
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocus.bind(this)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        id={`name${i}`}
                        value={el.desgination || ""}
                        onChange={this.handleFormChange.bind(this, i)}
                        name="desgination"
                      />
                      <label className={"mdl-textfield__label "}>
                        Desgination
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width " +
                        this.state.isDepartmentFocussed
                      }
                    >
                      <input
                        name="selectedDepartment"
                        onKeyPress={(e) => e.preventDefault()}
                        autoComplete="off"
                        onFocus={this.onDeptDropDownFocus.bind(this, i)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        id={`department`}
                        value={el.selectedDepartment || ""}
                        onChange={this.handleFormChange.bind(this, i)}
                      />
                      <label
                        className={
                          "mdl-textfield__label " +
                          this.state.backgroundDepartment
                        }
                      >
                        Department
                      </label>
                      {this.state.onDepartmentFocus &&
                      this.state.selectedDepartmentIndex === i ? (
                        <div className="mdl-menu__container is-upgraded dropdown-list is-visible">
                          <div className="mdl-menu__outline mdl-menu--bottom-left dropdown-div">
                            <ul className="scrollable-menu mdl-menu mdl-menu--bottom-left mdl-js-menu ul-list">
                              {this.displayDepartment(i)}
                            </ul>
                          </div>
                        </div>
                      ) : (
                        <p></p>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                      }
                    >
                      <input
                        autoComplete="off"
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocus.bind(this)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        id="sample2"
                        value={el.email || ""}
                        onChange={this.handleFormChange.bind(this, i)}
                        name="email"
                      />
                      <label className={"mdl-textfield__label "}>Email</label>
                    </div>
                  </div>
                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                      }
                    >
                      <input
                        autoComplete="off"
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocus.bind(this)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        id="sample2"
                        value={el.contact || ""}
                        onChange={this.handleFormChange.bind(this, i)}
                        name="contact"
                      />
                      <label className={"mdl-textfield__label "}>Mobile</label>
                    </div>
                  </div>
                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                      }
                    >
                      <input
                        autoComplete="off"
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocus.bind(this)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        id="sample2"
                        value={el.emergencyContact || ""}
                        onChange={this.handleFormChange.bind(this, i)}
                        name="emergencyContact"
                      />
                      <label className={"mdl-textfield__label "}>
                        Emergency Contact
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                      }
                    >
                      <input
                        autoComplete="off"
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocus.bind(this)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        id="sample2"
                        value={el.parentName || ""}
                        onChange={this.handleFormChange.bind(this, i)}
                        name="parentName"
                      />
                      <label className={"mdl-textfield__label "}>
                        Parents / SpouseName
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width is-focused"
                      }
                    >
                      <input
                        value={
                          el.joiningDate ||
                          moment(new Date()).format("YYYY-MM-DD")
                        }
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocus.bind(this)}
                        autoComplete="off"
                        className="mdl-textfield__input display-border"
                        type="date"
                        id="sample2"
                        onChange={this.handleFormChange.bind(this, i)}
                        name="joiningDate"
                      />
                      <label className={"mdl-textfield__label "}>
                        Joining Date
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                      }
                    >
                      <input
                        autoComplete="off"
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocus.bind(this)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        id="address"
                        value={el.address || ""}
                        onChange={this.handleFormChange.bind(this, i)}
                        name="address"
                      />
                      <label className={"mdl-textfield__label "}>Address</label>
                    </div>
                  </div>
                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width " +
                        this.state.isGenderFocussed
                      }
                    >
                      <input
                        name="gender"
                        onKeyPress={(e) => e.preventDefault()}
                        autoComplete="off"
                        onFocus={this.onGenderDropDownFocus.bind(this, i)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        id={`Gender`}
                        value={el.gender || ""}
                        onChange={this.handleFormChange.bind(this, i)}
                      />
                      <label
                        className={
                          "mdl-textfield__label " + this.state.backgroundBlood
                        }
                      >
                        Gender
                      </label>
                      {this.state.onGenderFocus &&
                      this.state.selectedGenderIndex === i ? (
                        <div className="mdl-menu__container is-upgraded dropdown-list is-visible">
                          <div className="mdl-menu__outline mdl-menu--bottom-left dropdown-div">
                            <ul className="scrollable-menu mdl-menu mdl-menu--bottom-left mdl-js-menu ul-list">
                              <li
                                className="mdl-menu__item animation"
                                id="Male"
                                onClick={this.onGenderGroupSelect.bind(this, i)}
                              >
                                Male
                              </li>
                              <li
                                className="mdl-menu__item animation1"
                                id="Female"
                                onClick={this.onGenderGroupSelect.bind(this, i)}
                              >
                                Female
                              </li>
                              <li
                                className="mdl-menu__item animation2"
                                id="Others"
                                onClick={this.onGenderGroupSelect.bind(this, i)}
                              >
                                Others
                              </li>
                            </ul>
                          </div>
                        </div>
                      ) : (
                        <p></p>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width is-focused"
                      }
                    >
                      <input
                        value={
                          el.dob || moment(new Date()).format("YYYY-MM-DD")
                        }
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocus.bind(this)}
                        autoComplete="off"
                        className="mdl-textfield__input display-border"
                        type="date"
                        id="dob"
                        onChange={this.handleFormChange.bind(this, i)}
                        name="dob"
                      />
                      <label className={"mdl-textfield__label "}>DOB</label>
                    </div>
                  </div>
                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                      }
                    >
                      <input
                        autoComplete="off"
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocus.bind(this)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        id="motherTongue"
                        value={el.motherTongue || ""}
                        onChange={this.handleFormChange.bind(this, i)}
                        name="motherTongue"
                      />
                      <label className={"mdl-textfield__label "}>
                        Mother Tongue
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width " +
                        this.state.isBloodFocussed
                      }
                    >
                      <input
                        name="bloodGroup"
                        onKeyPress={(e) => e.preventDefault()}
                        autoComplete="off"
                        onFocus={this.onBloodGroupDropDownFocus.bind(this, i)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        id={`Blood`}
                        value={el.bloodGroup || ""}
                        onChange={this.handleFormChange.bind(this, i)}
                      />
                      <label
                        className={
                          "mdl-textfield__label " + this.state.backgroundBlood
                        }
                      >
                        Blood Group
                      </label>
                      {this.state.onBloodFocus &&
                      this.state.selectedBGIndex === i ? (
                        <div className="mdl-menu__container is-upgraded dropdown-list is-visible">
                          <div className="mdl-menu__outline mdl-menu--bottom-left dropdown-div">
                            <ul className="scrollable-menu mdl-menu mdl-menu--bottom-left mdl-js-menu ul-list">
                              <li
                                className="mdl-menu__item animation"
                                id="A+"
                                onClick={this.onBloodGroupSelect.bind(this, i)}
                              >
                                A+
                              </li>
                              <li
                                className="mdl-menu__item animation1"
                                id="A-"
                                onClick={this.onBloodGroupSelect.bind(this, i)}
                              >
                                A-
                              </li>
                              <li
                                className="mdl-menu__item animation2"
                                id="B+"
                                onClick={this.onBloodGroupSelect.bind(this, i)}
                              >
                                B+
                              </li>
                              <li
                                className="mdl-menu__item animation"
                                id="B-"
                                onClick={this.onBloodGroupSelect.bind(this, i)}
                              >
                                B-
                              </li>
                              <li
                                className="mdl-menu__item animation1"
                                id="O+"
                                onClick={this.onBloodGroupSelect.bind(this, i)}
                              >
                                O+
                              </li>
                              <li
                                className="mdl-menu__item animation2"
                                id="O-"
                                onClick={this.onBloodGroupSelect.bind(this, i)}
                              >
                                O-
                              </li>
                              <li
                                className="mdl-menu__item animation"
                                id="AB+"
                                onClick={this.onBloodGroupSelect.bind(this, i)}
                              >
                                AB+
                              </li>
                              <li
                                className="mdl-menu__item animation1"
                                id="AB-"
                                onClick={this.onBloodGroupSelect.bind(this, i)}
                              >
                                AB-
                              </li>
                            </ul>
                          </div>
                        </div>
                      ) : (
                        <p></p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-1 p-t-20">
                    <button
                      type="button"
                      onClick={this.removeClick.bind(this, i)}
                      className="btn btn-primary m-t-15 m-l-30"
                    >
                      X
                    </button>
                  </div>
                </div>
              ))}
              <div className="col-sm-8 p-t-20">
                <button
                  type="button"
                  onClick={this.addClick.bind(this)}
                  className="btn btn-primary m-t-15 m-l-30"
                  disabled={!this.state.enabledAdd}
                >
                  Add
                </button>
                <button
                  type="button"
                  onClick={this.formSubmit.bind(this)}
                  className="btn btn-primary m-t-15 m-l-30"
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={this.resetForm.bind(this)}
                  className="btn btn-primary m-t-15 m-l-30"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderNonTeacherRole() {
    return (
      <div>
        <div className="row">
          <div className="col-sm-12">
            <div className="card-box">
              <div className="card-body row">
                <div className="col-lg-2 p-t-20">
                  <div
                    className={
                      "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                    }
                  >
                    <input
                      autoComplete="off"
                      onBlur={this.onFocusOut.bind(this)}
                      onChange={this.collegeDetailsChange.bind(this)}
                      onFocus={this.onDropDownFocus.bind(this)}
                      className="mdl-textfield__input display-border"
                      type="text"
                      id={`collegeCode`}
                      value={this.state.collegeCode || ""}
                      name="collegeCode"
                      style={{ textTransform: "uppercase" }}
                    />
                    <label className={"mdl-textfield__label "}>
                      College Code
                    </label>
                  </div>
                </div>
                <div className="col-lg-2 p-t-20">
                  <div
                    className={
                      "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                    }
                  >
                    <input
                      autoComplete="off"
                      onBlur={this.onFocusOut.bind(this)}
                      onChange={this.collegeDetailsChange.bind(this)}
                      onFocus={this.onDropDownFocus.bind(this)}
                      className="mdl-textfield__input display-border"
                      type="text"
                      id={`collegeName`}
                      value={this.state.collegeName || ""}
                      name="collegeName"
                    />
                    <label className={"mdl-textfield__label "}>
                      College Name
                    </label>
                  </div>
                </div>
              </div>
              {this.state.values.map((el, i) => (
                <div className="card-body row" key={i}>
                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                      }
                    >
                      <input
                        autoComplete="off"
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocus.bind(this)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        readOnly
                        id={`name${i}`}
                        value={el.userID || ""}
                        onChange={this.handleFormChange.bind(this, i)}
                        name="userID"
                      />
                      <label className={"mdl-textfield__label "}>User ID</label>
                    </div>
                  </div>
                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                      }
                    >
                      <input
                        autoComplete="off"
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocus.bind(this)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        readOnly
                        id={`password${i}`}
                        value={el.password || ""}
                        onChange={this.handleFormChange.bind(this, i)}
                        name="password"
                      />
                      <label className={"mdl-textfield__label "}>
                        Password
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                      }
                    >
                      <input
                        autoComplete="off"
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocus.bind(this)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        id="sample2"
                        value={el.userName || ""}
                        onChange={this.handleFormChange.bind(this, i)}
                        name="userName"
                      />
                      <label className={"mdl-textfield__label "}>Name</label>
                    </div>
                  </div>
                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                      }
                    >
                      <input
                        autoComplete="off"
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocus.bind(this)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        readOnly
                        id={`name${i}`}
                        value={el.qualifications || ""}
                        onChange={this.handleFormChange.bind(this, i)}
                        name="qualifications"
                      />
                      <label className={"mdl-textfield__label "}>
                        Qualifications
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                      }
                    >
                      <input
                        autoComplete="off"
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocus.bind(this)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        readOnly
                        id={`name${i}`}
                        value={el.desgination || ""}
                        onChange={this.handleFormChange.bind(this, i)}
                        name="qualifications"
                      />
                      <label className={"mdl-textfield__label "}>
                        Desgination
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                      }
                    >
                      <input
                        autoComplete="off"
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocus.bind(this)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        id="sample2"
                        value={el.email || ""}
                        onChange={this.handleFormChange.bind(this, i)}
                        name="email"
                      />
                      <label className={"mdl-textfield__label "}>Email</label>
                    </div>
                  </div>
                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                      }
                    >
                      <input
                        autoComplete="off"
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocus.bind(this)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        id="sample2"
                        value={el.contact || ""}
                        onChange={this.handleFormChange.bind(this, i)}
                        name="contact"
                      />
                      <label className={"mdl-textfield__label "}>Mobile</label>
                    </div>
                  </div>
                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                      }
                    >
                      <input
                        autoComplete="off"
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocus.bind(this)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        id="sample2"
                        value={el.emergencyContact || ""}
                        onChange={this.handleFormChange.bind(this, i)}
                        name="emergencyContact"
                      />
                      <label className={"mdl-textfield__label "}>
                        Emergency Contact
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                      }
                    >
                      <input
                        autoComplete="off"
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocus.bind(this)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        id="sample2"
                        value={el.parentName || ""}
                        onChange={this.handleFormChange.bind(this, i)}
                        name="parentName"
                      />
                      <label className={"mdl-textfield__label "}>
                        Parents / SpouseName
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width is-focused"
                      }
                    >
                      <input
                        value={
                          el.joiningDate ||
                          moment(new Date()).format("YYYY-MM-DD")
                        }
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocus.bind(this)}
                        autoComplete="off"
                        className="mdl-textfield__input display-border"
                        type="date"
                        id="sample2"
                        onChange={this.handleFormChange.bind(this, i)}
                        name="joiningDate"
                      />
                      <label className={"mdl-textfield__label "}>
                        Joining Date
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                      }
                    >
                      <input
                        autoComplete="off"
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocus.bind(this)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        id="address"
                        value={el.address || ""}
                        onChange={this.handleFormChange.bind(this, i)}
                        name="address"
                      />
                      <label className={"mdl-textfield__label "}>Address</label>
                    </div>
                  </div>
                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width " +
                        this.state.isDepartmentFocussed
                      }
                    >
                      <input
                        name="selectedDepartment"
                        onKeyPress={(e) => e.preventDefault()}
                        autoComplete="off"
                        onFocus={this.onDeptDropDownFocus.bind(this, i)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        id={`department`}
                        value={el.selectedDepartment || ""}
                        onChange={this.handleFormChange.bind(this, i)}
                      />
                      <label
                        className={
                          "mdl-textfield__label " +
                          this.state.backgroundDepartment
                        }
                      >
                        Department
                      </label>
                      {this.state.onDepartmentFocus &&
                      this.state.selectedDepartmentIndex === i ? (
                        <div className="mdl-menu__container is-upgraded dropdown-list is-visible">
                          <div className="mdl-menu__outline mdl-menu--bottom-left dropdown-div">
                            <ul className="scrollable-menu mdl-menu mdl-menu--bottom-left mdl-js-menu ul-list">
                              {this.displayDepartment(i)}
                            </ul>
                          </div>
                        </div>
                      ) : (
                        <p></p>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width " +
                        this.state.isGenderFocussed
                      }
                    >
                      <input
                        name="gender"
                        onKeyPress={(e) => e.preventDefault()}
                        autoComplete="off"
                        onFocus={this.onGenderDropDownFocus.bind(this, i)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        id={`Gender`}
                        value={el.gender || ""}
                        onChange={this.handleFormChange.bind(this, i)}
                      />
                      <label
                        className={
                          "mdl-textfield__label " + this.state.backgroundBlood
                        }
                      >
                        Gender
                      </label>
                      {this.state.onGenderFocus &&
                      this.state.selectedGenderIndex === i ? (
                        <div className="mdl-menu__container is-upgraded dropdown-list is-visible">
                          <div className="mdl-menu__outline mdl-menu--bottom-left dropdown-div">
                            <ul className="scrollable-menu mdl-menu mdl-menu--bottom-left mdl-js-menu ul-list">
                              <li
                                className="mdl-menu__item animation"
                                id="Male"
                                onClick={this.onGenderGroupSelect.bind(this, i)}
                              >
                                Male
                              </li>
                              <li
                                className="mdl-menu__item animation1"
                                id="Female"
                                onClick={this.onGenderGroupSelect.bind(this, i)}
                              >
                                Female
                              </li>
                              <li
                                className="mdl-menu__item animation2"
                                id="Others"
                                onClick={this.onGenderGroupSelect.bind(this, i)}
                              >
                                Others
                              </li>
                            </ul>
                          </div>
                        </div>
                      ) : (
                        <p></p>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                      }
                    >
                      <input
                        autoComplete="off"
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocus.bind(this)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        id="motherTongue"
                        value={el.motherTongue || ""}
                        onChange={this.handleFormChange.bind(this, i)}
                        name="motherTongue"
                      />
                      <label className={"mdl-textfield__label "}>
                        Mother Tongue
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width " +
                        this.state.isBloodFocussed
                      }
                    >
                      <input
                        name="bloodGroup"
                        onKeyPress={(e) => e.preventDefault()}
                        autoComplete="off"
                        onFocus={this.onBloodGroupDropDownFocus.bind(this, i)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        id={`Blood`}
                        value={el.bloodGroup || ""}
                        onChange={this.handleFormChange.bind(this, i)}
                      />
                      <label
                        className={
                          "mdl-textfield__label " + this.state.backgroundBlood
                        }
                      >
                        Blood Group
                      </label>
                      {this.state.onBloodFocus &&
                      this.state.selectedBGIndex === i ? (
                        <div className="mdl-menu__container is-upgraded dropdown-list is-visible">
                          <div className="mdl-menu__outline mdl-menu--bottom-left dropdown-div">
                            <ul className="scrollable-menu mdl-menu mdl-menu--bottom-left mdl-js-menu ul-list">
                              <li
                                className="mdl-menu__item animation"
                                id="A+"
                                onClick={this.onBloodGroupSelect.bind(this, i)}
                              >
                                A+
                              </li>
                              <li
                                className="mdl-menu__item animation1"
                                id="A-"
                                onClick={this.onBloodGroupSelect.bind(this, i)}
                              >
                                A-
                              </li>
                              <li
                                className="mdl-menu__item animation2"
                                id="B+"
                                onClick={this.onBloodGroupSelect.bind(this, i)}
                              >
                                B+
                              </li>
                              <li
                                className="mdl-menu__item animation"
                                id="B-"
                                onClick={this.onBloodGroupSelect.bind(this, i)}
                              >
                                B-
                              </li>
                              <li
                                className="mdl-menu__item animation1"
                                id="O+"
                                onClick={this.onBloodGroupSelect.bind(this, i)}
                              >
                                O+
                              </li>
                              <li
                                className="mdl-menu__item animation2"
                                id="O-"
                                onClick={this.onBloodGroupSelect.bind(this, i)}
                              >
                                O-
                              </li>
                              <li
                                className="mdl-menu__item animation"
                                id="AB+"
                                onClick={this.onBloodGroupSelect.bind(this, i)}
                              >
                                AB+
                              </li>
                              <li
                                className="mdl-menu__item animation1"
                                id="AB-"
                                onClick={this.onBloodGroupSelect.bind(this, i)}
                              >
                                AB-
                              </li>
                            </ul>
                          </div>
                        </div>
                      ) : (
                        <p></p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-1 p-t-20">
                    <button
                      type="button"
                      onClick={this.removeClick.bind(this, i)}
                      className="btn btn-primary m-t-15 m-l-30"
                    >
                      X
                    </button>
                  </div>
                </div>
              ))}
              <div className="col-sm-8 p-t-20">
                <button
                  type="button"
                  onClick={this.addClick.bind(this)}
                  className="btn btn-primary m-t-15 m-l-30"
                  disabled={!this.state.enabledAdd}
                >
                  Add
                </button>
                <button
                  type="button"
                  onClick={this.formSubmit.bind(this)}
                  className="btn btn-primary m-t-15 m-l-30"
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={this.resetForm.bind(this)}
                  className="btn btn-primary m-t-15 m-l-30"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderStudentRole() {
    return (
      <div>
        <div className="row">
          <div className="col-sm-12">
            <div className="card-box">
              <div className="card-body row">
                <div className="col-lg-2 p-t-20">
                  <div
                    className={
                      "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                    }
                  >
                    <input
                      autoComplete="off"
                      onBlur={this.onFocusOut.bind(this)}
                      onChange={this.collegeDetailsChange.bind(this)}
                      onFocus={this.onDropDownFocus.bind(this)}
                      className="mdl-textfield__input display-border"
                      type="text"
                      id={`collegeCode`}
                      value={this.state.collegeCode || ""}
                      name="collegeCode"
                      style={{ textTransform: "uppercase" }}
                    />
                    <label className={"mdl-textfield__label "}>
                      College Code
                    </label>
                  </div>
                </div>
                <div className="col-lg-2 p-t-20">
                  <div
                    className={
                      "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                    }
                  >
                    <input
                      autoComplete="off"
                      onBlur={this.onFocusOut.bind(this)}
                      onChange={this.collegeDetailsChange.bind(this)}
                      onFocus={this.onDropDownFocus.bind(this)}
                      className="mdl-textfield__input display-border"
                      type="text"
                      id={`collegeName`}
                      value={this.state.collegeName || ""}
                      name="collegeName"
                    />
                    <label className={"mdl-textfield__label "}>
                      College Name
                    </label>
                  </div>
                </div>
              </div>
              {this.state.values.map((el, i) => (
                <div className="card-body row" key={i}>
                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                      }
                    >
                      <input
                        autoComplete="off"
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocus.bind(this)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        readOnly
                        id={`name${i}`}
                        value={el.userID || ""}
                        onChange={this.handleFormChange.bind(this, i)}
                        name="userID"
                      />
                      <label className={"mdl-textfield__label "}>User ID</label>
                    </div>
                  </div>
                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                      }
                    >
                      <input
                        autoComplete="off"
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocus.bind(this)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        readOnly
                        id={`password${i}`}
                        value={el.password || ""}
                        onChange={this.handleFormChange.bind(this, i)}
                        name="password"
                      />
                      <label className={"mdl-textfield__label "}>
                        Password
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                      }
                    >
                      <input
                        autoComplete="off"
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocus.bind(this)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        id="sample2"
                        value={el.userName || ""}
                        onChange={this.handleFormChange.bind(this, i)}
                        name="userName"
                      />
                      <label className={"mdl-textfield__label "}>Name</label>
                    </div>
                  </div>
                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width " +
                        this.state.isDepartmentFocussed
                      }
                    >
                      <input
                        name="selectedDepartment"
                        onKeyPress={(e) => e.preventDefault()}
                        autoComplete="off"
                        onFocus={this.onDeptDropDownFocus.bind(this, i)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        id={`department`}
                        value={el.selectedDepartment || ""}
                        onChange={this.handleFormChange.bind(this, i)}
                      />
                      <label
                        className={
                          "mdl-textfield__label " +
                          this.state.backgroundDepartment
                        }
                      >
                        Department
                      </label>
                      {this.state.onDepartmentFocus &&
                      this.state.selectedDepartmentIndex === i ? (
                        <div className="mdl-menu__container is-upgraded dropdown-list is-visible">
                          <div className="mdl-menu__outline mdl-menu--bottom-left dropdown-div">
                            <ul className="scrollable-menu mdl-menu mdl-menu--bottom-left mdl-js-menu ul-list">
                              {this.displayDepartment(i)}
                            </ul>
                          </div>
                        </div>
                      ) : (
                        <p></p>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width " +
                        this.state.isDegreeFocussed
                      }
                    >
                      <input
                        name="degreeName"
                        onKeyPress={(e) => e.preventDefault()}
                        autoComplete="off"
                        onFocus={this.onDegreeDropDownFocus.bind(this, i)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        id={`degree`}
                        value={el.degreeName || ""}
                        onChange={this.handleFormChange.bind(this, i)}
                      />
                      <label
                        className={
                          "mdl-textfield__label " + this.state.backgroundDegree
                        }
                      >
                        Degree
                      </label>
                      {this.state.onDegreeFocus &&
                      this.state.selectedDegreeIndex === i ? (
                        <div className="mdl-menu__container is-upgraded dropdown-list is-visible">
                          <div className="mdl-menu__outline mdl-menu--bottom-left dropdown-div">
                            <ul className="scrollable-menu mdl-menu mdl-menu--bottom-left mdl-js-menu ul-list">
                              {this.displayDegree(i)}
                            </ul>
                          </div>
                        </div>
                      ) : (
                        <p></p>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                      }
                    >
                      <input
                        autoComplete="off"
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocus.bind(this)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        id="sample2"
                        value={el.email || ""}
                        onChange={this.handleFormChange.bind(this, i)}
                        name="email"
                      />
                      <label className={"mdl-textfield__label "}>Email</label>
                    </div>
                  </div>
                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                      }
                    >
                      <input
                        autoComplete="off"
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocus.bind(this)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        id="sample2"
                        value={el.contact || ""}
                        onChange={this.handleFormChange.bind(this, i)}
                        name="contact"
                      />
                      <label className={"mdl-textfield__label "}>Mobile</label>
                    </div>
                  </div>
                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                      }
                    >
                      <input
                        autoComplete="off"
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocus.bind(this)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        id="sample2"
                        value={el.emergencyContact || ""}
                        onChange={this.handleFormChange.bind(this, i)}
                        name="emergencyContact"
                      />
                      <label className={"mdl-textfield__label "}>
                        Emergency Contact
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                      }
                    >
                      <input
                        autoComplete="off"
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocus.bind(this)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        id="sample2"
                        value={el.parentName || ""}
                        onChange={this.handleFormChange.bind(this, i)}
                        name="parentName"
                      />
                      <label className={"mdl-textfield__label "}>
                        Parents / Guardian Name
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width is-focused"
                      }
                    >
                      <input
                        value={
                          el.admissionDate ||
                          moment(new Date()).format("YYYY-MM-DD")
                        }
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocus.bind(this)}
                        autoComplete="off"
                        className="mdl-textfield__input display-border"
                        type="date"
                        id="sample2"
                        onChange={this.handleFormChange.bind(this, i)}
                        name="admissionDate"
                      />
                      <label className={"mdl-textfield__label "}>
                        Admission Date
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                      }
                    >
                      <input
                        autoComplete="off"
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocus.bind(this)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        id="address"
                        value={el.address || ""}
                        onChange={this.handleFormChange.bind(this, i)}
                        name="address"
                      />
                      <label className={"mdl-textfield__label "}>Address</label>
                    </div>
                  </div>
                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width " +
                        this.state.isGenderFocussed
                      }
                    >
                      <input
                        name="gender"
                        onKeyPress={(e) => e.preventDefault()}
                        autoComplete="off"
                        onFocus={this.onGenderDropDownFocus.bind(this, i)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        id={`Gender`}
                        value={el.gender || ""}
                        onChange={this.handleFormChange.bind(this, i)}
                      />
                      <label
                        className={
                          "mdl-textfield__label " + this.state.backgroundBlood
                        }
                      >
                        Gender
                      </label>
                      {this.state.onGenderFocus &&
                      this.state.selectedGenderIndex === i ? (
                        <div className="mdl-menu__container is-upgraded dropdown-list is-visible">
                          <div className="mdl-menu__outline mdl-menu--bottom-left dropdown-div">
                            <ul className="scrollable-menu mdl-menu mdl-menu--bottom-left mdl-js-menu ul-list">
                              <li
                                className="mdl-menu__item animation"
                                id="Male"
                                onClick={this.onGenderGroupSelect.bind(this, i)}
                              >
                                Male
                              </li>
                              <li
                                className="mdl-menu__item animation1"
                                id="Female"
                                onClick={this.onGenderGroupSelect.bind(this, i)}
                              >
                                Female
                              </li>
                              <li
                                className="mdl-menu__item animation2"
                                id="Others"
                                onClick={this.onGenderGroupSelect.bind(this, i)}
                              >
                                Others
                              </li>
                            </ul>
                          </div>
                        </div>
                      ) : (
                        <p></p>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width is-focused"
                      }
                    >
                      <input
                        value={
                          el.dob || moment(new Date()).format("YYYY-MM-DD")
                        }
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocus.bind(this)}
                        autoComplete="off"
                        className="mdl-textfield__input display-border"
                        type="date"
                        id="dob"
                        onChange={this.handleFormChange.bind(this, i)}
                        name="dob"
                      />
                      <label className={"mdl-textfield__label "}>DOB</label>
                    </div>
                  </div>
                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                      }
                    >
                      <input
                        autoComplete="off"
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocus.bind(this)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        id="motherTongue"
                        value={el.motherTongue || ""}
                        onChange={this.handleFormChange.bind(this, i)}
                        name="motherTongue"
                      />
                      <label className={"mdl-textfield__label "}>
                        Mother Tongue
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width " +
                        this.state.isBloodFocussed
                      }
                    >
                      <input
                        name="bloodGroup"
                        onKeyPress={(e) => e.preventDefault()}
                        autoComplete="off"
                        onFocus={this.onBloodGroupDropDownFocus.bind(this, i)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        id={`Blood`}
                        value={el.bloodGroup || ""}
                        onChange={this.handleFormChange.bind(this, i)}
                      />
                      <label
                        className={
                          "mdl-textfield__label " + this.state.backgroundBlood
                        }
                      >
                        Blood Group
                      </label>
                      {this.state.onBloodFocus &&
                      this.state.selectedBGIndex === i ? (
                        <div className="mdl-menu__container is-upgraded dropdown-list is-visible">
                          <div className="mdl-menu__outline mdl-menu--bottom-left dropdown-div">
                            <ul className="scrollable-menu mdl-menu mdl-menu--bottom-left mdl-js-menu ul-list">
                              <li
                                className="mdl-menu__item animation"
                                id="A+"
                                onClick={this.onBloodGroupSelect.bind(this, i)}
                              >
                                A+
                              </li>
                              <li
                                className="mdl-menu__item animation1"
                                id="A-"
                                onClick={this.onBloodGroupSelect.bind(this, i)}
                              >
                                A-
                              </li>
                              <li
                                className="mdl-menu__item animation2"
                                id="B+"
                                onClick={this.onBloodGroupSelect.bind(this, i)}
                              >
                                B+
                              </li>
                              <li
                                className="mdl-menu__item animation"
                                id="B-"
                                onClick={this.onBloodGroupSelect.bind(this, i)}
                              >
                                B-
                              </li>
                              <li
                                className="mdl-menu__item animation1"
                                id="O+"
                                onClick={this.onBloodGroupSelect.bind(this, i)}
                              >
                                O+
                              </li>
                              <li
                                className="mdl-menu__item animation2"
                                id="O-"
                                onClick={this.onBloodGroupSelect.bind(this, i)}
                              >
                                O-
                              </li>
                              <li
                                className="mdl-menu__item animation"
                                id="AB+"
                                onClick={this.onBloodGroupSelect.bind(this, i)}
                              >
                                AB+
                              </li>
                              <li
                                className="mdl-menu__item animation1"
                                id="AB-"
                                onClick={this.onBloodGroupSelect.bind(this, i)}
                              >
                                AB-
                              </li>
                            </ul>
                          </div>
                        </div>
                      ) : (
                        <p></p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-1 p-t-20">
                    <button
                      type="button"
                      onClick={this.removeClick.bind(this, i)}
                      className="btn btn-primary m-t-15 m-l-30"
                    >
                      X
                    </button>
                  </div>
                </div>
              ))}
              <div className="col-sm-8 p-t-20">
                <button
                  type="button"
                  onClick={this.addClick.bind(this)}
                  className="btn btn-primary m-t-15 m-l-30"
                  disabled={!this.state.enabledAdd}
                >
                  Add
                </button>
                <button
                  type="button"
                  onClick={this.formSubmit.bind(this)}
                  className="btn btn-primary m-t-15 m-l-30"
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={this.resetForm.bind(this)}
                  className="btn btn-primary m-t-15 m-l-30"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderManagementRole() {
    return (
      <div>
        <div className="row">
          <div className="col-sm-12">
            <div className="card-box">
              <div className="card-body row">
                <div className="col-lg-2 p-t-20">
                  <div
                    className={
                      "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                    }
                  >
                    <input
                      autoComplete="off"
                      onBlur={this.onFocusOut.bind(this)}
                      onChange={this.collegeDetailsChange.bind(this)}
                      onFocus={this.onDropDownFocus.bind(this)}
                      className="mdl-textfield__input display-border"
                      type="text"
                      id={`collegeCode`}
                      value={this.state.collegeCode || ""}
                      name="collegeCode"
                      style={{ textTransform: "uppercase" }}
                    />
                    <label className={"mdl-textfield__label "}>
                      College Code
                    </label>
                  </div>
                </div>
                <div className="col-lg-2 p-t-20">
                  <div
                    className={
                      "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                    }
                  >
                    <input
                      autoComplete="off"
                      onBlur={this.onFocusOut.bind(this)}
                      onChange={this.collegeDetailsChange.bind(this)}
                      onFocus={this.onDropDownFocus.bind(this)}
                      className="mdl-textfield__input display-border"
                      type="text"
                      id={`collegeName`}
                      value={this.state.collegeName || ""}
                      name="collegeName"
                    />
                    <label className={"mdl-textfield__label "}>
                      College Name
                    </label>
                  </div>
                </div>
              </div>
              {this.state.values.map((el, i) => (
                <div className="card-body row" key={i}>
                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                      }
                    >
                      <input
                        autoComplete="off"
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocus.bind(this)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        readOnly
                        id={`name${i}`}
                        value={el.userID || ""}
                        onChange={this.handleFormChange.bind(this, i)}
                        name="userID"
                      />
                      <label className={"mdl-textfield__label "}>User ID</label>
                    </div>
                  </div>
                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                      }
                    >
                      <input
                        autoComplete="off"
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocus.bind(this)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        readOnly
                        id={`password${i}`}
                        value={el.password || ""}
                        onChange={this.handleFormChange.bind(this, i)}
                        name="password"
                      />
                      <label className={"mdl-textfield__label "}>
                        Password
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                      }
                    >
                      <input
                        autoComplete="off"
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocus.bind(this)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        id="sample2"
                        value={el.userName || ""}
                        onChange={this.handleFormChange.bind(this, i)}
                        name="userName"
                      />
                      <label className={"mdl-textfield__label "}>Name</label>
                    </div>
                  </div>
                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                      }
                    >
                      <input
                        autoComplete="off"
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocus.bind(this)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        readOnly
                        id={`name${i}`}
                        value={el.qualifications || ""}
                        onChange={this.handleFormChange.bind(this, i)}
                        name="qualifications"
                      />
                      <label className={"mdl-textfield__label "}>
                        Qualifications
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                      }
                    >
                      <input
                        autoComplete="off"
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocus.bind(this)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        readOnly
                        id={`name${i}`}
                        value={el.desgination || ""}
                        onChange={this.handleFormChange.bind(this, i)}
                        name="qualifications"
                      />
                      <label className={"mdl-textfield__label "}>
                        Desgination
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                      }
                    >
                      <input
                        autoComplete="off"
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocus.bind(this)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        id="sample2"
                        value={el.email || ""}
                        onChange={this.handleFormChange.bind(this, i)}
                        name="email"
                      />
                      <label className={"mdl-textfield__label "}>Email</label>
                    </div>
                  </div>
                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                      }
                    >
                      <input
                        autoComplete="off"
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocus.bind(this)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        id="sample2"
                        value={el.contact || ""}
                        onChange={this.handleFormChange.bind(this, i)}
                        name="contact"
                      />
                      <label className={"mdl-textfield__label "}>Mobile</label>
                    </div>
                  </div>
                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                      }
                    >
                      <input
                        autoComplete="off"
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocus.bind(this)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        id="sample2"
                        value={el.emergencyContact || ""}
                        onChange={this.handleFormChange.bind(this, i)}
                        name="emergencyContact"
                      />
                      <label className={"mdl-textfield__label "}>
                        Emergency Contact
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                      }
                    >
                      <input
                        autoComplete="off"
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocus.bind(this)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        id="sample2"
                        value={el.parentName || ""}
                        onChange={this.handleFormChange.bind(this, i)}
                        name="parentName"
                      />
                      <label className={"mdl-textfield__label "}>
                        Parents / SpouseName
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width is-focused"
                      }
                    >
                      <input
                        value={
                          el.joiningDate ||
                          moment(new Date()).format("YYYY-MM-DD")
                        }
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocus.bind(this)}
                        autoComplete="off"
                        className="mdl-textfield__input display-border"
                        type="date"
                        id="sample2"
                        onChange={this.handleFormChange.bind(this, i)}
                        name="joiningDate"
                      />
                      <label className={"mdl-textfield__label "}>
                        Joining Date
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                      }
                    >
                      <input
                        autoComplete="off"
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocus.bind(this)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        id="address"
                        value={el.address || ""}
                        onChange={this.handleFormChange.bind(this, i)}
                        name="address"
                      />
                      <label className={"mdl-textfield__label "}>Address</label>
                    </div>
                  </div>
                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width " +
                        this.state.isDepartmentFocussed
                      }
                    >
                      <input
                        name="selectedDepartment"
                        onKeyPress={(e) => e.preventDefault()}
                        autoComplete="off"
                        onFocus={this.onDeptDropDownFocus.bind(this, i)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        id={`department`}
                        value={el.selectedDepartment || ""}
                        onChange={this.handleFormChange.bind(this, i)}
                      />
                      <label
                        className={
                          "mdl-textfield__label " +
                          this.state.backgroundDepartment
                        }
                      >
                        Department
                      </label>
                      {this.state.onDepartmentFocus &&
                      this.state.selectedDepartmentIndex === i ? (
                        <div className="mdl-menu__container is-upgraded dropdown-list is-visible">
                          <div className="mdl-menu__outline mdl-menu--bottom-left dropdown-div">
                            <ul className="scrollable-menu mdl-menu mdl-menu--bottom-left mdl-js-menu ul-list">
                              {this.displayDepartment(i)}
                            </ul>
                          </div>
                        </div>
                      ) : (
                        <p></p>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width " +
                        this.state.isGenderFocussed
                      }
                    >
                      <input
                        name="gender"
                        onKeyPress={(e) => e.preventDefault()}
                        autoComplete="off"
                        onFocus={this.onGenderDropDownFocus.bind(this, i)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        id={`Gender`}
                        value={el.gender || ""}
                        onChange={this.handleFormChange.bind(this, i)}
                      />
                      <label
                        className={
                          "mdl-textfield__label " + this.state.backgroundBlood
                        }
                      >
                        Gender
                      </label>
                      {this.state.onGenderFocus &&
                      this.state.selectedGenderIndex === i ? (
                        <div className="mdl-menu__container is-upgraded dropdown-list is-visible">
                          <div className="mdl-menu__outline mdl-menu--bottom-left dropdown-div">
                            <ul className="scrollable-menu mdl-menu mdl-menu--bottom-left mdl-js-menu ul-list">
                              <li
                                className="mdl-menu__item animation"
                                id="Male"
                                onClick={this.onGenderGroupSelect.bind(this, i)}
                              >
                                Male
                              </li>
                              <li
                                className="mdl-menu__item animation1"
                                id="Female"
                                onClick={this.onGenderGroupSelect.bind(this, i)}
                              >
                                Female
                              </li>
                              <li
                                className="mdl-menu__item animation2"
                                id="Others"
                                onClick={this.onGenderGroupSelect.bind(this, i)}
                              >
                                Others
                              </li>
                            </ul>
                          </div>
                        </div>
                      ) : (
                        <p></p>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                      }
                    >
                      <input
                        autoComplete="off"
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocus.bind(this)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        id="motherTongue"
                        value={el.motherTongue || ""}
                        onChange={this.handleFormChange.bind(this, i)}
                        name="motherTongue"
                      />
                      <label className={"mdl-textfield__label "}>
                        Mother Tongue
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-2 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width " +
                        this.state.isBloodFocussed
                      }
                    >
                      <input
                        name="bloodGroup"
                        onKeyPress={(e) => e.preventDefault()}
                        autoComplete="off"
                        onFocus={this.onBloodGroupDropDownFocus.bind(this, i)}
                        className="mdl-textfield__input display-border"
                        type="text"
                        id={`Blood`}
                        value={el.bloodGroup || ""}
                        onChange={this.handleFormChange.bind(this, i)}
                      />
                      <label
                        className={
                          "mdl-textfield__label " + this.state.backgroundBlood
                        }
                      >
                        Blood Group
                      </label>
                      {this.state.onBloodFocus &&
                      this.state.selectedBGIndex === i ? (
                        <div className="mdl-menu__container is-upgraded dropdown-list is-visible">
                          <div className="mdl-menu__outline mdl-menu--bottom-left dropdown-div">
                            <ul className="scrollable-menu mdl-menu mdl-menu--bottom-left mdl-js-menu ul-list">
                              <li
                                className="mdl-menu__item animation"
                                id="A+"
                                onClick={this.onBloodGroupSelect.bind(this, i)}
                              >
                                A+
                              </li>
                              <li
                                className="mdl-menu__item animation1"
                                id="A-"
                                onClick={this.onBloodGroupSelect.bind(this, i)}
                              >
                                A-
                              </li>
                              <li
                                className="mdl-menu__item animation2"
                                id="B+"
                                onClick={this.onBloodGroupSelect.bind(this, i)}
                              >
                                B+
                              </li>
                              <li
                                className="mdl-menu__item animation"
                                id="B-"
                                onClick={this.onBloodGroupSelect.bind(this, i)}
                              >
                                B-
                              </li>
                              <li
                                className="mdl-menu__item animation1"
                                id="O+"
                                onClick={this.onBloodGroupSelect.bind(this, i)}
                              >
                                O+
                              </li>
                              <li
                                className="mdl-menu__item animation2"
                                id="O-"
                                onClick={this.onBloodGroupSelect.bind(this, i)}
                              >
                                O-
                              </li>
                              <li
                                className="mdl-menu__item animation"
                                id="AB+"
                                onClick={this.onBloodGroupSelect.bind(this, i)}
                              >
                                AB+
                              </li>
                              <li
                                className="mdl-menu__item animation1"
                                id="AB-"
                                onClick={this.onBloodGroupSelect.bind(this, i)}
                              >
                                AB-
                              </li>
                            </ul>
                          </div>
                        </div>
                      ) : (
                        <p></p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-1 p-t-20">
                    <button
                      type="button"
                      onClick={this.removeClick.bind(this, i)}
                      className="btn btn-primary m-t-15 m-l-30"
                    >
                      X
                    </button>
                  </div>
                </div>
              ))}
              <div className="col-sm-8 p-t-20">
                <button
                  type="button"
                  onClick={this.addClick.bind(this)}
                  className="btn btn-primary m-t-15 m-l-30"
                  disabled={!this.state.enabledAdd}
                >
                  Add
                </button>
                <button
                  type="button"
                  onClick={this.formSubmit.bind(this)}
                  className="btn btn-primary m-t-15 m-l-30"
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={this.resetForm.bind(this)}
                  className="btn btn-primary m-t-15 m-l-30"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <>
        <Router>
          <div id="bg-color-white">
            <div  className="d-flex flex-column">
              <div id="content">
                <button
                  className="btn btn-sm btn-primary shadow-sm logout m-t-20 m-r-20"
                  onClick={this.logout.bind(this)}
                >
                  {" "}
                  <i className="fa fa-power-off m-r-15"></i>Logout
                </button>
                {this.props &&
                this.props.location &&
                this.props.location.state &&
                this.props.location.state.userDetails ? (
                  <>
                    <p className="logout m-t-30 m-r-40">
                      {this.props.location.state.userDetails.userRole
                        .charAt(0)
                        .toUpperCase() +
                        this.props.location.state.userDetails.userRole.slice(
                          1
                        )}{" "}
                      Dashboard
                    </p>
                    <p className="logout m-t-30 m-r-40">
                      {this.props.location.state.userDetails.userID}
                    </p>
                  </>
                ) : (
                  <></>
                )}
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                  <h1 className="h3 mb-0 text-gray-800 m-t-20 m-l-20">
                    Add User Details
                  </h1>
                </div>
                <div className="col-lg-2 p-t-20">
                  <div className="form-group">
                    <label htmlFor="demo_overview_minimal">
                      Select Your Role
                    </label>
                    <select
                      id="role_select"
                      className="form-control"
                      data-role="select-dropdown"
                      data-profile="minimal"
                      onChange={this.onRoleSelect.bind(this)}
                    >
                      <option value="nothing">Nothing Selected</option>
                      <option value="student">Student</option>
                      <option value="teacher">Teacher</option>
                      <option value="non-teaching">Non-Teaching Staff</option>
                      <option value="management">Management</option>
                    </select>
                  </div>
                </div>
                {this.state.selectedRole === "student" &&
                  this.renderStudentRole()}
                {this.state.selectedRole === "teacher" &&
                  this.renderTeacherRole()}
                {this.state.selectedRole === "non-teaching" &&
                  this.renderNonTeacherRole()}
                {this.state.selectedRole === "management" &&
                  this.renderManagementRole()}
              </div>
            </div>
          </div>
        </Router>
      </>
    );
  }
}

export default XakalAdminPortal;
