import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import axios from 'axios';
import moment from 'moment';
import Pagination from '../../utils/pagination'
import '../../styles/classnote.css';
import "../../styles/table.css";
import "../../minified-css/material-min.css";
import "../../styles/dropdowns.css";
import "../../styles/theme-style.css";
import 'bootstrap/dist/css/bootstrap.min.css';

const LeaveStatus = (props) => {
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostPerPage] = useState(5);
  const [modal, setmodal] = useState(false)
  const togglePopup = () => setmodal(!modal);

  useEffect(() => {
    const list = async () => {
      if (props && props.location && props.location.userID) {
        const userID = props.location.userID.userID;
        axios.get(`${process.env.PUBLIC_URL}/xakal/attendance/studentleaveID/${userID}`)
          .then((response) => {
            setPosts(response.data)
          });
      }
    }
    list();
  }, [])



  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const list = [];
  posts.map((p) => {
    if (p.status) {
      list.push(p)
    }
  })
  const currentPost = list.slice(indexOfFirstPost, indexOfLastPost)

  const paginate = (num) => setCurrentPage(num)
  let counter = 0;

  return (
    <div className="background ">
      <div className="background leave-resp" style={{ marginLeft: "10rem", width: '150%',marginTop:"7rem" }}>

        <nav className="navbar navbar-expand-lg lg-nav navprac1 bar leave-bar">
          <div className="title">Leave</div>
          <div className="collapse navbar-collapse">
            <form className="form-inline my-2 my-lg-0">
              <input style={{ width: '500px', marginLeft: '120px', backgroundColor: 'rgba(54, 42, 65, 0.04)', height: '45px', fontStyle: 'italic' }} className="form-control mr-sm-2 " type="search" placeholder="Search....." aria-label="Search"></input>
              {/* <i class="fal fa-search"></i> */}
            </form>
          </div>
        </nav>
        <div className="row">
          {/* <h4 className="head attress" style={{ marginLeft: '200px', marginTop: '150px', width: '160px' }}>Check Leave </h4>
          <h4 className="head attress" style={{ marginLeft: '390px', marginTop: '150px', width: '160px' }}>Apply Leave </h4>
          <div className="leave-head attress" style={{ marginTop: '210px' }}>Your leave application is
            just one click away!</div> */}
          <div className="stick"></div>
          <div>
            {/* <button className="leave-button leave-text " onclick={togglePopup} onClick={() => setmodal(true)}>Apply Leave</button> */}

            <Modal isOpen={modal} >

              <div className="row">
                <div className="frame">
                  <button className="close-modal btn " onClick={() => setmodal(false)}>x</button>
                  <p className="text-leave-req"> Leave request for John Doe</p>
                  <form class="form-in">
                    <div class="col-6">
                      <label class="visually-hidden" for="autoSizingInput">From date:</label>
                      <input type="text" class="form-control" />
                    </div>

                    <div class="col-6">
                      <label class="visually-hidden" for="autoSizingInputGroup">To date:</label>
                      <input type="text" class="form-control" />

                    </div>
                    <div class="col-12">
                      <label for="inputAddress" class=" visually-hidden">Leave type:</label>
                      <input type="text" class="form-control" id="inputAddress" />
                    </div>
                    <div class="col-12">
                      <label for="inputAddress2" class="visually-hidden">Reason:</label>
                      <textarea type="text" class="form-control" id="inputAddress2" />
                    </div>
                    <div class="col-md-6">
                      <label for="inputCity" class="visually-hidden validation">Validation:</label>
                      <div type="text" class=" text-val validation1">Upload a doc as a proof or validation</div>
                    </div>
                    <div class="col-md-4">
                      <button type="submit" class="cancel form-but-upload">Upload </button>
                    </div>
                    <div class="col-12">
                    </div>
                    <div class="col-12">
                      <button type="submit" class="getresult form-but-leave" >Apply Leave</button>
                    </div>
                  </form>
                </div>
              </div>

            </Modal>
          </div>
          {/* <div>
            <button className="leave-button leave-text " onclick={togglePopup}>Apply Leave</button>
          </div> */}
        </div>
        <div >
          <div>
            <div>
              <h4
                className="input-text labell  leave-sem"
                style={{ marginLeft: '190px', marginTop: '-70px' }}
              >
                Semester Number
              </h4>
              <input
                autoComplete="off"
                className="new-xakal-input background height leave-lab"
                type="text"
                id="Semester"
                style={{ marginLeft: '190px', width: '368px', marginTop: '10px' }}
              />



              <div style={{ width: '368px', marginLeft: '-170%', marginTop: '170%' }} >
                <div className="mdl-menu__outline  dropdown-div dropd" style={{ width: '368px', marginLeft: '63px', marginTop: '10px' }} >
                  <ul className="scrollable-menu  ul-list" style={{ width: '368%' }} >
                    <li
                      className="mdl-menu__item animation"
                      id="Semester 1" style={{ width: '100%' }}
                    >
                      Semester 1
                    </li>
                    <li
                      className="mdl-menu__item animation1"
                      id="Semester 2"
                    >
                      Semester 2
                    </li>
                    <li
                      className="mdl-menu__item animation2"
                      id="Semester 3"
                    >
                      Semester 3
                    </li>
                    <li
                      className="mdl-menu__item animation"
                      id="Semester 4" style={{ width: '200px' }}
                    >
                      Semester 4
                    </li>
                    <li
                      className="mdl-menu__item animation1"
                      id="Semester 5"
                    >
                      Semester 5
                    </li>
                    <li
                      className="mdl-menu__item animation2"
                      id="Semester 6"
                    >
                      Semester 6
                    </li>
                    <li
                      className="mdl-menu__item animation"
                      id="Semester 7"
                    >
                      Semester 7
                    </li>
                    <li
                      className="mdl-menu__item animation1"
                      id="Semester 8"
                    >
                      Semester 8
                    </li>
                  </ul>
                </div>
              </div>



            </div>
          </div>
        </div>
        {/* <div className="row ">
          <div className="col-sm-3 box-card1 ">
            <h4 className="card-text">Sick leave</h4>
          </div>
          <div className="col-sm-3  box-card11 ">
            <h4 className="card-text">Casual leave</h4>
          </div>
          <div className="col-sm-3 box-card22 ">
            <h4 className="card-text">On-Duties (ODs)</h4>
          </div>

        </div> */}
        {/* <h4 className="attendance background heading-weight heading-size p-t-40 p-b-20" >Leave Status</h4> */}
        <div className="limiter background">
          <div className="container-table100" style={{ marginTop: '-1900px', width: '700px', marginLeft: '10px' }} >
            <div className="wrap-table100 background" >
              <div className=" background " >
                <table style={{ marginTop: '-3000px', width: '1000px', marginLeft: '0px' }}>
                  <thead >
                    <tr className="row100 head background">
                      <th className={"column100 vertical-border-head heading-weight heading-size column1 "} data-column="column1" >S. No.</th>
                      <th className={"column100 vertical-border-head heading-weight heading-size column1 "}>From Date</th>
                      <th className={"column100 vertical-border-head heading-weight heading-size column1 "}>To Date</th>
                      <th className={"column100 vertical-border-head heading-weight heading-size column1 "}>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentPost.map((singleData, index) => {
                      const fromDate = moment(new Date(singleData.fromDate)).format('MM/DD/YYYY');
                      const toDate = moment(new Date(singleData.toDate)).format('MM/DD/YYYY');
                      return (
                        <tr className="row100">
                          <td className="column100 grid-cont vertical-border column1" key={counter++} data-column="column1">{++index}</td>
                          <td className={"column100 grid-cont vertical-border column2 "} key={counter++}>{fromDate}</td>
                          <td className={"column100 grid-cont vertical-border column3 "} key={counter++}>{toDate}</td>
                          <td className={"column100 grid-cont vertical-border column4 "} key={counter++}>
                            {singleData.status !== "cancelled" ? (singleData.status === "approved" ?
                              <span className="btn btn-success m-t-4 m-r-2 heading-weight button-size" >Approved</span> :
                              <span className="btn btn-warning m-t-4 m-l-2 heading-weight button-size" >Pending</span>) :
                              <span className="btn btn-danger m-t-4 m-l-2 heading-weight button-size" >Rejected</span>
                            }
                          </td>
                        </tr>)
                    })}

                  </tbody>
                </table>

              </div>
              {/* <div className="row">
                <div className="down1">
                  <div >
                    <h2 className="ag">Download leave summary for the semester</h2>
                    <button className="bt-download p">
                      Download Leave Summary

                    </button>
                  </div>

                </div>
              </div> */}
              <div style={{ marginLeft: '12%' }}>
                <Pagination postPerPage={postsPerPage} totalPost={list.length} paginate={paginate} />
              </div></div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default LeaveStatus;
