import React, { Component } from "react";
import "../../../styles/classroom.css";
import StaffSelector1 from "./StaffSelector1";
import StaffSelector2 from "./StaffSelector2";

class AllocateStaffs extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <>
                <div>
                    <h1 style={{marginLeft:'30px'}}>Allocate Subjects to Staffs</h1>
                    <div className="limiter">
                        <div className="container-table100">
                            <div className="wrap-table100"  >
                                <div className="table100 ver5 m-b-110 table table-responsive" style={{boxShadow: '0px 16px 32px rgba(54, 42, 65, 0.32)'}}>
                                    <table style={{width:'100%',marginLeft:'0'}}>
                                        <thead>
                                            <tr className="row100 head">
                                                <th className="column100 column1" data-column="column1">
                                                    S.No
                                                </th>
                                                <th className="column100 column1" data-column="column1">
                                                    Department
                                                </th>
                                                <th className="column100 column1" data-column="column1">
                                                    Department ID
                                                </th>
                                                <th className="column100 column1" data-column="column1">
                                                    Semester
                                                </th>
                                                <th className="column100 column1" data-column="column1">
                                                    Subject Name
                                                </th>
                                                <th className="column100 column1" data-column="column1">
                                                    Subject Code
                                                </th>
                                                <th className="column100 column1" data-column="column1">
                                                    Staff
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!this.props.state.rendered ? <h2>Loading...</h2> : null}
                                            {this.props.state.subjects.length > 0 ? (
                                                this.props.state.subjects.map((sub, i) => (
                                                    <tr key={`subject-row-${i}`}>
                                                        <td className={sub._id}>{i + 1}</td>
                                                        <td className={sub._id}>
                                                            {sub.department && sub.department.toUpperCase()}
                                                        </td>
                                                        <td className={sub._id}>{sub.departmentID}</td>
                                                        <td className={sub._id}>{sub.semester}</td>
                                                        <td className={sub._id}>{sub.name}</td>
                                                        <td className={sub._id}>{sub.code}</td>
                                                        <td className={sub._id}>
                                                            {sub.staff && sub.staff.length ? (
                                                                sub.staff.map((existingStaff, index) => (
                                                                    <StaffSelector1
                                                                        i={i}
                                                                        sub={sub}
                                                                        index={index}
                                                                        existingStaff={existingStaff}
                                                                        addStaffSelector={(i) =>
                                                                            this.props.addStaffSelector(i)
                                                                        }
                                                                        allocateStaffs={(e, subId) =>
                                                                            this.props.allocateStaffs(e, subId)
                                                                        }
                                                                        removeStaffSelector={(
                                                                            i,
                                                                            index,
                                                                            existingStaffId,
                                                                            subId
                                                                        ) =>
                                                                            this.props.removeStaffSelector(
                                                                                i,
                                                                                index,
                                                                                existingStaffId,
                                                                                subId
                                                                            )
                                                                        }
                                                                        state={this.props.state}
                                                                    />
                                                                ))
                                                            ) : (
                                                                <StaffSelector2
                                                                    i={i}
                                                                    sub={sub}
                                                                    addStaffSelector={(i) =>
                                                                        this.props.addStaffSelector(i)
                                                                    }
                                                                    allocateStaffs={(e, subId) =>
                                                                        this.props.allocateStaffs(e, subId)
                                                                    }
                                                                    removeStaffSelector={(
                                                                        i,
                                                                        index,
                                                                        existingStaffId,
                                                                        subId,
                                                                        singleStaff
                                                                    ) =>
                                                                        this.props.removeStaffSelector(
                                                                            i,
                                                                            index,
                                                                            existingStaffId,
                                                                            subId,
                                                                            singleStaff
                                                                        )
                                                                    }
                                                                    state={this.props.state}
                                                                />
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <></>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div style={{ display: "flex", flexDirection: "row" }}> */}
                        <button style={{ display: 'flex', margin: '10px 10px 10px 20px',height:'50px',textAlign:'center',padding:'15px 10px', color:'white', background:'#362A41',   boxShadow: '0px 16px 32px rgba(54, 42, 65, 0.32)'}}
                            type="submit"
                            className="form-btn"
                            class="btn btn-light"
                            onClick={this.props.allocateStaffsSubmit.bind(this)}
                            
                        >
                            Submit
                        </button>
                    {/* </div> */}
                </div>
            </>
        );
    }
}

export default AllocateStaffs;
