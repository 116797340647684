import React, { Component } from "react";
import "../../../styles/table.css";
import "../../../minified-css/material-min.css";
import "../../../styles/dropdowns.css";
import "../../../styles/theme-style.css";
import axios from "axios";
import moment from "moment";
// import { Redirect } from "react-router-dom";
// import CreatedTimetable from "./created-timetable";

class CreateStudentTimetable extends Component {
  constructor(props) {
    super(props);

    this.onChangeSelectedSemester = this.onChangeSelectedSemester.bind(this);
    this.onChangeForm = this.onChangeForm.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      column1: "",
      column2: "",
      start_time: '',
      end_time: '',
      start_time_info: {},
      end_time_info: {},
      period_duration: '',
      tea_breaks: '',
      tea_breaks_position: '',
      tea_breaks_duration: '',
      lunch_breaks_duration: '',
      lunch_breaks_position: '',
      searchAllowed: false,
      absenceList: [],
      background: "",
      isFocussed: "",
      onFocus: false,
      userID: "",
      isManagementPortal: false,
      toDashboard: false,
      table_id: '',
    };
    // this.baseState = this.state;
  }

  onChangeSelectedSemester(e) {
    this.setState({ selectedSemester: e.target.value });
  }

  onChangeForm(event) {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  componentDidMount() {
    this.getPortal();
    if (this.props && this.props.location && this.props.location.userID && this.props.state) {
      const userID = this.props.location.userID;
      const tableID = this.props.state;
      this.setState({ userID: userID.userID });
      this.setState({ table_id: tableID.table_id });
    }
    this.unlisten = this.props.history.listen((location, action) => {
      this.setState(this.baseState);
      this.getPortal();
    });
  }

  /**
   * Checks for portal logged in
   */
  getPortal() {
    const pathArray = this.props.location.pathname.split("/");
    if (
      pathArray.includes("management-portal") ||
      pathArray.includes("hod-portal")
    ) {
      this.setState({ isManagementPortal: true });
      this.fetchDepartmentDetails();
    } else {
      this.setState({ isManagementPortal: false });
    }
  }

  componentWillUnmount() {
    this.unlisten();
  }

  /**
   * Adds the hover class when date is hovered
   */
  dateHover(event) {
    var element = event.target.className;
    if (element === "column100 column2 ") {
      this.setState({ column1: "hov-column-head-ver5" });
    }
  }

  /**
   * Adds the hover class when reason is hovered
   */
  reasonHover(event) {
    var element = event.target.className;
    if (element === "column100 column3 ") {
      this.setState({ column2: "hov-column-head-ver5" });
    }
  }

  typeHover(event) {
    var element = event.target.className;
    if (element === "column100 column4 ") {
      this.setState({ column2: "hov-column-head-ver5" });
    }
  }

  actionHover(event) {
    var element = event.target.className;
    if (element === "column100 column5 ") {
      this.setState({ column2: "hov-column-head-ver5" });
    }
  }

  /**
   * Adds the hover class when userID is hovered
   */
  userHover(event) {
    var element = event.target.className;
    if (element === "column100 column4 ") {
      this.setState({ column3: "hov-column-head-ver5" });
    }
  }

  /**
   * Resets the state variables when hover is removed
   */
  hoverOff() {
    this.setState({
      column1: "",
      column2: "",
      column3: "",
      column4: "",
      column5: "",
    });
  }

  /**
   * Sets the semester selected
   */
  onDropDownSelect(event) {
    this.setState({
      selectedSemester: event.target.id,
      onFocus: false,
      background: "is-hidden",
      hasSemesterValue: true,
    });
    if (this.state.searchAllowed) {
      this.setState({ searchAllowed: false });
    }
  }

  onDropDownFocus() {
    this.setState({
      isFocussed: "is-focused",
      onFocus: true,
      background: "is-shown",
    });
    if (this.state.hasDepartmentValue) {
      this.setState({
        onDepartmentFocus: false,
        backgroundDepartment: "is-hidden",
        isDepartmentFocussed: "is-focused",
      });
    } else {
      this.setState({
        onDepartmentFocus: false,
        backgroundDepartment: "is-hidden",
        isDepartmentFocussed: "is-hidden",
      });
    }
  }

  /**
   * Allows the grid to display the values
   */
  getResult() {
    if (
      this.state.selectedSemester !== "" &&
      this.state.selectedDepartment !== ""
    ) {
      this.fetchAbsenceDetails();
    } else {
      alert("Please select department and semester");
      this.setState({ searchAllowed: false });
    }
  }

  /**
   * Fetches the date of absence based on semester selected
   */
  fetchAbsenceDetails() {
    var userID;
    this.setState({ searchAllowed: true });
    var semester = this.state.selectedSemester;
    if (this.state.isManagementPortal === true) {
      userID = { userID: this.state.selectedStudent };
    } else {
      userID = { userID: this.state.userID };
    }
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/attendance/studentleave/${semester}`, { params: userID })
      .then((response) => {
        console.log("al", response.data);
        this.setState({ absenceList: response.data });
      });
  }

  /**
   * Fetches all department
   */
  fetchDepartmentDetails() {
    axios.get(`${process.env.PUBLIC_URL}/xakal/departmentdetail`).then((response) => {
      this.setState({ departmentDetails: response.data });
    });
  }

  /**
   * Triggers when department dropdown is focused
   */
  onDeptDropDownFocus() {
    this.setState({
      isDepartmentFocussed: "is-focused",
      onDepartmentFocus: true,
      backgroundDepartment: "is-shown",
    });
    if (this.state.hasSemesterValue) {
      this.setState({
        onFocus: false,
        background: "is-hidden",
        isFocussed: "is-focused",
      });
    } else {
      this.setState({
        onFocus: false,
        background: "is-hidden",
        isFocussed: "is-hidden",
      });
    }
  }

  /**
   * Triggers when the department is changed and stores the values in state
   * @param event form values
   */
  handleDepartmentChange(event) {
    this.setState({
      selectedDepartment: event.target.id,
      onDepartmentFocus: false,
      backgroundDepartment: "is-hidden",
      background: "is-hidden",
      hasDepartmentValue: true,
    });
    this.fetchStudentDetailsByDept(event.target.id);
  }

  /**
   * Displays the list of department based on the API response
   */
  displayDepartment() {
    if (
      this.state &&
      this.state.departmentDetails &&
      this.state.departmentDetails.length
    ) {
      return this.state.departmentDetails.map((singleDepartment, index) => {
        return (
          <li className="mdl-menu__item animation" key={index}>
            <button
              id={singleDepartment.name}
              name={singleDepartment.name}
              onClick={this.handleDepartmentChange.bind(this)}
            >
              {singleDepartment.name}
            </button>
          </li>
        );
      });
    }
  }

  /**
   * Fetches all students for selected department
   */
  fetchStudentDetailsByDept(departmentName) {
    this.setState({ studentDetails: [] });
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/studentdetail/department/${departmentName}`)
      .then((response) => {
        this.setState({ studentDetails: response.data });
      });
  }

  /**
   * Removes the selected row
   * @param index selected row index
   */
  removeClick(singleData, i) {
    if (moment(new Date(singleData.fromDate)).isBefore(moment(new Date()))) {
      alert("Cannot cancel this leave. Please contact admin.");
    } else {
      if (window.confirm("Are you sure to cancel this leave?")) {
        let absenceList = 1;
        absenceList.splice(i, 1);
        this.setState({ absenceList });
        axios
          .put(`${process.env.PUBLIC_URL}/xakal/attendance/cancelLeave/${singleData._id}`)
          .then((response) => {
            // this.setState({ studentDetails: response.data });
          });
      }
    }
  }

  onDropDownFocusIn(event) {
    if (event.target) {
      event.target.parentNode.classList.add("is-focused");
      event.target.nextSibling.classList.add("is-shown");
    }
  }

  onFocusOut(event) {
    if (event.target) {
      if (event.target.value === "") {
        event.target.parentNode.classList.remove("is-focused");
      }
      event.target.nextSibling.classList.remove("is-shown");
    }
  }

  /**
   * Displays the list of notes based on the API response
   */

  tt = [
    {
      one: "Physics 1",
      two: "chemistry 1",
      three: "Maths 1",
      four: "EVS 1",
      five: "OOAD 1",
    },
    {
      one: "Physics 2",
      two: "chemistry 2",
      three: "Maths 2",
      four: "EVS 2",
      five: "OOAD 2",
    },
    {
      one: "Physics 3",
      two: "chemistry 3",
      three: "Maths 3",
      four: "EVS 3",
      five: "OOAD 3",
    },
    {
      one: "Physics 4",
      two: "chemistry 4",
      three: "Maths 4",
      four: "EVS 4",
      five: "OOAD 4",
    },
    {
      one: "Physics 5",
      two: "chemistry 5",
      three: "Maths 5",
      four: "EVS 5",
      five: "OOAD 5",
    },
    {
      one: "Physics 6",
      two: "chemistry 6",
      three: "Maths 6",
      four: "EVS 6",
      five: "OOAD 6",
    },
    {
      one: "Physics 7",
      two: "chemistry 7",
      three: "Maths 7",
      four: "EVS 7",
      five: "OOAD 7",
    },
    {
      one: "Physics 8",
      two: "chemistry 8",
      three: "Maths 8",
      four: "EVS 8",
      five: "OOAD 8",
    },
  ];

  displayTable() {
    // let counter = 0;
    return this.state.absenceList.map((singleData, index) => {
      // const fromDate = moment(new Date(singleData.fromDate)).format(
      //   "MM/DD/YYYY"
      // );
      // const toDate = moment(new Date(singleData.toDate)).format("MM/DD/YYYY");
      return (
        <>
          {this.tt.map((i) => (
            <tr className="row100">
              <td className="column100 column1" data-column="column1">
                {++index}
              </td>
              <td
                className={"column100 column2 "}
                onMouseEnter={this.userHover.bind(this)}
                onMouseLeave={this.hoverOff.bind(this)}
              >
                {i.one}
              </td>
              <td
                className={"column100 column2 "}
                onMouseEnter={this.dateHover.bind(this)}
                onMouseLeave={this.hoverOff.bind(this)}
              >
                {i.two}
              </td>
              <td
                className={"column100 column3 "}
                onMouseEnter={this.reasonHover.bind(this)}
                onMouseLeave={this.hoverOff.bind(this)}
              >
                {i.three}
              </td>
              <td
                className={"column100 column4 "}
                onMouseEnter={this.typeHover.bind(this)}
                onMouseLeave={this.hoverOff.bind(this)}
              >
                {i.four}
              </td>
              <td
                className={"column100 column5 "}
                onMouseEnter={this.typeHover.bind(this)}
                onMouseLeave={this.hoverOff.bind(this)}
              >
                {i.five}
              </td>
            </tr>
          ))}
        </>
      );
    });
  }

  /**
   * Triggers when student is focused
   */
  onStudentFocussed() {
    this.setState({
      isStudentFocussed: "is-focused",
      onFocus: false,
      onStudentFocus: true,
      backgroundStudent: "is-shown",
    });
  }

  /**
   * Triggers when the student is changed and stores the values in state
   * @param event form values
   */
  handleStudentChange(event) {
    this.setState({
      selectedStudentName: event.target.name,
      selectedStudent: event.target.id,
      onStudentFocus: false,
      backgroundStudent: "is-hidden",
    });
  }

  /**
   * Displays the list of student based on the API response
   */
  displayStudent() {
    if (
      this.state &&
      this.state.studentDetails &&
      this.state.studentDetails.length
    ) {
      return this.state.studentDetails.map((singleStudent, index) => {
        return (
          <li className="mdl-menu__item animation" key={index}>
            <button
              id={singleStudent.userID}
              name={singleStudent.name}
              onClick={this.handleStudentChange.bind(this)}
            >
              {singleStudent.name}
            </button>
          </li>
        );
      });
    }
  }

  tt = [
    {
      name: "Monday",
    },
    {
      name: "Tuesday",
    },
    {
      name: "Wednesday",
    },
    {
      name: "Thursday",
    },
    {
      name: "Friday",
    },
  ];

  period = [
    {
      name: "Period 1",
      stime: "Period 1 Starting Time",
      etime: "Period 1 Ending Time",
    },
    {
      name: "Period 2",
      stime: "Period 2 Starting Time",
      etime: "Period 2 Ending Time",
    },
    {
      name: "Period 3",
      stime: "Period 3 Starting Time",
      etime: "Period 3 Ending Time",
    },
    {
      name: "Period 4",
      stime: "Period 4 Starting Time",
      etime: "Period 4 Ending Time",
    },
    {
      name: "Period 5",
      stime: "Period 5 Starting Time",
      etime: "Period 5 Ending Time",
    },
    {
      name: "Period 6",
      stime: "Period 6 Starting Time",
      etime: "Period 6 Ending Time",
    },
  ];

  resetForm() {
    this.setState({
      values: [
        {
          name: "",
          designation: "",
          qualification: "",
          contact: "",
          email: "",
          departmentName: "",
          emergencyContact: "",
          joiningDate: moment(new Date()).format("hh:mm A"),
        },
      ],
    });
  }

  handleFormChange(i, event) {
    if (event && event.target) {
      let values = [...this.state.values];
      const { name, value } = event.target;
      values[i][name] = value;
      this.setState({ values });
    }
  }

  handleCheckClick = (i, event) => {
    let values = [...this.state.values];
    values[i]["isElective"] = event.target.checked;
    this.setState({ values });
  };

  handleSubmit = () => {
    this.setState(() => ({ toDashboard: true }));
  };

  calcTotalPeriodsPerDay(periods_in_a_day, tea_breaks, lunch_break) {
    return Number(periods_in_a_day) + Number(tea_breaks) + Number(lunch_break);
  }

  getMinutesValue(timeInHours, lunchCount = 1) {
    return timeInHours * 60;
  }

  computeStartTime(startTime) {
    const timeData = startTime;
    const start_time = timeData.split(" ")[0];
    const hh = start_time.split(":")[0],
      mm = start_time.split(":")[1],
      meridiem = timeData.split(" ")[1],
      minutesValue = Number(start_time.split(":")[0]) * 60;

    return {
      hh,
      mm,
      meridiem,
      minutesValue,
    };
  }

  computeEndTime(startTime, periodsPerDay, lunchCount = 1) {
    console.log('startTime', startTime)
    let data = this.state;
    console.log('computeEndTime', {
      startTimeMinutesValue: Number(startTime.minutesValue),
      totalPeriodsDuration: (Number(data.period_duration) * Number(periodsPerDay)),
      lunchDuration: (Number(lunchCount) * Number(data.lunch_breaks_duration)),
      teaBreaksDuration: (Number(data.tea_breaks) * Number(data.tea_breaks_duration))
    })
    let endTime = {};
    endTime.minutesValue =
      Number(startTime.minutesValue) +
      (Number(data.period_duration) * Number(periodsPerDay)) +
      (Number(lunchCount) * Number(data.lunch_breaks_duration)) +
      (Number(data.tea_breaks) * Number(data.tea_breaks_duration));
    endTime.mm = endTime.minutesValue % 60;
    endTime.hh = Math.floor(endTime.minutesValue / 60);
    endTime.meridiem = "am";
    if (endTime.hh > 12) {
      endTime.hh %= 12;
      endTime.meridiem = "pm";
    }
    if (endTime.mm % 60 === 0) {
      endTime.mm = "00";
    }
    if (String(endTime.mm).length == 1) {
      endTime.mm = `0${endTime.mm}`;
    }
    return endTime;
  }

  onSubmit(e) {
    e.preventDefault();
    const {
      start_time, end_time, period_duration, tea_breaks, tea_breaks_position,
      tea_breaks_duration, lunch_breaks_duration, lunch_breaks_position } = this.state;

    const data = {
      start_time,
      end_time,
      period_duration,
      tea_breaks,
      tea_breaks_position,
      tea_breaks_duration,
      lunch_breaks_duration,
      lunch_breaks_position,
      start_time_info: this.computeStartTime(start_time)
    };
    const screen_one_data = this.props.location.state;
    data.totalPeriodsPerDay = this.calcTotalPeriodsPerDay(screen_one_data.periods_in_a_day, data.tea_breaks, 1)
    data.end_time_info = this.computeEndTime(data.start_time_info, screen_one_data.periods_in_a_day)
    console.log('screen one data', screen_one_data)
    console.log('data', data)

    this.setState({
      end_time: `${data.end_time_info.hh}:${data.end_time_info.mm} ${data.end_time_info.meridiem}`
    })

    axios.post('/xakal/period/', data)
      .then(function (response) {
        console.log(response.data)
      });

    // alert(`responses :
    //   ${start_time} ${end_time} ${period_duration} ${tea_breaks}
    //   ${tea_breaks_position} ${tea_breaks_duration} ${lunch_breaks_duration} ${lunch_breaks_position}
    //   `);

    alert('Details submitted');
  }

  render() {

    return (

      <form>
        <div className="student">
          <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 className="h3 mb-0 text-gray-800 m-t-20 m-l-20">
              Create Student Time Table - Screen 2  {this.state.table_id}
            </h1>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card-box-student">

                <div className="card-body row">


                  { /* choose period div */}

                  <div className="row">
                    {/* subject 1 started...*/}
                    <div className="card-body row">

                      {/* period 1 input starts */}
                      <div className="col-lg-3  p-r-5">
                        <div
                          className={
                            "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width is-focused"
                          }
                        >
                          <input
                            autoComplete="off"
                            placeholder=""
                            className="mdl-textfield__input display-border"
                            type="text"
                            id="startingTime"
                            onChange={this.onChangeForm}
                            name="start_time"
                          />
                          <label
                            htmlFor="startingTime"
                            className={"mdl-textfield__label "}
                          >
                            Start Time of the Day

                          </label>
                        </div>
                      </div>

                      <div className="col-lg-3  p-r-5">
                        <div
                          className={
                            "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width is-focused"
                          }
                        >
                          <input
                            autoComplete="off"
                            placeholder=""
                            className="mdl-textfield__input display-border"
                            type="text"
                            id="startingTime"
                            onChange={this.onChangeForm}
                            name="end_time"
                            value={this.state.end_time}
                            disabled
                          />
                          <label
                            htmlFor="startingTime"
                            className={"mdl-textfield__label "}
                          >
                            End Time of the Day

                          </label>
                        </div>
                      </div>

                      {/* period 1 input close */}
                      <div className="col-lg-3  p-r-5">
                        <div
                          className={
                            "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width is-focused"
                          }
                        >
                          <input
                            autoComplete="off"
                            placeholder="(in minutes)"
                            className="mdl-textfield__input display-border"
                            type="number"
                            id="startingTime"
                            onChange={this.onChangeForm}
                            name="period_duration"
                          />
                          <label
                            htmlFor="startingTime"
                            className={"mdl-textfield__label "}
                          >
                            Duration of Period <br />
                          </label>
                        </div>
                      </div>

                      <div className="col-lg-3 p-r-5">
                        <div
                          className={
                            "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width is-focused"
                          }
                        >
                          <input
                            autoComplete="off"
                            placeholder="(optional)"
                            className="mdl-textfield__input display-border"
                            type="number"
                            id="startingTime"
                            onChange={this.onChangeForm}
                            name="tea_breaks"
                          />
                          <label
                            htmlFor="endingTime"
                            className={"mdl-textfield__label "}
                          >
                            Number of Tea Breaks <br />
                          </label>
                        </div>
                      </div>
                    </div>
                    {/* subject 2 started...*/}
                    <div className="card-body row">

                      {/* period 1 input starts */}
                      <div className="col-lg-3  p-r-5">
                        <div
                          className={
                            "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width is-focused"
                          }
                        >
                          <input
                            autoComplete="off"
                            placeholder="(After nth period)"
                            className="mdl-textfield__input display-border"
                            type="text"
                            id="startingTime"
                            onChange={this.onChangeForm}
                            name="tea_breaks_position"
                          />
                          <label
                            htmlFor="startingTime"
                            className={"mdl-textfield__label "}
                          >
                            Position of Tea Breaks <br />

                          </label>
                        </div>
                      </div>

                      <div className="col-lg-3  p-r-5">
                        <div
                          className={
                            "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width is-focused"
                          }
                        >
                          <input
                            autoComplete="off"
                            placeholder="(in minutes)"
                            className="mdl-textfield__input display-border"
                            type="number"
                            id="startingTime"
                            onChange={this.onChangeForm}
                            name="tea_breaks_duration"
                          />
                          <label
                            htmlFor="startingTime"
                            className={"mdl-textfield__label "}
                          >
                            Duration of Tea Breaks <br />

                          </label>
                        </div>
                      </div>

                      {/* period 1 input close */}
                      <div className="col-lg-3  p-r-5">
                        <div
                          className={
                            "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width is-focused"
                          }
                        >
                          <input
                            autoComplete="off"
                            placeholder="(in minutes)"
                            className="mdl-textfield__input display-border"
                            type="number"
                            id="startingTime"
                            onChange={this.onChangeForm}
                            name="lunch_breaks_duration"
                          />
                          <label
                            htmlFor="startingTime"
                            className={"mdl-textfield__label "}
                          >
                            Duration of Lunch Breaks <br />
                          </label>
                        </div>
                      </div>

                      <div className="col-lg-3 p-r-5">
                        <div
                          className={
                            "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width is-focused"
                          }
                        >
                          <input
                            autoComplete="off"
                            placeholder="(After nth period)"
                            className="mdl-textfield__input display-border"
                            type="number"
                            id="startingTime"
                            onChange={this.onChangeForm}
                            name="lunch_breaks_position"
                          />
                          <label
                            htmlFor="endingTime"
                            className={"mdl-textfield__label "}
                          >
                            Position of Lunch Breaks <br />
                          </label>
                        </div>
                      </div>
                    </div>


                  </div>

                </div>
                <div className="col-sm-8 p-b-20">
                  <button
                    type="button"
                    onClick={this.onSubmit.bind(this)}
                    // onClick={() =>
                    //   this.props.history.push(
                    //     "/management-portal/created-timetable"
                    //   )
                    // }
                    className="btn btn-primary m-t-15 m-l-30"
                  >
                    Generate Table
                  </button>
                </div>
              </div>
            </div>
          </div>

          {this.state.searchAllowed ? (
            <div className="card-body row">
              <div className="col-sm-8 p-t-20">
                <button
                  type="button"
                  onClick={this.resetForm.bind(this)}
                  className="btn btn-primary m-t-15 m-l-30"
                  style={{marginLeft:50}}
                >
                  Add
                </button>
                <button
                  type="button"
                  onClick={this.resetForm.bind(this)}
                  className="btn btn-primary m-t-15 m-l-30"
                >
                  Submit
                </button>
                <button
                  type="button"
                  onClick={this.resetForm.bind(this)}
                  className="btn btn-primary m-t-15 m-l-30"
                  style={{boxShadow: '0px 16px 32px rgba(54, 42, 65, 0.32)'
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          ) : (
            <p></p>
          )}
        </div>

      </form>
    );
  }
}

export default CreateStudentTimetable;
