import React from "react";
import { useState, useEffect } from "react";
import "../styles/login.css";
import "../styles/utils.css";
import Logo from "../images/Mask group.png";
import { Link } from "react-router-dom";
import axios from "axios";
const Forgetpassword = () => {
  const [mail, setMail] = useState();
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [enable, setEnable] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [data, setData] = useState();
  const [valid, setValid] = useState(false);
  const [verify, setVerify] = useState(false);
  const [errors, setErrors] = useState(null);
  const [error2, setError2] = useState(null);
  const [count, setCount] = useState(5);
  const [disable, setDisable] = useState(true);
  const [message, setMessage] = useState(null);
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const [spinner, setSpiner] = useState(false);
  const [otp, setOtp] = useState("");
  const handleChange = (event) => {
    if (!isValidEmail(event.target.value)) {
      setError("Email is invalid");
      setEnabled(false);
    } else {
      setError(null);
      setEnabled(!enabled);
    }

    setEmail(event.target.value);
  };

  const handleInput = (e) => {
    e.preventDefault();
    axios
      .post(`${process.env.PUBLIC_URL}/xakal/validateForgotEmail`, {
        email,
      })
      .then((response) => {
        if (response && response.data && response.data.error) {
          setMail(response.data.error);
        }
      })
      .catch((err) => console.log(err.error));
  };

  function isValidOtp(otp) {
    return /^[0-9]{4,4}$/.test(otp);
  }

  const handleChanges = (event) => {
    setOtp(event.target.value);
    if (!isValidOtp(event.target.value)) {
      setErrors("Incorrect OTP.");
      setValid(false);
    } else {
      setErrors(null);
      setValid(true);
    }
  };

  const handleEnable = (event) => {
    event.preventDefault()
    setEnable(true);
    setMinutes(1);
    setSeconds(0);
    setEnabled(false);
    // sendOtp();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  const sendOtp = async (e) => {
    if (email === "") {
      alert("Enter Your Email !");
    } else if (!email.includes("@")) {
      alert("Enter Valid Email !");
    } else {
      setSpiner(true);
      const data = {
        email: email,
      };

      const response = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/forgot-password`,
        data
      );
      if (response.status === 200) {
        setSpiner(false);
        handleEnable(e);
      } else {
        // toast.error(response.response.data.error);
      }
    }
  };
  const resendOtp = async (e) => {
    e.preventDefault();
    
    if (email === "") {
      alert("Enter Your Email !");
    } else if (!email.includes("@")) {
      alert("Enter Valid Email !");
    } else {
      // setSpiner(true);
      const data = {
        email: email,
      };

      const response = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/resendPasswordOtp`,
        data
      );
      if (response.status === 200) {
        // setSpiner(false);
        handleEnable(e);
      } else {
        // toast.error(response.response.data.error);
      }
    }
  };
  const verifyOtp = async (e) => {
    e.preventDefault();
    if (otp === "") {
      alert("Enter Your Otp");
      setVerify(true);
      setEnabled(true);
    } else if (!/[^a-zA-Z]/.test(otp)) {
      setErrors("Enter Valid Otp");
    } else {
      axios
        .post(`${process.env.PUBLIC_URL}/xakal/reset-password`, {
          otp,
          email,
        })
        .then((response) => {
          console.log(response);
          setVerify(true);
          setEnable(true);
          setMessage(response.data.message);

          if (response.data.success) {
            setVerify(true);
            setMessage(response.data.message);
            console.log(response.data.message);
          } else if (count > 1) {
            setCount(count - 1);
            setError2("Incorrect OTP.");
          } else if (count === 1) {
            setMinutes(15);
            setSeconds(0);
            setCount(5);
            setError2(null);
            setValid(false);
            setDisable(false);
          } else {
            setErrors(response.data.error);
          }
        })
        .catch((err) => setErrors(err.error));
    }
  };
  return (
    <div>
      <div className="container-login100 waveBg">
        <img className="logo-in" src={Logo} alt="img" />
        <div className="wrap-login100 p-b-20">
          <div className="d-flex">
            <div className="">
              <Link to="/">
                <div className="back">
                  <i className="fa fa-arrow-left"></i>
                </div>
              </Link>
              <div className="login-title2" style={{ color: "#362A41" }}>
                <h1> Forgot Your Password ?</h1>
              </div>
              <p>Reset your password in two quick steps</p>
              <p className="textpass font-face-gm">Email-ID</p>
              <div className={"wrap-input100"}>
                <input
                  autoComplete="off"
                  className={"input101 "}
                  onChange={handleChange}
                  onBlur={handleInput}
                />
              </div>
              {mail && <span className="text-danger">{mail}</span>}
              <p style={{ color: "red" }}>{error}</p>

              <div className="container-login100-form-btn m-b-20">
                <button
                  className="login100-form-btn"
                  disabled={!enabled}
                  style={{ opacity: enabled ? "100%" : "50%" }}
                  onClick={sendOtp}
                >
                  Get OTP
                </button>
              </div>

              {enable ? (
                <div>
                  <div className="verify">
                    <div className="container-login100-form-btn">
                      <p className="otp">OTP</p>
                    </div>

                    <div className={"wrap-input100 "}>
                      <input
                        type="password"
                        className={"input102 "}
                        // name="otp"
                        // value={otp}
                        onChange={handleChanges}
                        disabled={!disable && seconds}
                      ></input>
                    </div>

                    <div>
                      <button
                        className="verify-text"
                        onClick={verifyOtp}
                        disabled={!valid}
                        style={{ opacity: valid ? "100%" : "50%" }}
                      >
                        VERIFY
                      </button>
                    </div>
                  </div>

                  <p
                    style={{
                      color: "red",
                      position: "relative",
                      left: "130px",
                      marginTop: "-35px",
                    }}
                  >
                    {errors}
                  </p>

                  {errors ? (
                    <div style={{ display: "flex", gap: "30px" }}>
                      <p style={{ color: "red" }}>{count} attempt left</p>
                      <p style={{ color: "red" }}>
                        After {count} attempt you have to wait for 1 minute.
                      </p>
                    </div>
                  ) : (
                    ""
                  )}

                  {verify ? (
                    <p
                      style={{
                        color: "green",
                        fontSize: "20px",
                        textAlign: "center",
                        position: "relative",
                        top: "10px",
                      }}
                    >
                      {message}
                    </p>
                  ) : (
                    ""
                  )}

                  {seconds > 0 || minutes > 0 ? (
                    <p>
                      Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                      {seconds < 10 ? `0${seconds}` : seconds}
                    </p>
                  ) : (
                    <p>
                      Didn't recieve code?{" "}
                      <span style={{ color: "blue" }} onClick={resendOtp}>
                        Resend OTP
                      </span>
                    </p>
                  )}
                </div>
              ) : (
                <></>
              )}

              {enable ? (
                <div className="container-login100-form-btn">
                  <Link to={{ pathname: "/new-password", email: email }}>
                    <button
                      className="login100-form-btn"
                      type="submit"
                      disabled={!verify}
                      style={{ opacity: verify ? "100%" : "50%" }}
                    >
                      Change Password
                    </button>
                  </Link>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forgetpassword;
