import React, { Component } from "react";
import axios from "axios";
import Pagination from "../../utils/pagination";
import "../../styles/table.css";
import "../../styles/dropdown.css";
import "../../styles/course-dropdown.css";
import "../../styles/classnote.css";
import "../../styles/responsiveDesign.css";
import "../../styles/responsiveDesign.css";
import "../../styles/responsive.css";

class DepartmentDetailsMaintain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      departmentDetails: [],
      isEdit: false,
      isDelete: false,
      userID: "",
      currentPage: 1,
      postsPerPage: 5,
    };
    this.baseState = this.state;
    this.departmentArray = [];
    this.departmentID = [];
    this.paginate = this.paginate.bind(this);
  }
  deleteArray = [];
  componentDidMount() {
    if (this.props && this.props.location && this.props.location.userID) {
      this.setState({
        routerLink: this.props.location.pathname,
        userID: this.props.location.userID.userID,
      });
    }
    this.fetchDepartmentDetails();
  }

  fetchDepartmentDetails() {
    this.deleteArray = [];
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/departmentdetail`)
      .then((response) => {
        this.setState({
          departmentDetails: response.data.data,
          values: response.data,
        });
      });
  }

  displayDepartmentDetails() {
    const indexOfLastPost = this.state.currentPage * this.state.postsPerPage;
    const indexOfFirstPost = indexOfLastPost - this.state.postsPerPage;
    let da = this.state.departmentDetails
    let da1 = this.state.departmentDetails.data
    let currentPost = []
    if(da1 && da1.length>0){
       currentPost = da1.slice(indexOfFirstPost, indexOfLastPost)
    }else{
      currentPost = da.slice(indexOfFirstPost, indexOfLastPost)
    }
   
   

    console.log(this.state.departmentDetails);
    return currentPost.map((singleData, index) => {
      return (
        <tr className="row100" key={index++}>
          <td
            className={"column100 grid-cont vertical-border column3"}
            key={index++}
          >
            {singleData.name}
          </td>
          <td
            className={"column100 grid-cont vertical-border column3"}
            key={index++}
          >
            {singleData.headOfDepartment}
          </td>
          <td
            className={"column100 grid-cont vertical-border column3"}
            key={index++}
          >
            {singleData.email}
          </td>
          <td
            className={"column100 grid-cont vertical-border column3"}
            key={index++}
          >
            {singleData.contact}
          </td>
          <td
            className={"column100 grid-cont vertical-border column3"}
            key={index++}
          >
            {singleData.startingYear}
          </td>
          <td
            className={"lcolumn100 grid-cont vertical-border column3"}
            key={index++}
          >
            {singleData.studentCapacity}
          </td>
        </tr>
      );
    });
  }

  /**
   * Adds remove element in the end
   */
  deleteDepartmentDetails() {
    const indexOfLastPost = this.state.currentPage * this.state.postsPerPage;
    const indexOfFirstPost = indexOfLastPost - this.state.postsPerPage;
    const currentPost = this.state.departmentDetails.slice(indexOfFirstPost, indexOfLastPost)

    return currentPost.map((singleData, index) => {
      return (
        <tr className="odd gradeX" key={index++}>
          <td className={"left vertical-border column100 column2"} key={index++}>
            {singleData.name}
          </td>
          <td className={"left vertical-border column100 column2"} key={index++}>
            {singleData.headOfDepartment}
          </td>
          <td className={"left vertical-border column100 column2"} key={index++}>
            {singleData.email}
          </td>
          <td className={"left vertical-border column100 column2"} key={index++}>
            {singleData.contact}
          </td>
          <td className={"left vertical-border column100 column2"} key={index++}>
            {singleData.startingYear}
          </td>
          <td className={"left vertical-border column100 column2"} key={index++}>
            {singleData.studentCapacity}
          </td>
          <td className="vertical-border column100 column2">
            {" "}
            <button
              type="button"
              onClick={(i) => this.removeClick(singleData, (index -= 7))}
              className="btn btn-danger m-t-4 m-l-30"
            >
              X
            </button>
          </td>
        </tr>
      );
    });
  }

  /**
   * Removes the selected row
   * @param index selected row index
   */
  removeClick(singleData, i) {
    this.deleteArray.push(singleData);
    let departmentDetails = [...this.state.departmentDetails];
    departmentDetails.splice(i, 1);
    this.setState({ departmentDetails });
  }

  /**
   * Reverts back to the original state
   */
  discardChanges() {
    this.departmentArray = [];
    this.departmentID = [];
    this.setState({
      isEdit: false,
      isDelete: false,
      departmentDetails: this.state.values,
    });
    this.displayDepartmentDetails();
    this.fetchDepartmentDetails()
  }

  onEdit(singleElement, changedField, context) {
    const userID = singleElement._id;
    if (this.departmentArray.length) {
      this.departmentArray.forEach((element) => {
        if (element._id === userID) {
          element[changedField] = context.target.value;
        } else {
          if (!this.departmentID.includes(userID)) {
            this.insertUpdatedDetails(
              userID,
              singleElement,
              changedField,
              context
            );
          }
        }
      });
    } else {
      if (!this.departmentID.includes(userID)) {
        this.insertUpdatedDetails(userID, singleElement, changedField, context);
      }
    }
  }

  insertUpdatedDetails(userID, singleElement, changedField, context) {
    this.departmentID.push(userID);
    this.departmentArray.push(singleElement);
    this.departmentArray.forEach((element) => {
      if (element._id === userID) {
        element[changedField] = context.target.value;
      }
    });
  }

  /**
   * sets the edit flag to true
   */
  redirect() {
    this.setState({ isEdit: true, isDelete: false });
  }

  /**
   * sets the delete flag to true
   */
  deleteRedirect() {
    this.setState({ isDelete: true, isEdit: false });
  }

  updateDetails() {
    let isUpdated = false;
    if (
      this.state.isEdit &&
      this.departmentArray &&
      this.departmentArray.length
    ) {
      this.departmentArray.forEach((element) => {
        const params = {
          name: element.name,
          headOfDepartment: element.headOfDepartment,
          email: element.email.toLowerCase(),
          updatedBy: this.state.userID.toUpperCase(),
          updatedDate: new Date(Date.now()).toLocaleString(),
          contact: element.contact,
          startingYear: element.startingYear,
          studentCapacity: element.studentCapacity,
        };
        axios
          .put(
            `${process.env.PUBLIC_URL}/xakal/departmentdetail/update/${element._id}`,
            params
          )
          .then(() => {
            if (!isUpdated) {
              alert("Updated Successfully");
            }
            isUpdated = true;
            this.setState({ isEdit: false });
          })
          .catch((err) => console.log(err));
      });
    } else if (
      this.state.isDelete &&
      this.deleteArray &&
      this.deleteArray.length
    ) {
      this.deleteArray.forEach((element) => {
        axios
          .delete(
            `${process.env.PUBLIC_URL}/xakal/departmentdetail/${element._id}`
          )
          .then(() => {
            if (!isUpdated) {
              alert("Deleted Successfully");
            }
            isUpdated = true;
            this.setState({ isDelete: false });
            this.fetchDepartmentDetails();
          })
          .catch((err) => console.log(err));
      });
    }
  }

  editDepartmentDetails() {
    const indexOfLastPost = this.state.currentPage * this.state.postsPerPage;
    const indexOfFirstPost = indexOfLastPost - this.state.postsPerPage;
    const currentPost = this.state.departmentDetails.slice(
      indexOfFirstPost,
      indexOfLastPost
    );

    return currentPost.map((singleData, index) => {
      return (
        <tr className="odd gradeX" key={index++}>
          <td className={"left"} key={index++}>
            <input
              type="text"
              className="add-border"
              onChange={this.onEdit.bind(this, singleData, "name")}
              defaultValue={singleData.name}
            ></input>
          </td>
          <td className={"left"} key={index++}>
            <input
              type="text"
              className="add-border"
              onChange={this.onEdit.bind(this, singleData, "headOfDepartment")}
              defaultValue={singleData.headOfDepartment}
            ></input>
          </td>
          <td className={"left"} key={index++}>
            <input
              type="text"
              className="add-border"
              onChange={this.onEdit.bind(this, singleData, "email")}
              defaultValue={singleData.email}
            ></input>
          </td>
          <td className={"left"} key={index++}>
            <input
              type="number"
              className="add-border"
              onChange={this.onEdit.bind(this, singleData, "contact")}
              defaultValue={singleData.contact}
            ></input>
          </td>
          <td className={"left"} key={index++}>
            <input
              type="number"
              className="add-border"
              onChange={this.onEdit.bind(this, singleData, "startingYear")}
              defaultValue={singleData.startingYear}
            ></input>
          </td>
          <td className={"left"} key={index++}>
            <input
              type="number"
              className="add-border"
              onChange={this.onEdit.bind(this, singleData, "studentCapacity")}
              defaultValue={singleData.studentCapacity}
            ></input>
          </td>
        </tr>
      );
    });
  }

  paginate(num) {
    this.setState({ currentPage: num });
  }

  render() {
    return (
      <div className="container-fluid background bk">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <nav
            className="navbar navbar-expand-lg  navprac bar"
            style={{ marginTop: "-70px" }}
          >
            <div className="title">Department</div>
            <div className="collapse navbar-collapse">
              <form className="form-inline my-2 my-lg-0">
                <input
                  style={{
                    width: "500px",
                    marginLeft: "120px",
                    backgroundColor: "rgba(54, 42, 65, 0.04)",
                    fontStyle: "italic",
                    height: "45px",
                  }}
                  className="form-control mr-sm-2 "
                  type="search"
                  placeholder="Search....."
                  aria-label="Search"
                ></input>
              </form>
            </div>
          </nav>
          <div className="background" style={{ color: "#714F90" }}>
            <h4
              className="head "
              style={{ marginLeft: "-800px", marginTop: "150px" }}
            >
              All Departments
            </h4>

            {/* <h1 className="h3 mb-0 text-gray-800 m-t-20">Notes</h1> */}
          </div>
          {/* <h1 className="h3 mb-0 text-gray-800 m-t-20">All Departments</h1> */}
        </div>
        <div class="col-md-12">
          <div class=" background" >
            <div>
              <table className="table" >
                <tr>
                  <th className="column100  column2 background vertical-border heading-weight heading-size">
                    {" "}
                    Name{" "}
                  </th>
                  <th className="column100  column2 background vertical-border heading-weight heading-size">
                    {" "}
                    Head Of Department{" "}
                  </th>
                  <th
                    className="column100 column2 background vertical-border heading-weight heading-size"
                    data-column="column3"
                  >
                    {" "}
                    Email{" "}
                  </th>
                  <th
                    className="column100 column2 background vertical-border heading-weight heading-size"
                    data-column="column4"
                  >
                    {" "}
                    Mobile{" "}
                  </th>
                  <th
                    className="column100 column2 background vertical-border heading-weight heading-size"
                    data-column="column5"
                  >
                    {" "}
                    Starting Year{" "}
                  </th>
                  <th
                    className="column100 column2 background vertical-border heading-weight heading-size"
                    data-column="column6"
                  >
                    {" "}
                    Student Capacity{" "}
                  </th>
                  {this.state.isDelete ?<th className="column100 column2 background vertical-border heading-weight heading-size">Action</th> : <></>}
                </tr>

                {this.state.isEdit ? (
                  <tbody>{this.editDepartmentDetails()}</tbody>
                ) : this.state.isDelete ? (
                  <tbody>{this.deleteDepartmentDetails()}</tbody>
                ) : (
                  <tbody>{this.displayDepartmentDetails()}</tbody>
                )}
              </table>
            </div>

            {this.state.isEdit ? (
              <Pagination
                postPerPage={this.state.postsPerPage}
                totalPost={this.state.departmentDetails.length}
                paginate={this.paginate}
              />
            ) : this.state.isDelete ? (
              <Pagination
                postPerPage={this.state.postsPerPage}
                totalPost={this.state.departmentDetails.length}
                paginate={this.paginate}
              />
            ) : (
              <Pagination
                postPerPage={this.state.postsPerPage}
                totalPost={this.state.departmentDetails.length}
                paginate={this.paginate}
              />
            )}

            <div className="d-flex">
              {this.state.routerLink ===
                "/management-portal/department-details" ||
              this.state.routerLink === "/hod-portal/department-details" ? (
                <div hidden={this.state.isEdit} className="right p-t-20">
                  <button
                    type="button"
                    onClick={this.deleteRedirect.bind(this)}
                    hidden={this.state.isDelete}
                    className=" delete m-t-15 m-l-20 del-details"
                  >
                    Delete Details
                  </button>
                  {this.state.isDelete ? (
                    <button
                      type="button"
                      onClick={this.updateDetails.bind(this)}
                      className=" m-t-15 m-l-30 save"
                    >
                      Save
                    </button>
                  ) : (
                    <p></p>
                  )}
                  {this.state.isDelete ? (
                    <button
                      type="button"
                      onClick={this.discardChanges.bind(this)}
                      className=" m-t-15 m-l-30 cancel"
                    >
                      Cancel
                    </button>
                  ) : (
                    <p></p>
                  )}
                </div>
              ) : (
                <p></p>
              )}

              {this.state.routerLink ===
                "/management-portal/department-details" ||
              this.state.routerLink === "/hod-portal/department-details" ? (
                <div hidden={this.state.isDelete} className="right p-t-20">
                  <button
                    type="button"
                    onClick={this.redirect.bind(this)}
                    hidden={this.state.isEdit}
                    className=" delete m-t-15 m-l-30 edit-details"
                  >
                    Edit Details
                  </button>
                  {this.state.isEdit ? (
                    <button
                      type="button"
                      onClick={this.updateDetails.bind(this)}
                      className=" m-t-15 m-l-30 save"
                    >
                      Save
                    </button>
                  ) : (
                    <p></p>
                  )}
                  {this.state.isEdit ? (
                    <button
                      type="button"
                      onClick={this.discardChanges.bind(this)}
                      className=" m-t-15 m-l-30 save"
                    >
                      Cancel
                    </button>
                  ) : (
                    <p></p>
                  )}
                </div>
              ) : (
                <p></p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DepartmentDetailsMaintain;
