import React, { useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Amplify, Auth } from "aws-amplify";

Amplify.configure({
  Auth: {
    identityPoolId: "us-east-1:9f20ee67-2689-4af8-9d12-c9893279b334",
    region: "us-east-1",
    identityPoolRegion: "us-east-1",
    userPoolId: "us-east-1_sExLCIvAJ",
    userPoolWebClientId: "7hugeimfu8fnk38k4df5nb1jf1",
    oauth: {
      domain: "xakalpro.auth.us-east-1.amazoncognito.com",
      redirectSignIn: "https://stage.thexakal.com/",
      redirectSignOut: "https://stage.thexakal.com/",
      responseType: "token",
    },
  },
});
const NewDashboard = () => {
  const history = useHistory();
  const logout = () => {
    axios
      .post(`${process.env.PUBLIC_URL}/xakal/logout`)
      .then((response) => {
        console.log(response);
        if (response) {
          history.push("/");
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <>
      <div style={{ textAlign: "center", fontSize: "40px" }}>NewDashboard</div>

      <button className="btn- btn-primary" onClick={logout}>
        Logout
      </button>
      <button onClick={() => Auth.signOut()}>Sign Out</button>
    </>
  );
};

export default NewDashboard;
