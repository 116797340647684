import React, { Component } from "react";
// import axios from "axios";
// import moment from "moment";
import "../../../../styles/table.css";
import "../../../../minified-css/material-min.css";
import "../../../../styles/dropdowns.css";
import "../../../../styles/theme-style.css";

class ShowBusTemplate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            
        }
    }

    componentDidMount() {
    }

    render() {
        return (
            <div class="item col-md-3 add-bus-container" style={{ margin: 10, backgroundColor: 'white', cursor: 'default' }}>
                <div style={{ textTransform: 'uppercase' ,color: 'black', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', flexDirection: 'column', height: 200 }}>
                    <div>
                        <h4 style={{ textAlign: 'center', fontWeight: 'bold' }}>{this.props.busStop.name}</h4>
                    </div>
                    <div>
                        <h4 style={{ textAlign: 'center' }}>Arriving Buses:</h4>
                    </div>
                    <div style={{display: 'flex'}}>
                        {this.props.busStop.arrivingBuses.length > 0 && this.props.busStop.arrivingBuses.map(bus => (
                            <button style={{ backgroundColor: '#f5f5ef', borderRadius: '50%', textAlign: 'center', fontWeight: 'bold', padding: 10, margin: 5, color: 'black' }}>{bus.busNumber}</button>
                        ))}
                    </div>
                    <div>
                        <button
                            type="button"
                            className="btn btn-dark m-t-15 m-l-30"
                            onClick={() => this.props.deleteBusStop(this.props.busStop._id)}
                        >
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default ShowBusTemplate;