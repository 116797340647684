import React, { Component } from 'react';
import "../../../styles/classroom.css";
import SubjectName from './SubjectName'
import SubjectCode from './SubjectCode'
import StaffName from './StaffName'
import * as classHelper from '../helpers/classHelper'

class CreateClass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentClass: {},
            subjects: this.props.state.subjects
        }
    }

    handleChangeInput(e, subId, isStaffName) {
        isStaffName ? classHelper.getStaffNameForSubject.bind(this)(e.target.value, subId) : classHelper.handleChangeInput.bind(this)(e)
    }

    handleSubmit(e) {
        e.preventDefault();
        classHelper.handleSubmit.bind(this)();
        this.props.fetchAllSubjects.bind(this);
    }

    render() {
        return (
            <div>
                <h1 style={{marginLeft:'30px'}} >Create Class</h1>
                <form id="add-class-form" style={{ display: 'flex', marginTop: '20px',marginLeft:'30px' }} onSubmit={(event) => this.props.handleAddSub(event)}>
                {/* <div class="a" style={{marginTop:'0px'}}> */}
                        {/* <div class="col-md-12">
                        <div class="row"> */}
                            {/* <div class="col-md-3"> */}
                    <input
                    style={{margin:'10px',height:'50px'}}
                    className="new-xakal-input"
                    
                        type="text"
                        placeholder='year'
                        name='year'
                        onChangeCapture={(event) => this.handleChangeInput(event)}
                    />
                    {/* </div> */}
                    {/* <div class="col-md-3"> */}
                    <input
                                        style={{margin:'10px',height:'50px'}}
                                        className="new-xakal-input"
                        type="text"
                        placeholder='class name'
                        name='className'
                        onChangeCapture={(event) => this.handleChangeInput(event)}
                    />
                    {/* </div> */}
                     <div class="col-md-3">
                    <input
                                        style={{margin:'10px',height:'50px'}}
                                        className="new-xakal-input"
                        type="text"
                        placeholder='department'
                        name='department'
                        onChangeCapture={(event) => this.handleChangeInput(event)}
                    />
                    {/* </div> */}
                    {/* </div> */}
                    {/* </div> */}
                    </div>
                </form>
                <div className="limiter" style={{ marginTop: '50px' }}>
                    <div className="container-table100">
                        <div className="wrap-table100">
                            <div className="table100 ver5 m-b-110 table table-responsive" style={{boxShadow: '0px 16px 32px rgba(54, 42, 65, 0.32)'}}>
                                <table style={{marginLeft:'0'}} >
                                    <thead>
                                        <tr>
                                            <th>
                                                S.No
                                            </th>
                                            <th>
                                                Class Name
                                            </th>
                                            <th>
                                                Department
                                            </th>
                                            <th>
                                                Year
                                            </th>
                                            <th>
                                                Subject Name
                                            </th>
                                            <th>
                                                Subject Code
                                            </th>
                                            <th>
                                                Staff
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.currentClass
                                                ?
                                                <tr>
                                                    <td>{1}</td>
                                                    <td>{this.state.currentClass.className}</td>
                                                    <td>{this.state.currentClass.department}</td>
                                                    <td>{this.state.currentClass.year}</td>
                                                    <td>
                                                        <SubjectName
                                                            subjects={this.state.currentClass.unprocessedSubjects}
                                                        />
                                                    </td>
                                                    <td>
                                                        <SubjectCode
                                                            subjects={this.state.currentClass.unprocessedSubjects}
                                                        />
                                                    </td>
                                                    <td>
                                                        <StaffName
                                                            subjects={this.state.currentClass.unprocessedSubjects}
                                                            handleChangeInput={this.handleChangeInput.bind(this)}
                                                        />
                                                    </td>
                                                </tr>
                                                :
                                                <></>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex' }}>
                    <button style={{ display: 'flex', margin: '10px 10px 10px 20px',height:'50px',textAlign:'center',padding:'15px 10px', color:'white',background:'#362A41',boxShadow: '0px 16px 32px rgba(54, 42, 65, 0.32)'}}
                        type='submit'
                        className='form-btn'
                       
                        class="btn btn-light"
                        onClick={this.handleSubmit.bind(this)}
                       
                    >
                        Submit
                   </button>
                </div>
            </div>
        );
    }
}

export default CreateClass;