import React, { Component } from 'react';

class AddSubjects extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    render() {
        return (
            <div>
                <h1 style={{marginLeft:'30px'}}>Add Subjects</h1>
                <form id="add-sub-form" style={{ display: 'flex', margin:'10px'}} onSubmit={(event) => this.props.handleAddSub(event)}>
                    <div class="a" style={{marginTop:'0px'}}>
                        <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-3">
                    <input
                        style={{margin:'10px', height:'50px'}}
                        className="new-xakal-input"
                        type="text"
                        placeholder='name'
                        name='name'
                    />
                            </div>
                            <div class="col-md-3">
                            <input
                        style={{margin:'10px', height:'50px'}}
                        type="text"
                        placeholder='code'
                        name='code'
                        className="new-xakal-input"
                    />
                            </div>
                            <div class="col-md-3">
                            <input
                        style={{margin:'10px', height:'50px'}}
                        type="text"
                        placeholder='semester'
                        name='semester'
                        className="new-xakal-input"
                    />
                            </div>
                            <div class="col-md-3">
                            <input
                        style={{margin:'10px', height:'50px'}}     
                        type="text"
                        placeholder='department'
                        name='department'
                        className="new-xakal-input"
                    />
                            </div>
                        </div>
                        <div class='row'>
                            <div class="col-md-3">
                        <input
                        style={{margin:'10px', height:'50px'}}
                        type="text"
                        placeholder='departmentId'
                        name='departmentID'
                        className="new-xakal-input"
                    />
                            </div>
                            <div class="col-md-4">
                                <div class="row">
                            <button style={{ display: 'flex', margin: '10px 10px 10px 20px',height:'50px',textAlign:'center',padding:'15px 10px' , boxShadow: '0px 16px 32px rgba(54, 42, 65, 0.32)'}}
                                        type="submit"
                                       class="btn btn-light"
                                        
                                    >Submit</button>
<button style={{ display: 'flex', margin: '10px 10px 10px 20px',height:'50px',textAlign:'center',padding:'15px 10px', color:'white', background:'#362A41',   boxShadow: '0px 16px 32px rgba(54, 42, 65, 0.32)'}}
                                        class="btn btn-light"
                                        onClick={(e) => this.props.clearSubInputFields(e)} 
                                    >Add Another
                            </button></div>
                            </div>
                        </div>
                        </div>
                    </div>
                    
                                {/* <div > */}
                                <div className="col-lg-3 p-t-20 m-t-12">
                    </div>
                                    
                    {/* </div> */}
                </form>
            </div>
        )
    }
}

export default AddSubjects;