import React from "react";
import { useState, useEffect } from "react";
import "../styles/login.css";
import "../styles/utils.css";
import Logo from "../images/Mask group.png";
import { Link } from "react-router-dom";
import { Spinner } from "reactstrap";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
function Signup() {
  const [input, setInput] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [showButton, setShowButton] = useState(false);

  const usePriest = () => {
    setShowButton(true);
  };

  const [error, setError] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [passwordVal, setPasswordValidation] = useState({
    displayVal: "none",
    //startWithLetter:false,
    uppercase: false,
    lowercase: false,
    specialChar: false,
    numeral: false,
    minchar: false,
    valid: false,
  });
  const [enable, setEnable] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [spinner, setSpiner] = useState(false);
  const [otp, setOtp] = useState("");
  const [verfifySpinner, setVerifySpiner] = useState(false);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [errors, setErrors] = useState(null);
  const [valid, setValid] = useState(false);
  const [verify, setVerify] = useState(false);
  const [type, setType] = useState(true);
  const [type2, setType2] = useState(true);
  const [error2, setError2] = useState(null);
  const [count, setCount] = useState(5);
  const [disable, setDisable] = useState(true);
  const [message, setMessage] = useState(null);
  const [mail, setMail] = useState(null);

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));

    validateInput(e);
    validateInput2(e);
    validateInput3(e);
    validateInput4(e);
    validateInput5(e);
    validateInput6(e);
  };

  // const onInputChanges = (e) => {
  //   const { name, value } = e.target;
  //   setInput((prev) => ({
  //     ...prev,
  //     [name]: value,
  //   }));

  //   // validateInput(e);
  //   validateInput2(e);
  //   validateInput3(e);
  //   validateInput4(e);
  //   validateInput5(e);
  //   validateInput6(e);
  // };

  const uppercaseRegExp = /(?=.*?[A-Z])/;
  const lowercaseRegExp = /(?=.*?[a-z])/;
  const digitsRegExp = /(?=.*?[0-9])/;
  const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
  const minLengthRegExp = /.{8,}/;

  const validateInput = (e) => {
    let { name, value } = e.target;
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "firstName":
          const regex = /^[a-zA-Z]+$/.test(value);
          if (value === "") {
            stateObj[name] = "Please Enter FirstName";
          } else if (!regex) {
            stateObj[name] = "Please Enter Valid Name.";
          }
          break;

        case "middleName":
          const regex1 = /^[a-zA-Z]+$/.test(value);
          // if(!value){
          //   stateObj[name] = "Please Enter MiddleName";
          // }
          if (!regex1 && value !== "") {
            stateObj[name] = "Please Enter Valid Name.";
          }
          break;

        case "lastName":
          const regex2 = /^[a-zA-Z]+$/.test(value);
          if (!value) {
            stateObj[name] = "Please Enter LastName";
          } else if (!regex2) {
            stateObj[name] = "Please Enter Valid Name.";
          }
          break;

        case "email":
          const mail = /\S+@\S+\.\S+/.test(value);
          if (!value) {
            stateObj[name] = "Please Enter email";
          } else if (!mail) {
            stateObj[name] = "Please enter valid email";
          } else {
            setTimeout(() => {
              submitSignup();
            }, 1000);
          }
          break;
        case "password":
          const uppercasePassword = uppercaseRegExp.test(value);
          const lowercasePassword = lowercaseRegExp.test(input.password);
          const digitsPassword = digitsRegExp.test(value);
          const specialCharPassword = specialCharRegExp.test(value);
          const minLengthPassword = minLengthRegExp.test(value);

          if (!value) {
            stateObj[name] = "Please enter Password.";
          } else if (input.password && value !== input.password) {
            stateObj["confirmPassword"] =
              "Re-entered Password doesn't match with the Previous password.";
          } else if (!uppercasePassword) {
            stateObj[name] = "At least one Uppercase";
          } else if (!lowercasePassword) {
            stateObj[name] = "At least one Lowercase";
          } else if (!digitsPassword) {
            stateObj[name] = "At least one digit";
          } else if (!specialCharPassword) {
            stateObj[name] = "At least one Special Characters";
          } else if (!minLengthPassword) {
            stateObj[name] = "At least minumum 8 characters";
          } else {
            stateObj["confirmPassword"] = input.confirmPassword
              ? ""
              : error.confirmPassword;
          }
          break;

        case "confirmPassword":
          if (!value) {
            stateObj[name] = "Please enter Confirm Password.";
          } else if (input.password && value !== input.password) {
            stateObj[name] =
              "Re-entered Password doesn't match with the Previous password.";
          }
          break;

        default:
          break;
      }
      console.log(stateObj[name]);
      console.log(input.firstName);
      if (
        stateObj[name] === "" &&
        input.firstName != "" &&
        input.lastName != "" &&
        input.email != "" &&
        input.password != "" &&
        input.confirmPassword != ""
      ) {
        setEnable(true);
      } else {
        setEnable(false);
      }
      return stateObj;
    });
  };
  const validateInput2 = (e) => {
    let value = e.target.value;
    const uppercasePassword = uppercaseRegExp.test(value);

    if (uppercasePassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, uppercase: true };
      });
    } else if (!uppercasePassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, uppercase: false };
      });
    }
  };

  const validateInput3 = (e) => {
    let value = e.target.value;
    const lowercasePassword = lowercaseRegExp.test(value);

    if (lowercasePassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, lowercase: true };
      });
    } else if (!lowercasePassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, lowercase: false };
      });
    }
  };

  const validateInput4 = (e) => {
    let value = e.target.value;
    const digitsPassword = digitsRegExp.test(value);

    if (digitsPassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, numeral: true };
      });
    } else if (!digitsPassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, numeral: false };
      });
    }
  };

  const validateInput5 = (e) => {
    let value = e.target.value;
    const specialCharPassword = specialCharRegExp.test(value);

    if (specialCharPassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, specialChar: true };
      });
    } else if (!specialCharPassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, specialChar: false };
      });
    }
  };

  const validateInput6 = (e) => {
    let value = e.target.value;
    const minLengthPassword = minLengthRegExp.test(value);

    if (minLengthPassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, minchar: true };
      });
    } else if (!minLengthPassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, minchar: false };
      });
    }
  };

  const sendOtp = async (e) => {
    e.preventDefault();
    setEnabled(true);
    // setMinutes(1);
    setSeconds(5);
    setEnable(false);
    if (input.email === "") {
      alert("Enter Your Email !");
    } else if (!input.email.includes("@")) {
      alert("Enter Valid Email !");
    } else {
      setSpiner(true);
      const data = {
        email: input.email,
      };

      const response = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/otp`,
        data
      );
      if (response.status === 200) {
        setSpiner(false);
      } else {
        // toast.error(response.response.data.error);
      }
    }
  };

  const verifyOtp = async (e) => {
    e.preventDefault();

    if (otp === "") {
      alert("Enter Your Otp");
      setVerify(true);
      setEnabled(true);
    } else if (!/[^a-zA-Z]/.test(otp)) {
      setErrors("Enter Valid Otp");
    } else {
      axios

        .post(`${process.env.PUBLIC_URL}/xakal/verify`, {
          otp,

          email: input.email,
        })

        .then((response) => {
          console.log(response);
          setVerify(true);
          setMessage(response.data.message);
          if (response.data.success) {
            setVerify(true);
            setMessage(response.data.message);
            console.log(response.data.message);
          } else if (count > 1) {
            setCount(count - 1);
            setError2("Incorrect OTP.");
          } else if (count === 1) {
            setMinutes(15);
            setSeconds(0);
            setCount(5);
            setError2(null);
            setValid(false);
            setDisable(false);
          } else {
            setErrors(response.data.error);
          }
        })
        .catch((err) => setErrors(err.error));
    }
  };

  const submitSignup = (e) => {
    axios

      .post(`${process.env.PUBLIC_URL}/xakal/validateUserEmail`, {
        email: input.email,
      })

      .then((response) => {
        console.log(response);

        // setMail(response.data.message);
        if (response.data.success) {
          setMail(response.data.message);
          console.log(response.data.message);
        } else {
          setMail("");
        }
      })

      .catch((err) => setErrors(err.error));
  };

  // const verifyOtp = (e) => {
  //   e.preventDefault();

  //   if(otp==='1234'){
  //     setVerify(true);
  //     setEnabled(true);
  //     setError2(null);
  //   }
  //   else if(count > 1){
  //     setCount(count-1);
  //     setError2('Incorrect OTP.');
  //   }
  //   else if (count === 1){
  //     setMinutes(1);
  //     setSeconds(59);
  //     setCount(5);
  //     setError2(null);
  //     setValid(false);
  //     setDisable(false);
  //   }
  // }

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  function isValidOtp(otp) {
    return /^[0-9]{4,4}$/.test(otp);
  }

  const handleChange = (event) => {
    setOtp(event.target.value);
    if (!isValidOtp(event.target.value)) {
      setErrors("Incorrect OTP.");
      setValid(false);
    } else {
      setErrors(null);
      setValid(true);
    }
  };

  const Eye = () => {
    setType(!type);
  };
  const Eye2 = () => {
    setType2(!type2);
  };
  const history = useHistory();

  const submitRegister = (e) => {
    e.preventDefault();
    // setDisable("submitted");

    const response = axios.post(
      `${process.env.PUBLIC_URL}/xakal/create`,
      input
    );

    if (response.status === 200) {
      setInput({
        ...input,
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
      });
    } else {
      // toast.error(response.response.data.error);
    }
    history.push("/dashboardnew");
  };

  return (
    <div>
      <div className="container-login100 waveBg">
        <img className="logo-in" src={Logo} alt="img" />
        <div className="wrap-login100 p-b-20">
          <form
            className="login100-form"
            // onSubmit={this.formSubmit.bind(this)}
          >
            <div className="d-flex">
              <div className="">
                <Link to="/">
                  <div className="back">
                    <i className="fa fa-arrow-left"></i>
                  </div>
                </Link>
                <div className="login-title2" style={{ color: "#362A41" }}>
                  <h1> Sign Up</h1>
                </div>

                <p className="textreg  font-face-gm">First Name*</p>
                <div className={"wrap-input100"}>
                  <input
                    autoComplete="off"
                    className={"input101 "}
                    type="text"
                    name="firstName"
                    placeholder="Enter FirstName"
                    value={input.firstName}
                    onChange={onInputChange}
                    onBlur={validateInput}
                    // onMouseEnter={handleClick}
                  />
                </div>
                {error.firstName && (
                  <span className="text-danger">{error.firstName}</span>
                )}
                <p className="textpass font-face-gm">Middle Name</p>
                <div className={"wrap-input100"}>
                  <input
                    autoComplete="off"
                    className={"input101 "}
                    type="text"
                    name="middleName"
                    placeholder="Enter Middle Name"
                    value={input.middleName}
                    onChange={onInputChange}
                    onBlur={validateInput}
                  />
                </div>
                {error.middleName && (
                  <span className="text-danger">{error.middleName}</span>
                )}
                <p className="textpass font-face-gm">Last Name*</p>
                <div className={"wrap-input100"}>
                  <input
                    autoComplete="off"
                    type="text"
                    className={"input101 "}
                    name="lastName"
                    placeholder="Enter Last Name"
                    value={input.lastName}
                    onChange={onInputChange}
                    onBlur={validateInput}
                  />
                </div>
                {error.lastName && (
                  <span className="text-danger">{error.lastName}</span>
                )}
                <p className="textpass font-face-gm">Email-ID*</p>
                <div className={"wrap-input100"}>
                  <input
                    autoComplete="off"
                    type="email"
                    className={"input101 "}
                    name="email"
                    placeholder="Enter Email-Id"
                    value={input.email}
                    onChange={onInputChange}
                    onBlur={validateInput}
                  />
                </div>
                {error.email && (
                  <span className="text-danger">{error.email}</span>
                )}
                {mail && <span className="text-danger">{mail}</span>}
                <p className="textpass font-face-gm">Password*</p>
                <div className={"wrap-input100"}>
                  <input
                    autoComplete="off"
                    type={type ? "password" : "text"}
                    className={"input101 "}
                    name="password"
                    placeholder="Enter Password"
                    value={input.password}
                    onChange={onInputChange}
                    onBlur={validateInput}
                  />
                  {type ? (
                    <i
                      class="fa fa-eye-slash"
                      onClick={Eye}
                      style={{
                        fontSize: "36px",
                        color: "#362A41",
                        position: "relative",
                        left: "310px",
                        bottom: "30px",
                      }}
                    ></i>
                  ) : (
                    <i
                      class="fa fa-eye"
                      onClick={Eye}
                      style={{
                        fontSize: "36px",
                        color: "#362A41",
                        position: "relative",
                        left: "310px",
                        bottom: "30px",
                      }}
                    ></i>
                  )}
                </div>
                {error.password && (
                  <span className="text-danger">{error.password}</span>
                )}
                <p className="textpass font-face-gm">Re-type Password*</p>
                <div className={"wrap-input100 m-b-30"}>
                  <input
                    autoComplete="off"
                    type={type2 ? "password" : "text"}
                    className={"input101 "}
                    name="confirmPassword"
                    placeholder="Enter Confirm Password"
                    value={input.confirmPassword}
                    onChange={onInputChange}
                    onBlur={validateInput}
                  />
                  {type2 ? (
                    <i
                      class="fa fa-eye-slash"
                      onClick={Eye2}
                      style={{
                        fontSize: "36px",
                        color: "#362A41",
                        position: "relative",
                        left: "310px",
                        bottom: "30px",
                      }}
                    ></i>
                  ) : (
                    <i
                      class="fa fa-eye"
                      onClick={Eye2}
                      style={{
                        fontSize: "36px",
                        color: "#362A41",
                        position: "relative",
                        left: "310px",
                        bottom: "30px",
                      }}
                    ></i>
                  )}
                </div>
                {error.confirmPassword && (
                  <span className="text-danger">{error.confirmPassword}</span>
                )}

                <div className="container-login100-form-btn m-b-20">
                  <button
                    className="login100-form-btn"
                    disabled={!enable}
                    style={{ opacity: enable ? "100%" : "50%" }}
                    onClick={sendOtp}
                  >
                    Get OTP
                    {spinner ? (
                      <span>
                        <Spinner animation="border" />
                      </span>
                    ) : (
                      ""
                    )}
                  </button>
                </div>

                {enabled ? (
                  <div>
                    <div className="verify">
                      <div className="container-login100-form-btn">
                        <p className="otp">OTP</p>
                      </div>

                      <div className={"wrap-input100 "}>
                        <input
                          type="password"
                          className={"input102 "}
                          onChange={handleChange}
                          disabled={!disable && seconds}
                        ></input>
                      </div>

                      <div>
                        <button
                          className="verify-text"
                          disabled={!valid}
                          onClick={verifyOtp}
                          style={{ opacity: valid ? "100%" : "50%" }}
                        >
                          VERIFY
                          {verfifySpinner ? (
                            <span>
                              <Spinner animation="border" />
                            </span>
                          ) : (
                            ""
                          )}
                        </button>
                      </div>
                    </div>
                    <p
                      style={{
                        color: "red",
                        position: "relative",
                        left: "130px",
                        marginTop: "-35px",
                      }}
                    >
                      {errors}
                    </p>

                    {error2 ? (
                      <div style={{ display: "flex", gap: "30px" }}>
                        <p style={{ color: "red" }}>{count} attempt left</p>
                        <p style={{ color: "red" }}>
                          After {count} attempt you have to wait for 1 minute.
                        </p>
                      </div>
                    ) : (
                      ""
                    )}

                    {verify ? (
                      <p
                        style={{
                          color: "green",
                          fontSize: "20px",
                          textAlign: "center",
                          position: "relative",
                          top: "10px",
                        }}
                      >
                        {message}
                      </p>
                    ) : (
                      ""
                    )}

                    {seconds > 0 || minutes > 0 ? (
                      <p>
                        Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}
                        :{seconds < 10 ? `0${seconds}` : seconds}
                      </p>
                    ) : (
                      <p>
                        Didn't recieve code?{" "}
                        <span style={{ color: "blue" }} onClick={sendOtp}>
                          Resend OTP
                        </span>
                      </p>
                    )}

                    <div className="container-login100-form-btn">
                      <button
                        type="submit"
                        className="login100-form-btn"
                        disabled={!verify}
                        style={{ opacity: verify ? "100%" : "50%" }}
                        onClick={submitRegister}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {error.password ? (
                  <div>
                    <div className="connect1">
                      <i className="fa fa-arrow-right"></i>
                    </div>
                    <div id="message1">
                      <h3>Password must contain the following:</h3>

                      <div style={{ display: "flex", gap: "20px" }}>
                        <div
                          className={
                            passwordVal.lowercase ? "check" : "uncheck"
                          }
                        ></div>
                        <p
                          className={
                            passwordVal.lowercase ? "valid" : "invalid"
                          }
                        >
                          Must have 1 Lower Case Alphabet
                        </p>
                      </div>

                      <div style={{ display: "flex", gap: "20px" }}>
                        <div
                          className={
                            passwordVal.uppercase ? "check" : "uncheck"
                          }
                        ></div>
                        <p
                          className={
                            passwordVal.uppercase ? "valid" : "invalid"
                          }
                        >
                          Must have 1 Upper Case Alphabet
                        </p>
                      </div>

                      <div style={{ display: "flex", gap: "20px" }}>
                        <div
                          className={passwordVal.numeral ? "check" : "uncheck"}
                        ></div>
                        <p
                          id="number"
                          className={passwordVal.numeral ? "valid" : "invalid"}
                        >
                          Must have 1 Numeral Case Alphabet
                        </p>
                      </div>

                      <div style={{ display: "flex", gap: "20px" }}>
                        <div
                          className={passwordVal.minchar ? "check" : "uncheck"}
                        ></div>
                        <p
                          id="length"
                          className={passwordVal.minchar ? "valid" : "invalid"}
                        >
                          Minimum 8 characters required
                        </p>
                      </div>

                      <div style={{ display: "flex", gap: "20px" }}>
                        <div
                          className={
                            passwordVal.specialChar ? "check" : "uncheck"
                          }
                        ></div>
                        <p
                          className={
                            passwordVal.specialChar ? "valid" : "invalid"
                          }
                        >
                          Must have 1 Special Character [#?!@$%^&*-]
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Signup;
