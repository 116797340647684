import React from 'react';
import "../styles/login.css";
import { Link } from "react-router-dom";

function Modal () {
    return(
        <div className='success'>
            <div className="content">
            <p >Password Changed Successfully</p>
            <Link to="/dashboard"><button>Continue</button></Link>
        </div>
        </div>
    )
}

export default Modal