import React, { Component } from "react";
// import "../../styles/table.css";
import "../../styles/dropdown.css";
import "../../styles/course-dropdown.css";
import "../../styles/classnote.css";
// import "../../styles/responsiveDesign.css";
// import "../../styles/responsive.css";

import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pagination from "../../utils/pagination";
import axios from "axios";
import moment from "moment";

class HodNotes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      column1: "",
      column2: "",
      column3: "",
      column4: "",
      selectedSemester: "",
      selectedCourse: "",
      selectedType: "",
      description: "",
      searchAllowed: false,
      uploadAllowed: false,
      courseList: [],
      notesList: [],
      background: "",
      backgroundCourse: "",
      backgroundDesc: "",
      isFocussed: "",
      isCourseFocussed: "",
      isTypeFocussed: "",
      onFocus: false,
      onCourseFocus: false,
      onTypeFocus: false,
      file: null,
      isStudentPortal: false,
      isStaffPortal: false,
      userID: "",
      currentPage: 1,
      postsPerPage: 5,
    };
    this.courseChange = this.onCourseChange.bind(this);
    this.onFileUpload = this.onFileUpload.bind(this);
    this.onChange = this.onChange.bind(this);
    this.fileUpload = this.fileUpload.bind(this);
    this.paginate = this.paginate.bind(this);
    this.baseState = this.state;
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    this.getTitle();
  }

  componentDidMount() {
    this.getPortal();
    this.getTitle();
    // debugger;
    if (this.props && this.props.location && this.props.location.userID) {
      const userID = this.props.location.userID;
      this.setState({ userID: userID.userID });
    }
    this.unlisten = this.props.history.listen((location, action) => {
      this.setState(this.baseState);
      if (this.props && this.props.location && this.props.location.userID) {
        const userID = this.props.location.userID;
        this.setState({ userID: userID.userID });
      }
      this.getPortal();
    });
  }

  getPortal() {
    const pathArray = this.props.location.pathname.split("/");
    if (pathArray.includes("students-portal")) {
      this.setState({ isStudentPortal: true, isStaffPortal: false });
    } else {
      this.setState({ isStaffPortal: true, isStudentPortal: false });
    }
  }

  getTitle() {
    if (this.props.location) {
      const pathArray = this.props.location.pathname.split("/");
      if (pathArray.includes("question-papers")) {
        this.setState({ questionPaper: true, notes: false, xakalNotes: false });
      } else if (pathArray.includes("class-notes")) {
        this.setState({ questionPaper: false, notes: true, xakalNotes: false });
      } else if (pathArray.includes("xakal-notes")) {
        this.setState({ questionPaper: false, notes: false, xakalNotes: true });
      }
    }
  }

  componentWillUnmount() {
    this.unlisten();
  }

  /**
   * Adds the hover class when description is hovered
   */
  descriptionHover(event) {
    var element = event.target.className;
    if (element === "column100 column2 ") {
      this.setState({ column1: "hov-column-head-ver5" });
    }
  }

  /**
   * Adds the hover class when download is hovered
   */
  downloadHover(event) {
    var element = event.target.className;
    if (element === "column100 column3 ") {
      this.setState({ column2: "hov-column-head-ver5" });
    }
  }

  /**
   * Adds the hover class when update date is hovered
   */
  uploadDateHover(event) {
    var element = event.target.className;
    if (element === "column100 column4 ") {
      this.setState({ column3: "hov-column-head-ver5" });
    }
  }

  /**
   * Adds the hover class when upload by is hovered
   */
  uploadByHover(event) {
    var element = event.target.className;
    if (element === "column100 column5 ") {
      this.setState({ column4: "hov-column-head-ver5" });
    }
  }

  /**
   * Resets the state variables when hover is removed
   */
  hoverOff() {
    this.setState({
      column1: "",
      column2: "",
      column3: "",
      column4: "",
    });
  }

  /**
   * Triggers the API call for course, based on the semester selected
   */
  onDropDownSelect(event) {
    this.setState({
      selectedSemester: event.target.id,
      selectedCourse: "",
      onFocus: false,
      background: "is-hidden",
    });
    var semester = event.target.id;
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/class-notes/course/${semester}`)
      .then((response) => {
        this.setState({ courseList: response.data });
      });
    if (this.state.searchAllowed) {
      this.setState({ searchAllowed: false });
    } else if (this.state.uploadAllowed) {
      this.setState({ uploadAllowed: false });
    }
  }

  onTypeDropDownSelect(event) {
    this.setState({
      selectedType: event.target.id,
      onTypeFocus: false,
      background: "is-hidden",
    });
  }

  onDropDownFocus() {
    this.setState({
      isFocussed: "is-focused",
      onFocus: true,
      onCourseFocus: false,
      background: "is-shown",
      backgroundCourse: "is-hidden",
      backgroundDesc: "is-hidden",
    });
    if (this.state.selectedCourse === "") {
      this.setState({ isCourseFocussed: "" });
    }
  }

  onCourseDropDownFocus() {
    this.setState({
      isCourseFocussed: "is-focused",
      isFocussed: "is-focused",
      onCourseFocus: true,
      onFocus: false,
      background: "is-hidden",
      backgroundCourse: "is-shown",
      backgroundDesc: "is-hidden",
    });
  }

  onTypeDropDownFocus() {
    this.setState({
      isTypeFocussed: "is-focused",
      onTypeFocus: true,
      background: "is-shown",
      backgroundCourse: "is-hidden",
      backgroundDesc: "is-hidden",
    });
  }

  onDescriptionFocus() {
    this.setState({
      isCourseFocussed: "is-focused",
      isFocussed: "is-focused",
      isDescriptionFocussed: "is-focused",
      onCourseFocus: false,
      onFocus: false,
      background: "is-hidden",
      backgroundCourse: "is-hidden",
      backgroundDesc: "is-shown",
    });
  }

  /**
   * Updates the selected course name
   */
  onCourseChange(event) {
    this.setState({
      selectedCourse: event.target.id,
      onCourseFocus: false,
      backgroundCourse: "is-hidden",
      background: "is-hidden",
    });
    if (this.state.searchAllowed) {
      this.setState({ searchAllowed: false });
    } else if (this.state.uploadAllowed) {
      this.setState({ uploadAllowed: false });
    }
  }

  onDescriptionChanged(event) {
    this.setState({
      description: event.target.value,
      backgroundCourse: "is-hidden",
      background: "is-hidden",
    });
    if (this.state.searchAllowed) {
      this.setState({ searchAllowed: false });
    } else if (this.state.uploadAllowed) {
      this.setState({ uploadAllowed: false });
    }
  }

  /**
   * Allows the grid to display the values based on routing
   */
  getNotes() {
    if (
      this.state.selectedSemester !== "" &&
      this.state.selectedCourse !== "" &&
      this.state.selectedType !== ""
    ) {
      if (this.state.selectedType === "Question Paper")
        this.fetchQuestionPapers();
      else if (this.state.selectedType === "Class Notes")
        this.fetchClassNotes();
      else if (this.state.selectedType === "Xakal Notes")
        this.fetchXakalNotes();
      else alert("Please select the values");
    } else {
      alert("Please select the values");
      this.setState({ searchAllowed: false, uploadAllowed: false });
    }
  }

  /**
   * Fetches the class notes based on semester and course selected
   */
  fetchClassNotes() {
    this.setState({ searchAllowed: true });
    var semester = this.state.selectedSemester;
    var course = this.state.selectedCourse;
    axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/class-notes/classnote/${semester}/${course}`
      )
      .then((response) => {
        this.setState({ notesList: response.data });
      });
  }

  /**
   * Fetches the question papers based on semester and course selected
   */
  fetchQuestionPapers() {
    this.setState({ searchAllowed: true });
    var semester = this.state.selectedSemester;
    var course = this.state.selectedCourse;
    axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/class-notes/questionpaper/${semester}/${course}`
      )
      .then((response) => {
        this.setState({ notesList: response.data });
      });
  }

  /**
   * Fetches the xakal notes based on semester and course selected
   */
  fetchXakalNotes() {
    this.setState({ searchAllowed: true });
    var semester = this.state.selectedSemester;
    var course = this.state.selectedCourse;
    axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/class-notes/xakalnote/${semester}/${course}`
      )
      .then((response) => {
        this.setState({ notesList: response.data });
      });
  }

  onFileUpload(e) {
    let randomString = Math.random().toString(36);

    this.setState({
      theInputKey: randomString,
    });
    if (
      this.state.selectedSemester !== "" &&
      this.state.selectedCourse !== "" &&
      this.state.selectedType !== ""
    ) {
      e.preventDefault(); // Stop form submit
      this.fileUpload(this.state.file);
    } else {
      alert("Please select the values");
      this.setState({ searchAllowed: false, uploadAllowed: false });
    }
  }

  /**
   * Triggers when file is selected
   */
  onChange(e) {
    this.setState({
      file: e.target.files[0],
      background: "is-hidden",
      backgroundCourse: "is-hidden",
      backgroundDesc: "is-hidden",
    });
  }

  /**
   * Uploads the file to online URL
   */
  fileUpload(files) {
    console.log(this.props.location);
    const formData = new FormData();
    formData.append("semester", this.state.selectedSemester);
    formData.append("course", this.state.selectedCourse);
    formData.append("description", this.state.description);
    formData.append("uploadedBy", this.state.userID.toUpperCase());
    formData.append("uploadedFile", files);
    formData.append("uploadedDate", new Date());
    axios
      .post(`${process.env.PUBLIC_URL}/xakal/files/`, formData)
      .then((res) => {
        this.currentSite(formData, res.data.url, res.data.file);
      });
  }

  /**
   * Uploads document based on the router location
   */
  currentSite(formData, url, file) {
    if (this.props.location) {
      switch (this.props.location.pathname) {
        case "/staff-portal/question-papers":
        case "/hod-portal/question-papers":
          this.uploadQuestionPapers(formData, url, file);
          break;
        case "/staff-portal/class-notes":
          this.uploadClassNotes(formData, url, file);
          break;
        case "/hod-portal/class-notes":
          this.uploadClassNotes(formData, url, file);
          break;
        default:
          break;
      }
    }
  }

  /**
   * Uploads the file URL to DB
   */
  uploadQuestionPapers(formData, url, files) {
    const classNote = {
      semester: this.state.selectedSemester,
      course: this.state.selectedCourse,
      description: this.state.description,
      uploadedBy: formData.get("uploadedBy"),
      uploadedDate: formData.get("uploadedDate"),
      type: "questionPaper",
      uploadedFile: files,
      url,
    };
    axios
      .post(
        `${process.env.PUBLIC_URL}/xakal/class-notes/questionpaper`,
        classNote
      )
      .then(() => {
        alert("File uploaded successfully");
        this.setState(this.baseState);
      })
      .then((res) => {
        console.log(res);
        this.setState(this.baseState);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  /**
   * Uploads the class file URL to DB
   */
  uploadClassNotes(formData, url, file) {
    const classNote = {
      semester: this.state.selectedSemester,
      course: this.state.selectedCourse,
      description: this.state.description,
      uploadedBy: formData.get("uploadedBy"),
      uploadedDate: formData.get("uploadedDate"),
      type: "classNote",
      uploadedFile: file,
      url,
    };
    console.log("test", classNote);
    axios
      .post(`${process.env.PUBLIC_URL}/xakal/class-notes/classnote`, classNote)
      .then(() => {
        alert("File uploaded successfully");
        this.setState(this.baseState);
      })
      .catch((err) => {
        alert(err);
      });
  }

  /**
   * Displays the list of notes based on the API response
   */
  displayTable() {
    const indexOfLastPost = this.state.currentPage * this.state.postsPerPage;
    const indexOfFirstPost = indexOfLastPost - this.state.postsPerPage;
    const currentPost = this.state.notesList.slice(
      indexOfFirstPost,
      indexOfLastPost
    );

    const type =
      this.props.location.pathname === "/students-portal/question-papers"
        ? "questionPaper"
        : "classNote";
    return currentPost.map((singleData, index) => {
      var hrefValue =
        "/" +
        type +
        "/" +
        singleData.semester +
        "/" +
        singleData.course +
        "/" +
        singleData.uploadedFile;
      return (
        <tr className="row100">
          <td
            className="column100 grid-cont vertical-border column1 "
            data-column="column1"
          >
            {++index}
          </td>
          <td className={"column100 grid-cont vertical-border column2 "}>
            {singleData.description}
          </td>
          <td className={"column100 grid-cont vertical-border column3 "}>
            {moment(new Date(singleData.uploadedDate)).format("DD/MM/YYYY")}
          </td>
          <td className={"column100 grid-cont vertical-border column4 "}>
            {singleData.uploadedBy}
          </td>
          <td className={"column100 grid-cont vertical-border column5 "}>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={singleData.url ? singleData.url : ""}
            >
              <FontAwesomeIcon
                className="fa-sm vertical-border download"
                icon={faDownload}
              />
            </a>
          </td>
        </tr>
      );
    });
  }

  paginate(num) {
    this.setState({ currentPage: num });
  }

  /**
   * Displays the list of courses based on the API response
   */
  displayCourse() {
    if (this.state && this.state.courseList && this.state.courseList.length) {
      return this.state.courseList.map((singleCourse, index) => {
        return (
          <li className="mdl-menu__item animation label-weight" key={index}>
            <button id={singleCourse.course} onClick={this.courseChange}>
              {singleCourse.course}
            </button>
          </li>
        );
      });
    }
  }

  render() {
    return (
      <div className="container-fluid background bk">
        <div className="bar background">
          <nav className="navbar navbar-expand-lg  navprac">
            <div className="title">College Notes</div>
            <form className="form-inline my-2 my-lg-0">
              {/* <i class="fal fa-search"></i> */}
            </form>

            <div className="collapse navbar-collapse">
              <form className="form-inline my-2 my-lg-0">
                <input
                  style={{
                    marginLeft: "200px",
                    width: "467px",
                    backgroundColor: "rgba(54, 42, 65, 0.04)",
                    fontStyle: "italic",
                    height: "45px",
                  }}
                  className="form-control mr-sm-2 "
                  type="search"
                  placeholder="Search....."
                  aria-label="Search"
                ></input>
                {/* <i class="fal fa-search"></i> */}
              </form>
            </div>
          </nav>

          {/* <nav className="navbar navbar-expand-lg  line ">
              <a className="navbar-brand" href="#">Class Notes</a>
              <a className="navbar-brand" href="#">Xakal Notes</a>
              <a className="navbar-brand" href="#">Question Papers</a>
              <div class="collapse navbar-collapse" id="navbarSupportedContent">
              </div>
            </nav> */}
        </div>
        <div className="row m-r-2  ">
          <h4 className="hodNotes">Class Notes</h4>
          <div className="col-sm-12 ">
            <div className="card-box-class   ">
              <div className="card-body row  ">
                <div className="col-lg-4 ">
                  <div
                    className={
                      // " responsive mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width " +
                      this.state.isFocussed
                    }
                  >
                    <label
                      className="input-text  labell"
                      style={{ marginLeft: "5px", marginTop: "40px" }}
                    >
                      Semester Number:
                    </label>
                    <input
                      onKeyPress={(e) => e.preventDefault()}
                      autoComplete="off"
                      onFocus={this.onDropDownFocus.bind(this)}
                      className="new-xakal-input box  background"
                      type="text"
                      id="Semester"
                      value={this.state.selectedSemester}
                      style={{ marginLeft: "5px", marginTop: "-5px" }}
                    />

                    {this.state.onFocus ? (
                      <div
                        className="mdl-menu__container is-upgraded dropdown-list is-visible"
                        style={{ width: "360px", marginLeft: "105px" }}
                      >
                        <div
                          className="mdl-menu__outline  dropdown-div dropres"
                          style={{
                            width: "300px",
                            marginLeft: "-90px",
                            marginTop: "5px",
                          }}
                        >
                          <ul
                            className="scrollable-menu  ul-list"
                            style={{ width: "360%" }}
                          >
                            <li
                              className="mdl-menu__item animation label-weight"
                              id="Semester 1"
                              style={{ width: "100%" }}
                              onClick={this.onDropDownSelect.bind(this)}
                            >
                              Semester 1
                            </li>
                            <li
                              className="mdl-menu__item animation1 label-weight"
                              id="Semester 2"
                              onClick={this.onDropDownSelect.bind(this)}
                            >
                              Semester 2
                            </li>
                            <li
                              className="mdl-menu__item animation2 label-weight"
                              id="Semester 3"
                              onClick={this.onDropDownSelect.bind(this)}
                            >
                              Semester 3
                            </li>
                            <li
                              className="mdl-menu__item animation label-weight"
                              id="Semester 4"
                              style={{ width: "200px" }}
                              onClick={this.onDropDownSelect.bind(this)}
                            >
                              Semester 4
                            </li>
                            <li
                              className="mdl-menu__item animation1 label-weight"
                              id="Semester 5"
                              onClick={this.onDropDownSelect.bind(this)}
                            >
                              Semester 5
                            </li>
                            <li
                              className="mdl-menu__item animation2 label-weight"
                              id="Semester 6"
                              onClick={this.onDropDownSelect.bind(this)}
                            >
                              Semester 6
                            </li>
                            <li
                              className="mdl-menu__item animation label-weight"
                              id="Semester 7"
                              onClick={this.onDropDownSelect.bind(this)}
                            >
                              Semester 7
                            </li>
                            <li
                              className="mdl-menu__item animation1 label-weight"
                              id="Semester 8"
                              onClick={this.onDropDownSelect.bind(this)}
                            >
                              Semester 8
                            </li>
                          </ul>
                        </div>
                      </div>
                    ) : (
                      <p></p>
                    )}
                  </div>
                </div>
                <div className="col-lg-4">
                  <div
                    className={
                      // "mdl-textfield  mdl-js-textfield mdl-textfield--floating-label  getmdl-select getmdl-select__fix-height select-width " +
                      this.state.isCourseFocussed
                    }
                  >
                    <label
                      className="input-text labell"
                      style={{ marginRight: "8px", marginTop: "40px" }}
                    >
                      Course:
                    </label>
                    <input
                      //  className="cls"
                      onKeyPress={(e) => e.preventDefault()}
                      autoComplete="off"
                      onFocus={this.onCourseDropDownFocus.bind(this)}
                      className="new-xakal-input box  background "
                      type="text"
                      id="sample2"
                      value={this.state.selectedCourse}
                      style={{ marginRight: "8px", marginTop: "-5px" }}
                    />

                    {this.state.onCourseFocus ? (
                      <div
                        className="mdl-menu__container is-upgraded dropdown-list is-visible"
                        style={{ width: "348px", marginLeft: "105px" }}
                      >
                        <div
                          className="mdl-menu__outline  dropdown-div dropres"
                          style={{
                            width: "348px",
                            marginLeft: "-27%",
                            marginTop: "1%",
                          }}
                        >
                          <ul
                            className="scrollable-menu  ul-list"
                            style={{ width: "348%" }}
                          >
                            {this.displayCourse()}
                          </ul>
                        </div>
                      </div>
                    ) : (
                      <p></p>
                    )}
                  </div>
                </div>
                <div className="col-lg-4 ">
                  <div
                    className={
                      // " responsive mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width " +
                      this.state.isTypeFocussed
                    }
                  >
                    <label
                      className="input-text labell"
                      style={{ marginRight: "8px", marginTop: "40px" }}
                    >
                      Type:
                    </label>
                    <input
                      onKeyPress={(e) => e.preventDefault()}
                      autoComplete="off"
                      onFocus={this.onTypeDropDownFocus.bind(this)}
                      className="new-xakal-input box  background"
                      type="text"
                      id="Type"
                      value={this.state.selectedType}
                      style={{ marginRight: "8px", marginTop: "-5px" }}
                    />

                    {this.state.onTypeFocus ? (
                      <div
                        className="mdl-menu__container is-upgraded dropdown-list is-visible typedropres"
                        style={{ width: "360px", marginLeft: "105px" }}
                      >
                        <div
                          className="mdl-menu__outline  dropdown-div dropres1"
                          style={{
                            width: "300px",
                            marginLeft: "-26%",
                            marginTop: "1%",
                          }}
                        >
                          {this.state.isStudentPortal ? (
                            <ul
                              className="scrollable-menu  ul-list"
                              style={{ width: "360%" }}
                            >
                              <li
                                className="mdl-menu__item animation label-weight"
                                id="Class Notes"
                                style={{ width: "100%" }}
                                onClick={this.onTypeDropDownSelect.bind(this)}
                              >
                                Class Notes
                              </li>
                              <li
                                className="mdl-menu__item animation1 label-weight"
                                id="Xakal Notes"
                                onClick={this.onTypeDropDownSelect.bind(this)}
                              >
                                Xakal Notes
                              </li>
                              <li
                                className="mdl-menu__item animation2 label-weight"
                                id="Question Paper"
                                onClick={this.onTypeDropDownSelect.bind(this)}
                              >
                                Question Paper
                              </li>
                            </ul>
                          ) : (
                            <ul
                              className="scrollable-menu  ul-list"
                              style={{ width: "360%" }}
                            >
                              <li
                                className="mdl-menu__item animation label-weight"
                                id="Class Notes"
                                style={{ width: "100%" }}
                                onClick={this.onTypeDropDownSelect.bind(this)}
                              >
                                Class Notes
                              </li>
                              <li
                                className="mdl-menu__item animation2 label-weight"
                                id="Question Paper"
                                onClick={this.onTypeDropDownSelect.bind(this)}
                              >
                                Question Paper
                              </li>
                            </ul>
                          )}
                        </div>
                      </div>
                    ) : (
                      <p></p>
                    )}
                  </div>
                </div>

                {this.state.isStudentPortal ? (
                  <div className="col-lg-4 m-t-5">
                    <button
                      // className="cls"
                      type="button"
                      onClick={this.getNotes.bind(this)}
                      // style={{backgroundColor:"#362A41",padding:"1rem",borderRadius:"8px"}}
                      className=" m-t-15 m-l-30 getresult heading-weight button-size rs"
                      style={{ marginLeft: "10px", height: "30px" }}
                    >
                      Get Results
                    </button>
                  </div>
                ) : (
                  <>
                    <div className="row">
                      <div className="col-lg-6">
                        <input
                          className="choose-max"
                          type="file"
                          onChange={this.onChange}
                          key={this.state.theInputKey || ""}
                          style={{ marginLeft: "15px", marginTop: "-5px" }}
                        />
                      </div>
                      <div className="col-lg-6">
                        <button
                          type="button"
                          className="btn btn-primary resup"
                          onClick={this.onFileUpload}
                          // className=" col-sm-2 btn btn-primary m-t-15 m-l-30"
                          style={{
                            backgroundColor: "#362A41",
                            color: "#fff",
                            marginTop: "44px",
                            borderRadius: "8px",
                            height: "40px",
                            marginLeft: "-15rem",
                            boxShadow: " 0px 16px 32px rgba(54, 42, 65, 0.32)",
                          }}
                        >
                          Upload
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {this.state.searchAllowed ? (
          <div class="container">
            <div class="col-md-12">
              <div class="row">
                <table
                  className="table vertical-border-head background"
                  style={{ color: "#362A41", marginTop: "-50px" }}
                >
                  <thead style={{ color: "#B8B3BC" }}>
                    <tr>
                      <th
                        style={{
                          color: "#B8B3BC",
                          border: "1px solid rgba(0, 0,0, 0.1)",
                        }}
                        className="column100 column1 background vertical-border-head heading-weight heading-size"
                        data-column="column1"
                      >
                        {" "}
                        S.No
                      </th>

                      <th
                        style={{
                          color: "#B8B3BC",
                          backgroundColor: "  #F6F6F6",
                          border: "1px solid rgba(0, 0,0, 0.1)",
                        }}
                        className={
                          "column100 column2 vertical-border-head heading-weight heading-size " +
                          this.state.column1
                        }
                      >
                        Description
                      </th>
                      <th
                        style={{
                          color: "#B8B3BC",
                          backgroundColor: " #F6F6F6",
                          border: "1px solid rgba(0, 0,0, 0.1)",
                        }}
                        className={
                          "column100 column3 vertical-border-head heading-weight heading-size " +
                          this.state.column2
                        }
                      >
                        Uploaded Date
                      </th>
                      <th
                        style={{
                          color: "#B8B3BC",
                          backgroundColor: "  #F6F6F6",
                          border: "1px solid rgba(0, 0,0, 0.1)",
                        }}
                        className={
                          "column100 column4 vertical-border-head heading-weight heading-size " +
                          this.state.column3
                        }
                      >
                        Uploaded By
                      </th>
                      <th
                        style={{
                          color: "#B8B3BC",
                          backgroundColor: " #F6F6F6",
                          border: "1px solid rgba(0, 0,0, 0.1)",
                        }}
                        className={
                          "column100 column5 vertical-border-head heading-weight heading-size " +
                          this.state.column4
                        }
                      >
                        Download
                      </th>
                    </tr>
                  </thead>
                  <tbody>{this.displayTable()}</tbody>
                </table>
              </div>
              <div style={{ marginLeft: "-6%" }}>
                <Pagination
                  postPerPage={this.state.postsPerPage}
                  totalPost={this.state.notesList.length}
                  paginate={this.paginate}
                />
              </div>
            </div>
          </div>
        ) : (
          <span></span>
        )}
      </div>
    );
  }
}

export default HodNotes;
