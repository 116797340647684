import React from 'react'

const Pagination = ({ postPerPage, totalPost, paginate }) => {
  const pageNumber = [];

  for (let i = 1; i <= Math.ceil(totalPost/ postPerPage); i++) {
    pageNumber.push(i);
  }
  console.log(pageNumber, postPerPage, totalPost)
   return (
    <nav>
      <ul>
        <li style={{marginLeft: '3%'}}>
        {pageNumber.map((num) => (
            <button
              onClick={() => paginate(num)}
              className= 'form-btn'
              style={{ marginLeft: '10px', height: '40px', width: '40px',backgroundColor:'#362A41', boxShadow:' 0px 16px 32px rgba(54, 42, 65, 0.32)'           }}
            >
            {num}
          </button>
        ))}
        </li>
      </ul>
    </nav>
  )
}

export default Pagination