import React, { Component } from "react";
import "../../styles/table.css";
import "../../minified-css/material-min.css";
import "../../styles/dropdowns.css";
import "../../styles/theme-style.css";
import "../../styles/classnote.css";
import axios from "axios";
import * as moment from "moment";
class AddWorkersDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchAllowed: false,
      salaryDetails: [],
      isFocussed: "",
      onFocus: false,
      onYearFocus: false,
      isYearFocussed: "",
      selectedMonth: "",
      selectedYear: "",
      background: "",
      yearBackground: "",
      selectedDepartment: "",
      userID: "",
      staffDetails: [],
      selectedType: "",
      selectedIncharge: false,
      values: [
        {
          name: "",
          designation: "",
          qualification: "",
          contact: "",
          departmentName: "",
          email: "",
          emergencyContact: "",
          joiningDate: moment(new Date()).format("YYYY-MM-DD"),
        },
      ],
    };
    this.baseState = this.state;
  }

  componentDidMount() {
    if (this.props && this.props.location && this.props.location.userID) {
      const userID = this.props.location.userID;
      this.setState({ userID: userID.userID });
    }
    this.unlisten = this.props.history.listen((location, action) => {
      this.setState(this.baseState);
    });
  }

  onDropDownFocus(event) {
    if (event.target) {
      // event.target.parentNode.classList.add("is-focused");
      // event.target.nextSibling.classList.add("is-shown");
    }
  }

  onFocusOut(event) {
    if (event.target) {
      if (event.target.value === "") {
        // event.target.parentNode.classList.remove("is-focused");
      }
      // event.target.nextSibling.classList.remove("is-shown");
    }
  }

  componentWillUnmount() {
    this.unlisten();
  }

  /**
   * Adds the empty form element
   */
  addClick() {
    this.setState((prevState) => ({
      values: [
        ...prevState.values,
        {
          name: "",
          designation: "",
          qualification: "",
          contact: "",
          email: "",
          emergencyContact: "",
          joiningDate: moment(new Date()).format("YYYY-MM-DD"),
        },
      ],
    }));
  }

  /**
   * Removes the selected row
   * @param i selected row index
   */
  removeClick(i) {
    let values = [...this.state.values];
    values.splice(i, 1);
    this.setState({ values });
  }
  /**
   * Triggers when the form is changed and stores the values in state
   * @param event form values
   */
  handleFormChange(i, event) {
    if (event && event.target) {
      let values = [...this.state.values];
      const { name, value } = event.target;
      values[i][name] = value;
      this.setState({ values });
    }
  }

  /**
   * Resets to base state
   */
  resetForm() {
    this.setState({
      values: [
        {
          name: "",
          designation: "",
          qualification: "",
          contact: "",
          email: "",
          departmentName: "",
          emergencyContact: "",
          joiningDate: moment(new Date()).format("YYYY-MM-DD"),
        },
      ],
    });
  }

  /**
   * Triggers when the form is submitted
   * Checks whether the values are entered properly
   */
  formSubmit() {
    let isUpdated = false;
    if (this.state.values && this.state.values.length > 0) {
      this.state.values.forEach((element) => {
        if (
          element.name &&
          element.designation &&
          element.qualification &&
          element.joiningDate &&
          element.contact &&
          element.emergencyContact &&
          element.email
        ) {
          const params = {
            name: element.name,
            qualification: element.qualification,
            uploadedBy: this.state.userID.toUpperCase(),
            uploadedDate: new Date(Date.now()).toLocaleString(),
            designation: element.designation,
            email: element.email,
            bloodGroup: "NA",
            contact: element.contact,
            emergencyContact: element.emergencyContact,
            parentSpouse: "NA",
            joiningDate: element.joiningDate,
            departmentName: element.departmentName,
          };
          axios
            .post(`${process.env.PUBLIC_URL}/xakal/nonteaching`, params)
            .then(() => {
              if (!isUpdated) {
                alert("Staff Added  Successfully");
              }
              isUpdated = true;
            })
            .catch((err) => console.log(err));
        } else {
          alert("Please give all the details");
        }
      });
      this.resetForm();
    } else {
      alert("Please give atleast one record to proceed");
    }
  }

  render() {
    return (
      <div className="container-fluid background bk">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <nav
            className="navbar navbar-expand-lg  navprac bar"
            style={{ marginTop: "-60px" }}
          >
            <div className="title">Staff</div>
            <div className="collapse navbar-collapse">
              <form className="form-inline my-2 my-lg-0">
                <input
                  style={{
                    width: "500px",
                    marginLeft: "120px",
                    backgroundColor: "rgba(54, 42, 65, 0.04)",
                    fontStyle: "italic",
                    height: "45px",
                  }}
                  className="form-control mr-sm-2 "
                  type="search"
                  placeholder="Search....."
                  aria-label="Search"
                ></input>
              </form>
            </div>
          </nav>
          <div className="d-sm-flex  mb-4 ">
            <h4
              className="head resp staff-del-max"
              style={{
                width: "150px",
                marginTop: "150px",
                marginLeft: "-810px",
              }}
            >
              Add Staff Details
            </h4>

            {/* <h1 className="h3 mb-0 text-gray-800 m-t-20">Notes</h1> */}
          </div>

          {/* <h4 style={{color:'#714F90',marginTop:'150px'}}> Add Staff Details</h4> */}

          {/* <h1 className="h3 mb-0 text-gray-800 m-t-20">Notes</h1> */}

          {/* <h1 className="h3 mb-0 text-gray-800 m-t-20">Add Staff Details</h1> */}
        </div>
        <div className="row m-r-20">
          <div className="col-sm-12">
            <div>
              <div>
                {this.state.values.map((el, i) => (
                  <div className="card-body row background m-l-20" key={i}>
                    <div className="col-lg-3 p-t-20">
                      <div
                        className={
                          "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                        }
                      >
                        <label className="input-text labell">Name</label>
                        <input
                          value={el.name}
                          autoComplete="off"
                          onBlur={this.onFocusOut.bind(this)}
                          onFocus={this.onDropDownFocus.bind(this)}
                          className="new-xakal-input background height"
                          type="text"
                          id="name"
                          onChange={this.handleFormChange.bind(this, i)}
                          name="name"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 p-t-20">
                      <div
                        className={
                          "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                        }
                      >
                        <label className="input-text labell">Department</label>
                        <input
                          value={el.departmentName}
                          autoComplete="off"
                          onBlur={this.onFocusOut.bind(this)}
                          onFocus={this.onDropDownFocus.bind(this)}
                          className="new-xakal-input background height"
                          type="text"
                          id="departmentName"
                          onChange={this.handleFormChange.bind(this, i)}
                          name="departmentName"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 p-t-20">
                      <div
                        className={
                          "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                        }
                      >
                        <label className="input-text labell">Designation</label>
                        <input
                          value={el.designation}
                          autoComplete="off"
                          onBlur={this.onFocusOut.bind(this)}
                          onFocus={this.onDropDownFocus.bind(this)}
                          className="new-xakal-input background height"
                          type="text"
                          id="designation"
                          onChange={this.handleFormChange.bind(this, i)}
                          name="designation"
                        />
                      </div>
                    </div>

                    <div className="col-lg-3 p-t-20">
                      <div
                        className={
                          "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                        }
                      >
                        <label className="input-text labell">
                          Qualification
                        </label>
                        <input
                          value={el.qualification}
                          autoComplete="off"
                          onBlur={this.onFocusOut.bind(this)}
                          onFocus={this.onDropDownFocus.bind(this)}
                          className="new-xakal-input background height"
                          type="text"
                          id="qualification"
                          onChange={this.handleFormChange.bind(this, i)}
                          name="qualification"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 p-t-20">
                      <div
                        className={
                          "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                        }
                      >
                        <label className="input-text labell">Mobile</label>
                        <input
                          value={el.contact}
                          autoComplete="off"
                          onBlur={this.onFocusOut.bind(this)}
                          onFocus={this.onDropDownFocus.bind(this)}
                          className="new-xakal-input background height"
                          type="number"
                          id="contact"
                          onChange={this.handleFormChange.bind(this, i)}
                          name="contact"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 p-t-20">
                      <div
                        className={
                          "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                        }
                      >
                        <label className="input-text labell">Email</label>
                        <input
                          value={el.email}
                          autoComplete="off"
                          onBlur={this.onFocusOut.bind(this)}
                          onFocus={this.onDropDownFocus.bind(this)}
                          className="new-xakal-input background height"
                          type="email"
                          id="email"
                          onChange={this.handleFormChange.bind(this, i)}
                          name="email"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 p-t-20">
                      <div
                        className={
                          "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                        }
                      >
                        <label className="input-text labell">
                          Emergency contact
                        </label>
                        <input
                          value={el.emergencyContact}
                          autoComplete="off"
                          onBlur={this.onFocusOut.bind(this)}
                          onFocus={this.onDropDownFocus.bind(this)}
                          className="new-xakal-input background height"
                          type="text"
                          id="emergencyContact"
                          onChange={this.handleFormChange.bind(this, i)}
                          name="emergencyContact"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 p-t-20">
                      <div
                        className={
                          "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width is-focused"
                        }
                      >
                        <label
                          htmlFor="joiningDate"
                          className="input-text labell"
                        >
                          Joining date
                        </label>
                        <input
                          value={
                            el.joiningDate ||
                            moment(new Date()).format("YYYY-MM-DD")
                          }
                          autoComplete="off"
                          placeholder=""
                          onBlur={this.onFocusOut.bind(this)}
                          onFocus={this.onDropDownFocus.bind(this)}
                          className="new-xakal-input background height"
                          type="date"
                          id="joiningDate"
                          onChange={this.handleFormChange.bind(this, i)}
                          name="joiningDate"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 p-t-20">
                      {this.state.values.length !== 1 && (
                        <div className="row">
                          <button
                            type="button"
                            onClick={this.removeClick.bind(this, i)}
                            className=" col-sm-2 btn  m-t-15 con-name"
                            style={{
                              backgroundColor: "#362A41",
                              marginLeft: "12px",
                              color: "#fff",
                              boxShadow:
                                " 0px 16px 32px rgba(54, 42, 65, 0.32)",
                            }}
                          >
                            X
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>

              {this.state.selectedIncharge !== "" ? (
                <div className="card-body">
                  <div className="col-sm-4 p-t-0">
                    {/* <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                      }
                    >
                      <label className="input-text">From</label>
                      <input
                        // value={el.name}
                        autoComplete="off"
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocus.bind(this)}
                        className="new-xakal-input"
                        type="text"
                        id="from"
                        // onChange={this.handleFormChange.bind(this, i)}
                        name="from"
                        style={{backgroundColor:'#E5E5E5'}}
                      />
                      
                    </div> */}
                  </div>
                  <div className="col-sm-4 p-t-0">
                    {/* <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width "
                      }
                    >
                      <label className="input-text">To</label>
                      <input
                        // value={el.name}
                        autoComplete="off"
                        onBlur={this.onFocusOut.bind(this)}
                        onFocus={this.onDropDownFocus.bind(this)}
                        className="new-xakal-input"
                        type="text"
                        id="to"
                        // onChange={this.handleFormChange.bind(this, i)}
                        name="to"
                        style={{backgroundColor:'#E5E5E5'}}
                      />
                     
                    </div> */}
                  </div>
                </div>
              ) : (
                <p></p>
              )}

              <div className="card-body row">
                <div className="col-sm-8 p-t-20">
                  {/* <button
                    type="button"
                    onClick={this.state.selectedIncharge}
                    className="btn m-t-0"
                    style={{backgroundColor:"#FCFBFF",color:"#362A41", padding:"10px",boxShadow: "0px 24px 56px rgba(80, 74, 85, 0.27)"}}
                  >
                    Add Incharge of
                  </button> */}
                  <button
                    type="button"
                    onClick={this.addClick.bind(this)}
                    className=" m-t-15  m-l-30 cancel resp"
                  >
                    Add
                  </button>
                  <button
                    type="button"
                    onClick={this.formSubmit.bind(this)}
                    className=" m-t-15 m-l-30 save resp"
                    style={{
                      boxShadow: " 0px 16px 32px rgba(54, 42, 65, 0.32)",
                    }}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    onClick={this.resetForm.bind(this)}
                    className=" resp m-t-15 m-l-10 cancel"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddWorkersDetails;
