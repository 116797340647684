import React, { Component } from 'react';

class SubjectCode extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div>
                {
                    this.props.subjects
                        ?
                        this.props.subjects.map(e => (
                            <table>
                                <tr>
                                    <td style={{ height: '100%' }}>
                                        {e.code}
                                    </td>
                                </tr>
                            </table>
                        ))
                        :
                        <></>
                }
            </div>
        );
    }
}

export default SubjectCode;