import React, { Component } from "react";
import "../../../styles/table.css";
import "../../../minified-css/material-min.css";
import "../../../styles/dropdowns.css";
import "../../../styles/theme-style.css";
import '../../../styles/classnote.css';
import Pagination from '../../../utils/pagination'
import axios from "axios";

class SemesterDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      column1: "",
      column2: "",
      column3: "",
      column4: "",
      selectedSemester: "",
      searchAllowed: false,
      notesList: [],
      background: "",
      isFocussed: "",
      onFocus: false,
      userID: "",
      currentPage: 1,
      postsPerPage: 5,
    };
    this.paginate = this.paginate.bind(this)
    this.baseState = this.state;
  }

  // componentDidMount() {
  //   if (this.props && this.props.location && this.props.location.userID) {
  //     const userID = this.props.location.userID;
  //     this.setState({ userID: userID.userID });
  //   }
  //   this.unlisten = this.props.history.listen((location, action) => {
  //     this.setState(this.baseState);
  //   });
  // }

  // componentWillUnmount() {
  //   this.unlisten();
  // }

  /**
   * Adds the hover class when course is hovered
   */
  courseHover(event) {
    var element = event.target.className;
    if (element === "column100 column2 ") {
      this.setState({ column1: "hov-column-head-ver5" });
    }
  }

  /**
   * Adds the hover class when grade is hovered
   */
  gradeHover(event) {
    var element = event.target.className;
    if (element === "column100 column3 ") {
      this.setState({ column2: "hov-column-head-ver5" });
    }
  }

  /**
   * Adds the hover class when result is hovered
   */
  resultHover(event) {
    var element = event.target.className;
    if (element === "column100 column4 ") {
      this.setState({ column3: "hov-column-head-ver5" });
    }
  }

  /**
   * Resets the state variables when hover is removed
   */
  hoverOff() {
    this.setState({
      column1: "",
      column2: "",
      column3: "",
      column4: "",
    });
  }

  /**
   * Sets the semester selected
   */
  onDropDownSelect(event) {
    this.setState({
      selectedSemester: event.target.id,
      onFocus: false,
      background: "is-hidden",
    });
    if (this.state.searchAllowed) {
      this.setState({ searchAllowed: false });
    }
  }

  onDropDownFocus() {
    this.setState({
      isFocussed: "is-focused",
      onFocus: true,
      background: "is-shown",
    });
  }

  /**
   * Allows the grid to display the values
   */
  getResult() {
    if (this.state.selectedSemester !== "") {
      this.fetchSemesterDetails();
    } else {
      alert("Please select the values");
      this.setState({ searchAllowed: false });
    }
  }

  /**
   * Fetches the semester marks based on semester selected
   */
  fetchSemesterDetails() {
    this.setState({ searchAllowed: true });
    var semester = this.state.selectedSemester;
    var userID = { userID: this.state.userID };
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/assessment/semesterdetail/${semester}`, { params: userID })
      .then((response) => {
        this.setState({ notesList: response.data });
      });
  }

  /**
   * Displays the list of notes based on the API response
   */
  displayTable() {
    const indexOfLastPost = this.state.currentPage * this.state.postsPerPage;
    const indexOfFirstPost = indexOfLastPost - this.state.postsPerPage;
    const currentPost = this.state.notesList.slice(indexOfFirstPost, indexOfLastPost)

    return currentPost.map((singleData, index) => {
      return (
        <tr className="row100">
          <td className="column100 grid-cont vertical-border column1" data-column="column1">
            {++index}
          </td>
          <td
            className={"column100 grid-cont vertical-border column2 "}
            onMouseEnter={this.courseHover.bind(this)}
            onMouseLeave={this.hoverOff.bind(this)}
          >
            {singleData.course}
          </td>
          <td
            className={"column100 grid-cont vertical-border column3 "}
            onMouseEnter={this.gradeHover.bind(this)}
            onMouseLeave={this.hoverOff.bind(this)}
          >
            {singleData.grade}
          </td>
          <td
            className={"column100 grid-cont vertical-border column4 "}
            onMouseEnter={this.resultHover.bind(this)}
            onMouseLeave={this.hoverOff.bind(this)}
          >
            {singleData.result}
          </td>
        </tr>
      );
    });
  }

  paginate(num) { this.setState({ currentPage: num }) }


  render() {
    return (
      <div className="container-fluid background bk">
        <div className="d-sm-flex mb-4" >

          {/* <nav className="navbar navbar-expand-lg   lineseme"style={{marginTop:'12%'}}>
  <a className="navbar-brand" href="#">Internals</a>
  <a className="navbar-brand" href="#">Semester</a>
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
  </div>
</nav>  */}
          {/* <h1 className="h3 mb-0  m-t-20">Semester Number</h1> */}
        </div>

        <div className="d-sm-flex  mb-4 ">
          <div className="head intrnalheadres" style={{ marginLeft: '8px', marginTop: '40px' }}>Semester</div>

          {/* <h1 className="h3 mb-0 text-gray-800 m-t-20">Notes</h1> */}
        </div>
        <div className="row m-r-20">
          <div className="col-sm-12">

            <div className="row">
              <div className="col-lg-4 m-t-0">
                <div
                  className={
                    // "mdl-textfield  mdl-js-textfield mdl-textfield--floating-label  getmdl-select getmdl-select__fix-height select-width " +
                    this.state.isFocussed
                  }
                >

                  <label
                    className="labell intrnalheadres"
                    style={{ marginLeft: '35px', marginTop: '20px' }}
                  // className={
                  //   "mdl-textfield__label " + this.state.background
                  // }
                  >
                    Semester Number:
                  </label>
                  <input
                    autoComplete="off"
                    onKeyPress={(e) => e.preventDefault()}
                    onFocus={this.onDropDownFocus.bind(this)}
                    className="new-xakal-input height background intrnalheadres1"
                    type="text"
                    id="sample2"
                    value={this.state.selectedSemester}
                    style={{ marginLeft: '35px', marginTop: '2px', width: '368px' }}
                  />
                  {/* <label
                      className={
                        "mdl-textfield__label " + this.state.background
                      }
                    >
                      Semester
                    </label> */}


                  {this.state.onFocus ? (
                    <div className="mdl-menu__container is-upgraded dropdown-list is-visible dropsemester" style={{ width: '368px' }}>
                      <div className="mdl-menu__outline  dropdown-div dropsemester" style={{ marginLeft: '12%', width: '368px', marginTop: '-3%' }}>
                        <ul className="scrollable-menu  ul-list" style={{ width: '368px' }}>
                          <li
                            className="mdl-menu__item animation label-weight"
                            id="Semester 1"
                            onClick={this.onDropDownSelect.bind(this)}
                          >
                            Semester 1
                          </li>
                          <li
                            className="mdl-menu__item animation1 label-weight"
                            id="Semester 2"
                            onClick={this.onDropDownSelect.bind(this)}
                          >
                            Semester 2
                          </li>
                          <li
                            className="mdl-menu__item animation2 label-weight"
                            id="Semester 3"
                            onClick={this.onDropDownSelect.bind(this)}
                          >
                            Semester 3
                          </li>
                          <li
                            className="mdl-menu__item animation label-weight"
                            id="Semester 4"
                            onClick={this.onDropDownSelect.bind(this)}
                          >
                            Semester 4
                          </li>
                          <li
                            className="mdl-menu__item animation1 label-weight"
                            id="Semester 5"
                            onClick={this.onDropDownSelect.bind(this)}
                          >
                            Semester 5
                          </li>
                          <li
                            className="mdl-menu__item animation2 label-weight"
                            id="Semester 6"
                            onClick={this.onDropDownSelect.bind(this)}
                          >
                            Semester 6
                          </li>
                          <li
                            className="mdl-menu__item animation label-weight"
                            id="Semester 7"
                            onClick={this.onDropDownSelect.bind(this)}
                          >
                            Semester 7
                          </li>
                          <li
                            className="mdl-menu__item animation1 label-weight"
                            id="Semester 8"
                            onClick={this.onDropDownSelect.bind(this)}
                          >
                            Semester 8
                          </li>
                        </ul>
                      </div>
                    </div>
                  ) : (
                    <p></p>
                  )}
                </div>
              </div>

              <div className="col-lg-4 p-t-20">
                <button
                  type="button"
                  onClick={this.getResult.bind(this)}
                  className="getresult heading-weight button-size intrnalheadres"
                  style={{ marginTop: '19px', width: '150px', marginLeft: '34%' }}

                >
                  Get Results
                </button>
              </div>
            </div>
          </div>
        </div>
        {this.state.searchAllowed ? (
          <div className="limiter background align">
            <div class="container   background align">
              <div class="col-md-12  background align" >
                <div class="row  align" >
                  <table className="vertical-border-head  background" style={{ marginTop: '-100px' }} >
                    <thead >
                      <tr className="row100 head">
                        <th
                          className="column100 backcolor column1 vertical-border-head heading-weight heading-size "
                          data-column="column1"
                        >SL No</th>
                        <th
                          className={"column100 backcolor vertical-border-head heading-weight heading-size column1 " + this.state.column1}
                          onMouseEnter={this.courseHover.bind(this)}
                          onMouseLeave={this.hoverOff.bind(this)}
                        >
                          Course
                        </th>
                        <th
                          className={"column100 backcolor vertical-border-head heading-weight heading-size column1 " + this.state.column2}
                          onMouseEnter={this.gradeHover.bind(this)}
                          onMouseLeave={this.hoverOff.bind(this)}
                        >
                          Grade
                        </th>
                        <th
                          className={"column100 backcolor vertical-border-head heading-weight heading-size column1 " + this.state.column3}
                          onMouseEnter={this.resultHover.bind(this)}
                          onMouseLeave={this.hoverOff.bind(this)}
                        >
                          Result
                        </th>
                      </tr>
                    </thead>
                    <tbody>{this.displayTable()}</tbody>
                  </table>
                </div>
                <div style={{ marginLeft: '-13%' }}>
                  <Pagination postPerPage={this.state.postsPerPage} totalPost={this.state.notesList.length} paginate={this.paginate} />
                </div></div>
            </div>
          </div>
        ) : (
          <span></span>
        )}
      </div>
    );
  }
}

export default SemesterDetails;
