import React, { Component } from "react";
import "../styles/loadingpage.css";
import ReactLoading from "react-loading";

export default class LoadingPage extends Component {
  render() {
    return (
      <div className="main_container">
        <ReactLoading
          type={"spinningBubbles"}
          height={"2%"}
          width={"2%"}
          color={"#362A41"}
          size={100}
        />
      </div>
    );
  }
}
