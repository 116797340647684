import React, { Component } from "react";
// import axios from "axios";
// import moment from "moment";
import "../../../styles/table.css";
import "../../../minified-css/material-min.css";
import "../../../styles/dropdowns.css";
import "../../../styles/theme-style.css";

class StudentsTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ...this.props.state,
            students: this.props.bus.students,
            onboardedStudents: [],
            leaveApplied: [],
            leaveApproved: [],
            leaveCancelled: []
        }
    }

    componentDidMount() {
        this.initArrangeStudentsByLeave();
    }

    initArrangeStudentsByLeave() {
        //1.onboarded
        this.setState({
            onboardedStudents: this.state.students
        })

        //2.leave applied
        this.sortByApplied();

        //3.leave approved
        this.sortByApproved();

        //4.leave cancelled
        this.sortByCancelled();
    }

    sortByApplied() {
        this.state.students.forEach((student, index) => {
            student.attendance.forEach((a, i) => {
                if(a) {
                    if(a.type === 'Leave') {
                        this.setState({
                            leaveApplied: [...this.state.leaveApplied, student]
                        })
                    }
                }
            })
        });
    }

    sortByApproved() {
        this.state.students.forEach((student, index) => {
            student.attendance.forEach((a, i) => {
                if(a) {
                    if(a.type === 'Leave' && a.status === 'approved') {
                        this.setState({
                            leaveApproved: [...this.state.leaveApplied, student]
                        })
                    }
                }
            })
        });
    }

    sortByCancelled() {
        this.state.students.forEach((student, index) => {
            student.attendance.forEach((a, i) => {
                if(a) {
                    if(a.type === 'Leave' && a.isCancelled) {
                        this.setState({
                            leaveCancelled: [...this.state.leaveApplied, student]
                        })
                    }
                }
            })
        });
    }

    handleStudentsChangeInput(e) {
        const groupByValue = e.target.value;
        this.arrangeStudentsByLeave(groupByValue)
    }

    arrangeStudentsByLeave(groupByValue) {
        if(groupByValue === 'onboarded') {
            this.setState({
                students: this.props.bus.students || []
            })
        } else if(groupByValue === 'leave applied') {
            this.setState({
                students: this.state.leaveApplied || []
            })
        } else if(groupByValue === 'leave approved') {
            this.setState({
                students: this.state.leaveApproved || []
            })
        } else if(groupByValue === 'leave cancelled') {
            this.setState({
                students: this.state.leaveCancelled || []
            })
        } 
    }

    render() {
        return (
            <div className="studentsTable" style={{ marginLeft: 80, border: '1px solid #74a5f2', backgroundColor: '#fff', color: 'black', padding: 0 }}>
                <div style={{ position: 'absolute', right: 40, top: 20 }}>
                    <button
                        type="button"
                        className="btn btn-outline-danger m-t-15 m-l-30"
                        onClick={() => this.props.displayStudents(false)}
                    >
                        X
                    </button>
                </div>
                <div style={{ marginLeft: 30, marginTop: 0, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <h2>Students Record</h2>
                </div>
                <div style={{ marginLeft: 30, marginTop: 0, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center' }}>
                    <select onChange={this.handleStudentsChangeInput.bind(this)}>
                        <option selected>onboarded</option>
                        <option>leave applied</option>
                        <option>leave approved</option>
                        <option>leave cancelled</option>
                    </select>
                </div>
                <div className="limiter">
                    <div className="container-table100">
                        <div className="wrap-table100">
                            <div className="table100 ver5 m-b-110 table table-responsive">
                                <table>
                                    <thead>
                                        <tr className="row100 head">
                                            <th
                                                className="column100 column1"
                                                data-column="column1"
                                            >
                                                S.No
                                            </th>
                                            <th
                                                className="column100 column1"
                                                data-column="column1"
                                            >
                                                Student Name

                                            </th>
                                            <th
                                                className="column100 column1"
                                                data-column="column1"
                                            >
                                                User ID
                                            </th>
                                            <th
                                                className="column100 column1"
                                                data-column="column1"
                                            >
                                                Dept
                                            </th>
                                            <th
                                                className="column100 column1"
                                                data-column="column1"
                                            >
                                                Contact
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.students.length > 0 
                                            ?
                                            this.state.students.map((stud, i) => (
                                                <tr key={`student-row-${i}`} style={{ backgroundColor: stud.rowColor }}>
                                                    <td className={stud._id}>
                                                        {i + 1}
                                                    </td>
                                                    <td className={stud._id}>
                                                        {stud.name}
                                                    </td>
                                                    <td className={stud._id}>
                                                        {stud.userID}
                                                    </td>
                                                    <td className={stud._id}>
                                                        {stud.branch}
                                                    </td>
                                                    <td className={stud._id}>
                                                        {stud.contact}
                                                    </td>
                                                </tr>
                                            ))
                                            :
                                            <>
                                            </>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default StudentsTable;