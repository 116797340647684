import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../../src/minified-css/navbar.min.css";
import classNotes from "./students-portal/class-notes";
import Dashboard from "./students-portal/dashboard";
import AttendanceSummary from "./summary";
import "../styles/navbar.css";
import "../styles/classnote.css";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import SemesterDetails from "./students-portal/assessments/semester-details";
import InternalDetails from "./students-portal/assessments/internal-details";
import StudentAssessment from "./students-portal/assessments/assessment";
import Attendance from "./students-portal/attendance";
import Transport from "./students-portal/transport";
import EditTransport from "./students-portal/edit-transport";
import TimeTable from "./students-portal/timetable";
import Payment from "./students-portal/payment";
import LeaveStatus from "./students-portal/leave-status";
import ViewSemesterDetails from "./staff-portal/assessments/view-semester-details";
import SalaryDetails from "./staff-portal/salary-details";
import StaffAttendance from "./staff-portal/attendance";
import StaffDashboard from "./staff-portal/dashboard";
import StudentDetailsMaintain from "./staff-portal/student-details-maintain";
import Login from "./login";
import EditInternalDetails from "./staff-portal/assessments/edit-internal-details";
import StaffAssessment from "./staff-portal/assessments/staff-assessment";
import StaffTimetable from "./staff-portal/timetable/staff-timetable";
import CreateStudentTimetable from "./staff-portal/timetable/create-student-timetable";
import Forum from "./forum";
import ManagementDashboard from "./management-portal/dashboard";
import AttendanceMaintain from "./staff-portal/attendance-maintain";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import {
  faAddressBook,
  faLayerGroup,
  faBookReader,
  faGraduationCap,
  faLaptopCode,
  faHourglassHalf,
  faGem,
  faUsers,
  faBusinessTime,
} from "@fortawesome/free-solid-svg-icons";
// import logo from "../images/xakal-logo.png";
import xalogo from "../images/xalogo.svg";
import NonTeachingDashboard from "./non-teaching-portal/dashboard";
import CreatedTimetable from "./staff-portal/timetable/created-timetable";
import dashboardicon from "../images/dashicon.svg";
import boardicon from "../images/boardicon.svg";
import notesicon from "../images/notesicon.svg";
import {
  DesktopOutlined,
  UsergroupAddOutlined,
  DollarCircleOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import assicon from "../images/assicon.png";
import tableicon from "../images/calicon.svg";
import logouticon from "../images/singout.svg";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PostAddIcon from "@material-ui/icons/PostAdd";
import AssignmentIcon from "@material-ui/icons/Assignment";
import DesktopWindowsIcon from "@material-ui/icons/DesktopWindows";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import DateRangeIcon from "@material-ui/icons/DateRange";
import PeopleIcon from "@material-ui/icons/People";
import Signup from "./signup";
import Setting from "./setting";
import Forgetpassword from "./changePassword";

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showClassNotes: false,
      assessments: false,
      timetable: false,
      transport: false,

      routerLink: "",
      userID: (name) =>
        `; ${document.cookie}`.split(`; ${name}=`).pop().split(";").shift(),
    };
    this.baseState = this.state;
  }

  logout() {
    axios
      .post(`${process.env.PUBLIC_URL}/xakal/logout`)
      .then(() => (window.location.href = "/"))
      .catch((e) => (window.location.href = "/"));
  }

  componentDidMount() {
    if (!this.state.userID("userid")) {
      this.logout();
    } else {
      if (
        this.props &&
        this.props.state &&
        this.props.state.location &&
        this.props.state.location.state
      ) {
        if (this.props.state.location.state.userRole === "student") {
          this.setState({ routerLink: "/students-portal" });
        } else if (this.props.state.location.state.userRole === "staff") {
          this.setState({ routerLink: "/staff-portal" });
        } else if (this.props.state.location.state.userRole === "hod") {
          this.setState({ routerLink: "/hod-portal" });
        } else if (this.props.state.location.state.userRole === "management") {
          this.setState({ routerLink: "/management-portal" });
        } else if (
          this.props.state.location.state.userRole === "non-teaching"
        ) {
          this.setState({ routerLink: "/non-teaching-portal" });
        }
      }
    }
  }

  componentWillUnmount() {
    // this.unlisten();
  }

  /**
   * Handles the sub menu of college notes
   */
  onClassNotesClick() {
    this.setState({ assessments: false });
    if (this.state.showClassNotes) {
      this.setState({ showClassNotes: false });
    } else {
      this.setState({ showClassNotes: true });
    }
  }
  onAttendanceClick() {
    // this.resetNavBarClick();
    if (this.state.attendance) {
      this.setState({ attendance: false });
    } else {
      this.setState({ attendance: true });
    }
  }

  /**
   * Handles the sub menu of assessments
   */
  onAssessmentsClick() {
    this.setState({ showClassNotes: false });
    if (this.state.assessments) {
      this.setState({ assessments: false });
    } else {
      this.setState({ assessments: true });
    }
  }

  onTimeTableClick() {
    if (this.state.timetable) {
      this.setState({ timetable: false });
    } else {
      this.setState({ timetable: true });
    }
  }

  onTransportClick() {
    if (this.state.transport) {
      this.setState({ transport: false });
    } else {
      this.setState({ transport: true });
    }
  }

  render() {
    return (
      <Router>
        <div className="background">
          <div>
            <ul
              className="navbar-nav  bg-gradient-primary sidebar sidebar-dark accordion html"
              id="accordionSidebar"
              style={{
                overflowY: "scroll",
                backgroundColor: "#FFFFFF",
                marginTop: "-5px",
              }}
            >
              <button className="sidebar-brand d-flex align-items-center justify-content-center">
                <div className="logo-container ">
                  <img
                    style={{ marginLeft: "-50px", marginTop: "40px" }}
                    src={xalogo}
                    alt="XAKAL"
                  />
                </div>
                {/* <div className="sidebar-brand-text mx-3 logo-color">Xakal</div> */}
              </button>
              <hr className="sidebar-divider my-0" />
              <br></br>

              <li className="nav-item">
                <Link
                  style={{ width: "12rem" }}
                  to={{
                    pathname: `${this.state.routerLink}/dashboard`,
                    userID: this.props.userID || this.state.userID("userid"),
                  }}
                  className="nav-link collapsed "
                >
                  {/* <img src={dashboardicon} class="icon-hover" ></img> */}
                  <DashboardIcon style={{ height: "17px" }} />
                  <span
                    style={{ marginLeft: "8px" }}
                    className="pro nav-weight"
                  >
                    {" "}
                    Dashboard
                  </span>
                </Link>
              </li>
              {this.state.routerLink === "/students-portal" ||
              this.state.routerLink === "/staff-portal" ||
              this.state.routerLink === "/hod-portal" ||
              this.state.routerLink === "/management-portal" ||
              this.state.routerLink === "/non-teaching-portal" ? (
                <li className="nav-item">
                  <Link
                    style={{ width: "170px" }}
                    to={{
                      pathname: `${this.state.routerLink}/whiteboard`,
                      state: this.props.userID || this.state.userID("userid"),
                    }}
                    className="nav-link align-items-center"
                  >
                    {/* <img src={boardicon} style={{ color: "black" }}></img> */}
                    {/* <FontAwesomeIcon className="fa-sm" icon={faLayerGroup} />
                  <i className="fas fa-fw fa-tachometer-alt"></i> */}
                    <DesktopWindowsIcon style={{ height: "17px" }} />
                    <span
                      style={{ marginLeft: "8px", width: "2rem" }}
                      className="nav-weight"
                    >
                      White Board
                    </span>
                  </Link>
                </li>
              ) : (
                <span></span>
              )}
              {this.state.routerLink === "/staff-portal" ? (
                <li className="nav-item">
                  <Link
                    to={{
                      pathname: `${this.state.routerLink}/view-student-details`,
                      userID: this.props.userID || this.state.userID("userid"),
                    }}
                    className="nav-link"
                  >
                    <PeopleIcon />
                    <span className="nav-weight"> Student Details</span>
                  </Link>
                </li>
              ) : (
                <span></span>
              )}
              <hr className="sidebar-divider" />
              {this.state.routerLink === "/students-portal" ||
              this.state.routerLink === "/staff-portal" ? (
                <>
                  <li className="nav-item">
                    <Link
                      style={{ width: "12rem" }}
                      to={{
                        pathname: `${this.state.routerLink}/college-notes`,
                        userID:
                          this.props.userID || this.state.userID("userid"),
                      }}
                      className="nav-link collapsed "
                    >
                      {/* <img src={notesicon} ></img> */}
                      <FontAwesomeIcon
                        style={{
                          height: "17px",
                          marginLeft: "-10px",
                          marginTop: "-5px",
                        }}
                      />
                      <i
                        style={{ marginLeft: "-1px" }}
                        class="fa fa-pencil-square-o fa-lg notes-margin"
                        aria-hidden="true"
                      ></i>
                      <span className="nav-weight">College Notes</span>
                    </Link>
                    {/* {this.state.transport ? (
                    <i  style={{marginTop:'-5px'}}
                      className="fa fa-microchip "
                      aria-hidden="true"
                    ></i>
                    ) : (
                      <i  style={{marginTop:'-20px'}}
                        className="fa fa-angle-right fa-lg notes-margin "
                        aria-hidden="true"
                      ></i>
                    )} */}
                  </li>
                  {/* {this.state.showClassNotes ? (
                  <div>


                    <li className="nav-item  vector rect">
                      <Link
                       style={{width:'12rem'}}
                        to={{
                          pathname: `${this.state.routerLink}/class-notes`,
                          userID:
                            this.props.userID || this.state.userID("userid"),
                        }}
                        className="nav-link collapsed "
                      >
                        <i className="fas fa-fw fa-tachometer-alt"></i>
                        <i className="fas fa-fw fa-tachometer-alt"></i>
                        <span className="nav-weight">  Class Notes</span>
                      </Link>
                    </li>
                    {this.state.routerLink === "/students-portal" ? (
                      <li className="nav-item  vector rect">
                        <Link
                         style={{width:'12rem'}}
                          to={{
                            pathname: `${this.state.routerLink}/xakal-notes`,
                            userID:
                              this.props.userID || this.state.userID("userid"),
                          }}
                          className="nav-link collapsed "
                        >
                          <i className="fas fa-fw fa-tachometer-alt"></i>
                          <i className="fas fa-fw fa-tachometer-alt"></i>
                          <span style={{ marginLeft: '3%'}} className="nav-weight">Xakal Notes</span>
                        </Link>
                      </li>
                    ) : (
                      <p></p>
                    )}
                    <li className="nav-item vector rect">
                      <Link
                      style={{width:'12rem'}}
                        to={{
                          pathname: `${this.state.routerLink}/question-papers`,
                          userID:
                            this.props.userID || this.state.userID("userid"),
                        }}
                        className="nav-link collapsed "
                      >
                        <i className="fas fa-fw fa-tachometer-alt"></i>
                        <i className="fas fa-fw fa-tachometer-alt"></i>
                        <span  style={{ marginLeft: '3%' }} className="nav-weight">Question Papers</span>
                      </Link>
                    </li>
                  </div>
                ) : (
                  <div></div>
                )} */}
                  <li className="nav-item">
                    <Link
                      style={{ width: "170px" }}
                      to={{
                        pathname: `${this.state.routerLink}/assessment`,
                        userID:
                          this.props.userID || this.state.userID("userid"),
                      }}
                      className="nav-link collapsed "
                    >
                      <AssignmentIcon style={{ height: "17px" }} />

                      <span
                        style={{ marginLeft: "12px" }}
                        className="nav-weight"
                      >
                        Assessments
                      </span>
                    </Link>
                  </li>
                  {/* {this.state.assessments ? (
                  <div>
                    <li className="nav-item vector rect">
                      <Link
                       style={{width:'12rem'}}
                        to={{
                          pathname: `${this.state.routerLink}/internal-details`,
                          userID:
                            this.props.userID || this.state.userID("userid"),
                        }}
                        className="nav-link collapsed"
                      >
                        <i className="fas fa-fw fa-tachometer-alt"></i>
                        <i className="fas fa-fw fa-tachometer-alt"></i>
                        <span className="nav-weight">Internals</span>
                      </Link>
                    </li>
                    {this.state.routerLink === "/students-portal" ? (
                      <div>
                      <li className="nav-item vector rect ">
                        <Link
                                              style={{width:'12rem'}}

                          to={{
                            pathname: `${this.state.routerLink}/semester-details`,
                            userID:
                              this.props.userID || this.state.userID("userid"),
                          }}
                          className="nav-link collapsed"
                        >
                          <i className="fas fa-fw fa-tachometer-alt"></i>
                          <i className="fas fa-fw fa-tachometer-alt"></i>
                          <span className="nav-weight">Semester</span>
                        </Link>
                      </li>
                      </div>
                    ) : (
                      <li className="nav-item vector">
                        <Link
                          to={{
                            pathname: `${this.state.routerLink}/semester-details`,
                            userID:
                              this.props.userID || this.state.userID("userid"),
                          }}
                          className="nav-link collapsed"
                        >
                          <i className="fas fa-fw fa-tachometer-alt"></i>
                          <i className="fas fa-fw fa-tachometer-alt"></i>
                          <span className="nav-weight">Semester</span>
                        </Link>
                      </li>
                    )}
                  </div>
                ) : (
                  <div></div>
                )} */}

                  {this.state.routerLink === "/staff-portal" ? (
                    <li className="nav-item">
                      <button
                        className="nav-link collapsed"
                        onClick={this.onTimeTableClick.bind(this)}
                      >
                        <DateRangeIcon style={{ height: "17px" }} />
                        <span className="nav-weight"> Time Table</span>
                        {this.state.timetable ? (
                          <i
                            className="fa fa-angle-down fa-lg time-margin"
                            aria-hidden="true"
                          ></i>
                        ) : (
                          <i
                            className="fa fa-angle-right fa-lg time-margin"
                            aria-hidden="true"
                          ></i>
                        )}
                      </button>
                    </li>
                  ) : (
                    <div></div>
                  )}

                  {this.state.timetable ? (
                    <div>
                      <li className="nav-item vector rect">
                        <Link
                          style={{ width: "170px" }}
                          to={{
                            pathname: `${this.state.routerLink}/staff-timetable`,
                            userID:
                              this.props.userID || this.state.userID("userid"),
                          }}
                          className="nav-link collapsed"
                        >
                          <i className="fas fa-fw fa-tachometer-alt"></i>
                          <i className="fas fa-fw fa-tachometer-alt"></i>
                          <span className="nav-weight1">Staff Time Table</span>
                        </Link>
                      </li>

                      <li className="nav-item vector rect">
                        <Link
                          style={{ width: "12rem" }}
                          to={{
                            pathname: `${this.state.routerLink}/create-student-timetable`,
                            userID:
                              this.props.userID || this.state.userID("userid"),
                          }}
                          className="nav-link collapsed"
                        >
                          <i className="fas fa-fw fa-tachometer-alt"></i>
                          <i className="fas fa-fw fa-tachometer-alt"></i>
                          <span className="nav-weight1">Create Time Table</span>
                        </Link>
                      </li>
                    </div>
                  ) : (
                    <div></div>
                  )}

                  <hr className="sidebar-divider d-none d-md-block" />
                  {this.state.routerLink === "/students-portal" ? (
                    <>
                      <li className="nav-item vector rect">
                        <button
                          className="nav-link collapsed"
                          onClick={this.onAttendanceClick.bind(this)}
                          style={{ width: "170px", marginLeft: "-24px" }}
                        >
                          <FontAwesomeIcon
                            className="fa-sm atstyle1"
                            icon={faHourglassHalf}
                          />
                          <i className="fas fa-fw fa-tachometer-alt "></i>
                          <span className="nav-weight atstyle">Attendance</span>
                          {this.state.attendance ? (
                            <i
                              className="fa fa-angle-down fa-lg att-margin "
                              aria-hidden="true"
                            ></i>
                          ) : (
                            <i
                              className="fa fa-angle-right fa-lg att-margin"
                              aria-hidden="true"
                            ></i>
                          )}
                        </button>
                      </li>
                      {this.state.attendance ? (
                        <div>
                          <li className="nav-item vector rect">
                            <Link
                              style={{ width: "170px", marginLeft: "-10px" }}
                              to={{
                                pathname: `${this.state.routerLink}/attendance`,
                                userID: this.props.userID,
                              }}
                              className="nav-link collapsed"
                            >
                              <i className="fas fa-fw fa-tachometer-alt"></i>
                              <i className="fas fa-fw fa-tachometer-alt"></i>
                              <span
                                style={{ marginLeft: "-32px", width: "100px" }}
                                className="nav-weight"
                              >
                                {" "}
                                Attendance Summary
                              </span>
                            </Link>
                          </li>
                          <li className="nav-item vector rect">
                            <Link
                              style={{
                                width: "12rem",
                                width: "170px",
                                marginLeft: "-10px",
                              }}
                              to={{
                                pathname: `${this.state.routerLink}/leave-status`,
                                userID: this.props.userID,
                              }}
                              className="nav-link collapsed"
                            >
                              <i className="fas fa-fw fa-tachometer-alt"></i>
                              <i className="fas fa-fw fa-tachometer-alt"></i>
                              <span
                                style={{ marginLeft: "-24%" }}
                                className="nav-weight"
                              >
                                {" "}
                                Leave
                              </span>
                            </Link>
                          </li>
                        </div>
                      ) : (
                        <div></div>
                      )}

                      <li className="nav-item">
                        <Link
                          style={{ width: "170px" }}
                          to={{
                            pathname: `${this.state.routerLink}/attendance-maintain`,
                            userID:
                              this.props.userID || this.state.userID("userid"),
                          }}
                          className="nav-link"
                        >
                          <FontAwesomeIcon
                            style={{ height: "17px", marginLeft: "4%" }}
                            className="fa-lg"
                            // icon={faHourglassHalf}
                          />
                          <i
                            style={{ marginLeft: "0%" }}
                            className="fa fa-rocket fa-lg"
                          ></i>
                          <span
                            style={{ marginLeft: "6%" }}
                            className="nav-weight"
                          >
                            Apply Leave
                          </span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          style={{ width: "170px" }}
                          to={{
                            pathname: `${this.state.routerLink}/setting`,
                            userID:
                              this.props.userID || this.state.userID("userid"),
                          }}
                          className="nav-link"
                        >
                          <FontAwesomeIcon
                            style={{ height: "17px", marginLeft: "4%" }}
                            className="fa-lg"
                            // icon={faHourglassHalf}
                          />
                          <i
                            style={{ marginLeft: "0%" }}
                            className="fa fa-rocket fa-lg"
                          ></i>
                          <span
                            style={{ marginLeft: "6%" }}
                            className="nav-weight"
                          >
                            Setting
                          </span>
                        </Link>
                      </li>
                      {this.state.routerLink === "/students-portal"}
                      <li className="nav-item">
                        <Link
                          style={{ width: "170px" }}
                          to={{
                            pathname: `${this.state.routerLink}/leave-status`,
                            userID:
                              this.props.userID || this.state.userID("userid"),
                          }}
                          className="nav-link"
                        >
                          <FontAwesomeIcon
                            style={{ height: "17px", marginLeft: "10%" }}

                            // className="fa-sm"
                            // icon={faHourglassHalf}
                          />
                          <i
                            style={{ marginLeft: "1%" }}
                            class="fa fa-bar-chart "
                            aria-hidden="true"
                          ></i>
                          <span
                            style={{ marginLeft: "7%" }}
                            className="nav-weight"
                          >
                            Leave Status
                          </span>
                        </Link>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}

              {this.state.routerLink === "/students-portal" ? (
                // <li className="nav-item">
                //   <button
                //     className="nav-link collapsed"
                //     onClick={this.onTransportClick.bind(this)}
                //   >
                //     {/* <Link
                //     to={{
                //       pathname: `${this.state.routerLink}/transport`,
                //       userID: this.props.userID,
                //     }}
                //     className="nav-link"
                //   > */}
                //     <FontAwesomeIcon style={{height:'17px',marginLeft: '4%' ,marginTop:'-5px'}} />
                //     <i class="fa fa-bus" aria-hidden="true"></i>
                //     <span  style={{ marginLeft: '8%' }} className="nav-weight">Transport</span>
                //     {this.state.transport ? (
                //       <i  style={{marginTop:'-5px'}}
                //         className="fa fa-angle-down fa-lg transport-margin"
                //         aria-hidden="true"
                //       ></i>
                //      ) : (
                //        <i  style={{marginTop:'-5px'}}
                //        className="fa fa-angle-right fa-lg transport-margin"
                //        aria-hidden="true"
                //       ></i>
                //      )}
                //     {/* </Link> */}
                //   </button>
                // </li>
                <></>
              ) : (
                <></>
              )}

              {this.state.transport ? (
                <div>
                  <li className="nav-item vector rect">
                    <Link
                      style={{ width: "12rem" }}
                      to={{
                        pathname: `${this.state.routerLink}/transport`,
                        userID:
                          this.props.userID || this.state.userID("userid"),
                        explore: true,
                      }}
                      className="nav-link"
                    >
                      <i className="fas fa-fw fa-tachometer-alt"></i>
                      <i className="fas fa-fw fa-tachometer-alt"></i>
                      <span
                        style={{ marginLeft: "-20%", marginTop: "5%" }}
                        className="nav-weight"
                      >
                        Explore Bus Station
                      </span>
                    </Link>
                  </li>
                  {this.state.routerLink === "/students-portal" ? (
                    <li className="nav-item vector rect">
                      <Link
                        style={{ width: "12rem" }}
                        to={{
                          pathname: `${this.state.routerLink}/edit-transport`,
                          userID:
                            this.props.userID || this.state.userID("userid"),
                          edit: true,
                        }}
                        className="nav-link"
                      >
                        <i className="fas fa-fw fa-tachometer-alt"></i>
                        <i className="fas fa-fw fa-tachometer-alt"></i>
                        <span
                          style={{ marginLeft: "-18%" }}
                          className="nav-weight"
                        >
                          Change Bus Station
                        </span>
                      </Link>
                    </li>
                  ) : (
                    <li className="nav-item">
                      <Link
                        to={{
                          pathname: `${this.state.routerLink}/semester-details`,
                          userID:
                            this.props.userID || this.state.userID("userid"),
                        }}
                        className="nav-link collapsed"
                      >
                        <i className="fas fa-fw fa-tachometer-alt"></i>
                        <i className="fas fa-fw fa-tachometer-alt"></i>
                        <span className="nav-weight">Semester</span>
                      </Link>
                    </li>
                  )}
                </div>
              ) : (
                <div></div>
              )}

              {this.state.routerLink === "/students-portal" ? (
                // <li className="nav-item">
                //   <Link
                //   // style={{marginTop:'-5%'}}
                //     to={{
                //       pathname: `${this.state.routerLink}/timetable`,
                //       userID: this.props.userID || this.state.userID("userid"),
                //     }}
                //     className="nav-link "
                //   >
                //     <DateRangeIcon style={{ marginLeft: '-1%',marginTop:'-3%',height:'18px'}}  from />
                //     <span style={{marginTop:'9%',marginLeft:'10px'}} className="nav-weight"> Time Table </span>
                //   </Link>
                // </li>
                <></>
              ) : (
                <></>
              )}

              {/* <li className="nav-item">
                            <Link to={{ pathname: `${this.state.routerLink}/attendance-maintain`, userID: this.props.userID }} className="nav-link">
                                <FontAwesomeIcon className="fa-sm" icon={faHourglassHalf} />
                                <i className="fas fa-fw fa-tachometer-alt"></i>
                                <span>Apply Leave</span>
                            </Link>
                        </li> */}

              {this.state.routerLink === "/students-portal" ? (
                <li className="nav-item">
                  <Link
                    style={{ width: "170px" }}
                    to={{
                      pathname: `${this.state.routerLink}/payment`,
                      userID: this.props.userID || this.state.userID("userid"),
                    }}
                    className="nav-link"
                  >
                    <FontAwesomeIcon
                      className="fa-sm"
                      style={{ marginLeft: "2%" }}
                      icon={faGem}
                    />
                    <i className="fas fa-fw fa-tachometer-alt"></i>
                    <span className="nav-weight ml">Payment</span>
                  </Link>
                </li>
              ) : (
                // <span></span>
                <li className="nav-item">
                  <Link
                    style={{ width: "170px" }}
                    to={{
                      pathname: `${this.state.routerLink}/salary`,
                      userID: this.props.userID || this.state.userID("userid"),
                    }}
                    className="nav-link"
                  >
                    {/* <FontAwesomeIcon className="fa-sm" icon={faGem} />
                  <i className="fas fa-fw fa-tachometer-alt"></i> */}
                    <LocalAtmIcon />
                    <span className="nav-weight mll"> Salary</span>
                  </Link>
                </li>
              )}
              {/* {this.state.routerLink === "/students-portal" ? (
              <li className="nav-item">
                <Link   style={{width:'170px'}}className="nav-link" to="#">
                  <FontAwesomeIcon className="fa-sm" style={{ marginLeft: '2%'}}  icon={faGraduationCap} />
                  <i className="fas fa-fw fa-tachometer-alt"></i>
                  <span className="nav-weight ml">Placement</span>
                </Link>
              </li>
            ) : (
              <p></p>
            )} */}

              <img
                src={logouticon}
                className="logout m-t-40 m-r-20 m-l-20"
                onClick={this.logout.bind(this)}
                style={{ borderTop: "2px solid whitesmoke", width: "100px" }}
              ></img>

              {/* <button
                className="btn btn-sm btn-outline-danger logout m-t-20 m-r-20"
                onClick={this.logout.bind(this)}
              >
                {" "}
                <i className="fa fa-power-off m-r-15"></i>
              </button> */}
              {/* <LogoutOutlined /> */}
            </ul>
            <div
              id="content-wrapper "
              className="d-flex flex-column background"
            >
              <div id="content" className="background">
                {/* <button
                className="btn btn-sm btn-outline-danger logout m-t-20 m-r-20"
                onClick={this.logout.bind(this)}
              >
                {" "}
                <i className="fa fa-power-off m-r-15"></i>Logout
              </button>
              {this.props &&
                this.props.userID &&
                this.props.userID.userDetails ? (
                <>
                  <p className="logoutuser m-t-30 m-r-40">
                    {this.props.userID.userDetails.userRole
                      .charAt(0)
                      .toUpperCase() +
                      this.props.userID.userDetails.userRole.slice(1)}{" "}
                    Dashboard
                  </p>
                  <p className="logoutuser m-t-30 m-r-40">
                    {this.props.userID.userDetails.userID}
                  </p>
                </>
              ) : (
                <></>
              )} */}
                <Switch>
                  {/* student portal links */}
                  <Route
                    path="/students-portal/college-notes"
                    component={classNotes}
                  />
                  <Route path="/signup" element={Signup} />

                  {/* <Route
                  path="/students-portal/xakal-notes"
                  component={classNotes}
                />
                <Route
                  path="/students-portal/question-papers"
                  component={classNotes}
                /> */}
                  <Route
                    path="/students-portal/dashboard"
                    component={Dashboard}
                  />
                  <Route path="/students-portal/setting" component={Setting} />
             
                  <Route path="/students-portal/changepassword" component={Forgetpassword} />

                  <Route
                    path="/students-portal/semester-details"
                    component={SemesterDetails}
                  />
                  <Route
                    path="/students-portal/assessment"
                    component={StudentAssessment}
                  />
                  <Route
                    path="/students-portal/attendance"
                    component={Attendance}
                  />
                  <Route
                    path="/students-portal/attendance-maintain"
                    component={AttendanceMaintain}
                  />
                  <Route
                    path="/students-portal/leave-status"
                    component={LeaveStatus}
                  />
                  <Route
                    path="/students-portal/AttendanceSummary"
                    component={AttendanceSummary}
                  />
                  {/* <Route
                  path="/students-portal/transport"
                  component={Transport}
                /> */}
                  {/* <Route
                  path="/students-portal/edit-transport"
                  component={EditTransport}
                /> */}
                  <Route
                    path="/students-portal/timetable"
                    component={TimeTable}
                  />
                  <Route path="/students-portal/payment" component={Payment} />
                  <Route path="/students-portal/whiteboard" component={Forum} />
                  <Route
                    path="/students-portal/student-profile"
                    component={Dashboard}
                  />
                  <Route
                    path="/students-portal/staff-profile"
                    component={StaffDashboard}
                  />
                  <Route
                    path="/students-portal/manangement-profile"
                    component={ManagementDashboard}
                  />
                  <Route
                    path="/students-portal/hod-profile"
                    component={StaffDashboard}
                  />

                  {/* 
                 links */}
                  <Route
                    path="/staff-portal/college-notes"
                    component={classNotes}
                  />
                  {/* <Route
                  path="/staff-portal/question-papers"
                  component={classNotes}
                />
                <Route
                  path="/staff-portal/dashboard"
                  component={StaffDashboard}
                /> */}
                  <Route
                    path="/staff-portal/assessment"
                    component={StaffAssessment}
                  />
                  {/* <Route
                  path="/staff-portal/internal-details"
                  component={EditInternalDetails}
                /> */}
                  <Route
                    path="/staff-portal/staff-timetable"
                    component={StaffTimetable}
                  />
                  <Route
                    path="/staff-portal/create-student-timetable"
                    component={CreateStudentTimetable}
                  />
                  <Route
                    path="/management-portal/created-timetable"
                    component={CreatedTimetable}
                  />
                  <Route
                    path="/staff-portal/attendance"
                    component={StaffAttendance}
                  />
                  <Route
                    path="/staff-portal/salary"
                    component={SalaryDetails}
                  />
                  <Route
                    path="/staff-portal/view-student-details"
                    component={StudentDetailsMaintain}
                  />
                  <Route path="/staff-portal/whiteboard" component={Forum} />
                  <Route
                    path="/staff-portal/student-profile"
                    component={Dashboard}
                  />
                  <Route
                    path="/staff-portal/staff-profile"
                    component={StaffDashboard}
                  />
                  <Route
                    path="/staff-portal/manangement-profile"
                    component={ManagementDashboard}
                  />
                  <Route
                    path="/staff-portal/hod-profile"
                    component={StaffDashboard}
                  />
                  <Route exact path="/" component={Login} />

                  {/* non-teaching staff portal links */}
                  <Route
                    path="/non-teaching-portal/whiteboard"
                    component={Forum}
                  />
                  <Route
                    path="/non-teaching-portal/dashboard"
                    component={NonTeachingDashboard}
                  />
                  <Route
                    path="/non-teaching-portal/salary"
                    component={SalaryDetails}
                  />
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </Router>
    );
  }
}

export default NavBar;
