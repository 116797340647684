import React from "react";
import { useState } from "react";
import "../styles/login.css";
import "../styles/utils.css";
import Logo from "../images/Mask group.png";
import { Link } from "react-router-dom";
import Modal from "./ModalChangePassword";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";

const Forgetpassword = () => {
  const [valid, setValid] = useState(false);
  const [success, setSuccess] = useState(false);
  const [type, setType] = useState(true);
  const [type2, setType2] = useState(true);
  const [input, setInput] = useState({
    currentPassword: "",
    password: "",
    confirmPassword: "",
  });

  const [error, setError] = useState({
    currentPassword: "",
    password: "",
    confirmPassword: "",
  });

  const [passwordVal, setPasswordValidation] = useState({
    displayVal: "none",
    //startWithLetter:false,
    uppercase: false,
    lowercase: false,
    specialChar: false,
    numeral: false,
    minchar: false,
    valid: false,
  });

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));

    validateInput(e);
    validateInput2(e);
    validateInput3(e);
    validateInput4(e);
    validateInput5(e);
    validateInput6(e);
  };

  const uppercaseRegExp = /(?=.*?[A-Z])/;
  const lowercaseRegExp = /(?=.*?[a-z])/;
  const digitsRegExp = /(?=.*?[0-9])/;
  const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
  const minLengthRegExp = /.{8,}/;

  const validateInput = (e) => {
    let { name, value } = e.target;
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "password":
          const uppercasePassword = uppercaseRegExp.test(value);
          const lowercasePassword = lowercaseRegExp.test(input.password);
          const digitsPassword = digitsRegExp.test(value);
          const specialCharPassword = specialCharRegExp.test(value);
          const minLengthPassword = minLengthRegExp.test(value);

          if (!value) {
            stateObj[name] = "Please enter Password.";
          } else if (input.confirmPassword && value !== input.confirmPassword) {
            stateObj["confirmPassword"] =
              "Password and Confirm Password does not match.";
          } else if (!uppercasePassword) {
            stateObj[name] = "At least one Uppercase";
          } else if (!lowercasePassword) {
            stateObj[name] = "At least one Lowercase";
          } else if (!digitsPassword) {
            stateObj[name] = "At least one digit";
          } else if (!specialCharPassword) {
            stateObj[name] = "At least one Special Characters";
          } else if (!minLengthPassword) {
            stateObj[name] = "At least minumum 8 characters";
          } else {
            stateObj["confirmPassword"] = input.confirmPassword
              ? ""
              : error.confirmPassword;
          }
          break;

        case "confirmPassword":
          if (!value) {
            stateObj[name] = "Please enter Confirm Password.";
          } else if (input.password && value !== input.password) {
            stateObj[name] = "Password and Confirm Password does not match.";
          }
          break;

        default:
          break;
      }
      if (
        stateObj[name] == "" &&
        input.currentPassword != "" &&
        input.password != "" &&
        input.confirmPassword != ""
      ) {
        setValid(true);
      }
      return stateObj;
    });
  };
  const Eye = () => {
    setType(!type);
  };
  const Eye2 = () => {
    setType2(!type2);
  };

  const validateInput2 = (e) => {
    let value = e.target.value;
    const uppercasePassword = uppercaseRegExp.test(value);

    if (uppercasePassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, uppercase: true };
      });
    } else if (!uppercasePassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, uppercase: false };
      });
    }
  };

  const validateInput3 = (e) => {
    let value = e.target.value;
    const lowercasePassword = lowercaseRegExp.test(value);

    if (lowercasePassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, lowercase: true };
      });
    } else if (!lowercasePassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, lowercase: false };
      });
    }
  };

  const validateInput4 = (e) => {
    let value = e.target.value;
    const digitsPassword = digitsRegExp.test(value);

    if (digitsPassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, numeral: true };
      });
    } else if (!digitsPassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, numeral: false };
      });
    }
  };

  const validateInput5 = (e) => {
    let value = e.target.value;
    const specialCharPassword = specialCharRegExp.test(value);

    if (specialCharPassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, specialChar: true };
      });
    } else if (!specialCharPassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, specialChar: false };
      });
    }
  };

  const validateInput6 = (e) => {
    let value = e.target.value;
    const minLengthPassword = minLengthRegExp.test(value);

    if (minLengthPassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, minchar: true };
      });
    } else if (!minLengthPassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, minchar: false };
      });
    }
  };
  const history = useHistory();
  const submitRegister = (e) => {
    e.preventDefault();
    // setDisable("submitted");

    const response = axios.put(
      `${process.env.PUBLIC_URL}/xakal/update-password`,
      input
    );

    if (response.status === 200) {
      setInput({
        ...input,
        currentPassword: "",
        password: "",
        confirmPassword: "",
      });
    } else {
      // toast.error(response.response.data.error);
    }
  };
  function handleSubmit(e) {
    e.preventDefault();
    setSuccess(true);
    submitRegister(e);
  }
  return (
    <div>
      <div className="container-login100 waveBg">
        <img className="logo-in" src={Logo} alt="img" />
        <div className="wrap-login100 p-b-20">
          <form className="login100-form" onSubmit={handleSubmit}>
            <div className="d-flex">
              <div className="">
                <Link to="./forget-password">
                  <div className="back">
                    <i className="fa fa-arrow-left"></i>
                  </div>
                </Link>
                <p
                  className="login-title"
                  style={{ fontSize: "20px", color: "#362A41" }}
                >
                  Change Your Password here
                </p>

                <p className="textpass font-face-gm">Current Password</p>
                <div
                  className={"wrap-input100"}
                  style={{ position: "relative", top: "10px" }}
                >
                  <input
                    autoComplete="off"
                    type={type ? "password" : "text"}
                    className={"input101 "}
                    name="currentPassword"
                    placeholder="Enter Password"
                    value={input.currentPassword}
                    onChange={onInputChange}
                    onBlur={validateInput}
                  />
                  {type ? (
                    <i
                      class="fa fa-eye-slash"
                      onClick={Eye}
                      style={{
                        fontSize: "36px",
                        color: "#362A41",
                        position: "relative",
                        left: "360px",
                        bottom: "30px",
                      }}
                    ></i>
                  ) : (
                    <i
                      class="fa fa-eye"
                      onClick={Eye}
                      style={{
                        fontSize: "36px",
                        color: "#362A41",
                        position: "relative",
                        left: "400px",
                        bottom: "30px",
                      }}
                    ></i>
                  )}
                </div>
                <Link
                  to="./forget-password"
                  style={{ marginLeft: "300px", height: "30px" }}
                >
                  Forgot password ?
                </Link>
                <p className="textpass font-face-gm">New Password</p>
                <div className={"wrap-input100"}>
                  <input
                    autoComplete="off"
                    type={type ? "password" : "text"}
                    className={"input101 "}
                    name="password"
                    placeholder="Enter Password"
                    value={input.password}
                    onChange={onInputChange}
                    onBlur={validateInput}
                  />
                  {type ? (
                    <i
                      class="fa fa-eye-slash"
                      onClick={Eye}
                      style={{
                        fontSize: "36px",
                        color: "#362A41",
                        position: "relative",
                        left: "360px",
                        bottom: "30px",
                      }}
                    ></i>
                  ) : (
                    <i
                      class="fa fa-eye"
                      onClick={Eye}
                      style={{
                        fontSize: "36px",
                        color: "#362A41",
                        position: "relative",
                        left: "400px",
                        bottom: "30px",
                      }}
                    ></i>
                  )}
                </div>
                {error.password && (
                  <span className="text-danger">{error.password}</span>
                )}
                <p className="textpass font-face-gm">Re-type Password</p>
                <div className={"wrap-input100 m-b-30"}>
                  <input
                    autoComplete="off"
                    type={type2 ? "password" : "text"}
                    className={"input101 "}
                    name="confirmPassword"
                    placeholder="Enter Confirm Password"
                    value={input.confirmPassword}
                    onChange={onInputChange}
                    onBlur={validateInput}
                    disabled={error.password}
                  />
                  {type2 ? (
                    <i
                      class="fa fa-eye-slash"
                      onClick={Eye2}
                      style={{
                        fontSize: "36px",
                        color: "#362A41",
                        position: "relative",
                        left: "360px",
                        bottom: "30px",
                      }}
                    ></i>
                  ) : (
                    <i
                      class="fa fa-eye"
                      onClick={Eye2}
                      style={{
                        fontSize: "36px",
                        color: "#362A41",
                        position: "relative",
                        left: "400px",
                        bottom: "30px",
                      }}
                    ></i>
                  )}
                </div>
                {error.confirmPassword && (
                  <span className="text-danger">{error.confirmPassword}</span>
                )}

                {error.password ? (
                  <div>
                    <div className="connect2">
                      <i className="fa fa-arrow-right"></i>
                    </div>
                    <div id="message2">
                      <h3>Password must contain the following:</h3>

                      <div style={{ display: "flex", gap: "20px" }}>
                        <div
                          className={
                            passwordVal.lowercase ? "check" : "uncheck"
                          }
                        ></div>
                        <p
                          className={
                            passwordVal.lowercase ? "valid" : "invalid"
                          }
                        >
                          Must have 1 Lower Case Alphabet
                        </p>
                      </div>

                      <div style={{ display: "flex", gap: "20px" }}>
                        <div
                          className={
                            passwordVal.uppercase ? "check" : "uncheck"
                          }
                        ></div>
                        <p
                          className={
                            passwordVal.uppercase ? "valid" : "invalid"
                          }
                        >
                          Must have 1 Upper Case Alphabet
                        </p>
                      </div>

                      <div style={{ display: "flex", gap: "20px" }}>
                        <div
                          className={passwordVal.numeral ? "check" : "uncheck"}
                        ></div>
                        <p
                          id="number"
                          className={passwordVal.numeral ? "valid" : "invalid"}
                        >
                          Must have 1 Numeral Case Alphabet
                        </p>
                      </div>

                      <div style={{ display: "flex", gap: "20px" }}>
                        <div
                          className={passwordVal.minchar ? "check" : "uncheck"}
                        ></div>
                        <p
                          id="length"
                          className={passwordVal.minchar ? "valid" : "invalid"}
                        >
                          Minimum 8 characters required
                        </p>
                      </div>

                      <div style={{ display: "flex", gap: "20px" }}>
                        <div
                          className={
                            passwordVal.specialChar ? "check" : "uncheck"
                          }
                        ></div>
                        <p
                          className={
                            passwordVal.specialChar ? "valid" : "invalid"
                          }
                        >
                          Must have 1 Special Character [#?!@$%^&*-]
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div className="container-login100-form-btn">
                  <button
                    type="submit"
                    className="login100-form-btn"
                    disabled={!valid}
                    style={{ opacity: valid ? "100%" : "50%" }}
                    onClick={handleSubmit}
                  >
                    Change Password
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {success ? <Modal /> : ""}
    </div>
  );
};

export default Forgetpassword;
