import {
    addSubjects,
    getAllSubjects,
    getAllStaffsByDepartment,
    allocateSubjects
} from '../../../api/classroom';

function getSubAndStaffList(subjects) {
    return subjects.map(sub => ({
        subject: sub._id,
        staffs: sub.staff.map(stf => stf._id)
    }))
}

export async function fetchAllSubjects() {
    const allSub = await getAllSubjects()
    if (!allSub) {
        return this.setState({ rendered: true })
    };
    if (!allSub.data.length) {
        return this.setState({ rendered: true })
    };
    if (allSub.data && allSub.data.length) {
        const subjects = this.sortSubjectsByField(filterDupStaff(allSub.data));
        this.setState({
            subjects,
            subAndStaffList: getSubAndStaffList(subjects),
            rendered: true
        })
        getSubjectsAndStaffs.bind(this)(subjects)
        const depts = getAllDepartmentIDS(subjects)
        getStaffs.bind(this)(depts)
    }
}

function filterDupStaff(subjects) {
    return subjects
        .map(sub => {
            if (!sub.staff.length) return sub;
            return {
                ...sub,
                staffIds: [...new Set(sub.staff.map(e => e._id))]
            }
        })
        .map(sub => {
            if (!sub.staff.length) return sub;
            return {
                ...sub,
                staff: getUniqueStaffs(sub.staffIds, sub.staff)
            }
        })
}

function getUniqueStaffs(staffIds, staffArr) {
    let staff = [];
    staffIds.forEach(id => {
        let obj = staffArr.find(s => s._id == id)
        staff.push(obj)
    })
    return staff;
}

function getStaffWithSelectors(staffs, subId) {
    return staffs.map((stf, index) => ({
        _id: stf._id,
        selectorId: `select-staff-${subId}-${index + 1}`
    }))
}

const checkInstance = (elem) => {
    if (elem instanceof Array && elem instanceof Object) {
        return 'array'
    } else if (!(elem instanceof Array) && elem instanceof Object) {
        return 'object'
    } else if (typeof elem === 'string') {
        return 'string'
    } else if (typeof elem === 'number') {
        return 'number'
    }
}

export function getSubjectsAndStaffs(subjects) {
    if (subjects.length) {
        let data = subjects
            .map((e, i) => ({
                staff: e.staff.length == 0 ? [] : getStaffWithSelectors(e.staff, e._id),
                subject: e._id
            }))
        this.setState({ subsAndStaffsForm: data })
    }
}

function getAllDepartmentIDS(subjects) {
    return subjects.map(sub => sub.departmentID)
}

function getStaffs(depts) {
    depts = [...new Set([...depts])]
    depts.forEach(async deptID => {
        const staffs = await getAllStaffsByDepartment(deptID);
        const data = {
            deptID: deptID,
            staffs: staffs.map(staff => ({
                _id: staff._id,
                name: staff.name,
                userID: staff.userID
            }))
        }
        this.setState({ availableStaffs: [...this.state.availableStaffs, data] })
    })
}

export function allocateStaffs(evt, subId) {
    if (evt.target.value) {
        let data = {
            staff: [{
                _id: evt.target.value,
                selectorId: evt.target.id
            }],
            subject: subId
        }
        let formData = [data];
        if (this.state.subsAndStaffsForm.length) {
            //add staff (from new selector)
            let existingForm = this.state.subsAndStaffsForm;
            let index = existingForm.findIndex(e => e.subject == subId)
            console.log('index', index)
            if (index !== -1) {
                let existingData = existingForm.find(e => e.subject == subId)

                let prevStaffs = existingData.staff.map(stf => ({
                    _id: stf._id,
                    selectorId: stf.selectorId
                }))

                let staff = [...prevStaffs, { _id: evt.target.value, selectorId: evt.target.id }]

                //edit staff (from same selector)
                let idx = existingData.staff.findIndex(e => (e.selectorId == evt.target.id))
                console.log('idx', idx)
                if (idx !== -1) {
                    existingData.staff.splice(idx, 1, { _id: evt.target.value, selectorId: evt.target.id })
                    staff = existingData.staff;
                    console.log('staff', staff)
                }
                data = {
                    subject: subId,
                    staff
                }
                existingForm.splice(index, 1, data)
                formData = existingForm;
            } else {
                formData = [...this.state.subsAndStaffsForm.filter(s => s.subject), data]
            }
        } else {
            formData = [...this.state.subsAndStaffsForm.filter(s => s.subject), data]
        }
        console.log('formData', formData)
        this.setState({
            subsAndStaffsForm: formData
        })
    }
}

const delay = async (ms) => new Promise(res => {
    setTimeout(() => res(), ms)
})

export async function allocateStaffsSubmit(e) {
    // e.preventDefault();
    if (!this.state.subsAndStaffsForm.length) return alert('No Changes!')
    const formData = this.state.subsAndStaffsForm.map(f => ({ subject: f.subject, staff: f.staff.map(s => s._id) }))
    console.log({ formData })
    await allocateSubjects(formData, this.state.userDetails._id);
    await delay(2000)
    this.setState({ rendered: false })
    await this.fetchAllSubjects()
    alert('Staffs Allocated!')
}

export function addStaffSelector(subIndex) {
    const subjects = this.state.subjects;
    let staff = subjects[subIndex].staff.length
        ? subjects[subIndex].staff
        : [{}];
    subjects[subIndex].staff = [...staff, {}];
    this.setState({ subjects });
}

function removeStaff(subId, staffId, subjects, staffIndex, subIndex) {
    subjects[subIndex].staff.splice(staffIndex, 1);
    return subjects.map((sub, i) => {
        if (sub._id == subId) {
            let staff = sub.staff;
            const index = staff.findIndex((staff) => staff._id == staffId);
            if (index !== -1) {
                sub.staff.splice(index, 1);
            }
        }
        return sub;
    });
}

export function removeStaffSelector(subIndex, staffIndex, staffId, subId, singleStaff) {
    let subjects = this.state.subjects;

    if (singleStaff) {

        subjects[subIndex].staff.splice(0, 1, {});

    } else if (!singleStaff) {

        subjects = removeStaff.bind(this)(
            subId,
            staffId,
            subjects,
            staffIndex,
            subIndex
        );

    }
    getSubjectsAndStaffs.bind(this)(subjects);
    this.setState({ subjects });
}