import React, { Component } from "react";
import "../../styles/table.css";
import "../../minified-css/material-min.css";
import "../../styles/dropdowns.css";
import "../../styles/theme-style.css";
import '../../styles/classnote.css';
import Pagination from "../../utils/pagination";
import axios from "axios";
import moment from "moment";
import {
  faDownload
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      column1: "",
      column2: "",
      column3: "",
      searchAllowed: false,
      paymentList: [],
      isFocussed: "",
      onFocus: false,
      selectedSemester: "",
      background: "",
      userID: "",
      currentPage: 1,
      postsPerPage: 5
    };
    this.baseState = this.state;
    this.paginate = this.paginate.bind(this);
  }

  componentDidMount() {
    if (this.props && this.props.location && this.props.location.userID) {
      const userID = this.props.location.userID;
      this.setState({ userID: userID.userID });
    }
    this.unlisten = this.props.history.listen((location, action) => {
      this.setState(this.baseState);
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  /**
   * Adds the hover class when date is hovered
   */
  dateHover(event) {
    var element = event.target.className;
    if (element === "column100 column3 ") {
      this.setState({ column2: "hov-column-head-ver5" });
    }
  }

  /**
   * Adds the hover class when description is hovered
   */
  descriptionHover(event) {
    var element = event.target.className;
    if (element === "column100 column2 ") {
      this.setState({ column1: "hov-column-head-ver5" });
    }
  }

  paginate(num) {
    this.setState({ currentPage: num })
  }

  /**
   * Adds the hover class when receipt is hovered
   */
  receiptHover(event) {
    var element = event.target.className;
    if (element === "column100 column4 ") {
      this.setState({ column3: "hov-column-head-ver5" });
    }
  }

  /**
   * Resets the state variables when hover is removed
   */
  hoverOff() {
    this.setState({
      column1: "",
      column2: "",
      column3: "",
    });
  }

  /**
   * Sets the semester selected
   */
  onDropDownSelect(event) {
    this.setState({
      selectedSemester: event.target.id,
      onFocus: false,
      background: "is-hidden",
    });
    if (this.state.searchAllowed) {
      this.setState({ searchAllowed: false });
    }
  }

  onDropDownFocus() {
    this.setState({
      isFocussed: "is-focused",
      onFocus: true,
      background: "is-shown",
    });
  }

  /**
   * Allows the grid to display the values
   */
  getResult() {
    if (this.state.selectedSemester !== "") {
      this.fetchPaymentDetails();
    } else {
      alert("Please select the values");
      this.setState({ searchAllowed: false });
    }
  }

  /**
   * Fetches the details of Payment based on semester selected
   */
  fetchPaymentDetails() {
    this.setState({ searchAllowed: true });
    var semester = this.state.selectedSemester;
    var userID = { userID: this.state.userID };
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/payment/${semester}`, { params: userID })
      .then((response) => {
        this.setState({ paymentList: response.data });
      });
  }

  /**
   * Displays the list of payment details based on the API response
   */
  displayTable() {
    const indexOfLastPost = this.state.currentPage * this.state.postsPerPage;
    const indexOfFirstPost = indexOfLastPost - this.state.postsPerPage;
    const currentPost = this.state.paymentList.slice(indexOfFirstPost, indexOfLastPost)

    return currentPost.map((singleData, index) => {
      var hrefValue =
        `../../../` +
        singleData.userID +
        "/" +
        singleData.semester +
        "/" +
        singleData.uploadedReceipt;
      console.log(singleData);
      return (
        <tr className="row100">
          <td className="column100  grid-cont vertical-border column1 space" data-column="column1">
            {++index}
          </td>
          <td
            className={"column100 grid-cont vertical-border  column2 space"}
            onMouseEnter={this.descriptionHover.bind(this)}
            onMouseLeave={this.hoverOff.bind(this)}
          >
            {singleData.description}
          </td>
          <td
            className={"column100 grid-cont vertical-border  column3 space"}
            onMouseEnter={this.dateHover.bind(this)}
            onMouseLeave={this.hoverOff.bind(this)}
          >
            {moment(new Date(singleData.paymentDate)).format("DD/MM/YYYY")}
          </td>
          <td
            className={"column100  grid-cont vertical-border column4 space"}
            onMouseEnter={this.receiptHover.bind(this)}
            onMouseLeave={this.hoverOff.bind(this)}
          >
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={singleData.uploadedReceiptUrl ? singleData.uploadedReceiptUrl : "#"}
            >
              <FontAwesomeIcon className="fa-sm vertical-border download" icon={faDownload} />
            </a>
          </td>
        </tr>
      );
    });
  }

  render() {
    return (
      <div className="container-fluid pay background">
        <div className="d-sm-flex mb-4">
          <nav className="navbar navbar-expand-lg  navprac bar " style={{ marginLeft: '1%', width: '90%',marginTop:"7rem" }}>
            <div className="title" >Payment</div>
            <div className="collapse navbar-collapse">
              <form className="form-inline my-2 my-lg-0">
                <input style={{ width: '500px', marginLeft: '120px', backgroundColor: 'rgba(54, 42, 65, 0.04)', fontStyle: 'italic', height: '45px' }} className="form-control mr-sm-2 " type="search" placeholder="Search....." aria-label="Search"></input>
              </form>
            </div>
          </nav>
          <div className="row">
            <h4 className="head"
              style={{ marginLeft: '-879px', marginTop: '250px' }}>Payment</h4>

            {/* <h1 className="h3 mb-0 text-gray-800 m-t-20">Notes</h1> */}
          </div>
          {/* <h1 className="h3 mb-0 text-gray-800 m-t-20 m-l-20">Payment</h1> */}
        </div>
        <div className="row" style={{ marginLeft: "1.5rem" }}>
          <div className="col-sm-12">
            <div className="card-box-payment">
              <div className="card-body row">
                <div className="col-lg-6 p-t-20">


                  <label className="labell pay1"
                    style={{marginTop: '-27px' }}

                  // className={
                  //   "mdl-textfield__label " + this.state.background

                  // }
                  >
                    Semester Number
                  </label>
                  <input
                    onKeyPress={(e) => e.preventDefault()}

                    onFocus={this.onDropDownFocus.bind(this)}
                    className="new-xakal-input paysem"
                    type="text"
                    id="sample2"
                    value={this.state.selectedSemester}
                    style={{ backgroundColor: '#F6F6F6', height: '40px', marginTop: '2px', width: '368px' }}
                  />



                  {this.state.onFocus ? (
                    <div className="mdl-menu__container is-upgraded dropdown-list is-visible paysem" style={{ width: '368px' }}>
                      <div className="mdl-menu__outline  dropdown-div drop1" style={{  width: '368px', marginTop: '-2%' }}>
                        <ul className="scrollable-menu  ul-list" style={{ width: '368px' }}>
                          <li
                            className="mdl-menu__item animation  label-weight"
                            id="Semester 1"
                            onClick={this.onDropDownSelect.bind(this)}
                          >
                            Semester 1
                          </li>
                          <li
                            className="mdl-menu__item animation  label-weight1"
                            id="Semester 2"
                            onClick={this.onDropDownSelect.bind(this)}
                          >
                            Semester 2
                          </li>
                          <li
                            className="mdl-menu__item animation  label-weight2"
                            id="Semester 3"
                            onClick={this.onDropDownSelect.bind(this)}
                          >
                            Semester 3
                          </li>
                          <li
                            className="mdl-menu__item animation  label-weight"
                            id="Semester 4"
                            onClick={this.onDropDownSelect.bind(this)}
                          >
                            Semester 4
                          </li>
                          <li
                            className="mdl-menu__item animation  label-weight1"
                            id="Semester 5"
                            onClick={this.onDropDownSelect.bind(this)}
                          >
                            Semester 5
                          </li>
                          <li
                            className="mdl-menu__item animation  label-weight2"
                            id="Semester 6"
                            onClick={this.onDropDownSelect.bind(this)}
                          >
                            Semester 6
                          </li>
                          <li
                            className="mdl-menu__item animation  label-weight"
                            id="Semester 7"
                            onClick={this.onDropDownSelect.bind(this)}
                          >
                            Semester 7
                          </li>
                          <li
                            className="mdl-menu__item animation  label-weight1"
                            id="Semester 8"
                            onClick={this.onDropDownSelect.bind(this)}
                          >
                            Semester 8
                          </li>
                        </ul>
                      </div>
                    </div>
                  ) : (
                    <p></p>
                  )}

                </div>
                <div className="col-sm-6 p-t-20">

                  <button
                    type="btn btn-primary button "
                    onClick={this.getResult.bind(this)}
                    className="getresult m-t-20 heading-weight button-size paysem"
                    style={{ marginLeft: '30%', marginTop: '30px' }}
                  >
                    Get Results
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.searchAllowed ? (
          <div className="limiter background bk">
            <div class="container background" style={{ marginTop: '0px', marginLeft: '87px' }}>
              <div class="col-md-12">
                <div class="row">
                  <table className="background">
                    <thead style={{ color: '#B8B3BC' }}>
                      <tr className="row100 head" >
                        <th
                          style={{ color: '#B8B3BC' }}
                          className="column100 column1  background vertical-border-head heading-weight heading-size space"
                          data-column="column1"

                        >SL No</th>
                        <th
                          style={{ color: '#B8B3BC' }}
                          className={"column100  background vertical-border-head heading-weight heading-size column2 space" + this.state.column1}
                          onMouseEnter={this.descriptionHover.bind(this)}
                          onMouseLeave={this.hoverOff.bind(this)}
                        >
                          Description
                        </th>
                        <th
                          style={{ color: '#B8B3BC' }}
                          className={"column100 background vertical-border-head heading-weight heading-size column3 space" + this.state.column2}
                          onMouseEnter={this.dateHover.bind(this)}
                          onMouseLeave={this.hoverOff.bind(this)}
                        >
                          Date of Payment
                        </th>
                        <th style={{ color: '#B8B3BC' }}
                          className={"column100  background vertical-border-head heading-weight heading-size column1 space" + this.state.column3}
                          onMouseEnter={this.receiptHover.bind(this)}
                          onMouseLeave={this.hoverOff.bind(this)}
                        >
                          Receipts / Acknowledgement
                        </th>
                      </tr>
                    </thead>
                    <tbody>{this.displayTable()}</tbody>
                  </table>
                </div>
                <div style={{ marginLeft: '-8%' }}>
                  <Pagination postPerPage={this.state.postsPerPage} totalPost={this.state.paymentList.length} paginate={this.paginate} />
                </div></div>
            </div>
          </div>
        ) : (
          <span></span>
        )}
      </div>
    );
  }
}

export default Payment;
