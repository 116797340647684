import React, { Component } from 'react';

class EditStaffName extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div>
                {
                    this.props.subjects.length
                        ?
                        this.props.subjects.map((sub, index) => (
                            <table>
                                <tr>
                                    <td>
                                        <select
                                            onChangeCapture={(e) =>
                                                this.props.editStaff(e, sub, index)
                                            }
                                            style={{ border: '0px', outline: '0px', width: '60%' }}
                                        >
                                            <option value={sub.staff[0]._id}>{sub.staff[0].name}</option>
                                            {
                                                sub.staff.slice(1, sub.staff.length).map((stf) => <option value={stf._id}>{stf.name}</option>)
                                            }
                                        </select>
                                    </td>
                                </tr>
                            </table>
                        ))
                        :
                        <></>
                }
            </div>
        );
    }
}

export default EditStaffName;