import React, { Component } from "react";
import "../../styles/table.css";
import "../../minified-css/material-min.css";
import "../../styles/dropdowns.css";
import "../../styles/theme-style.css";
import "../../styles/classnote.css";
import axios from "axios";
import moment from "moment";

class Transport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      column1: "",
      column2: "",
      selectedSemester: "",
      searchAllowed: false,
      absenceList: [],
      background: "",
      isFocussed: "",
      onFocus: false,
      userID: "",
      isManagementPortal: false,
      caption: {},
      buStop: {},
      availableBusStops: [],
      student: {},

    };
    this.baseState = this.state;
  }

  componentDidMount() {
    this.getAllBusStops();
    this.getPortal();
    if (this.props && this.props.location && this.props.location.userID) {
      const userID = this.props.location.userID;
      this.setState({ userID: userID.userID });
      this.getBusStopDetailsById(userID.userID);
    }
    this.unlisten = this.props.history.listen((location, action) => {
      this.setState(this.baseState);
      this.getPortal();
    });
  }

  handleFormChange(e) {
    console.log('caption id', this.state.availableBusStops.find(item => item.name === e.target.value)._id)
    this.setState({
      caption: {
        _id: this.state.availableBusStops.find(item => item.name === e.target.value)._id,
        name: e.target.value,

      }
    })
  }

  getResult() {
    if (!this.state.caption) {
      return alert("Please Provide Bus Stop Name!")
    }
    this.getBusStopDetailsByName();
  }

  getAllBusStops() {
    let adminId = '5dd6ed68c0052a052cbdb09c';
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/transport/admin/getAllBusStops/${adminId}`)
      .then((response) => {
        console.log('getAllBusStops', response)
        if (response.status !== 200) {
          return alert(response.data.msg)
        }
        this.setDropDown(response.data.data);
      })
      .catch(e => alert(e))
  }

  setDropDown(buses) {
    let busArr = buses.map(b => ({ _id: b._id, name: b.name })).sort();
    console.log('busArr', busArr)
    this.setState({ availableBusStops: busArr, caption: busArr[0] });
  }

  getBusStopDetailsById(userId) {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/studentdetail/${userId}`, {
      })
      .then((response) => {
        console.log('stud', response)
        if (response.status !== 200) {
          return alert(response.data.msg)
        }
        this.setState({ student: response.data.user })
        return response.data.user.busStop._id;
      })
      .then((busStopId) => {
        console.log('busStopId', busStopId)
        axios
          .get(`/xakal/transport/getStudentsBusStopDetailsById/${busStopId}`, {
          })
          .then((response) => {
            console.log('getBusStopDetailsById', response)
            if (response.status !== 200) {
              return alert(response.data.msg)
            }
            this.setState({ busStop: response.data.data });
          })
      })
      .catch(e => {
        console.log({ error: e })
        alert(e)
      })
  }

  getBusStopDetailsByName() {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/transport/getStudentsBusStopDetailsByName`, {
        params: {
          name: this.state.caption.name
        }
      })
      .then((response) => {
        console.log('getBusStopDetailsByName', response)
        if (response.status !== 200) {
          return alert(response.data.msg)
        }
        this.setState({ busStop: response.data.data });
      })
      .catch(e => alert(e))
  }

  async saveBusStop() {
    console.log('caption', this.state.caption)
    axios
      .put(`${process.env.PUBLIC_URL}/xakal/studentdetail/update/${this.state.userID}`, {
        busStop: this.state.caption._id
      })
      .then((response) => {
        console.log('update student', response.data)
        this.setState({ student: response.data.student });
      })
      .catch(e => alert(e))
  }

  /**
   * Checks for portal logged in
   */
  getPortal() {
    const pathArray = this.props.location.pathname.split("/");
    if (
      pathArray.includes("management-portal") ||
      pathArray.includes("hod-portal")
    ) {
      this.setState({ isManagementPortal: true });
      this.fetchDepartmentDetails();
    } else {
      this.setState({ isManagementPortal: false });
    }
  }

  componentWillUnmount() {
    this.unlisten();
  }

  /**
   * Adds the hover class when date is hovered
   */
  dateHover(event) {
    var element = event.target.className;
    if (element === "column100 column2 ") {
      this.setState({ column1: "hov-column-head-ver5" });
    }
  }

  /**
   * Adds the hover class when reason is hovered
   */
  reasonHover(event) {
    var element = event.target.className;
    if (element === "column100 column3 ") {
      this.setState({ column2: "hov-column-head-ver5" });
    }
  }

  typeHover(event) {
    var element = event.target.className;
    if (element === "column100 column4 ") {
      this.setState({ column2: "hov-column-head-ver5" });
    }
  }

  actionHover(event) {
    var element = event.target.className;
    if (element === "column100 column5 ") {
      this.setState({ column2: "hov-column-head-ver5" });
    }
  }

  /**
   * Adds the hover class when userID is hovered
   */
  userHover(event) {
    var element = event.target.className;
    if (element === "column100 column4 ") {
      this.setState({ column3: "hov-column-head-ver5" });
    }
  }

  /**
   * Resets the state variables when hover is removed
   */
  hoverOff() {
    this.setState({
      column1: "",
      column2: "",
      column3: "",
      column4: "",
    });
  }

  /**
   * Sets the semester selected
   */
  onDropDownSelect(event) {
    this.setState({
      selectedSemester: event.target.id,
      onFocus: false,
      background: "is-hidden",
      hasSemesterValue: true,
    });
    if (this.state.searchAllowed) {
      this.setState({ searchAllowed: false });
    }
  }

  onDropDownFocus() {
    this.setState({
      isFocussed: "is-focused",
      onFocus: true,
      background: "is-shown",
    });
    if (this.state.hasDepartmentValue) {
      this.setState({
        onDepartmentFocus: false,
        backgroundDepartment: "is-hidden",
        isDepartmentFocussed: "is-focused",
      });
    } else {
      this.setState({
        onDepartmentFocus: false,
        backgroundDepartment: "is-hidden",
        isDepartmentFocussed: "is-hidden",
      });
    }
  }

  /**
   * Allows the grid to display the values
   */

  /**
   * Fetches the date of absence based on semester selected
   */
  fetchAbsenceDetails() {
    var userID;
    this.setState({ searchAllowed: true });
    var semester = this.state.selectedSemester;
    if (this.state.isManagementPortal === true) {
      userID = { userID: this.state.selectedStudent };
    } else {
      userID = { userID: this.state.userID };
    }
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/attendance/studentleave/${semester}`, { params: userID })
      .then((response) => {
        this.setState({ absenceList: response.data });
      });
  }

  /**
   * Fetches all department
   */
  fetchDepartmentDetails() {
    axios.get(`${process.env.PUBLIC_URL}/xakal/departmentdetail`).then((response) => {
      this.setState({ departmentDetails: response.data });
    });
  }

  /**
   * Triggers when department dropdown is focused
   */
  onDeptDropDownFocus() {
    this.setState({
      isDepartmentFocussed: "is-focused",
      onDepartmentFocus: true,
      backgroundDepartment: "is-shown",
    });
    if (this.state.hasSemesterValue) {
      this.setState({
        onFocus: false,
        background: "is-hidden",
        isFocussed: "is-focused",
      });
    } else {
      this.setState({
        onFocus: false,
        background: "is-hidden",
        isFocussed: "is-hidden",
      });
    }
  }

  /**
   * Triggers when the department is changed and stores the values in state
   * @param event form values
   */
  handleDepartmentChange(event) {
    this.setState({
      selectedDepartment: event.target.id,
      onDepartmentFocus: false,
      backgroundDepartment: "is-hidden",
      background: "is-hidden",
      hasDepartmentValue: true,
    });
    this.fetchStudentDetailsByDept(event.target.id);
  }

  /**
   * Displays the list of department based on the API response
   */
  displayDepartment() {
    if (
      this.state &&
      this.state.departmentDetails &&
      this.state.departmentDetails.length
    ) {
      return this.state.departmentDetails.map((singleDepartment, index) => {
        return (
          <li className="mdl-menu__item animation" key={index}>
            <button
              id={singleDepartment.name}
              name={singleDepartment.name}
              onClick={this.handleDepartmentChange.bind(this)}
            >
              {singleDepartment.name}
            </button>
          </li>
        );
      });
    }
  }

  /**
   * Fetches all students for selected department
   */
  fetchStudentDetailsByDept(departmentName) {
    this.setState({ studentDetails: [] });
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/studentdetail/department/${departmentName}`)
      .then((response) => {
        this.setState({ studentDetails: response.data });
      });
  }

  /**
   * Removes the selected row
   * @param index selected row index
   */
  removeClick(singleData, i) {
    if (moment(new Date(singleData.fromDate)).isBefore(moment(new Date()))) {
      alert("Cannot cancel this leave. Please contact admin.");
    } else {
      if (window.confirm("Are you sure to cancel this leave?")) {
        let absenceList = [...this.state.absenceList];
        absenceList.splice(i, 1);
        this.setState({ absenceList });
        axios
          .put(`${process.env.PUBLIC_URL}/xakal/attendance/cancelLeave/${singleData._id}`)
          .then((response) => {
            // this.setState({ studentDetails: response.data });
          });
      }
    }
  }

  /**
   * Displays the list of notes based on the API response
   */
  displayTable() {
    let counter = 0;
    return this.state.absenceList.map((singleData, index) => {
      const fromDate = moment(new Date(singleData.fromDate)).format(
        "MM/DD/YYYY"
      );
      const toDate = moment(new Date(singleData.toDate)).format("MM/DD/YYYY");
      return (
        <tr className="row100">
          <td
            className="column100 column1"
            key={counter++}
            data-column="column1"
          >
            {++index}
          </td>
          <td
            className={"column100 column2 "}
            key={counter++}
            onMouseEnter={this.userHover.bind(this)}
            onMouseLeave={this.hoverOff.bind(this)}
          >
            {singleData.userID}
          </td>
          <td
            className={"column100 column2 "}
            key={counter++}
            onMouseEnter={this.dateHover.bind(this)}
            onMouseLeave={this.hoverOff.bind(this)}
          >
            {fromDate} - {toDate}
          </td>
          <td
            className={"column100 column3 "}
            key={counter++}
            onMouseEnter={this.reasonHover.bind(this)}
            onMouseLeave={this.hoverOff.bind(this)}
          >
            {singleData.reason}
          </td>
          <td
            className={"column100 column4 "}
            key={counter++}
            onMouseEnter={this.typeHover.bind(this)}
            onMouseLeave={this.hoverOff.bind(this)}
          >
            {singleData.type}
          </td>
          {this.state.isManagementPortal ? (
            <td
              className={"column100 column5 "}
              key={counter++}
              onMouseEnter={this.actionHover.bind(this)}
              onMouseLeave={this.hoverOff.bind(this)}
            >
              <button
                type="button"
                onClick={(i) => this.removeClick(singleData, (index -= 4))}
                className="btn btn-danger m-t-4"
              >
                Cancel Leave
              </button>
            </td>
          ) : (
            <></>
          )}
        </tr>
      );
    });
  }

  /**
   * Triggers when student is focused
   */
  onStudentFocussed() {
    this.setState({
      isStudentFocussed: "is-focused",
      onFocus: false,
      onStudentFocus: true,
      backgroundStudent: "is-shown",
    });
  }

  /**
   * Triggers when the student is changed and stores the values in state
   * @param event form values
   */
  handleStudentChange(event) {
    this.setState({
      selectedStudentName: event.target.name,
      selectedStudent: event.target.id,
      onStudentFocus: false,
      backgroundStudent: "is-hidden",
    });
  }

  /**
   * Displays the list of student based on the API response
   */
  displayStudent() {
    if (
      this.state &&
      this.state.studentDetails &&
      this.state.studentDetails.length
    ) {
      return this.state.studentDetails.map((singleStudent, index) => {
        return (
          <li className="mdl-menu__item animation" key={index}>
            <button
              id={singleStudent.userID}
              name={singleStudent.name}
              onClick={this.handleStudentChange.bind(this)}
            >
              {singleStudent.name}
            </button>
          </li>
        );
      });
    }
  }

  render() {
    return (
      <div className="container-fluid transcon background ">
        <div className="bartrans" >
          <nav className="navbar navbar-expand-lg  navprac">
            <div className="title ">Transport</div>

            <div className="collapse navbar-collapse">


              <form className="form-inline my-2 my-lg-0">
                <input style={{ width: '500px', marginLeft: '120px', backgroundColor: 'rgba(54, 42, 65, 0.04)', fontStyle: 'italic', height: '45px' }} className="form-control mr-sm-2 " type="search" placeholder="Search....." aria-label="Search"></input>
              </form>

            </div>
          </nav>
        </div>

        <div className="trans">
          <div className="d-sm-flex align-items-center justify-content-between mb-4" style={{ marginLeft: "2rem" }}>
            <h3 className="h3  m-t-0 m-l-20 location" style={{ marginTop: '-80px' }}>Check Pickup point for your location</h3>
          </div>

          <div>
            <div className="row">
              <div className="col-sm-12">
                {/* <div className="card-box-trans"> */}
                <div className="card-body row">
                  <div className="col-lg-6 p-t-20">
                    <div
                      className={
                        "mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height select-width " +
                        this.state.isFocussed
                      }
                      style={{ marginLeft: "3rem" }}
                    >

                      <select onChange={this.handleFormChange.bind(this)} style={{ width: '200px' }}
                      >
                        <option style={{ color: 'black' }}>Select your location:</option>
                        {
                          this.state.availableBusStops.length &&
                          this.state.availableBusStops.map(item =>
                            <option style={{ backgroundColor: "#362A41" }} id={item._id}>{item.name}</option>)
                        }
                      </select>
                    </div>
                  </div>


                  <div className="col-lg-6 p-t-20 ">
                    <button
                      type="button"
                      onClick={this.getResult.bind(this)}
                      className="getresult m-t-1 transport "
                      style={{ marginLeft: '5%' }}
                    >
                      Get Results!
                    </button>
                  </div>
                </div>
                {/* </div> */}
              </div>
            </div>


          </div>
          <div class="row" style={{ marginLeft: 10 }}>
            {
              this.state.busStop ?

                <div class="item col-md-5" style={{ margin: 30, backgroundColor: "#362A41" }}>
                  <h3>BusStop: {this.state.busStop.name}</h3>
                  <div>
                    <span>
                      Arriving Buses : {this.state.busStop.arrivingBuses.map((b, i) => {
                        return (
                          i === this.state.busStop.arrivingBuses.length - 1 ?
                            <span>
                              &nbsp;{b.busNumber}.
                            </span>
                            :
                            <span>
                              &nbsp;{b.busNumber},&nbsp;
                            </span>
                        )
                      })}
                    </span>
                  </div>
                </div>
                :
                <></>
            }
          </div>
        </div>

      </div>
    );
  }
}

export default Transport;
