
import axios from 'axios';

export const addSubjects = async (user_id, data) => {
    return axios
        .post(`/xakal/class/addSub/${user_id}`, data)
        .then((res) => {
            if (res.status == 304) {
                console.log({ error: res.data })
                return alert(res.data.msg)
            }
            alert(res.data.msg)
            return res.data;
        })
        .catch(error => alert(error.response.data))
}

export const getSubjectsByDepartment = (dept) => {
    return axios
        .post(`/xakal/class/getSubjectsByDepartment/${dept}`)
        .then((res) => {
            if (res.status !== 200) {
                return alert(res.data.msg)
            }
            console.log('allSub', res.data)
            return res.data;
        })
        .catch(e => alert(e))
}

export const getAllSubjects = () => {
    return axios
        .post(`/xakal/class/getAllSubjects`)
        .then((res) => {
            if (res.status !== 200) {
                return alert(res.data.msg)
            }
            return res.data;
        })
        .catch(e => alert(e))
}

export const getAllStaffsByDepartment = (deptId) => {
    return axios
        .get(`/xakal/staffdetail/department/${deptId}`)
        .then((res) => {
            return res.data;
        })
        .catch(e => alert(e))
}

export const allocateSubjects = (data, userId) => {
    return axios
        .post(`/xakal/class/allocateSubjects/${userId}`, { data })
        .then((res) => {
            return res.data;
        })
        .catch(e => alert(e))
}

export const createClass = (data, userId) => {
    return axios
        .post(`/xakal/class/addClass/${userId}`, data)
        .then((res) => {
            if(!res.data) return;
            alert(res.data.msg)
            return res.data.data;
        })
        .catch(error => alert(error.response.data))
}

export const getAllClass = (userId) => {
    return axios
        .get(`/xakal/class/getClassByQuery/${userId}`)
        .then((res) => {
            if(!res.data) return;
            return res.data;
        })
        .catch(error => alert(error.response.data))
}

export const getClassById = (id, userId) => {
    return axios
        .get(`/xakal/class/getClassById/${id}/${userId}`)
        .then((res) => {
            if(!res.data) return;
            alert(res.data.msg)
            return res.data;
        })
        .catch(error => alert(error.response.data))
}

export const getClassByQuery = (query) => {
    return axios
        .get(`/xakal/class/getClassByQuery`, {
            params: query
        })
        .then((res) => {
            if(!res.data) return;
            return res.data.data;
        })
        .catch(error => alert(error.response.data))
}

export const editClass = (id, userId, data) => {
    return axios
        .put(`/xakal/class/updateClass/${id}/${userId}`, data)
        .then((res) => {
            if(!res.data) return;
            alert(res.data.msg)
            return res.data;
        })
        .catch(error => alert(error.response.data))
}

export const deleteClasses = (userId, data) => {
    return axios
        .put(`/xakal/class/deleteBatch/${userId}`, data)
        .then((res) => {
            if(!res.data) return;
            alert(res.data.msg);
        })
        .catch(error => alert(error.response.data))
}