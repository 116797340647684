import React, { Component } from "react";
// //import logo from "../images/xakal-logo.png";
import vector from "../images/wave.svg";
import xalogo from "../images/xalogo.svg";
import "../styles/login.css";
import "../styles/utils.css";
import axios from "axios";
import { Redirect } from "react-router-dom";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idClassName: "",
      passwordClassName: "",
      errorID: "",
      errorPassword: "",
      loginID: "",
      password: "",
      studentRedirect: false,
      staffRedirect: false,
      managementRedirect: false,
      hodRedirect: false,
      nonTeaching: false,
      xakalAdmin: false,
      userID: (name) =>
        `; ${document.cookie}`.split(`; ${name}=`).pop().split(";").shift(),
    };
  }

  componentDidMount() {
    (() => {
      console.log(process.env.PUBLIC_URL);
      window.onload = function() {
        if(!window.location.hash) {
          window.location = window.location + '#';
          window.location.reload();
        }
      }
      axios
        .get(`${process.env.PUBLIC_URL}/xakal/studentdetail/verifyit`, {
          withCredentials: true,
        })
        .then((e) => {
          if (e.data.ok && e.data.message === "Access token needed") {
            window.location.href = "/";
            setTimeout(() => window.location.reload(), 1000);
          }
        });
    })();

    if (this.state.loginID) {
      this.setState({ idClassName: "has-val" });
    }
    if (this.state.password) {
      this.setState({ passwordClassName: "has-val" });
    }
  }

  /**
   * Triggers when username is given
   * Adds has-val className if the value is given
   * @param event contains the change event object
   */
  handleIDChange(event) {
    if (event.target.value) {
      this.setState({ idClassName: "has-val", loginID: event.target.value });
    } else {
      this.setState({
        idClassName: "",
        errorID: "has-error",
        loginID: event.target.value,
      });
    }
  }

  /**
   * Triggers when password is given
   * Adds has-val className if the value is given
   * @param event contains the change event object
   */
  handlePasswordChange(event) {
    if (event.target.value) {
      this.setState({
        passwordClassName: "has-val",
        password: event.target.value,
      });
    } else {
      this.setState({
        passwordClassName: "",
        errorPassword: "has-error",
        password: event.target.value,
      });
    }
  }

  resetLogin() {
    this.setState({
      studentRedirect: false,
      managementRedirect: false,
      staffRedirect: false,
      hodRedirect: false,
    });
  }

  /**
   * Triggers when the form is submitted
   * Checks whether the values are entered properly
   */
  formSubmit = (event) => {
    event.preventDefault();
    if (this.state.loginID !== "" && this.state.password !== "") {
      let userID = this.state.loginID.toUpperCase();
      let password = this.state.password;

      axios
        .post(`${process.env.PUBLIC_URL}/xakal/user/${this.state.loginID.toUpperCase()}`, { userID, password })
        .then((response) => {
          if (response && response.data) {
            this.resetLogin();
            if (response.data.ok) {
              this.setState({
                userRole: response.data.user.userRole,
                userDetails: response.data.user,
              });
              axios.defaults.headers.common["x-access-token"] =
                response.data.token;

              //set cookie
              document.cookie = `userid=${response.data.userID};`;

              if (response.data.user.userRole === "student") {
                this.setState({
                  studentRedirect: true,
                  loginID: response.data.user.userID,
                });
              }
               else if (response.data.user.userRole === "staff") {
                this.setState({
                  staffRedirect: true,
                  loginID: response.data.user.userID,
                });
              } else if (response.data.user.userRole === "management") {
                this.setState({
                  managementRedirect: true,
                  loginID: response.data.user.userID,
                });
                console.log('test')
              }
              else if (response.data.user.userRole === "hod") {
                this.setState({
                  hodRedirect: true,
                  loginID: response.data.user.userID,
                });
               } else if (response.data.user.userRole === "non-teaching") {
                this.setState({
                  nonTeaching: true,
                  loginID: response.data.user.userID,
                });
              } else if (response.data.user.userRole === "xakal-admin") {
                this.setState({
                  xakalAdmin: true,
                  loginID: response.data.user.userID,
                });
              }
               else {
                alert("Invalid user");
              }
            } else {
              alert("Incorrect password");
            }
          } else {
            alert("Invalid user");
          }
        })
        .catch((err) => {
          alert(err);
        });
    } else {
      alert("Please fill in the fields");
    }
  };

  render() {
    if (this.state.studentRedirect) {
      return (
        <Redirect
          to={{
            pathname: "students-portal/whiteboard",
            state: {
              userID: this.state.loginID || this.state.userID("userid"),
              userRole: "student",
              userDetails: this.state.userDetails,
            },
          }}
        />
      );
    } else if (this.state.staffRedirect) {
      return (
        <Redirect
          to={{
            pathname: "staff-portal/whiteboard",
            state: {
              userID: this.state.loginID || this.state.userID("userid"),
              userRole: "staff",
              userDetails: this.state.userDetails,
            },
          }}
        />
      );
    } else if (this.state.managementRedirect) {
      return (
        <Redirect
          to={{
            // pathname: 'management-portal/dashboard',
            pathname: "management-portal/whiteboard",
            state: {
              userID: this.state.loginID || this.state.userID("userid"),
              userRole: "management",
              userDetails: this.state.userDetails,
            },
          }}
        />
      );
    } else if (this.state.hodRedirect) {
      return (
        <Redirect
          to={{
            // pathname: 'hod-portal/dashboard',
            pathname: "hod-portal/whiteboard",
            state: {
              userID: this.state.loginID || this.state.userID("userid"),
              userRole: "hod",
              userDetails: this.state.userDetails,
            },
          }}
        />
      );
    } else if (this.state.nonTeaching) {
      return (
        <Redirect
          to={{
            // pathname: 'non-teaching-portal/dashboard',
            pathname: "non-teaching-portal/whiteboard",
            state: {
              userID: this.state.loginID || this.state.userID("userid"),
              userRole: this.state.userRole,
              userDetails: this.state.userDetails,
            },
          }}
        />
      );
    } else if (this.state.xakalAdmin) {
      return (
        <Redirect
          to={{
            pathname: "xakal-admin",
            state: {
              userID: this.state.loginID || this.state.userID("userid"),
              userRole: this.state.userRole,
              userDetails: this.state.userDetails,
            },
          }}
        />
      );
    } else {
      return (
        <div>
          {/* xalogo */}
          {/* <img src={vector} className="wave" alt="Wave" /> */}

          <div className="container-login100 waveBg">

            <div className="wrap-login100 p-b-20">

              <form
                className="login100-form"
                onSubmit={this.formSubmit.bind(this)}
              >

                <div className="d-flex">
                  <div className="">
                    <div className="login100-form-avatar">
                      <img src={xalogo} className="logo" alt="XAKAL" />
                    </div>
                    <p className="login100-form-title font-face-title">Student login</p>
                    <p className="textreg  font-face-gm"  >Registration Number</p>
                    <div className={"wrap-input100" + this.state.errorID} >

                      <input
                        autoComplete="off"
                        value={this.state.loginID}
                        className={"input100 " + this.state.idClassName}
                        type="text"
                        onChange={this.handleIDChange.bind(this)}
                        name="loginID"
                      />
                      {/* <span className={"focus-input100"} data-placeholder="ID"></span> */}
                    </div>

                    <p className="textpass font-face-gm" >Password</p>
                    <div
                      className={
                        "wrap-input100 m-b-50 " + this.state.errorPassword
                      }
                    >
                      <input
                        autoComplete="off"
                        value={this.state.password}
                        className={"input100 " + this.state.passwordClassName}
                        onChange={this.handlePasswordChange.bind(this)}
                        type="password"
                        name="pass"
                      />

                      {/* <span
                  className="focus-input100"
                  data-placeholder="Password"
                ></span> */}
                    </div>
                    <p className="forgot-password"> Forgot your password? <span> <a href="#">Click here</a>
                    </span></p>
                    <div className="container-login100-form-btn">
                      <button type="submit" className="login100-form-btn">
                        Login
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

        </div>
      );
    }
  }
}

export default Login;
