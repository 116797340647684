import React, { Component } from 'react';

class StaffCodeList extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div>
                {
                    this.props.subjects.length
                        ?
                        this.props.subjects.map((s, i) => (
                            <table>
                                <tr>
                                    <td>
                                        {s.subject ? s.subject.code : ""}
                                    </td>
                                </tr>
                            </table>
                        ))
                        :
                        <></>
                }
            </div>
        );
    }
}

export default StaffCodeList;